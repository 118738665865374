import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME } from 'Constants';
import React from 'react';

interface Props {
  domain: string;
}

const DialogConfirmResetProxiedServices = ({ domain }: Props) => {
  return (
    <div>
      <p>
        You are about to reset the proxy configuration of <b>{domain}</b>. This
        action will remove all proxy settings for all services and will make the
        configuration &quot;default&quot;, as if you had just installed{' '}
        {PRODUCT_NAME}.
      </p>
      <p>
        Mind that in order to obtain the list of third-party services and to
        avoid typing them manually, enable the &quot;all failed requests
        proxy&quot; toggle. You can disable it shortly after you get the list of
        all services if you don&apos;t want to proxy all services but just get a
        service list.
      </p>
      <p>
        Also mind that services that were proxied within the selected time frame
        on the Dashboard will still show up in the proxied services table, but
        their configuration will be defaulted.
      </p>
    </div>
  );
};

interface DialogArguments extends Props {
  onConfirm?: () => void;
}

export const showConfirmResetProxiedServicesDialog = (args: DialogArguments) =>
  showDialog({
    title: `Do you want to reset a proxy config of ${args.domain}?`,
    icon: 'settings',
    content: <DialogConfirmResetProxiedServices {...args} />,
    confirmButtonText: 'Reset',
    confirmButtonClass: 'danger',
    cancelButtonText: 'Cancel',
    onConfirmClick: args.onConfirm,
  });
