import { UiProperty } from '@dataunlocker/pkg-types';
import { updatePropertyProxyFlags } from 'Api';
import { showEnableUnhealthyPropertyDialog } from 'Components/Dialog/EnableUnhealthyProperty';
import { PRODUCT_NAME, ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH } from 'Constants';
import { useHistory } from 'react-router-dom';
import { Toast } from 'toaster-js';
import { getPropertyHealthSummary } from 'Utils';

/** Unifies proxy flags update behavior across pages. */
export const updatePropertyProxyFlagsHelper = ({
  setLoading,
  property,
  flags,
  onUpdate,
  history,
}: {
  setLoading: (loading: boolean) => any;
  property: UiProperty;
  flags: Parameters<typeof updatePropertyProxyFlags>[1];
  onUpdate: Function;
  history: ReturnType<typeof useHistory>;
}) => {
  const actualPropertyUpdate = async () => {
    setLoading(true);
    const result = await updatePropertyProxyFlags(property.id, flags);
    setLoading(false);

    if (result.errorMessage) {
      new Toast(result.errorMessage, Toast.TYPE_ERROR);
      return;
    }

    if (!property.proxyEnabled && !!result.response?.property?.proxyEnabled) {
      new Toast(
        `${PRODUCT_NAME} proxy was enabled for ${property.host}.`,
        Toast.TYPE_DONE
      );
    }
    onUpdate();
  };

  if (
    !!property.proxyEnabled === false &&
    !!flags.proxyEnabled &&
    !getPropertyHealthSummary(property).isHealthy
  ) {
    showEnableUnhealthyPropertyDialog({
      propertyHost: property.host,
      onCheckHealthClick: () => {
        history.push(ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH(property.id));
      },
      onComplete: actualPropertyUpdate,
    });
  } else {
    actualPropertyUpdate();
  }
};
