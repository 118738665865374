import React, { useState, useCallback } from 'react';
import Card, { CardHeader } from 'Components/Common/Card';
import MessageBlock from 'Components/Common/MessageBlock';
import styles from './styles.module.scss';
import { useSetRecoilState, propertiesListVersionState } from 'State';
import { PRODUCT_NAME } from 'Constants';
import { addPropertyService } from 'Api';
import { Toast } from 'toaster-js';
import { UiProperty } from '@dataunlocker/pkg-types';

interface AddNewServiceCardProps {
  onClose: () => void;
  property: UiProperty;
}

const AddNewServiceCard = ({ onClose, property }: AddNewServiceCardProps) => {
  const [loading, setLoading] = useState(false);
  const [addServiceName, setAddServiceName] = useState(
    'www.googletagmanager.com'
  );

  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const onAddServiceNameChanges = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setAddServiceName(event.currentTarget.value),
    []
  );
  const onAddProxyClick = useCallback(async () => {
    setLoading(true);
    const response = await addPropertyService(property.id, {
      domainName: addServiceName,
    });
    setLoading(false);

    if (response.errorMessage) {
      new Toast(response.errorMessage, Toast.TYPE_ERROR);
      return;
    }
    setPropertiesListVersion(Math.random());
    onClose();
  }, [property.id, addServiceName, setPropertiesListVersion, onClose]);

  return (
    <Card>
      <CardHeader title="Add a new service configuration" icon="setup" />
      <div>
        {property.proxyNotConfiguredServices ? (
          <MessageBlock type="warning">
            You don&apos;t need to manually add service configurations unless
            you disable proxy for &quot;all other services&quot;. Once{' '}
            {PRODUCT_NAME} unblocks the first traffic to any service, it
            automatically adds it to the proxied services table if it was
            missing.
          </MessageBlock>
        ) : (
          <MessageBlock type="info">
            Tip: you can enable proxy for &quot;all other services&quot; to
            quickly populate the proxied services table from any currently
            failing 3rd-party requests on your website. These services will
            remain configured once added to the table.
          </MessageBlock>
        )}
        <div>
          <span className={styles.margin}>3rd-party service domain name</span>{' '}
          <input
            value={addServiceName}
            onChange={onAddServiceNameChanges}
            placeholder="F.e. www.google-analytics.com"
          />
        </div>
        <button disabled={loading || !addServiceName} onClick={onAddProxyClick}>
          Add to configuration
        </button>
        <button disabled={loading} className="secondary" onClick={onClose}>
          Cancel
        </button>
      </div>
    </Card>
  );
};

export default AddNewServiceCard;
