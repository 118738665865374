import { UiProperty, UiTempProperty } from '@dataunlocker/pkg-types';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import PageError from 'Components/Common/Pages/Error';
import PageHeader from 'Components/Common/Pages/Header';
import {
  ROUTE_PROPERTIES_ADD,
  ROUTE_PROPERTY_DASHBOARD,
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
} from 'Constants';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { selectedPropertyIdState, useSetRecoilState } from 'State';
import { formatBytes, formatPercentage } from 'Utils';
import styles from './styles.module.scss';

interface Props {
  properties: UiProperty[];
  tempProperties: UiTempProperty[];
}

const PagePropertyList = ({ properties, tempProperties }: Props) => {
  const setPropertyIdState = useSetRecoilState(selectedPropertyIdState);
  const history = useHistory();
  const selectProperty = (id: string) => {
    setPropertyIdState(id);
    history.push(ROUTE_PROPERTY_DASHBOARD(id));
  };
  const selectTempProperty = (id: string) => {
    setPropertyIdState(id);
    history.push(ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(id));
  };
  const addPropertyClick = useCallback(() => {
    history.push(ROUTE_PROPERTIES_ADD);
  }, [history]);
  return !properties.length && !tempProperties.length ? (
    <PageError
      color="regular"
      icon="www"
      message="You don't have any properties yet."
      actionText="Add property"
      onActionClick={addPropertyClick}
    />
  ) : (
    <div className={styles.center}>
      <PageHeader icon="www" title={`My properties`} />
      <div className={styles.containerCenter}>
        {tempProperties.map((property) => (
          <Card
            hoverable
            key={property.id}
            onClick={() => selectTempProperty(property.id)}
          >
            <CardHeader title={property.host} />
            <div>Click to complete the setup of {property.host}.</div>
          </Card>
        ))}
        {properties.map((property) => {
          const proxyEnabled =
            property.proxyEnabled &&
            property.bytesProxied <= property.bytesPrepaid;
          return (
            <Card
              hoverable
              key={property.id}
              onClick={() => selectProperty(property.id)}
            >
              <CardHeader title={property.host}>
                <Icon
                  className={proxyEnabled ? styles.enabled : styles.disabled}
                  image={proxyEnabled ? 'checkmark' : 'close'}
                  size="small"
                  margin="small"
                />
                <span
                  className={proxyEnabled ? styles.enabled : styles.disabled}
                >
                  {proxyEnabled ? 'enabled' : 'disabled'}
                </span>
              </CardHeader>
              <div>
                <div>
                  <span className={styles.emphasis}>
                    Proxied/prepaid traffic:{' '}
                  </span>
                  <span>
                    {formatBytes(property.bytesProxied)}/
                    {formatBytes(property.bytesPrepaid)} (
                    {formatPercentage(
                      property.bytesProxied / property.bytesPrepaid
                    )}
                    )
                  </span>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
      <button
        className={styles.addPropertyButton}
        onClick={() => history.push(ROUTE_PROPERTIES_ADD)}
      >
        Add Property
      </button>
    </div>
  );
};

export default PagePropertyList;
