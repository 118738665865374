import Icon from 'Components/Common/Icon';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  icon: string;
  inProgress: boolean;
  done: boolean;
  isLast?: boolean;
}

const StepperStep = ({ icon, inProgress, done, isLast = false }: Props) => {
  return (
    <div className={styles.container}>
      <div
        className={`${styles.step}${inProgress ? ` ${styles.inProgress}` : ''}${
          done ? ` ${styles.done}` : ''
        }`}
      >
        <Icon image={icon} />
      </div>
      <div
        className={`${styles.strip}${done ? ` ${styles.done}` : ''}${
          isLast ? ` ${styles.hidden}` : ''
        }`}
      ></div>
    </div>
  );
};

export default StepperStep;
