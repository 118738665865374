import {
  PropertyProxyEndpoint,
  UiProperty,
  UiTempProperty,
} from '@dataunlocker/pkg-types';
import { getPropertyInstallScript } from 'Api';
import Button from 'Components/Common/Button';
import Card, { CardHeader } from 'Components/Common/Card';
import CopyButton from 'Components/Common/CopyButton';
import Icon from 'Components/Common/Icon';
import MessageBlock from 'Components/Common/MessageBlock';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import {
  LINK_DOCS_SCRIPT_DYNAMIC,
  LINK_DOCS_SCRIPT_STATIC,
  LINK_DOCS_SCRIPT_TESTING,
  PRODUCT_API_URL,
  PRODUCT_NAME,
  ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH,
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
} from 'Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatBytes } from 'Utils';
import NeedHelpFooter from '../NeedHelpFooter';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty | UiTempProperty;
}

const getTheMostHealthyEndpoint = (property: UiTempProperty) =>
  property.proxyEndpoints?.find(
    (e) =>
      e.health.ok && !e.blacklistedIn?.length && !!e.health.isBehindTheProxy
  ) ||
  property.proxyEndpoints?.find(
    (e) => e.health.ok && !e.blacklistedIn?.length
  ) ||
  property.proxyEndpoints?.find((e) => e.health.ok) ||
  property.proxyEndpoints?.[0];

const getProxyEndpointUrl = (
  endpoint: PropertyProxyEndpoint,
  property: UiTempProperty
) =>
  endpoint.type === 'dns'
    ? `${endpoint.urlKey}.${property.host}`
    : `${property.host}/${endpoint.urlKey}`;

const PropertySetupScript = ({ property }: ComponentProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [selectedScriptVersion, setSelectedScriptVersion] = useState('latest');
  const [scriptVersions, setScriptVersions] = useState(['latest']);
  const [scriptFetchErrorMessage, setScriptFetchErrorMessage] = useState('');
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [useDebugScript, setUseDebugScript] = useState(false);
  const [encodeScript, setEncodeScript] = useState(true);
  const [scriptHtml, setScriptHtml] = useState('');
  const [scriptGzipSize, setScriptGzipSize] = useState(0);
  const [isSetupForCi, setIsSetupForCi] = useState(false);
  const [selectedEndpointUrlKey, setSelectedEndpointUrlKey] = useState('');

  useEffect(() => {
    if (isSetupForCi) {
      const extraUrlParams: string[] = [];
      if (useDebugScript) {
        extraUrlParams.push('debug=true');
      }
      if (!encodeScript) {
        extraUrlParams.push('encode=false');
      }
      if (selectedEndpointUrlKey) {
        extraUrlParams.push(`endpointUrlKey=${selectedEndpointUrlKey}`);
      }
      setScriptHtml(
        `# This shell command injects ${PRODUCT_NAME}'s script into production-ready index.html file.\n` +
          `# Manage: ${window.location.href}\n\n` +
          `output=$(curl --fail -F html=@index.html '${PRODUCT_API_URL}/properties/${
            property.id
          }/scripts/latest/inject${
            extraUrlParams.length ? '?' + extraUrlParams.join('&') : ''
          }') && echo "$output" > index.html || echo "Failed to inject DataUnlocker's script"`
      );
      return;
    }
    setLoading(true);
    getPropertyInstallScript(property.id, {
      version: selectedScriptVersion,
      debug: useDebugScript,
      encoded: encodeScript,
      endpointUrlKey:
        selectedEndpointUrlKey || getTheMostHealthyEndpoint(property).urlKey,
    }).then(({ errorMessage, response }) => {
      setLoading(false);
      if (errorMessage) {
        setScriptFetchErrorMessage(errorMessage);
      } else {
        if (!response?.htmlCode) {
          setScriptFetchErrorMessage('Cannot get your script code 🤔');
        } else {
          setScriptFetchErrorMessage('');
          setScriptHtml(response?.htmlCode);
          setScriptVersions(response.allScriptVersions);
          setScriptGzipSize(response.size);
          if (selectedScriptVersion === 'latest') {
            setSelectedScriptVersion(response.version);
          }
        }
      }
    });
  }, [
    // Changing one of these will re-run effect
    property.id,
    isSetupForCi,
    selectedScriptVersion,
    useDebugScript,
    encodeScript,
    selectedEndpointUrlKey,
    property,
  ]);
  const onSelectScriptVersion = useCallback((event) => {
    setSelectedScriptVersion(event.target.value);
  }, []);
  const onSelectedEndpointValueChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedEndpointUrlKey(event.currentTarget.value);
    },
    []
  );
  const onUseCiClick = useCallback(() => {
    const isCi = !isSetupForCi;
    setIsSetupForCi(isCi);
  }, [isSetupForCi]);

  const selectedEndpoint = property.proxyEndpoints?.find(
    (e) =>
      e.urlKey ===
      (selectedEndpointUrlKey || getTheMostHealthyEndpoint(property).urlKey)
  );
  const selectedEndpointIsHealthy = !!selectedEndpoint?.health.ok;
  const selectedEndpointIsBlacklisted = !!selectedEndpoint?.blacklistedIn
    ?.length;

  return (
    <div>
      <div className={styles.endpointBox}>
        <table className={styles.topTable}>
          <tbody>
            <tr>
              <td>
                <div>Proxy endpoint</div>
                <div className={styles.sub}>
                  used by {isSetupForCi ? 'injected script' : 'this script'}
                </div>
              </td>
              <td>
                <div className={styles.endpointConfigBlock}>
                  <div>
                    <select
                      value={selectedEndpointUrlKey}
                      onChange={onSelectedEndpointValueChange}
                    >
                      <option value="">
                        auto (
                        {getProxyEndpointUrl(
                          getTheMostHealthyEndpoint(property),
                          property
                        )}
                        )
                      </option>
                      {property.proxyEndpoints?.map((endpoint) => (
                        <option key={endpoint.urlKey} value={endpoint.urlKey}>
                          {getProxyEndpointUrl(endpoint, property)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <div
                      className={
                        selectedEndpointIsBlacklisted ||
                        !selectedEndpointIsHealthy
                          ? styles.red
                          : styles.green
                      }
                    >
                      <Icon
                        image={
                          selectedEndpointIsHealthy &&
                          !selectedEndpointIsBlacklisted
                            ? 'checkmark'
                            : 'error'
                        }
                        margin="right-small"
                      />{' '}
                      <span>
                        The selected proxy endpoint is{' '}
                        {selectedEndpointIsBlacklisted
                          ? 'blacklisted'
                          : selectedEndpointIsHealthy
                          ? 'healthy'
                          : 'unhealthy'}
                      </span>
                    </div>
                    <div className={styles.sub}>
                      Endpoints are configured at the{' '}
                      <Link
                        to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)}
                      >
                        proxy step
                      </Link>
                      .
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Card>
        <CardHeader
          icon="code"
          title={
            isSetupForCi
              ? `Inject the script during your builds`
              : `Install the static script version`
          }
        />
        <div className={styles.twoCols}>
          <div className={styles.info}>
            {isSetupForCi ? (
              <span>
                By design, {PRODUCT_NAME} requires installing a synchronous
                script, which doesn&apos;t receive updates until updated
                manually. To make it automatic, add HTML code patching to your
                continuous integration pipeline to always use the latest script.{' '}
                <SafeExternalLink href={LINK_DOCS_SCRIPT_DYNAMIC}>
                  Learn more
                </SafeExternalLink>
              </span>
            ) : (
              <span>
                Add this script to all pages you want to unblock. {PRODUCT_NAME}{' '}
                will search for it in the <b>root of {property.host}</b> for
                health check purposes. Learn more about what this script does in{' '}
                {PRODUCT_NAME}&apos;s{' '}
                <SafeExternalLink href={LINK_DOCS_SCRIPT_STATIC}>
                  docs
                </SafeExternalLink>
                . This script&apos;s gzip size is{' '}
                <b>{formatBytes(scriptGzipSize)}</b>.
              </span>
            )}
          </div>
          <div className={styles.toolbarWrapper}>
            <Icon
              image="settings"
              size="huge"
              margin="left-regular"
              hoverable
              onClick={() => setSettingsOpened(!settingsOpened)}
            />
            <CopyButton text={scriptHtml} />
          </div>
        </div>
        {loading ? (
          <Icon image="loading" size="huge" margin="center" />
        ) : (
          <div>
            <code className={`block ${styles.scriptCode}`}>{scriptHtml}</code>
          </div>
        )}
        {scriptFetchErrorMessage && (
          <MessageBlock type="error" display="content">
            {scriptFetchErrorMessage}
          </MessageBlock>
        )}
        {settingsOpened && (
          <div className={styles.settingsContainer}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <select
                      value={isSetupForCi ? 'latest' : selectedScriptVersion}
                      onChange={onSelectScriptVersion}
                    >
                      {isSetupForCi ? (
                        <option key="latest" value="latest">
                          Latest
                        </option>
                      ) : (
                        scriptVersions.map((version) => (
                          <option key={version} value={version}>
                            {version}
                          </option>
                        ))
                      )}
                    </select>
                  </td>
                  <td>
                    <div>Script version</div>
                    <div className={styles.sub}>
                      Latest script versions bring support for new features and
                      possible bug fixes. Normally, you don&apos;t need to
                      update unless it affects you.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ToggleSwitch
                      checked={useDebugScript}
                      onChange={() => setUseDebugScript(!useDebugScript)}
                    />
                  </td>
                  <td>
                    <div>Use debug script</div>
                    <div className={styles.sub}>
                      Invoke{' '}
                      <b className={styles.monospace}>
                        window.localStorage.setItem(&quot;__du&quot;, &quot;
                        {'{}'}&quot;)
                      </b>{' '}
                      to get more logs about what {PRODUCT_NAME}&apos;s script
                      does under the hood.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ToggleSwitch
                      checked={encodeScript}
                      onChange={() => setEncodeScript(!encodeScript)}
                    />
                  </td>
                  <td>
                    <div>Encode script&apos;s content</div>
                    <div className={styles.sub}>
                      Unencoded script can be an easy find for some content
                      blockers (somewhen in the future). Use unencoded script if
                      encoding doesn&apos;t work for you.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Card>
      <div className={styles.warnsBlock}>
        {!isSetupForCi && (
          <div>
            <div>
              <Icon image="warning" size="small" margin="right-small" />
              <div>
                This script can break things.{' '}
                <SafeExternalLink href={LINK_DOCS_SCRIPT_TESTING}>
                  Test it
                </SafeExternalLink>{' '}
                before using in production.
              </div>
            </div>
            <div>
              <Icon image="warning" size="small" margin="right-small" />
              <div>
                Ensure this script <b>precedes all scripts</b> you want to
                unblock.
              </div>
            </div>
            <div>
              <Icon image="info" size="small" margin="right-small" />
              <div>
                Put this script directly to your HTML synchronous code.{' '}
                <SafeExternalLink href={LINK_DOCS_SCRIPT_STATIC}>
                  Learn why
                </SafeExternalLink>
                .
              </div>
            </div>
            <div>
              <Icon image="info" size="small" margin="right-small" />
              <div>
                Uncheck «encode script» if your CMS/etc can&apos;t handle script
                encoding.
              </div>
            </div>
          </div>
        )}
        {isSetupForCi && (
          <div>
            <div>
              <Icon image="info" size="small" margin="right-small" />
              <div>
                This command will patch your production-ready <b>index.html</b>{' '}
                file by uploading and immediately downloading it from{' '}
                {PRODUCT_NAME}
                &apos;s API endpoint.
              </div>
            </div>
            <div>
              <Icon image="info" size="small" margin="right-small" />
              <div>
                If you prefer to not to use continuous integration,{' '}
                {PRODUCT_NAME} may occasionally remind you via email to update
                the script, if it impacts you.
              </div>
            </div>
            <div>
              <Icon image="info" size="small" margin="right-small" />
              <div>
                This command will <b>keep</b> the original file in case of any
                parsing errors or API outages. {PRODUCT_NAME} won&apos;t notify
                you in case <code>/inject</code> request fails.
              </div>
            </div>
            <div>
              <Icon image="error" size="small" margin="right-small" />
              <div>
                {PRODUCT_NAME}&apos;s HTML code patching is smart enough to
                detect its own script in the HTML code (even if you format or
                change it). If detected, the existing script will be replaced
                with the latest one. You can use this feature to ensure the
                script is installed in 100% of cases, even when this endpoint
                fails.
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          type="secondary"
          image="backward"
          onClick={() =>
            history.push(ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id))
          }
        >
          Back to Proxy Setup
        </Button>
        <Button
          image={isSetupForCi ? 'code' : 'ci'}
          type="special"
          onClick={onUseCiClick}
        >
          {isSetupForCi
            ? 'Use Static Script Version'
            : 'Use Continuous Integration'}
        </Button>
        <Button
          image="forward"
          type="secondary"
          onClick={() =>
            history.push(ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH(property.id))
          }
        >
          Check Setup Health
        </Button>
      </div>
      <NeedHelpFooter property={property} />
    </div>
  );
};

export default PropertySetupScript;
