import {
  BytesPrepaidChangeReason,
  PurchaseState,
  UiProperty,
  UiPurchase,
} from '@dataunlocker/pkg-types';
import Icon from 'Components/Common/Icon';
import Table from 'Components/Common/Table';
import Tooltip from 'Components/Common/Tooltip';
import { showPropertyGetInvoiceDialog } from 'Components/Dialog/PropertyGetInvoice';
import { showRetryPropertyBillingDialog } from 'Components/Dialog/RetryPropertyBilling';
import React from 'react';
import { formatBytes, formatUsdCents, getPurchaseStateName } from 'Utils';
import styles from './styles.module.scss';

interface PurchasesTableProps {
  purchases: UiPurchase[];
  property?: UiProperty;
  selectedPurchaseIds?: string[];
  setSelectedPurchaseIds?: (ids: string[]) => any;
}

interface PurchaseTableRowData {
  at: number;
  purchase?: UiPurchase;
  trafficChange?: UiProperty['bytesPrepaidChanges'][42];
}

const purchaseIsInvoiceable = (purchase: UiPurchase) => {
  const expired =
    purchase.expiresAt < Date.now() && purchase.state === PurchaseState.pending;
  return (
    !expired &&
    purchase.state !== PurchaseState.reversed &&
    purchase.state !== PurchaseState.failed
  );
};

const mapPurchaseToTableRow = ({
  purchase,
  property,
  renderedRetriesForPropIds,
  selectedPurchaseIds,
  setSelectedPurchaseIds,
}: {
  purchase: UiPurchase;
  renderedRetriesForPropIds: Set<string>;
  property?: UiProperty;
  selectedPurchaseIds?: string[];
  setSelectedPurchaseIds?: (ids: string[]) => any;
}) => {
  const retryNeeded = !renderedRetriesForPropIds.has(purchase.propertyId || '');
  const isChecked = !!selectedPurchaseIds?.find((id) => id === purchase.id);
  if (purchase.isAuto && purchase.propertyId) {
    renderedRetriesForPropIds.add(purchase.propertyId);
  }
  return (
    <tr key={purchase.id}>
      <td
        className={`${styles.tableHeaderCheckboxContainer} ${styles.shrinkColumn}`}
      >
        {!!(
          property &&
          setSelectedPurchaseIds &&
          selectedPurchaseIds &&
          purchaseIsInvoiceable(purchase)
        ) && (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() =>
              setSelectedPurchaseIds(
                isChecked
                  ? selectedPurchaseIds.filter((id) => id !== purchase.id)
                  : selectedPurchaseIds.concat([purchase.id])
              )
            }
          />
        )}
      </td>
      <td className={styles.mono}>{purchase.id}</td>
      <td className={styles.shrinkColumn}>
        {new Date(purchase.paidAt || purchase.createdAt).toLocaleString()}
      </td>
      <td className={styles.shrinkColumn}>
        {purchase.isAuto
          ? 'Automatic purchase'
          : purchase.type === 'auth'
          ? 'Card authorization'
          : 'One-time purchase'}
      </td>
      <td
        className={
          purchase.state === PurchaseState.paid ||
          purchase.state === PurchaseState.refunded
            ? styles.success
            : purchase.state === PurchaseState.failed ||
              purchase.state === PurchaseState.reversed
            ? styles.fail
            : ''
        }
      >
        {getPurchaseStateName(purchase)}
      </td>
      <td>{formatUsdCents(purchase.value)}</td>
      <td>{formatBytes(purchase.bytesCredited)}</td>
      <td className={styles.actionsContainer}>
        {purchase.isAuto &&
          purchase.updatedAt < Date.now() - 60 * 1000 &&
          purchase.propertyId &&
          (purchase.state === PurchaseState.failed ||
            purchase.state === PurchaseState.new ||
            purchase.state === PurchaseState.pending) &&
          retryNeeded && (
            <Tooltip content="Retry this charge">
              <Icon
                image="refresh"
                hoverable
                margin="none"
                onClick={() =>
                  showRetryPropertyBillingDialog(purchase.propertyId!)
                }
              />
            </Tooltip>
          )}
        {property && purchaseIsInvoiceable(purchase) && (
          <Tooltip content="Get invoice for this purchase">
            <Icon
              image="invoice"
              hoverable
              margin="none"
              onClick={() =>
                showPropertyGetInvoiceDialog({
                  property,
                  purchases: [purchase],
                })
              }
            />
          </Tooltip>
        )}
        {purchase.expiresAt > Date.now() &&
          purchase.state === PurchaseState.pending &&
          purchase.redirectUrl && (
            <Tooltip content="Go to the payment page">
              <a href={purchase.redirectUrl}>
                <Icon margin="none" hoverable image="forward" />
              </a>
            </Tooltip>
          )}
      </td>
    </tr>
  );
};

const mapTrafficChangeToRow = (
  change: UiProperty['bytesPrepaidChanges'][42],
  property?: UiProperty
) => {
  return (
    <tr key={change.at}>
      <td></td>
      <td className={styles.mono}>
        {property
          ? property.id.slice(-8) +
            property.bytesPrepaidChanges
              .findIndex((c) => c === change)
              .toString()
              .padStart(4, '0')
          : '-'}
      </td>
      <td className={styles.shrinkColumn}>
        {new Date(change.at).toLocaleString()}
      </td>
      <td className={styles.shrinkColumn}>
        {change.on === BytesPrepaidChangeReason.init
          ? 'Initial traffic bonus'
          : change.on === BytesPrepaidChangeReason.gift
          ? change.by > 0
            ? '💜 Gift'
            : '💔 Deduction'
          : change.on === BytesPrepaidChangeReason.purchase
          ? 'Traffic purchase'
          : change.on === BytesPrepaidChangeReason.purchaseRevert
          ? 'Traffic purchase revert'
          : change.on === BytesPrepaidChangeReason.trafficTransfer
          ? 'Traffic transfer'
          : 'Unknown'}
      </td>
      <td>Executed</td>
      <td>-</td>
      <td>{formatBytes(change.by)}</td>
      <td></td>
    </tr>
  );
};

// Filters out traffic deltas which are associated with purchases.
const mergePurchasesAndTrafficChanges = (rows: PurchaseTableRowData[]) => {
  return rows
    .sort((p1, p2) => p2.at - p1.at)
    .filter((row, index, allRows) => {
      if (row.trafficChange) {
        const matchingPurchaseBefore =
          allRows[index - 1] && allRows[index - 1].purchase;
        const matchingPurchaseAfter =
          allRows[index + 1] && allRows[index + 1].purchase;
        if (
          matchingPurchaseBefore &&
          (matchingPurchaseBefore.id === row.trafficChange.purchase ||
            row.at === matchingPurchaseBefore.paidAt)
        ) {
          return false;
        }
        if (
          matchingPurchaseAfter &&
          (matchingPurchaseAfter.id === row.trafficChange.purchase ||
            row.at === matchingPurchaseAfter.paidAt)
        ) {
          return false;
        }
      }
      return true;
    });
};

const PurchasesListCard = ({
  purchases,
  property,
  selectedPurchaseIds,
  setSelectedPurchaseIds,
}: PurchasesTableProps) => {
  let renderedRetriesForPropIds = new Set<string>(); // A hacky way to render it only once, from up to down.

  const tableData = mergePurchasesAndTrafficChanges(
    new Array<PurchaseTableRowData>()
      .concat(purchases.map((p) => ({ at: p.updatedAt, purchase: p })))
      .concat(
        property
          ? property.bytesPrepaidChanges.map((c) => ({
              at: c.at,
              trafficChange: c,
            }))
          : []
      )
  );

  const invoiceablePurchases = purchases.filter(purchaseIsInvoiceable);
  return (
    <Table className={styles.summaryTable}>
      <thead>
        <tr>
          <th
            className={`${styles.tableHeaderCheckboxContainer} ${styles.shrinkColumn}`}
          >
            {!!(property && setSelectedPurchaseIds && selectedPurchaseIds) && (
              <input
                type="checkbox"
                checked={
                  !!purchases.length &&
                  invoiceablePurchases.length === selectedPurchaseIds.length
                }
                disabled={!invoiceablePurchases.length}
                onChange={() =>
                  setSelectedPurchaseIds(
                    invoiceablePurchases.length === selectedPurchaseIds.length
                      ? []
                      : invoiceablePurchases.map(({ id }) => id)
                  )
                }
              />
            )}
          </th>
          <th className={styles.shrinkColumn}>ID</th>
          <th className={styles.shrinkColumn}>Date</th>
          <th className={styles.shrinkColumn}>Type</th>
          <th>State</th>
          <th>Value</th>
          <th>Traffic equivalent</th>
          <th className={styles.shrinkColumn}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ purchase, trafficChange }) =>
          purchase
            ? mapPurchaseToTableRow({
                purchase,
                property,
                renderedRetriesForPropIds,
                selectedPurchaseIds,
                setSelectedPurchaseIds,
              })
            : trafficChange
            ? mapTrafficChangeToRow(trafficChange, property)
            : null
        )}
      </tbody>
    </Table>
  );
};

export default PurchasesListCard;
