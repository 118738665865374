import React from 'react';
import CardHeader from './CardHeader';

import styles from './styles.module.scss';

export { CardHeader };

interface CardProps {
  hoverable?: boolean;
  margin?: string;
  padding?: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => unknown;
}

const Card = ({
  hoverable = false,
  children = [],
  onClick,
  margin,
  className,
  padding,
}: CardProps) => {
  return (
    <div
      className={`${styles.card}${hoverable ? ` ${styles.hoverable}` : ''}${
        margin && styles[`margin-${margin}`]
          ? ' ' + styles[`margin-${margin}`]
          : ''
      }${
        padding && styles[`padding-${padding}`]
          ? ' ' + styles[`padding-${padding}`]
          : ''
      }${className ? ' ' + className : ''}`}
      onClick={onClick}
    >
      <div>{children}</div>
    </div>
  );
};

export default Card;
