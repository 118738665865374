import React from 'react';
import styles from './styles.module.scss';

export const RadioInput = ({
  name,
  value,
  label,
  checked,
  onChange,
  disabled = false,
}: {
  name: string;
  label: React.ReactNode;
  onChange?: () => void;
  disabled?: boolean;
  value?: string;
  checked: boolean;
}) => {
  return (
    <label className={styles.radioInput}>
      <input
        checked={checked}
        name={name}
        type="radio"
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <span className={styles.labelContent}>{label}</span>
    </label>
  );
};

export default RadioInput;
