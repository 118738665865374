import { UiPropertySecret, UiTempProperty } from '@dataunlocker/pkg-types';
import { propertyUpdateSecret } from 'Api';
import TextInput from 'Components/Common/TextInput';
import { showDialog } from 'Components/Dialog';
import { REGEXP_SECRET_VALUE } from 'Constants';
import React, { useCallback, useState } from 'react';
import { Toast } from 'toaster-js';

interface Props {
  property: UiTempProperty;
  secret: UiPropertySecret;
  onUpdate?: () => unknown;
}

const UpdatePropertySecret = ({
  secret,
  property,
  onUpdate,
}: Pick<Props, 'secret' | 'property'> & {
  onUpdate: (v: string) => unknown;
}) => {
  const [value, setValue] = useState('');
  const valueChange = useCallback(
    (v: string) => {
      setValue(v);
      onUpdate(v);
    },
    [onUpdate]
  );

  return (
    <div>
      <p>
        Enter a new value for the secret &quot;{secret.name}&quot; of property{' '}
        {property.host}. You will <b>never</b> be able to see this value again.
      </p>
      <TextInput
        validator={REGEXP_SECRET_VALUE}
        textarea
        label="New value"
        value={value}
        onChange={valueChange}
        wide
      />
    </div>
  );
};

export const showUpdatePropertySecretDialog = ({
  secret,
  property,
  onUpdate,
}: Props) => {
  let newValue = '';

  showDialog({
    title: `Update the value of "${secret.name}"`,
    icon: 'settings',
    content: (
      <UpdatePropertySecret
        secret={secret}
        property={property}
        onUpdate={(v) => (newValue = v)}
      />
    ),
    confirmButtonText: 'Update',
    cancelButtonText: 'Cancel',
    onConfirmClick: async () => {
      if (!newValue) {
        return false;
      }

      const response = await propertyUpdateSecret(property.id, {
        name: secret.name,
        value: newValue,
      });
      if (response.errorMessage) {
        new Toast(response.errorMessage, Toast.TYPE_ERROR);
        return false;
      } else {
        new Toast(
          `Property secret "${secret.name}" was updated.`,
          Toast.TYPE_DONE
        );
        onUpdate?.();
      }
    },
  });
};
