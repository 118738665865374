import Icon from 'Components/Common/Icon';
import React from 'react';
import styles from './styles.module.scss';

interface MessageConfig {
  type?: 'info' | 'warning' | 'error' | 'success';
  // 'card' = display as a card
  // 'content' = display inside a card
  display?: 'card' | 'content' | 'inline';
  children: React.ReactNode;
  className?: string;
}

const MessageBlock = ({
  type = 'info',
  display = 'card',
  children = [],
  className = '',
}: MessageConfig) => {
  return (
    <div
      className={`${styles.container} ${styles[`type-${type}`]} ${
        styles[`display-${display}`]
      } ${className}`}
    >
      <div>
        <Icon
          image={type === 'success' ? 'info' : type}
          size="regular"
          margin="right-regular"
        />
      </div>
      <span>{children}</span>
    </div>
  );
};

export default MessageBlock;
