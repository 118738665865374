import { GoogleOAuthProvider } from '@react-oauth/google';
import PageError from 'Components/Common/Pages/Error';
import PageWithPropertiesAndUser from 'Components/Common/Pages/WithPropertiesAndUser';
import PageWithPropertyAndUser from 'Components/Common/Pages/WithPropertyAndUser';
import PageFeedback from 'Components/Pages/Feedback';
import PageHelp from 'Components/Pages/Help';
import PagePropertyAdd from 'Components/Pages/Properties/Add';
import PagePropertyBilling from 'Components/Pages/Properties/Billing';
import PagePropertyDashboard from 'Components/Pages/Properties/Dashboard';
import PagePropertyDelete from 'Components/Pages/Properties/Delete';
import PagePropertyList from 'Components/Pages/Properties/List';
import PagePropertySettingsAutomationRefresh from 'Components/Pages/Properties/Settings/Automation/Refresh';
import PagePropertySettingsAutomationSecrets from 'Components/Pages/Properties/Settings/Automation/Secrets';
import PagePropertySettingsGeneral from 'Components/Pages/Properties/Settings/General';
import PagePropertySettingsSetup from 'Components/Pages/Properties/Settings/Setup';
import PagePropertySettingsUsers from 'Components/Pages/Properties/Settings/Users';
import PageSettingsGeneral from 'Components/Pages/Settings/General';
import PageSettingsPaymentMethods from 'Components/Pages/Settings/PaymentMethods';
import PageSignIn from 'Components/Pages/SignIn';
import {
  GOOGLE_OAUTH_CLIENT_ID,
  ROUTES_WITH_MENU_DISPLAYED,
  ROUTE_FEEDBACK,
  ROUTE_HELP,
  ROUTE_PROPERTIES_ADD,
  ROUTE_PROPERTIES_MY,
  ROUTE_PROPERTY_BILLING,
  ROUTE_PROPERTY_DASHBOARD,
  ROUTE_PROPERTY_DELETE,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_SECRETS,
  ROUTE_PROPERTY_SETTINGS_GENERAL,
  ROUTE_PROPERTY_SETTINGS_SETUP,
  ROUTE_PROPERTY_SETTINGS_USERS,
  ROUTE_ROOT,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_PAYMENT_METHODS,
  ROUTE_SIGN_IN,
} from 'Constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  currentUserState,
  sessionTokenState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'State';
import Header from './Header';
import styles from './styles.module.scss';

const Router = () => {
  const [superAdminRoutes, setSuperAdminRoutes] = useState<
    (() => ReactElement) | null
  >(null);
  const sessionCookie = useRecoilValue(sessionTokenState);
  const accountLoadable = useRecoilValueLoadable(currentUserState);
  const user =
    accountLoadable.state === 'hasValue' ? accountLoadable.contents : null;

  const isSuperAdmin = !!(user && user.isSuperAdmin);
  useEffect(() => {
    if (isSuperAdmin) {
      import('Components/SuperAdmin/Routes')
        .then((exp) => {
          for (const route of exp.ROUTES_WITH_MENU_DISPLAYED) {
            ROUTES_WITH_MENU_DISPLAYED.push(route);
          }
          setSuperAdminRoutes(exp.default);
        })
        .catch(console.error);
    }
  }, [isSuperAdmin]);

  return (
    <>
      <Header />
      <div className={styles.pageWrapper}>
        <Switch>
          <Route exact path={ROUTE_SIGN_IN}>
            <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
              <PageSignIn />
            </GoogleOAuthProvider>
          </Route>
          {!sessionCookie && (
            <Route path="*">
              <Redirect to={ROUTE_SIGN_IN} />
            </Route>
          )}
          <Route path={ROUTE_SETTINGS_GENERAL}>
            <PageSettingsGeneral user={user} />
          </Route>
          <Route
            path={ROUTE_PROPERTY_SETTINGS_AUTOMATION_SECRETS(':propertyId')}
          >
            <PageWithPropertyAndUser
              Page={PagePropertySettingsAutomationSecrets}
            />
          </Route>
          <Route
            path={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH(':propertyId')}
          >
            <PageWithPropertyAndUser
              Page={PagePropertySettingsAutomationRefresh}
            />
          </Route>
          <Route path={ROUTE_PROPERTY_SETTINGS_SETUP(':propertyId', ':type')}>
            <PageWithPropertyAndUser Page={PagePropertySettingsSetup} />
          </Route>
          <Route path={ROUTE_SETTINGS_PAYMENT_METHODS}>
            <PageSettingsPaymentMethods />
          </Route>
          <Route path={ROUTE_PROPERTIES_ADD}>
            <PageWithPropertiesAndUser Page={PagePropertyAdd} />
          </Route>
          <Route path={ROUTE_PROPERTY_DELETE(':propertyId')}>
            <PagePropertyDelete />
          </Route>
          <Route path={ROUTE_PROPERTY_DASHBOARD(':propertyId')}>
            <PagePropertyDashboard />
          </Route>
          <Route path={ROUTE_PROPERTY_BILLING(':propertyId')}>
            <PagePropertyBilling />
          </Route>
          <Route path={ROUTE_PROPERTY_SETTINGS_GENERAL(':propertyId')}>
            <PageWithPropertyAndUser Page={PagePropertySettingsGeneral} />
          </Route>
          <Route path={ROUTE_PROPERTY_SETTINGS_USERS(':propertyId')}>
            <PageWithPropertyAndUser Page={PagePropertySettingsUsers} />
          </Route>
          <Route path={ROUTE_PROPERTIES_MY}>
            <PageWithPropertiesAndUser Page={PagePropertyList} />
          </Route>
          <Route path={ROUTE_FEEDBACK}>
            <PageFeedback />
          </Route>
          <Route path={ROUTE_HELP}>
            <PageHelp />
          </Route>
          <Route exact path={ROUTE_ROOT}>
            <Redirect to={ROUTE_PROPERTIES_MY} />
          </Route>
          {superAdminRoutes}
          <Route path="*">
            <PageError
              message="This page does not exist!"
              icon="logoBroken"
              color="regular"
            />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default Router;
