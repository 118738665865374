import Tooltip from 'Components/Common/Tooltip';
import { PRODUCT_NAME } from 'Constants';
import React from 'react';

interface ProxyStatusTooltipProps {
  children: React.ReactNode;
}

const ProxyStatusTooltip = ({ children }: ProxyStatusTooltipProps) => {
  return (
    <Tooltip
      type="help"
      content={
        <>
          <p>
            <b>On demand</b> = try original request first and retry via proxy if
            failed or blocked. Despite {PRODUCT_NAME} tries its best to detect
            requests spoofing, failures or blocks, please note that the number
            of requests detected is close to 100% but never equals 100% due to
            some browsers, extensions or scripts may still outsmart{' '}
            {PRODUCT_NAME}.
          </p>
          <p>
            <b>Always</b> = try request via proxy first and retry via original
            URL if it fails. This guarantees that the request hits proxy first
            no matter how smart ad blocker software is. If neither{' '}
            {PRODUCT_NAME}&apos;s script nor proxy endpoint are blocked, the
            request is delivered.
          </p>
          <p>
            <b>Disabled</b> = never attempt to proxy this service, same as{' '}
            {PRODUCT_NAME} not being installed.
          </p>
        </>
      }
    >
      {children}
    </Tooltip>
  );
};

export default ProxyStatusTooltip;
