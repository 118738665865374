import { UiProperty } from '@dataunlocker/pkg-types';
import FeatureGuard from 'Components/Common/FeatureGuard';
import Icon from 'Components/Common/Icon';
import { PRODUCT_NAME } from 'Constants';
import React, { ReactElement, useState } from 'react';
import { DashboardView, DashboardViewService } from 'Types';
import { formatBytes, formatUsdCents, getUsdCentsPerBytes } from 'Utils';
import GroupHeaderRow from './GroupHeaderRow';
import ProxyStatusTooltip from './ProxyStatusTooltip';
import ServiceRow from './ServiceRow';
import styles from './styles.module.scss';
import UnconfiguredServicesRow from './UnconfiguredServicesRow';

interface SummaryTableProps {
  property: UiProperty;
  dashboardView: DashboardView;
}

const SummaryTable = ({ property, dashboardView }: SummaryTableProps) => {
  const [openedService, setOpenedService] = useState('');
  const serviceToServiceRow = (service: DashboardViewService) => (
    <ServiceRow
      key={service.name}
      service={service}
      property={property}
      opened={service.name === openedService}
      onClick={(serviceName: string) =>
        setOpenedService(serviceName === openedService ? '' : serviceName)
      }
    />
  );

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {/* Watch out for colSpans: replace colspan below <thead> as well */}
            <th rowSpan={2}>Service</th>
            <th rowSpan={2}>
              Proxy{' '}
              <FeatureGuard feature="alwaysProxy">
                <ProxyStatusTooltip>
                  <Icon
                    image="help"
                    margin="none"
                    size="small"
                    className={styles.minor}
                  />
                </ProxyStatusTooltip>
              </FeatureGuard>
            </th>
            <th colSpan={5}>Requests Proxied</th>
            <th colSpan={6}>Traffic Proxied</th>
          </tr>
          <tr>
            <th>2xx</th>
            <th>3xx</th>
            <th>4xx</th>
            <th>5xx</th>
            <th>Total</th>
            <th>2xx</th>
            <th>3xx</th>
            <th>4xx</th>
            <th>5xx</th>
            <th>Total</th>
            <th>Price</th>
          </tr>
          <tr className={styles.topTotalRow}>
            <th colSpan={2}>Total for the specified time range</th>
            <th>{dashboardView.totals.count[1]}</th>
            <th>{dashboardView.totals.count[2]}</th>
            <th className={dashboardView.totals.count[3] ? styles.error : ''}>
              {dashboardView.totals.count[3]}
            </th>
            <th className={dashboardView.totals.count[4] ? styles.error : ''}>
              {dashboardView.totals.count[4]}
            </th>
            <th>{dashboardView.totals.totalCount}</th>
            <th>{formatBytes(dashboardView.totals.bytes[1])}</th>
            <th>{formatBytes(dashboardView.totals.bytes[2])}</th>
            <th className={dashboardView.totals.bytes[3] ? styles.error : ''}>
              {formatBytes(dashboardView.totals.bytes[3])}
            </th>
            <th className={dashboardView.totals.bytes[4] ? styles.error : ''}>
              {formatBytes(dashboardView.totals.bytes[4])}
            </th>
            <th>{formatBytes(dashboardView.totals.totalBytes)}</th>
            <th>
              {formatUsdCents(
                getUsdCentsPerBytes(
                  dashboardView.totals.totalBytes,
                  property.billing
                )
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {dashboardView.groups.reduce((elements, group) => {
            elements.push(
              <GroupHeaderRow
                key={`group-${group.name}`}
                group={group}
                property={property}
              />
            );
            for (const service of group.services) {
              elements.push(serviceToServiceRow(service));
            }
            return elements;
          }, [] as ReactElement[])}
          {dashboardView.groups.length === 0 && (
            <tr>
              <td colSpan={13} className={styles.minorTextContainer}>
                <Icon image="info" size="small" margin="right-small" />
                <span>
                  {!property.proxyEnabled
                    ? `Enable ${PRODUCT_NAME} proxy in Settings. Unblocked 3rd-party services will start to appear in this table.`
                    : property.proxyNotConfiguredServices
                    ? `Services will start to appear here once DataUnlocker receives the first unblocked traffic from ${property.host} visitors.`
                    : `To start unblocking traffic, you can either (temporarily) enable proxy for "All other services" or manually add services.`}
                </span>
              </td>
            </tr>
          )}
          <UnconfiguredServicesRow property={property} />
        </tbody>
      </table>
    </div>
  );
};

export default SummaryTable;
