import { UiUser } from '@dataunlocker/pkg-types';
import { reconfirmEmail, requestLogout } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import PageHeader from 'Components/Common/Pages/Header';
import { showDeleteUserDialog } from 'Components/Dialog/DeleteUser';
import { ROUTE_PROPERTY_SETTINGS_USERS, ROUTE_SIGN_IN } from 'Constants';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { selectedPropertyIdState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

const PageSettingsGeneral = ({ user }: { user: UiUser | null }) => {
  const setSelectedPropertyId = useSetRecoilState(selectedPropertyIdState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const history = useHistory();
  const buttonDisabled = isLoading;

  const reconfirmClicked = useCallback(async () => {
    if (buttonDisabled) {
      return;
    }
    setIsLoading(true);
    const result = await reconfirmEmail();
    if (result.errorMessage) {
      new Toast(result.errorMessage, Toast.TYPE_ERROR);
    } else {
      new Toast(
        'Email confirmation has been sent. Please, check your inbox',
        Toast.TYPE_DONE
      );
      setIsSent(true);
    }
  }, [buttonDisabled, setIsLoading]);

  const onDeleteClick = useCallback(() => {
    if (!user) {
      return;
    }
    showDeleteUserDialog({
      user,
      onUserDeleted: async () => {
        await requestLogout();
        history.push(ROUTE_SIGN_IN);
      },
      onPropertyClick: async (id: string) => {
        setSelectedPropertyId(id);
        history.push(ROUTE_PROPERTY_SETTINGS_USERS(id));
      },
    });
  }, [user, history, setSelectedPropertyId]);

  return (
    <div className={styles.container}>
      <PageHeader icon="settings" title={`My settings`} />
      <div className={styles.containerCenter}>
        <Card>
          <CardHeader title="User settings" />
          <table>
            <tbody>
              <tr>
                <td className={styles.colorSecondary}>Email</td>
                <td>
                  <b>{user?.email}</b>
                </td>
                <td>
                  <Icon
                    image={user?.emailConfirmed ? 'checkmark' : 'warning'}
                    className={
                      user?.emailConfirmed ? styles.ok : styles.warning
                    }
                    margin="right-regular"
                  />
                  <span className={styles.textSecondary}>
                    {user?.emailConfirmed
                      ? 'Email confirmed'
                      : 'Please, confirm your email by clicking the link in your mailbox'}
                  </span>
                </td>
                {!user?.emailConfirmed && !isSent && (
                  <td>
                    <button
                      disabled={isLoading}
                      onClick={reconfirmClicked}
                      style={{ margin: 0 }}
                    >
                      Resend Confirmation
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </Card>
        <Card>
          <CardHeader title="Danger zone" />
          <button className="danger" onClick={onDeleteClick}>
            Delete User
          </button>
        </Card>
      </div>
    </div>
  );
};

export default PageSettingsGeneral;
