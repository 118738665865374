import { UiTempProperty } from '@dataunlocker/pkg-types';
import Row from 'Components/Pages/Properties/Settings/Setup/Health/Box/Row';
import { PRODUCT_NAME } from 'Constants';
import React from 'react';

interface Props {
  property: UiTempProperty;
  loading: boolean;
}

const RowScriptIsFirst = ({ property, loading }: Props) => {
  const expectedToFail = !property.health.scriptVersion;

  return (
    <Row
      ok={property.health.scriptIsOnTop}
      warn={!property.health.scriptIsOnTop}
      isExpectedToFail={expectedToFail}
      inProgress={loading}
      rowElement={
        <span>{PRODUCT_NAME}&apos;s script precedes other scripts</span>
      }
    >
      {expectedToFail ? (
        <span>
          This check is expected to fail because the script was not found at
          https://{property.host}/.
        </span>
      ) : (
        <span>
          Our test client made a request to <b>{property.host}</b> and found
          that the {PRODUCT_NAME}&apos;s script isn&apos;t the very first script
          on the page. While this by a lucky occasion might not cause any
          problems, note that in order to unblock other scripts, {PRODUCT_NAME}
          &apos;s script must precede them as it sets different hooks up to
          listen for failed script load attempts. {PRODUCT_NAME}&apos;s script
          don&apos;t impact your page load speed in any way, except for the time
          required by the browser to execute the script itself.
        </span>
      )}
    </Row>
  );
};

export default RowScriptIsFirst;
