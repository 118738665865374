import {
  AutomationPipelineStepDataCreateProxyEndpoint,
  AutomationPipelineStepId,
} from '@dataunlocker/pkg-types';
import MessageBlock from 'Components/Common/MessageBlock';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import { PipelineStepCommonProps } from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import {
  LINK_DOCS_AUTOMATION_REFRESH_SCRIPT_UPDATE,
  LINK_DOCS_SCRIPT_DYNAMIC,
  PRODUCT_NAME,
} from 'Constants';
import React, { useMemo } from 'react';
import PipelineStepWebhook from '../../PipelineStepWebhook';

const PipelineStepWebhookUpdateClientCode = (
  props: PipelineStepCommonProps
) => {
  const potentialEndpointType: 'dns' | 'path' =
    (props.pipeline.steps.find(
      (s) => s.id === AutomationPipelineStepId.CreateProxyEndpoint
    )?.data as AutomationPipelineStepDataCreateProxyEndpoint)
      ?.proxyEndpointType || 'dns';
  const latestProxyEndpoint =
    props.property.proxyEndpoints.find(
      (e) => e.urlKey === props.pipeline.context.newProxyEndpointUrlKey
    ) ||
    props.property.proxyEndpoints[props.property.proxyEndpoints.length - 1];
  const variables = useMemo(
    () => [
      {
        name: 'SCRIPT_HTML_CODE',
        example: `<script>...</script>`,
        description: (
          <span>
            A script HTML code which should be embedded directly into the HTML
            code of your website as a first script on the page. We also
            recommend using{' '}
            <SafeExternalLink href={LINK_DOCS_SCRIPT_DYNAMIC}>
              dynamic script injection
            </SafeExternalLink>
            , where this variable is not needed. <b>Do not</b> put{' '}
            {PRODUCT_NAME}&apos;s script externally, i.e. at the predictable URL
            which can be easily blacklisted.
          </span>
        ),
      },
      {
        name: 'SCRIPT_VERSION',
        example: '3.0.0',
        description:
          "The script version delivered within {{SCRIPT_HTML_CODE}}. It's always the latest script version.",
      },
      {
        name: 'PROXY_ENDPOINT_HOSTNAME',
        example: `${potentialEndpointType === 'dns' ? 'random-hash.' : ''}${
          props.property.host
        }`,
        description: `New proxy endpoint's domain name which ${PRODUCT_NAME} asked to create in case when a new DNS proxy endpoint type is created.`,
      },
      {
        name: 'PROXY_ENDPOINT_PATH',
        example: `${
          potentialEndpointType === 'path'
            ? `/${latestProxyEndpoint.urlKey}`
            : '/'
        }`,
        description: `New proxy endpoint's proxy path on the ${props.property.host} ${PRODUCT_NAME} asked to create in case when PATH proxy endpoint is used.`,
      },
      {
        name: 'PROXY_ENDPOINT_URL_KEY',
        example: latestProxyEndpoint.urlKey,
        description: `New proxy endpoint's URL key (a random part of domain in case of DNS proxy endpoint and a random URL path part in case of PATH proxy endpoint).`,
      },
      {
        name: 'PROXY_ENDPOINT_ENC_KEY',
        example: latestProxyEndpoint.encKey,
        description: `Proxy endpoint's unique key used for content encoding.`,
      },
    ],
    [
      potentialEndpointType,
      props.property.host,
      latestProxyEndpoint.urlKey,
      latestProxyEndpoint.encKey,
    ]
  );

  return (
    <PipelineStepWebhook
      initialUrl="https://my-api.com/handle"
      icon="code"
      title="Deliver the client code update webhook"
      variables={variables}
      {...props}
    >
      <MessageBlock display="inline">
        Implement an API endpoint which will update the client script in your
        web application when requested by {PRODUCT_NAME}.{' '}
        <SafeExternalLink href={LINK_DOCS_AUTOMATION_REFRESH_SCRIPT_UPDATE}>
          Learn more
        </SafeExternalLink>{' '}
        about implementing this webhook.
      </MessageBlock>
    </PipelineStepWebhook>
  );
};

export default PipelineStepWebhookUpdateClientCode;
