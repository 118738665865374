import { UiProperty, UiTempProperty, UiUser } from '@dataunlocker/pkg-types';
import PageError from 'Components/Common/Pages/Error';
import PageLoading from 'Components/Common/Pages/Loading';
import React, { FunctionComponent } from 'react';
import {
  currentUserState,
  myPropertiesState,
  myTempPropertiesState,
  useRecoilValueLoadable,
} from 'State';

interface Props {
  Page: FunctionComponent<{
    properties: UiProperty[];
    tempProperties: UiTempProperty[];
    user: UiUser;
  }>;
}

const PageWithPropertyAndUser = ({ Page }: Props) => {
  const propertiesLoadable = useRecoilValueLoadable(myPropertiesState);
  const tempPropertiesLoadable = useRecoilValueLoadable(myTempPropertiesState);
  const currentUserLoadable = useRecoilValueLoadable(currentUserState);
  const propertiesState =
    propertiesLoadable.state === 'hasValue' ? propertiesLoadable.contents : [];
  const tempPropertiesState =
    tempPropertiesLoadable.state === 'hasValue'
      ? tempPropertiesLoadable.contents
      : [];
  const user =
    currentUserLoadable.state === 'hasValue'
      ? currentUserLoadable.contents
      : null;
  const isLoading =
    propertiesLoadable.state === 'loading' ||
    tempPropertiesLoadable.state === 'loading' ||
    currentUserLoadable.state === 'loading';
  const isError =
    propertiesLoadable.state === 'hasError' ||
    tempPropertiesLoadable.state === 'hasError' ||
    currentUserLoadable.state === 'hasError';

  return isLoading ? (
    <PageLoading />
  ) : isError || !user ? (
    <PageError message={`We've got a problem displaying this page :(`} />
  ) : (
    <Page
      tempProperties={tempPropertiesState}
      properties={propertiesState}
      user={user}
    />
  );
};

export default PageWithPropertyAndUser;
