import { Permission, UiTempProperty, UiUser } from '@dataunlocker/pkg-types';
import { requestPropertyAddProxyEndpoint } from 'Api';
import Button from 'Components/Common/Button';
import IconWithSubtext from 'Components/Common/IconWithSubtext';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import NeedHelpFooter from 'Components/Pages/Properties/Settings/Setup/NeedHelpFooter';
import {
  LINK_DOCS_AUTOMATION_CONCEPTS,
  PRODUCT_NAME,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH,
  ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT,
} from 'Constants';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { propertiesListVersionState } from 'State';
import { Toast } from 'toaster-js';
import { useInterval, useTimeout } from 'Utils';
import ProxyEndpoint from './ProxyEndpoint';
import styles from './styles.module.scss';
import ProxyEndpointVisualLines from './VisualLines';

const MAX_NUMBER_OF_ENDPOINTS = 5;

interface Props {
  property: UiTempProperty;
  user: UiUser;
}

const PropertySetupRouting = ({ property, user }: Props) => {
  const history = useHistory();
  const fromLineRef = useRef<HTMLDivElement | null>(null);
  const toLineRef = useRef<HTMLDivElement | null>(null);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const isOwner = !!property.permissionBindings.find(
    (u) => u.userId === user.id && u.permissions.includes(Permission.owner)
  );
  const propertyId = property.id;
  const isAnyHealthy = !!property.proxyEndpoints?.find(
    ({ health }) => health.ok
  );
  const numberOfEndpoints = property.proxyEndpoints?.length || 0;

  const [addLoading, setAddLoading] = useState(false);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const onAddPress = useCallback(async () => {
    setAddLoading(true);

    const { errorMessage } = await requestPropertyAddProxyEndpoint(propertyId);

    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
    } else {
      setPropertiesListVersion(Math.random());
    }

    setAddLoading(false);
  }, [propertyId, setPropertiesListVersion]);

  const onNextPress = useCallback(() => {
    history.push(ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT(propertyId));
  }, [propertyId, history]);

  // Refreshes proxy endpoints periodically, for lastTrafficAt to tick.
  useInterval(() => {
    if (property.proxyEndpoints.find((e) => !!e.lastTrafficAt)) {
      setPropertiesListVersion(Math.random());
    }
  }, 30 * 1000);
  // Refreshes proxy endpoints initially, as when the user comes to this UI the "lastTrafficAt" can be outdated.
  useTimeout(() => {
    if (property.proxyEndpoints.find((e) => !!e.lastTrafficAt)) {
      setPropertiesListVersion(Math.random());
    }
  }, 2 * 1000);

  return (
    <div>
      <div className={styles.container}>
        <div>
          <div
            className={`${styles.anchorLineBlock} ${
              isAnyHealthy ? styles.ok : ''
            }`}
          >
            <IconWithSubtext size="giant" image="globe" text="Internet" />
            <div ref={(e) => (fromLineRef.current = e)}>
              <ProxyEndpointVisualLines
                leftRefPoint={fromLineRef}
                rightRefPoint={toLineRef}
                containerRef={mainContainerRef}
                proxyEndpoints={property.proxyEndpoints || []}
              />
            </div>
          </div>
        </div>
        <div
          className={styles.mainContainer}
          ref={(e) => (mainContainerRef.current = e)}
        >
          {(property.proxyEndpoints || []).map((endpoint, i) => (
            <ProxyEndpoint
              key={i}
              property={property}
              endpoint={endpoint}
              canEdit={isOwner}
            />
          ))}
        </div>
        <div
          className={`${styles.anchorLineBlock} ${
            isAnyHealthy ? styles.ok : ''
          }`}
        >
          <div ref={(e) => (toLineRef.current = e)}>
            <IconWithSubtext
              size="giant"
              image="logo"
              text={PRODUCT_NAME}
              margin="none"
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          image="add"
          type="secondary"
          disabled={
            addLoading ||
            !isOwner ||
            numberOfEndpoints >= MAX_NUMBER_OF_ENDPOINTS
          }
          disabledTooltip={
            !isOwner
              ? `You don't have permission to add new endpoints.`
              : numberOfEndpoints >= MAX_NUMBER_OF_ENDPOINTS
              ? `You can create up to ${MAX_NUMBER_OF_ENDPOINTS} endpoints only.`
              : ''
          }
          tooltip={
            <span>
              You can add more proxy endpoints to change the URL prefix used for
              proxy, for example, when your current endpoint is added to content
              filtering blacklists. You will be able to switch to this new
              endpoint by confirming it and updating the script. Normally, you
              don&apos;t need more than 1 proxy endpoint.
              <br />
              <br />
              <SafeExternalLink href={LINK_DOCS_AUTOMATION_CONCEPTS}>
                Learn
              </SafeExternalLink>{' '}
              how to automate proxy endpoints swapping with{' '}
              <Link
                to={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH(property.id)}
              >
                automation
              </Link>
              .
            </span>
          }
          onClick={onAddPress}
        >
          Add Endpoint
        </Button>
        <Button
          image="forward"
          type={isAnyHealthy ? 'primary' : 'secondary'}
          onClick={onNextPress}
        >
          Continue to Script
        </Button>
        <NeedHelpFooter property={property} />
      </div>
    </div>
  );
};

export default PropertySetupRouting;
