import { UiProperty, UiPurchase, UiUser } from '@dataunlocker/pkg-types';

export const API_PATH_USERS_LOGIN = '/users/login';
export interface ApiUsersLoginRequest {
  email: string;
  password: string;
  captchaToken: string;
}
export interface ApiUsersLoginResponse {
  user: UiUser;
  jwtToken: string;
}

// This will either log in or register the user, and return the jwt token for the client.
export const API_PATH_USERS_LOGIN_GOOGLE = '/users/login-google';
export interface ApiUsersLoginGoogleRequest {
  tokenId: string;
  useAuthCode?: boolean;
  redirectUrl?: string;
}
export interface ApiUsersLoginGoogleResponse {
  user: UiUser;
  jwtToken: string;
}

export const API_PATH_USERS_REGISTER = '/users/register';
export interface ApiUsersRegisterRequest {
  email: string;
  password: string;
  captchaToken: string;
}
export interface ApiUsersRegisterResponse {
  user: UiUser;
  jwtToken: string;
}

export const API_PATH_USERS_WHOAMI = '/users/whoami';
export interface ApiUsersWhoAmIResponse {
  user: UiUser;
}

export const API_PATH_AUTH_CHECK = '/users/check';
export interface ApiUsersCheckRequest {
  email: string;
  captchaToken: string;
}
export interface ApiUsersCheckResponse {
  exists: boolean;
}

export const API_PATH_USERS_CONFIRM_EMAIL = '/users/confirm-email';
export interface ApiUsersConfirmEmailRequestQuery {
  token?: string;
}
export interface ApiUsersConfirmEmailResponse {
  ok: boolean;
}

export const API_PATH_USERS_RECONFIRM_EMAIL = '/users/reconfirm-email';
export interface ApiUsersReconfirmEmailResponse {
  ok: boolean;
}

export const API_PATH_USERS_PASSWORD_RESET_REQUEST =
  '/users/password-reset-request';
export interface ApiUsersPasswordResetRequestRequest {
  email: string;
  captchaToken: string;
}
export interface ApiUsersPasswordResetRequestResponse {
  ok: boolean;
}

export const API_PATH_USER_PASSWORD_RESET = '/users/password-reset';
export interface ApiUsersPasswordResetRequest {
  passwordResetToken: string;
  newPassword: string;
}
export interface ApiUsersPasswordResetResponse {
  ok: boolean;
}

export const API_PATH_USERS_LIST_MY_PURCHASES = '/users/list-my-purchases';
export interface ApiUsersListMyPurchasesResponse {
  purchases: UiPurchase[];
}

export const API_PATH_USERS_DELETE_CARD = '/users/delete-card';
export interface ApiUsersDeleteCardRequest {
  cardNumber: string;
}
export interface ApiUsersDeleteCardResponse {
  user: UiUser;
}

export const API_PATH_ADMIN_V1_USERS_DELETE = (userId: string) =>
  `/users/${userId}/del`;
export interface ApiUsersDeleteRequest {}
export interface ApiUsersDeleteResponse {
  // If the user is not deleted, the reasons will be provided below.
  isDeleted: boolean;

  // Reasons why the system can't delete this user.
  whyNot?: {
    cardIsBoundToProperties: Pick<UiProperty, 'id' | 'host'>[];
    userIsBoundToProperties: Pick<UiProperty, 'id' | 'host'>[];
    userIsBoundToTempProperties: Pick<UiProperty, 'id' | 'host'>[];
  };
}
