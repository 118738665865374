import RouterComponent from 'Components/Router';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Toast } from 'toaster-js';
import 'toaster-js/default.scss';

import './basic.scss';
import './const.scss';

const App = () => {
  useEffect(() => {
    let element = document.createElement("div");
    let text1 = document.createElement("span");
    let link1 = document.createElement("a");
    let text2 = document.createElement("span");
    text1.textContent = "Our payment provider is having tough times but no worries! Please follow updates in our ";
    link1.textContent = "telegram channel"
    link1.href = 'https://t.me/s/dataunlocker';
    link1.setAttribute('target', '_blank');
    text2.textContent = ' or contact support@dataunlocker.com. Problems are on us, traffic is for free!';
    element.appendChild(text1);
    element.appendChild(link1);
    element.appendChild(text2);
    new Toast(element, Toast.TYPE_WARNING);
  }, []);

  return (
    <RecoilRoot>
      <Router>
        <RouterComponent />
      </Router>
    </RecoilRoot>
  );
};

export default App;
