import cookie from 'cocookie';
import {
  SESSION_HEADER_NAME,
  SESSION_LOCAL_STORAGE_KEY,
  SESSION_SUPERADMIN_LOCAL_STORAGE_KEY,
} from 'Constants';
import {
  getFromLocalStorage,
  clearFromLocalStorage,
  setToLocalStorage,
} from './local-storage';

export const getSessionToken = (): string => {
  const localStorageSessionToken = getFromLocalStorage(
    SESSION_LOCAL_STORAGE_KEY
  );
  const cookieValue = cookie(SESSION_HEADER_NAME).get();

  // Migration from session cookies to local storage, keep indefinitely.
  if (cookieValue) {
    setToLocalStorage(SESSION_LOCAL_STORAGE_KEY, cookieValue);
    cookie(SESSION_HEADER_NAME).delete({
      'Max-Age': 0,
      path: '/',
    });
  }

  return localStorageSessionToken || cookieValue || '';
};

export const getSuperAdminSessionToken = () =>
  getFromLocalStorage(SESSION_SUPERADMIN_LOCAL_STORAGE_KEY);

export const setSessionToken = (value: string) => {
  return setToLocalStorage<string>(SESSION_LOCAL_STORAGE_KEY, value);
};

export const setSuperAdminSessionToken = (value: string) => {
  return setToLocalStorage<string>(SESSION_SUPERADMIN_LOCAL_STORAGE_KEY, value);
};

export const clearSuperAdminSessionToken = () =>
  clearFromLocalStorage(SESSION_SUPERADMIN_LOCAL_STORAGE_KEY);

/* Clears session cookie so that the user is effectively logged out. */
export const clearSessionToken = (): void => {
  clearFromLocalStorage(SESSION_LOCAL_STORAGE_KEY);
};
