import React from 'react';
import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME } from 'Constants';
import { UiProperty } from '@dataunlocker/pkg-types';
import { formatBytes } from 'Utils';

const DialogDeleteProperty = ({ property }: { property: UiProperty }) => {
  return (
    <div>
      <p>
        Deleting <b>{property.host}</b> from {PRODUCT_NAME} is permanent and{' '}
        cannot be undone.
      </p>
      <p>
        Note that if you add <b>{property.host}</b> to {PRODUCT_NAME} again, the
        prepaid traffic of the newly created <b>{property.host}</b> will be
        unchanged and equal{' '}
        {formatBytes(property.bytesPrepaid - property.bytesProxied)}.
      </p>
    </div>
  );
};

export const showDeletePropertyDialog = ({
  property,
  onConfirmClick,
}: {
  property: UiProperty;
  onConfirmClick: () => void;
}) =>
  showDialog({
    title: `Delete ${property.host}?`,
    icon: 'trash',
    content: <DialogDeleteProperty property={property} />,
    confirmButtonText: 'DELETE PERMANENTLY',
    confirmButtonClass: 'danger',
    cancelButtonText: 'Cancel',
    onConfirmClick,
  });
