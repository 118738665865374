import { UiProperty } from '@dataunlocker/pkg-types';
import { updatePropertyLowBytesNotification } from 'Api';
import Card from 'Components/Common/Card';
import { LOW_BYTES_NOTIFY_BELOW_DEFAULT } from 'Constants';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { formatBytes } from 'Utils';
import React, { useCallback, useState } from 'react';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

interface UsageSummaryCardProps {
  property: UiProperty;
}

const initialLowBytesNotifyBelowToUnit = (lowBytesNotifyBelow: number) =>
  1024 * 1024 * (lowBytesNotifyBelow >= 1024 * 1024 * 1024 ? 1024 : 1);

const StatsLine = ({ property }: UsageSummaryCardProps) => {
  const lowBytesNotifyBelowInitial =
    property.lowBytesNotifyBelow || LOW_BYTES_NOTIFY_BELOW_DEFAULT;
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );
  const [notifyBelowUnit, setNotifyBelowUnit] = useState(
    initialLowBytesNotifyBelowToUnit(lowBytesNotifyBelowInitial)
  );
  const [notifyBelowInput, setNotifyBelowInput] = useState(
    (lowBytesNotifyBelowInitial / notifyBelowUnit)
      .toFixed(3)
      .replace(/\.?0+$/, '')
  );
  const [loading, setLoading] = useState(false);
  const notifyBelowBytes = Math.floor(
    parseFloat(notifyBelowInput) * notifyBelowUnit
  );

  const onNotifyBelowSaveClick = useCallback(async () => {
    setLoading(true);
    const { errorMessage } = await updatePropertyLowBytesNotification(
      property.id,
      {
        lowBytesNotifyBelow: notifyBelowBytes,
      }
    );
    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
    } else {
      setPropertiesListVersion(Math.random());
    }
    setLoading(false);
  }, [notifyBelowBytes, property.id, setPropertiesListVersion]);
  // const onNotifyBelowUnitChange = useCallback(
  //   (event: React.ChangeEvent<HTMLSelectElement>) => {
  //     setNotifyBelowUnit(+event.currentTarget.value);
  //   },
  //   []
  // );
  // const onNotifyBelowInputChange = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setNotifyBelowInput(event.currentTarget.value);
  //   },
  //   []
  // );
  const onResetClick = useCallback(() => {
    const unit = initialLowBytesNotifyBelowToUnit(lowBytesNotifyBelowInitial);
    setNotifyBelowUnit(unit);
    setNotifyBelowInput(
      (lowBytesNotifyBelowInitial / unit).toFixed(3).replace(/\.?0+$/, '')
    );
  }, [lowBytesNotifyBelowInitial]);

  const hasChanges = lowBytesNotifyBelowInitial !== notifyBelowBytes;
  return (
    <Card>
      <div className={styles.flex}>
        <div className={styles.accent}>
          <span>
            You have{' '}
            <b className={styles.emphasis}>
              {formatBytes(property.bytesPrepaid - property.bytesProxied)}
            </b>
          </span>
        </div>
        {/* <div>
          <Tooltip
            content={
              <span>
                When the traffic is at {formatBytes(notifyBelowBytes || 0)} or
                below, DataUnlocker will send email notifications to users
                receiving &quot;traffic&quot; notifications, which is specified
                in{' '}
                <Link to={ROUTE_PROPERTY_SETTINGS_USERS(property.id)}>
                  this property&apos;s user list
                </Link>
                .
              </span>
            }
          >
            <Icon hoverable image="bell" size="big" />
          </Tooltip>
        </div>
        <div>
          <input
            className={styles.bytesInput}
            value={notifyBelowInput}
            type="number"
            onChange={onNotifyBelowInputChange}
          />
          <select value={notifyBelowUnit} onChange={onNotifyBelowUnitChange}>
            <option value={1024 * 1024}>MB</option>
            <option value={1024 * 1024 * 1024}>GB</option>
          </select>
        </div> */}
      </div>
      {hasChanges && (
        <div className={styles.buttons}>
          <button disabled={loading} onClick={onNotifyBelowSaveClick}>
            Save
          </button>
          <button
            disabled={loading}
            onClick={onResetClick}
            className="secondary"
          >
            Reset
          </button>
        </div>
      )}
    </Card>
  );
};

export default StatsLine;
