import Icon from 'Components/Common/Icon';
import React, { ButtonHTMLAttributes } from 'react';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  image?: string;
  disabledTooltip?: React.ReactNode;
  tooltip?: React.ReactNode;
  type?: 'primary' | 'secondary' | 'special' | 'danger';
  compact?: boolean;
}

const Button = ({
  image,
  children,
  type = 'primary',
  className,
  compact = false,
  disabledTooltip,
  tooltip,
  ...buttonProps
}: Props) => {
  const button = (
    <button
      className={`${styles.button} ${type}${
        compact ? ` ${styles.compact}` : ''
      }${className ? ` ${className}` : ''}`}
      {...buttonProps}
    >
      {image && <Icon image={image} margin="right-small" />}
      <span>{children}</span>
    </button>
  );

  const tooltipToShow = (buttonProps.disabled && disabledTooltip) || tooltip;

  return tooltipToShow ? (
    <Tooltip
      type={buttonProps.disabled && disabledTooltip ? 'help' : 'info'}
      content={tooltipToShow}
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default Button;
