import FeatureGuard from 'Components/Common/FeatureGuard';
import Icon from 'Components/Common/Icon';
import MessageBlock from 'Components/Common/MessageBlock';
import PageError from 'Components/Common/Pages/Error';
import PageHeader from 'Components/Common/Pages/Header';
import PageLoading from 'Components/Common/Pages/Loading';
import PurchasesListCard from 'Components/Common/PurchasesListCard';
import { showTransferTrafficDialog } from 'Components/Dialog/TransferTraffic';
import PagePropertyDoesNotExist from 'Components/Pages/Properties/NotFound';
import { INTERVAL_1D, PRODUCT_NAME, TimeRangeTuple } from 'Constants';
import React, { useCallback, useState } from 'react';
import {
  myPropertiesState,
  propertiesListVersionState,
  selectedPropertyState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'State';
import BillingPlanSelect from './BillingPlanSelect';
import PrepaidTrafficChart from './PrepaidTrafficChart';
import PurchaseTraffic from './PurchaseTraffic';
import RecurringTrafficPurchaseCard from './RecurringTrafficPurchaseCard';
import StatsLine from './StatsLine';
import styles from './styles.module.scss';
import UsageCard from './UsageCard';

const PagePropertyBilling = () => {
  const propertyLoadable = useRecoilValueLoadable(selectedPropertyState);
  const propertiesListLoadable = useRecoilValueLoadable(myPropertiesState);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const updatePropertiesList = useCallback(() => {
    setPropertiesListVersion(Math.random());
  }, [setPropertiesListVersion]);

  const property =
    propertyLoadable.state === 'hasValue' ? propertyLoadable.contents : null;
  const propertiesList =
    propertiesListLoadable.state === 'hasValue'
      ? propertiesListLoadable.contents
      : null;

  const [timeRange, setTimeRange] = useState<TimeRangeTuple>([0, INTERVAL_1D]);
  const onTimeRangeChange = useCallback((newRange: TimeRangeTuple) => {
    setTimeRange(newRange);
  }, []);

  return propertyLoadable.state === 'hasError' ? (
    <PageError message={propertyLoadable.contents + ''} />
  ) : propertyLoadable.state === 'loading' ? (
    <PageLoading />
  ) : !property ? (
    <PagePropertyDoesNotExist />
  ) : (
    <div>
      <PageHeader icon="money" title={`${property.host} • Billing`}>
        <button
          className="borderless"
          onClick={() =>
            showTransferTrafficDialog({
              property,
              properties: propertiesList || [],
              onSuccess: updatePropertiesList,
            })
          }
        >
          <Icon image="transfer" margin="right-regular" /> Transfer Traffic
        </button>
      </PageHeader>
      {property.bytesPrepaidChanges.length < 2 && (
        <MessageBlock>
          Initially you have 512 MB of {PRODUCT_NAME} traffic free of charge.
          Use it for testing and estimate how much would you need to purchase.
          <br />
          When the prepaid traffic runs out, {PRODUCT_NAME} proxy will stop
          working. You will still be able to prepay more traffic which will
          immediately resume {PRODUCT_NAME} proxy.
        </MessageBlock>
      )}
      <div className={`${styles.containerCenter}`}>
        <FeatureGuard feature="billingPlans">
          <BillingPlanSelect property={property} />
        </FeatureGuard>
        <FeatureGuard feature="billingPlans" whenDisabled>
          <PrepaidTrafficChart
            property={property}
            onTimeRangeChange={onTimeRangeChange}
            timeRange={timeRange}
          />
          <StatsLine property={property} />
          <UsageCard property={property} />
        </FeatureGuard>
        <PurchaseTraffic property={property} />
        <RecurringTrafficPurchaseCard property={property} />
        <PurchasesListCard property={property} />
      </div>
    </div>
  );
};

export default PagePropertyBilling;
