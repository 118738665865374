import React, { useCallback } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import TextInput from '../TextInput';
import styles from './styles.module.scss';

type KeyValuePairs = { key: string; value: string }[];

interface Props {
  keyValues: KeyValuePairs;
  label?: string;
  disabled?: boolean;
  compact?: boolean;
  textarea?: boolean;
  addButtonText?: string;
  keyLabel?: string;
  valueLabel?: string;
  wide?: boolean;
  onChange: (newValue: KeyValuePairs) => any;
}

const KeyValueInput = ({
  keyValues,
  onChange,
  disabled,
  keyLabel,
  valueLabel,
  addButtonText,
  compact,
  label,
  wide,
}: Props) => {
  const onKvChange = useCallback(
    (index: number, key: string, value: string) => {
      const newKeyValues = keyValues.slice();
      newKeyValues[index].key = key;
      newKeyValues[index].value = value;
      onChange(newKeyValues);
    },
    [keyValues, onChange]
  );
  const addKvPair = useCallback(() => {
    const newKeyValues = keyValues.slice().concat([{ key: '', value: '' }]);
    onChange(newKeyValues);
  }, [keyValues, onChange]);
  const removeKvPair = useCallback(
    (i) => {
      if (disabled) {
        return;
      }
      const newKeyValues = keyValues.slice();
      newKeyValues.splice(i, 1);
      onChange(newKeyValues);
    },
    [keyValues, onChange, disabled]
  );

  return (
    <div
      className={[
        styles.kvInput,
        compact ? styles.compact : '',
        wide ? styles.wide : '',
      ].join(' ')}
    >
      {label && <label>{label}</label>}
      {keyValues.map(({ key, value }, i) => (
        <div className={styles.row} key={i}>
          <div>
            <TextInput
              compact
              value={key}
              label={keyLabel || 'Key'}
              onChange={(value) => onKvChange(i, value, keyValues[i].value)}
              disabled={!!disabled}
            />
          </div>
          <div>
            <TextInput
              compact
              label={valueLabel || 'Value'}
              value={value}
              onChange={(value) => onKvChange(i, keyValues[i].key, value)}
              disabled={!!disabled}
            />
          </div>
          <div>
            <Icon
              image="close"
              margin="right-regular"
              size="big"
              hoverable={!disabled}
              onClick={() => removeKvPair(i)}
            />
          </div>
        </div>
      ))}
      <div style={{ textAlign: 'right' }}>
        <Button
          disabled={disabled}
          type="secondary"
          compact
          image="add"
          onClick={addKvPair}
        >
          {addButtonText || 'Add Values'}
        </Button>
      </div>
    </div>
  );
};

export default KeyValueInput;
