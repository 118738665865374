import React from 'react';
import { TooltipProps } from 'recharts';
import { formatBytes } from 'Utils';
import styles from './styles.module.scss';

const timeFormatter = (n: any) =>
  new Date(n).toLocaleString(void 0, {
    dateStyle: 'short',
    timeStyle: 'short',
  } as any);

const totalBytesDataPointName = 'Total Bytes';
const totalCountDataPointName = 'Total Count';

const ChartTooltip = ({
  active,
  payload,
  label,
  stepSize,
}: TooltipProps & { stepSize: number }) => {
  if (active && payload && payload.length) {
    const plainPayload = payload[0]?.payload || {};
    const showCounts =
      typeof plainPayload[totalCountDataPointName] !== 'undefined';
    const showTotal =
      typeof plainPayload[totalBytesDataPointName] !== 'undefined';
    return (
      <div className={styles.customTooltipContainer}>
        <div>
          <div className={styles.tooltipTime}>
            {timeFormatter(+label!)} - {timeFormatter(+label! + stepSize)}
          </div>
          <table>
            <thead>
              <tr>
                <td>Service</td>
                <td>Bytes</td>
                {showCounts && <td>Requests</td>}
              </tr>
            </thead>
            <tbody>
              {payload.map(({ color, name, value }) => (
                <tr key={name}>
                  <td>
                    <b style={{ color }}>{name}</b>
                  </td>
                  <td>{formatBytes(+value)}</td>
                  {showCounts && <td>{plainPayload[`${name}-c`] || 0}</td>}
                </tr>
              ))}
              {showTotal && (
                <tr>
                  <td>
                    <b>Total</b>
                  </td>
                  <td>
                    {formatBytes(plainPayload[totalBytesDataPointName] || 0)}
                  </td>
                  {showCounts && (
                    <td>{plainPayload[totalCountDataPointName] || 0}</td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return null;
};

export default ChartTooltip;
