import Icon from 'Components/Common/Icon';
import { REVERSE_ROUTE_PROPERTY } from 'Constants';
import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { selectedPropertyIdState, useSetRecoilState } from 'State';
import styles from './styles.module.scss';

interface props {
  icon: string;
  text: string;
  path: string;
  to?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  warningMessage?: string;
}

const A = (props: any) => {
  // Something to satisfy TypeScript. If there's a better way to pass a dynamic tag - welcome to try it.
  const { children, ...other } = props;
  return <a {...other}>{children}</a>;
};

const MenuItem = ({
  icon,
  text,
  path,
  to,
  onClick,
  children = [],
  warningMessage = '',
}: props) => {
  const setSelectedPropertyId = useSetRecoilState(selectedPropertyIdState);

  const location = useLocation();
  const isExternalLink = path.indexOf('http') !== -1;
  const Component = isExternalLink ? A : Link;
  const propertyId = REVERSE_ROUTE_PROPERTY(path);
  const onMenuItemClick = useCallback(() => {
    setSelectedPropertyId(propertyId);
    if (onClick) {
      onClick();
    }
  }, [propertyId, onClick, setSelectedPropertyId]);

  return (
    <div
      className={[styles.menuItem]
        .concat(location.pathname === path ? styles.menuItemCurrent : [])
        .concat(
          location.pathname.indexOf(path) === 0 ? styles.menuItemInPath : []
        )
        .join(' ')}
    >
      <Component
        to={to || path}
        {...(isExternalLink ? { href: path, target: '_blank' } : {})}
        onClick={onMenuItemClick}
        className={warningMessage ? styles.withWarningMessage : undefined}
      >
        <Icon
          className={styles.menuItemIcon}
          image={icon}
          size="big"
          margin="none"
        />
        <div className={styles.menuItemTextContainer}>
          <div className={warningMessage ? styles.containerWithWarning : ''}>
            <div className={styles.textContainer}>
              <span>{text}</span>
            </div>
            <div
              className={`${styles.warningMessageContainer} ${styles.textContainer}`}
            >
              {warningMessage && (
                <Icon
                  image="warning"
                  size="small"
                  className="color-warning"
                  margin="right-small"
                />
              )}{' '}
              <span>{warningMessage ?? ''}</span>
            </div>
          </div>
        </div>
      </Component>
      <div
        className={`${styles.children}${
          location.pathname.indexOf(path) !== 0
            ? ' ' + styles.collapsedChildren
            : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default MenuItem;
