import React, { useCallback } from 'react';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';

interface ToggleSwitchProps {
  checked: boolean;
  label?: string;
  className?: string;
  onChange?: (enabled: boolean) => unknown;
  disabledTooltip?: React.ReactNode;
  disabled?: boolean;
  noMargin?: boolean;
}

const ToggleSwitch = ({
  checked,
  label,
  className,
  disabledTooltip,
  disabled = false,
  noMargin = false,
  onChange,
}: ToggleSwitchProps) => {
  const onCheckedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.checked);
      }
    },
    [onChange]
  );

  const switchElement = (
    <div className={`${styles.switch}${noMargin ? ' ' + styles.noMargin : ''}`}>
      <label
        className={`${styles.switchBox} ${className ? ` ${className}` : ''}`}
      >
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onCheckedChange}
        />
        <span className={styles.slider}></span>
      </label>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );

  return disabled && disabledTooltip ? (
    <Tooltip type={'help'} content={disabledTooltip}>
      {switchElement}
    </Tooltip>
  ) : (
    switchElement
  );
};

export default ToggleSwitch;
