import { PropertyProxyEndpoint, UiTempProperty } from '@dataunlocker/pkg-types';
import { getHostPathForEndpoint } from '@dataunlocker/pkg-utils';
import {
  requestPropertyCheckProxyEndpoint,
  requestPropertyDeleteProxyEndpoint,
  requestPropertyEditProxyEndpoint,
} from 'Api';
import Button from 'Components/Common/Button';
import CopyButton from 'Components/Common/CopyButton';
import Icon from 'Components/Common/Icon';
import MessageBlock from 'Components/Common/MessageBlock';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import Table from 'Components/Common/Table';
import TabsSwitch from 'Components/Common/TabsSwitch';
import Tooltip from 'Components/Common/Tooltip';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import { showDeleteProxyEndpointDialog } from 'Components/Dialog/DeleteProxyEndpoint';
import {
  LINK_ADGUARD,
  LINK_CLOUDFLARE_DNS_PROXY,
  LINK_CLOUDFLARE_WORKERS,
  LINK_DOCS_INSTALL_ROUTING,
  LINK_DOCS_INSTALL_ROUTING_PATH,
  LINK_EASYLIST_TO,
  PRODUCT_NAME,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH_PIPELINE,
} from 'Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import relativeDate from 'relative-date';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import { getDDnsDomainForDuKey } from 'Utils';
import styles from './styles.module.scss';
import {
  getEndpointExtendedState,
  getEndpointShortState,
  getTlsStateIcon,
} from './utils';

const isEndpointBlacklisted = (endpoint: PropertyProxyEndpoint) =>
  endpoint.blacklistedIn && endpoint.blacklistedIn.length > 0;

interface ProxyEndpointProps {
  property: UiTempProperty;
  canEdit?: boolean;
  endpoint: NonNullable<UiTempProperty['proxyEndpoints']>[42];
}

const PROXY_ENDPOINT_TABS: {
  title: string;
  type: ProxyEndpointProps['endpoint']['type'];
}[] = [
  {
    title: 'DNS Record',
    type: 'dns',
  },
  {
    title: 'Path Prefix',
    type: 'path',
  },
];

const PROXY_RECOMMENDATION = (
  <span>
    <Icon image="info" margin="right-tiny" size="small" /> It is recommended to
    set up a reverse proxy in front of {PRODUCT_NAME}
    &apos;s servers. You can use solutions like{' '}
    <UnsafeExternalLink href={LINK_CLOUDFLARE_DNS_PROXY}>
      Cloudflare DNS Proxy
    </UnsafeExternalLink>
    ,{' '}
    <UnsafeExternalLink href={LINK_CLOUDFLARE_WORKERS}>
      Cloudflare Workers
    </UnsafeExternalLink>{' '}
    or your own custom reverse proxy. Otherwise, {PRODUCT_NAME}&apos;s IP
    addresses will be exposed via your proxy endpoints and DNS crawlers ran by
    ad blocking community might find and block your proxy endpoint soon.{' '}
    <SafeExternalLink href={LINK_DOCS_INSTALL_ROUTING}>
      Learn more
    </SafeExternalLink>{' '}
    about setting up proxy endpoints.
  </span>
);

const ProxyEndpoint = ({ property, endpoint }: ProxyEndpointProps) => {
  const numberOfEndpoints = property.proxyEndpoints?.length || 0;
  const isTheOnlyEndpoint = numberOfEndpoints < 2;

  const [expanded, setExpanded] = useState(isTheOnlyEndpoint);
  const [selectedType, setSelectedType] = useState(endpoint.type);
  const [loading, setLoading] = useState(false);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const actualType = endpoint.editable ? selectedType : endpoint.type;
  const selectedTypeIndex =
    PROXY_ENDPOINT_TABS.findIndex(({ type }) => type === selectedType) || 0;
  const propertyId = property.id;
  const endpointUrlKey = endpoint.urlKey;
  const lastTlsState = endpoint.health.cert;

  const onSelectedTypeChange = useCallback(
    async (index: number) => {
      const selectedType = PROXY_ENDPOINT_TABS[index].type;

      setSelectedType(selectedType);

      setLoading(true);

      const { errorMessage } = await requestPropertyEditProxyEndpoint(
        propertyId,
        endpointUrlKey,
        {
          type: selectedType,
        }
      );

      if (errorMessage) {
        new Toast(errorMessage, Toast.TYPE_ERROR);
      } else {
        setPropertiesListVersion(Math.random());
      }

      setLoading(false);
    },
    [propertyId, endpointUrlKey, setPropertiesListVersion]
  );

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onDeleteClick = useCallback(async () => {
    showDeleteProxyEndpointDialog({
      property,
      endpoint: property.proxyEndpoints.find(
        (e) => e.urlKey === endpointUrlKey
      )!,
      onDelete: async () => {
        setLoading(true);

        const { errorMessage } = await requestPropertyDeleteProxyEndpoint(
          propertyId,
          endpointUrlKey
        );

        if (errorMessage) {
          new Toast(errorMessage, Toast.TYPE_ERROR);
        } else {
          setPropertiesListVersion(Math.random());
        }

        setLoading(false);
      },
    });
  }, [property, propertyId, endpointUrlKey, setPropertiesListVersion]);

  const onCheckClick = useCallback(async () => {
    setLoading(true);

    const { errorMessage } = await requestPropertyCheckProxyEndpoint(
      propertyId,
      endpointUrlKey
    );

    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
    } else {
      setPropertiesListVersion(Math.random());
    }

    setLoading(false);
  }, [propertyId, endpointUrlKey, setPropertiesListVersion]);

  const displayedUrl =
    actualType === 'dns'
      ? `${endpoint.urlKey}.${property.host}`
      : `${property.host}/${endpoint.urlKey}`;
  const targetDdnsHost =
    endpoint.dnsTarget || getDDnsDomainForDuKey(endpoint.urlKey);
  const { cert } = endpoint.health;

  const tlsCertIcon = getTlsStateIcon({ endpoint });
  const endpointState = getEndpointShortState({ property, endpoint });
  const cantDeleteDnsEndpoint =
    endpoint.type === 'dns' &&
    (endpoint.health.ingress > 0 ||
      // There might be a wildcard DNS record which will always resolve.
      // Hence, we check whether DNS is ok before allowing to delete the endpoint.
      !!endpoint.health.dnsOk);
  const cantDeletePathEndpoint =
    endpoint.type === 'path' && !!endpoint.health.pathOk;
  const deleteEndpointButton = (
    <Button
      image="trash"
      type="secondary"
      disabled={
        isTheOnlyEndpoint ||
        cantDeleteDnsEndpoint ||
        cantDeletePathEndpoint ||
        loading
      }
      disabledTooltip={
        isTheOnlyEndpoint ? (
          <span>
            You can&apos;t delete the last endpoint. Create a new one and then
            delete this one.
          </span>
        ) : cantDeleteDnsEndpoint ? (
          <span>
            To delete this endpoint, delete its DNS record first and then test
            it again. Prior deletion, note that endpoints will still receive
            traffic for a few days because its DNS records are heavily cached
            client-size (think of people not closing tabs). It is recommended to
            first switch the script to use a new endpoint, and then delete this
            one after a few days.
            <br />
            <br />
            {PRODUCT_NAME} allows proxy via this endpoint at any time until it
            is deleted.
          </span>
        ) : cantDeletePathEndpoint ? (
          <span>
            To delete this endpoint, first make sure your reverse proxy is not
            connected to it anymore.
          </span>
        ) : (
          ''
        )
      }
      onClick={onDeleteClick}
    >
      Delete
    </Button>
  );

  const secondLevelDomain =
    property.host.match(/[^.]+\.[^.]+$/)?.[0] || property.host;
  const thirdLevelDomain = property.host.replace(/\.?[^.]+\.[^.]+$/, '');
  const dnsCnameEndpointName = `${endpoint.urlKey}${
    thirdLevelDomain ? '.' : ''
  }${thirdLevelDomain}`;

  // Auto-refresh health when cert is "pending".
  useEffect(() => {
    if (lastTlsState !== 'pending') {
      return;
    }

    const timeout = setTimeout(() => {
      onCheckClick();
    }, 7000);

    return () => clearTimeout(timeout);
  }, [endpoint.health.checkedAt, lastTlsState, onCheckClick]);

  return (
    <div className={`${styles.card}${expanded ? ` ${styles.expanded}` : ''}`}>
      <div
        className={`${styles.head}${
          endpoint.health.ok ? ` ${styles.healthy}` : ''
        }`}
        onClick={toggleExpanded}
      >
        {tlsCertIcon}
        <div className={styles.displayedUrl}>{displayedUrl}</div>
        <div className={styles.flexGrow}></div>
        <div
          className={`${styles.quickStatusBox} ${
            endpoint.editable
              ? ''
              : isEndpointBlacklisted(endpoint)
              ? styles.red
              : endpoint.health.ok
              ? styles.green
              : styles.red
          }`}
        >
          {endpointState}
        </div>
      </div>
      <div className={`${styles.body}${expanded ? ` ${styles.expanded}` : ''}`}>
        <div className={styles.bodyContainer}>
          <div>
            {endpoint.editable && (
              <TabsSwitch
                tabs={PROXY_ENDPOINT_TABS}
                selectedIndex={selectedTypeIndex}
                onSelectedIndexChange={onSelectedTypeChange}
              />
            )}
            {actualType === 'dns' ? (
              <div className={styles.setupGuideContainer}>
                <div>
                  <div>
                    {`https://${displayedUrl}/* → https://${targetDdnsHost}/*`}
                  </div>
                  <div>
                    {endpoint.editable ? (
                      <span>
                        Enable this endpoint by adding the following DNS record
                        to your domain {secondLevelDomain}:
                      </span>
                    ) : (
                      <span>
                        This endpoint expects the following DNS configuration of
                        your domain {secondLevelDomain}, or an equivalent
                        reverse proxy configuration:
                      </span>
                    )}
                  </div>
                </div>
                <Table fullWidth>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>Type</td>
                      <td>TTL</td>
                      <td>Target</td>
                    </tr>
                    <tr>
                      <td>
                        <CopyButton
                          inline
                          withLabel
                          text={dnsCnameEndpointName}
                        />
                      </td>
                      <td>CNAME</td>
                      <td>300</td>
                      <td>
                        <CopyButton inline withLabel text={targetDdnsHost} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : actualType === 'path' ? (
              <div className={styles.setupGuideContainer}>
                <div>
                  <div>
                    {`https://${displayedUrl}/* → https://${targetDdnsHost}/*`}
                  </div>
                  <div>
                    {endpoint.editable ? (
                      <span>
                        Enable this endpoint by setting up your own reverse
                        proxy to talk to {PRODUCT_NAME}.
                      </span>
                    ) : (
                      <span>
                        This endpoint expects your own reverse proxy to talk to{' '}
                        {PRODUCT_NAME}.{' '}
                        <UnsafeExternalLink
                          href={LINK_DOCS_INSTALL_ROUTING_PATH}
                        >
                          Learn more
                        </UnsafeExternalLink>{' '}
                        about setting up path proxy endpoints.
                      </span>
                    )}
                  </div>
                </div>
                <Table fullWidth>
                  <tbody>
                    <tr>
                      <td>Request attribute</td>
                      <td>Value</td>
                    </tr>
                    <tr>
                      <td>
                        Origin (proxy to){' '}
                        <Tooltip
                          type="help"
                          content="The origin is the same for all proxy endpoints, including new proxy endpoints. It's tied to this property."
                        />
                      </td>
                      <td>
                        <CopyButton
                          inline
                          withLabel
                          text={`https://${targetDdnsHost}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>host</code> header{' '}
                        {endpoint.type === 'path' && (
                          <Tooltip
                            type="help"
                            content={`Please note that ${PRODUCT_NAME} serves a self-signed certificate when responding to a request with "${property.host}" header. Your reverse proxy must trust this certificate.`}
                          />
                        )}
                      </td>
                      <td>
                        <CopyButton inline withLabel text={property.host} />
                        {endpoint.type === 'path' && (
                          <>
                            &nbsp;or&nbsp;
                            <CopyButton
                              inline
                              withLabel
                              text={targetDdnsHost}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>x-forwarded-for</code> header
                      </td>
                      <td>The real IP of a client.</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className={styles.setupGuideContainer}>
                Unknown config type {endpoint.type}.
              </div>
            )}
          </div>
          {(endpoint.type === 'dns'
            ? endpoint.health.checkedAt > 0
            : !endpoint.editable) && (
            <div className={styles.endpointChecksBlock}>
              <Table fullWidth>
                <tbody className={styles.statsTable}>
                  <tr>
                    <td>Status</td>
                    <td>
                      <div className={styles.quickStatusBox}>
                        {getEndpointExtendedState({
                          property,
                          endpoint,
                          shortEndpointState: endpointState,
                        })}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Certificate</td>
                    <td>
                      {tlsCertIcon}
                      {cert === 'ok' ? (
                        endpoint.type === 'dns' ? (
                          <span>
                            Certificate on <code>{displayedUrl}</code> is OK.
                          </span>
                        ) : (
                          <span>
                            Certificate on <code>{property.host}</code> is OK.
                          </span>
                        )
                      ) : cert === 'pending' ? (
                        <span>
                          Certificate is being generated by DataUnlocker.
                        </span>
                      ) : cert === 'expired' ? (
                        <span>Certificate has expired and needs renewal.</span>
                      ) : cert === 'missing' ? (
                        <span>
                          {PRODUCT_NAME} will generate TLS cert once DNS record
                          is correct.
                        </span>
                      ) : cert === 'issue_problem' ? (
                        <span>
                          DataUnlocker was unable to issue TLS certificate.
                        </span>
                      ) : cert === 'bad' ? (
                        <span>
                          Invalid TLS certificate is served on this endpoint.
                        </span>
                      ) : (
                        <span>Unknown TLS certificate state.</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Routing</td>
                    <td>
                      {!endpoint.health.pathOk ? (
                        <span>
                          Unknown{' '}
                          <Tooltip
                            type="help"
                            content={
                              <>
                                This proxy endpoint is not configured properly
                                yet.
                                <br />
                                <br />
                                {PROXY_RECOMMENDATION}
                              </>
                            }
                          />
                        </span>
                      ) : endpoint.health.isBehindTheProxy ? (
                        <span>
                          <Icon image="checkmark" margin="right-small" /> Behind
                          the proxy{' '}
                          <Tooltip
                            type="help"
                            content={
                              <span>
                                This endpoint uses a reverse proxy to talk to{' '}
                                {PRODUCT_NAME}, which does not expose{' '}
                                {PRODUCT_NAME}
                                &apos;s IP addresses. This is good for your
                                privacy!
                                <br />
                                <br />
                                {PROXY_RECOMMENDATION}
                              </span>
                            }
                          />
                        </span>
                      ) : (
                        <span>
                          <span className={styles.red}>
                            <Icon image="warning" margin="right-small" />
                          </span>{' '}
                          Direct to {PRODUCT_NAME}{' '}
                          <Tooltip
                            type="help"
                            content={
                              <span>
                                This endpoint doesn&apos;t use a reverse proxy
                                to talk to {PRODUCT_NAME},{' '}
                                <b>
                                  which exposes {PRODUCT_NAME}
                                  &apos;s IP addresses
                                </b>
                                . For instance, if someone visits your site with{' '}
                                <UnsafeExternalLink href={LINK_ADGUARD}>
                                  AdGuard
                                </UnsafeExternalLink>{' '}
                                installed, AdGuard will notice that{' '}
                                {getHostPathForEndpoint(endpoint, property)}{' '}
                                resolves to {PRODUCT_NAME} and will block it
                                sooner or later.
                                <br />
                                <br />
                                {PROXY_RECOMMENDATION}
                              </span>
                            }
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                  {!!endpoint.createdAt && (
                    <tr>
                      <td>Created at</td>
                      <td>
                        {new Date(endpoint.createdAt).toLocaleString([], {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        } as any)}{' '}
                        ({relativeDate(endpoint.createdAt)})
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Created by</td>
                    <td>
                      {endpoint.automatedByPipelineId ? (
                        <span>
                          Automation pipeline{' '}
                          <Link
                            to={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH_PIPELINE(
                              property.id,
                              endpoint.automatedByPipelineId
                            )}
                          >
                            #{endpoint.automatedByPipelineId}
                          </Link>
                        </span>
                      ) : (
                        <span>
                          Manually by the user{' '}
                          <Tooltip
                            type="help"
                            content={
                              <span>
                                Consider implementing webhooks for{' '}
                                <Link
                                  to={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH(
                                    property.id
                                  )}
                                >
                                  automatic installation refresh
                                </Link>{' '}
                                to avoid re-creating proxy endpoints manually
                                once they land in content filtering lists.
                                Anyway, {PRODUCT_NAME} sends email notifications
                                once proxy endpoints land in blacklists.
                              </span>
                            }
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                  {!!endpoint.lastTrafficAt && (
                    <tr>
                      <td>Last traffic at</td>
                      <td>
                        {new Date(endpoint.lastTrafficAt).toLocaleString([], {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        } as any)}{' '}
                        ({relativeDate(endpoint.lastTrafficAt)})
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
          {endpoint.type === 'dns' && !endpoint.health.isBehindTheProxy && (
            <MessageBlock display="inline" type="info">
              Instead of creating a direct DNS record as suggested, we highly
              recommend placing a <b>reverse proxy</b> at <b>{displayedUrl}</b>{' '}
              for your privacy, since {PRODUCT_NAME}&apos;s IP addresses are
              tracked.{' '}
              <SafeExternalLink href={LINK_DOCS_INSTALL_ROUTING}>
                Learn more
              </SafeExternalLink>
              .
            </MessageBlock>
          )}
          {endpoint.editable ? (
            <MessageBlock display="inline" type="info">
              {endpoint.type === 'dns' ? (
                <span>
                  DNS record <code>{displayedUrl}</code>
                </span>
              ) : (
                <span>
                  Path prefix <code>/{endpoint.urlKey}</code>
                </span>
              )}{' '}
              may end up in{' '}
              <UnsafeExternalLink href={LINK_EASYLIST_TO}>
                URL filtering lists
              </UnsafeExternalLink>
              . Be ready to configure a new proxy endpoint once {PRODUCT_NAME}{' '}
              notifies you via email or set up{' '}
              <Link to={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH(propertyId)}>
                automation
              </Link>
              .
            </MessageBlock>
          ) : null}
          <div className={styles.buttonsBar}>
            <div>
              <Button
                image={
                  loading
                    ? 'loading'
                    : endpoint.editable
                    ? 'checkmark'
                    : 'pulse'
                }
                disabled={loading}
                onClick={onCheckClick}
                type={endpoint.health.ok ? 'secondary' : 'primary'}
              >
                {endpoint.editable ? `Validate` : 'Test'}
              </Button>
              {deleteEndpointButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProxyEndpoint;
