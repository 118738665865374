import {
  PropertyProxyEndpoint,
  ServiceSupportGrade,
  UiProperty,
  UiTempProperty,
} from '@dataunlocker/pkg-types';
import { PRODUCT_BASE_DOMAIN } from 'Constants';

export const isTempProperty = (property: UiProperty | UiTempProperty) =>
  !((property as unknown) as UiProperty).billing;

export const getBytesFromPaidCentsForProperty = (
  property: UiProperty,
  cents: number
) => {
  return Math.floor(
    ((1024 * 1024 * 1024 * cents) / property.billing.baseCentsPerGb) *
      property.billing.gbMultiplier
  );
};

export const getProxyEndpointUrl = (
  endpoint: PropertyProxyEndpoint,
  property: UiTempProperty
) => {
  return endpoint.type === 'dns'
    ? `${endpoint.urlKey}.${property.host}`
    : `${property.host}/${endpoint.urlKey}`;
};

export const getDDnsDomainForDuKey = (key: string) =>
  `${key}.ddns.${PRODUCT_BASE_DOMAIN.replace(
    // Strips port (for local development)
    /:[0-9]+$/,
    ''
  )}`;

export const getSupportGradeInfo = (
  grade?: ServiceSupportGrade
): {
  text: string;
  icon: string;
  colorCoding: 'error' | 'info' | 'warning' | 'success' | 'minor';
} =>
  grade === ServiceSupportGrade.full
    ? { text: 'Full support', icon: 'checkmark', colorCoding: 'success' }
    : grade === ServiceSupportGrade.info
    ? {
        text: 'Full support with note',
        icon: 'info',
        colorCoding: 'info',
      }
    : grade === ServiceSupportGrade.partial
    ? { text: 'Partial support', icon: 'warning', colorCoding: 'warning' }
    : grade === ServiceSupportGrade.degraded
    ? { text: 'Limited support', icon: 'error', colorCoding: 'warning' }
    : grade === ServiceSupportGrade.noSupport
    ? { text: 'Not supported', icon: 'close', colorCoding: 'error' }
    : { text: 'Standard support', icon: 'help', colorCoding: 'minor' };

export const getPropertyHealthSummary = (property: UiTempProperty) => {
  // There's a small possible thing of health still being OK while the endpoint health is not OK.
  // (it happens because proxy endpoint's health check can be performed without the script health check)
  // Detect and handle this case.
  const activeProxyEndpoint = property.proxyEndpoints?.find(
    ({ urlKey }) => urlKey === property.health.scriptProxyEndpointUrlKey
  );
  const isHealthy =
    !!property.health.ok &&
    !!activeProxyEndpoint?.health.ok &&
    !activeProxyEndpoint.blacklistedIn?.length;
  const canBeDeleted =
    !property.health.scriptVersion &&
    !!property.proxyEndpoints?.reduce(
      (acc, endpoint) => acc && !(endpoint.health.ingress > 0),
      true
    );

  return {
    isHealthy,
    canBeDeleted,
  };
};
