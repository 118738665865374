import {
  PurchaseState,
  SubscriptionState,
  UiProperty,
  UiSubscription,
} from '@dataunlocker/pkg-types';
import {
  getPropertyPurchasesList,
  requestAddCard,
  requestGetSubscriptions,
} from 'Api';
import { Toast } from 'toaster-js';

export const handleAddCardRequest = async () => {
  const cardAddRequest = await requestAddCard();
  if (cardAddRequest.errorMessage) {
    new Toast(cardAddRequest.errorMessage, Toast.TYPE_ERROR);
    return;
  }
  if (cardAddRequest.response?.purchase.redirectUrl) {
    window.location.href = cardAddRequest.response?.purchase.redirectUrl;
  }
};

export const getCurrentSubscription = async ({
  property,
}: {
  property: UiProperty;
}): Promise<{
  errorMessage?: string;
  subscription: UiSubscription | null;
}> => {
  const { errorMessage, response } = await requestGetSubscriptions({
    propertyId: property.id,
  });
  if (errorMessage || !response) {
    new Toast(errorMessage, Toast.TYPE_ERROR);
    return {
      errorMessage: errorMessage || 'Unable to get subscriptions.',
      subscription: null,
    };
  }

  const currentSubscriptions = response.subscriptions.filter(
    ({ state }) => state !== SubscriptionState.CANCELED
  );
  if (currentSubscriptions.length > 1) {
    return {
      errorMessage: 'Property has more than one subscription.',
      subscription: null,
    };
  }
  return { subscription: currentSubscriptions[0] || null };
};

export const getRedirectUrlForSubscription = async ({
  subscription,
}: {
  subscription: UiSubscription;
}): Promise<{ redirectUrl: string | null; errorMessage?: string }> => {
  const { errorMessage, response } = await getPropertyPurchasesList(
    subscription.propertyId
  );
  if (errorMessage || !response) {
    return {
      errorMessage: errorMessage || 'Unable to get purchases for property.',
      redirectUrl: null,
    };
  }
  const now = Date.now();
  const purchase = response.purchases.find(
    ({ subscriptionId, expiresAt, redirectUrl, state }) =>
      subscriptionId === subscription.id &&
      redirectUrl &&
      expiresAt > now &&
      state === PurchaseState.pending
  );
  return { redirectUrl: purchase?.redirectUrl || null };
};
