export const ROUTE_ROOT = '/';
export const ROUTE_SIGN_IN = '/sign-in';
export const ROUTE_PROPERTIES = '/properties';
export const ROUTE_PROPERTIES_MY = '/properties/my';
export const ROUTE_PROPERTIES_ADD = '/properties/add';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_GENERAL = '/settings/general';
export const ROUTE_SETTINGS_PAYMENT_METHODS = '/settings/payment-methods';
export const ROUTE_FEEDBACK = '/feedback';
export const ROUTE_HELP = '/help';
export const ROUTE_PROPERTY = (propertyId: string) => `/property/${propertyId}`;
export const REVERSE_ROUTE_PROPERTY = (path: string): string => {
  const match = path.match(new RegExp(`^${ROUTE_PROPERTY('([^/]+)')}`));
  return match?.[1] ?? '';
};
export const ROUTE_PROPERTY_DELETE = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/delete`;
export const ROUTE_PROPERTY_DASHBOARD = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/dashboard`;
export const ROUTE_PROPERTY_BILLING = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/billing`;
export const ROUTE_PROPERTY_SETTINGS = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/settings`;
export const ROUTE_PROPERTY_SETTINGS_GENERAL = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/settings/general`;
export const ROUTE_PROPERTY_SETTINGS_USERS = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/settings/users`;
export const ROUTE_PROPERTY_SETTINGS_AUTOMATION = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/settings/automation`;
export const ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH = (
  propertyId: string
) => `${ROUTE_PROPERTY(propertyId)}/settings/automation/refresh`;
export const ROUTE_PROPERTY_SETTINGS_AUTOMATION_SECRETS = (
  propertyId: string
) => `${ROUTE_PROPERTY(propertyId)}/settings/automation/secrets`;
export const ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH_PIPELINE = (
  propertyId: string,
  pipelineId: string
) => `${ROUTE_PROPERTY(propertyId)}/settings/automation/refresh/${pipelineId}`;

export const ROUTE_PROPERTY_SETTINGS_SETUP = (
  propertyId: string,
  type: 'routing' | 'script' | 'health-check' | ':type' | '' = ''
) => `${ROUTE_PROPERTY(propertyId)}/settings/setup/${type}`;
export const ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/settings/setup/routing`;
export const ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/settings/setup/script`;
export const ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH = (propertyId: string) =>
  `${ROUTE_PROPERTY(propertyId)}/settings/setup/health-check`;

// Prefixes work here too.
export const ROUTES_WITH_MENU_DISPLAYED = [
  ROUTE_PROPERTIES_ADD,
  ROUTE_PROPERTIES_MY,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_PAYMENT_METHODS,
  ROUTE_PROPERTY_DASHBOARD,
  ROUTE_PROPERTY_BILLING,
  ROUTE_PROPERTY_SETTINGS,
  ROUTE_PROPERTY_DELETE,
  ROUTE_HELP,
  ROUTE_FEEDBACK,
];
