import React from 'react';
import styles from './styles.module.scss';

interface SelectInputProps {
  value: any;
  options: { label: string; value: any }[];
  label?: string;
  disabled?: boolean;
  compact?: boolean;
  inline?: boolean;
  wide?: boolean;
  onChange: (newValue: any) => any;
}

const SelectInput = ({
  value,
  options,
  onChange,
  disabled,
  inline,
  compact,
  wide,
  label,
}: SelectInputProps) => {
  return (
    <div
      className={[
        styles.textInput,
        compact ? styles.compact : '',
        inline ? styles.inline : '',
        wide ? styles.wide : '',
      ].join(' ')}
    >
      {label && <label>{label}</label>}
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={!!disabled}
      >
        {options.map(({ label, value }, i) => (
          <option key={i} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
