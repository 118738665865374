import { UiProperty } from '@dataunlocker/pkg-types';
import { getPropertyUsageSummary } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Table from 'Components/Common/Table';
import Tooltip from 'Components/Common/Tooltip';
import { ApiAdminV1PropertiesUsageSummaryResponse } from 'Constants';
import React, { useEffect, useState } from 'react';
import { ApiResponse } from 'Types';
import { formatBytes, formatUsdCents } from 'Utils';
import styles from './styles.module.scss';

interface UsageSummaryCardProps {
  property: UiProperty;
}

const CHART_AUTO_REFRESH_RATE = 30 * 1000;

const UsageSummaryCard = ({ property }: UsageSummaryCardProps) => {
  const [stats, setStats] = useState(
    null as ApiResponse<ApiAdminV1PropertiesUsageSummaryResponse> | null
  );
  const [version, setVersion] = useState(0.333);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!document.hidden) {
        setVersion(Math.random());
      }
    }, CHART_AUTO_REFRESH_RATE);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    getPropertyUsageSummary(property.id)
      .then(setStats)
      .catch(() => setStats(null));
  }, [property.id, version]);

  const ready = !!stats?.isOk;
  const hasStats = ready && !!stats && !!stats.response;
  const bytesToCents =
    property.billing.baseCentsPerGb /
    property.billing.gbMultiplier /
    1024 /
    1024 /
    1024;
  return (
    <Card>
      <CardHeader
        title={
          <span>
            <span>Usage summary</span>
            <Tooltip
              type="help"
              content={`A table displaying usage stats for ${property.host}.`}
            />
          </span>
        }
      />
      <Table className={styles.summaryTable} fullWidth>
        <thead>
          <tr>
            <th rowSpan={2}></th>
            <th colSpan={4}>Traffic consumption</th>
            <th rowSpan={2}>Remaining</th>
          </tr>
          <tr>
            <th>All Time</th>
            <th>Last 30d</th>
            <th>Last 7d</th>
            <th>Last 24h</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Requests</th>
            <td>{hasStats ? stats!.response!.count.total : 0}</td>
            <td>{hasStats ? stats!.response!.count.last30d : 0}</td>
            <td>{hasStats ? stats!.response!.count.last7d : 0}</td>
            <td>{hasStats ? stats!.response!.count.last24h : 0}</td>
            <td>
              ≈
              {hasStats
                ? Math.floor(
                    stats!.response!.bytes.left /
                      (stats!.response!.bytes.total /
                        stats!.response!.count.total)
                  ) || '?'
                : '?'}
            </td>
          </tr>
          <tr>
            <th>Traffic</th>
            <td>{formatBytes(hasStats ? stats!.response!.bytes.total : 0)}</td>
            <td>
              {formatBytes(hasStats ? stats!.response!.bytes.last30d : 0)}
            </td>
            <td>{formatBytes(hasStats ? stats!.response!.bytes.last7d : 0)}</td>
            <td>
              {formatBytes(hasStats ? stats!.response!.bytes.last24h : 0)}
            </td>
            <td>
              <b>{formatBytes(hasStats ? stats!.response!.bytes.left : 0)}</b>
            </td>
          </tr>
          <tr>
            <th>Budget</th>
            <td>
              {formatUsdCents(
                hasStats ? stats!.response!.bytes.total * bytesToCents : 0
              )}
            </td>
            <td>
              {formatUsdCents(
                hasStats ? stats!.response!.bytes.last30d * bytesToCents : 0
              )}
            </td>
            <td>
              {formatUsdCents(
                hasStats ? stats!.response!.bytes.last7d * bytesToCents : 0
              )}
            </td>
            <td>
              {formatUsdCents(
                hasStats ? stats!.response!.bytes.last24h * bytesToCents : 0
              )}
            </td>
            <td>
              {formatUsdCents(
                hasStats ? stats!.response!.bytes.left * bytesToCents : 0
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

export default UsageSummaryCard;
