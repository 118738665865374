import React from 'react';
import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME } from 'Constants';

const ConfirmAddExactlyThisDomainDialog = ({
  enteredDomain,
  redirectsTo,
}: {
  enteredDomain: string;
  redirectsTo: string;
}) => {
  return (
    <div>
      <p>
        You have entered <b>{enteredDomain}</b>, but it redirects to{' '}
        <b>{redirectsTo}</b>. Press &quot;Back&quot; and enter &quot;
        {redirectsTo}&quot; in the form if you wanted to set up {PRODUCT_NAME}{' '}
        for <b>{redirectsTo}</b>.
      </p>

      <p>
        If your website is indeed served <u>exactly</u> on{' '}
        <b>{enteredDomain}</b>, you can continue, but note the following:
      </p>

      <ul>
        <li>
          This setup will only work for domain <b>{enteredDomain}</b>.
        </li>
        <li>
          At the end of the setup process, script-related health checks may fail
          because of redirects, but you will still be able to enable and use{' '}
          {PRODUCT_NAME} if the script is installed.
        </li>
      </ul>
    </div>
  );
};

export const showConfirmAddExactlyThisDomainDialog = ({
  enteredDomain,
  redirectsTo,
  onConfirmClick,
}: {
  enteredDomain: string;
  redirectsTo: string;
  onConfirmClick: () => void;
}) =>
  showDialog({
    title: `Did you mean ${redirectsTo}?`,
    icon: 'help',
    content: (
      <ConfirmAddExactlyThisDomainDialog
        enteredDomain={enteredDomain}
        redirectsTo={redirectsTo}
      />
    ),
    confirmButtonText: `No, proceed with ${enteredDomain}`,
    confirmButtonClass: 'danger',
    cancelButtonText: 'Back',
    onConfirmClick,
  });
