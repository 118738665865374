import React, { useEffect, useMemo, useRef } from 'react';
import Icon from '../Icon';
import styles from './styles.module.scss';

export interface StepperStep {
  label: string;
  icon?: string;
  status?: 'new' | 'progress' | 'done';
}

const HorizontalStepper = ({
  steps,
  currentStep,
  scrollable = false,
  font = 'regular',
  onStepSelect,
}: {
  steps: StepperStep[];
  currentStep: number;
  scrollable?: boolean;
  font?: 'regular' | 'small';
  onStepSelect?: (step: StepperStep, i: number) => any;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const htmlName = useMemo(
    () => `stepper-${Math.random().toFixed(10).slice(2)}`,
    []
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const allSelectedChildren = container.querySelectorAll<HTMLDivElement>(
      `.${styles.selected}`
    );
    const lastSelectedChild =
      allSelectedChildren[allSelectedChildren.length - 1];
    if (!lastSelectedChild) {
      return;
    }
    const containerRect = container.getBoundingClientRect();
    const { left, right } = lastSelectedChild.getBoundingClientRect();
    const scrollFocusX =
      lastSelectedChild.offsetLeft - containerRect.left + (right - left) / 2;
    const scrollLeft = scrollFocusX - containerRect.width / 2;
    container.scrollLeft = scrollLeft;
  }, [currentStep, steps.length]);

  return (
    <div
      className={`${styles.stepper}${
        scrollable ? ` ${styles.scrollable}` : ''
      }${font === 'small' ? ` ${styles.fontSmall}` : ''}`}
      ref={containerRef}
    >
      {steps.map((step, i) => (
        <div
          key={i}
          className={`${
            step.status
              ? step.status === 'progress'
                ? styles.progress
                : step.status === 'done'
                ? styles.done
                : ''
              : `${
                  i === currentStep
                    ? styles.progress
                    : i < currentStep
                    ? styles.done
                    : ''
                } ${i <= currentStep ? styles.selected : ''}`
          }${currentStep === i ? ` ${styles.selected}` : ''}`}
        >
          <div>
            <div></div>
            <label>
              <input
                name={htmlName}
                type="radio"
                checked={i === currentStep}
                onChange={() => onStepSelect?.(step, i)}
              />
              {step.icon && (
                <Icon
                  className={styles.icon}
                  image={step.icon}
                  size="big"
                  margin="none"
                />
              )}
            </label>
          </div>
          <div>
            <div>{step.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalStepper;
