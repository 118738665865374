export type TimeRangeTuple = [
  // Absolute or relative (to the Date.now()) point in time when the interval ENDS, in ms.
  // Examples:
  // 1604668663208 = 2020-11-06T13:17:43.208Z, absolute time.
  // 0 or negative value = relative time (to the Date.now()).
  // -3600000 = 1 minute ago
  number,
  // Interval length in milliseconds.
  number
];
// Returns absolute time range
export const getAbsoluteUnixTimeMs = (number: number) =>
  number <= 0 ? Date.now() + number : number;
export const INTERVAL_1H = 1000 * 60 * 60;
export const INTERVAL_6H = 1000 * 60 * 60 * 6;
export const INTERVAL_1D = 1000 * 60 * 60 * 24;
export const INTERVAL_7D = 1000 * 60 * 60 * 24 * 7;
export const INTERVAL_30D = 1000 * 60 * 60 * 24 * 30;
export const INTERVAL_MAX = Date.now();

export const COLOR_PALETTE = [
  '#AF7AC5',
  '#5DADE2',
  '#48C9B0',
  '#58D68D',
  '#F5B041',
  '#DC7633',
  '#5D6D7E',
];
