import {
  REACT_APP_ADMIN_API_URL,
  REACT_APP_XHR_WITH_CREDENTIALS,
  REQUEST_TIMEOUT_SECONDS,
  ROUTE_SIGN_IN,
  SESSION_HEADER_NAME,
} from 'Constants';
import { Toast } from 'toaster-js';
import { ApiRequestOptions, ApiResponse } from 'Types';
import { clearSessionToken, getSessionToken } from './auth';
import { getInitialUrl, setRedirectAfterLoginTo } from './login-navigation';

export const apiRequest = async <RequestType, ResponseType>(
  apiPath: string,
  requestBody?: RequestType,
  requestOptions: ApiRequestOptions<RequestType> = {}
) => {
  if (requestBody && !requestOptions.body) {
    requestOptions.body = requestBody;
  }
  return await httpRequest<RequestType, ResponseType>(
    `${REACT_APP_ADMIN_API_URL}${apiPath}`,
    requestOptions
  );
};

// Soft session expiry - should work when called from anywhere in the app.
let expiredModeOn = false;
export const markSessionAsExpired = ({
  redirectAfterLoginTo = '',
}: { redirectAfterLoginTo?: string } = {}) => {
  if (expiredModeOn) {
    return;
  }
  expiredModeOn = true;

  new Toast(
    'Your session has expired. Please, log in again.',
    Toast.TYPE_ERROR
  );

  setRedirectAfterLoginTo(redirectAfterLoginTo);
  setTimeout(() => {
    clearSessionToken();
    window.history.pushState(null, document.title, ROUTE_SIGN_IN);
  }, 3000);
};

const httpRequest = async <RequestType, ResponseType>(
  url: string,
  { headers = [], body, query, type }: ApiRequestOptions<RequestType> = {}
): Promise<ApiResponse<ResponseType>> => {
  let xhr = new XMLHttpRequest();

  if (query && Object.keys(query).length > 0) {
    url = url.replace(
      /\?|$/,
      (match) =>
        `?${Object.entries(query)
          .map(([a, b]) => `${encodeURIComponent(a)}=${encodeURIComponent(b)}`)
          .join('&')}${match ? '&' : ''}`
    );
  }
  xhr.open(type ? type : body ? 'POST' : 'GET', url);
  if (REACT_APP_XHR_WITH_CREDENTIALS) {
    xhr.withCredentials = true;
  }
  xhr.timeout = REQUEST_TIMEOUT_SECONDS * 1000;

  for (const { name, value } of headers) {
    xhr.setRequestHeader(name, value);
  }

  const authCookie = getSessionToken();
  if (authCookie) {
    xhr.setRequestHeader(SESSION_HEADER_NAME, authCookie);
  }
  if (body) {
    xhr.setRequestHeader('Content-Type', 'application/json');
  }

  return new Promise((resolve) => {
    xhr.ontimeout = () => {
      return resolve({
        isOk: false,
        statusCode: 500,
        errorMessage: `${url}: request timeout`,
      });
    };
    xhr.onerror = (e) => {
      return resolve({
        isOk: false,
        statusCode: 400,
        errorMessage: `${url}: request error; ${e.toString()}`,
      });
    };
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 401) {
          markSessionAsExpired({
            redirectAfterLoginTo: getInitialUrl(),
          });
          return; // Return without resolving as the page will be refreshed.
        }
        let body: any = {};
        if (xhr.responseText) {
          try {
            body = JSON.parse(xhr.responseText);
          } catch (e) {
            body = {
              error:
                xhr.responseText ||
                `Server responded with status code ${xhr.status} and no body text`,
            };
          }
        }
        const isOk = !body.error && xhr.status < 400;
        return resolve({
          isOk,
          statusCode: xhr.status,
          response: isOk ? (body as ResponseType) : undefined,
          errorMessage: body.error ?? undefined,
          errorCode: body.errorCode ?? undefined,
          errorData: body.errorData ?? undefined,
        });
      }
    };

    xhr.send(body ? JSON.stringify(body) : undefined);
  });
};
