import {
  getIsUserRegistered,
  requestLogin,
  requestLoginWithGoogle,
  requestPasswordReset,
  requestRegister,
  resetPassword,
} from 'Api';
import Icon from 'Components/Common/Icon';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import {
  LINK_PRIVACY_POLICY,
  LINK_TERMS_AND_CONDITIONS,
  PRODUCT_COPYRIGHT_YEAR,
  PRODUCT_NAME,
  PRODUCT_URL_FULL,
  ROUTE_PROPERTIES_MY,
  ROUTE_SIGN_IN,
  URL_PARAMETER_PASSWORD_RESET_TOKEN,
} from 'Constants';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sessionTokenState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import {
  getSessionToken,
  isFormValid,
  setFormInvalid,
  setFormValid,
  useGoogleSignIn,
  useReCaptcha,
} from 'Utils';
import {
  clearInitialUrl,
  clearRedirectAfterLoginTo,
  getRedirectAfterLoginTo,
} from 'Utils/login-navigation';
import styles from './styles.module.scss';

const steps = {
  init: 0,
  register: 1,
  login: 2,
  passwordReset: 3,
};
const titlesForSteps: {
  [key: string]: { title: string; subtitle?: string };
} = {
  '0': {
    title: 'Admin Panel Sign In',
    subtitle: 'Type your email to log in or register',
  },
  '1': {
    title: 'Registration',
    subtitle: 'Type your email and password to register',
  },
  '2': {
    title: 'Login',
    subtitle: 'Sign in using your login and password',
  },
  '3': {
    title: 'Password Reset',
    subtitle: 'Please type your new password',
  },
};
/* Make sure there's an Icon for every value in the list. */
const statusMessageTypes = {
  info: 'info',
  error: 'error',
};

const passwordResetSearchRegExp = new RegExp(
  `(?:\\?|&)${URL_PARAMETER_PASSWORD_RESET_TOKEN}=([^&#]+)`
);
const passwordResetToken = window.location.search.match(
  passwordResetSearchRegExp
)?.[1];

const PageSignIn = () => {
  const { getCaptchaToken } = useReCaptcha();
  const formRef = useRef<HTMLFormElement>(null!);
  const fieldsetRef = useRef<HTMLFieldSetElement>(null!);
  const emailInputRef = useRef<HTMLInputElement>(null!);
  const passwordInputRef = useRef<HTMLInputElement>(null!);
  const setSessionCookieState = useSetRecoilState(sessionTokenState);
  const history = useHistory();
  const [googleSignInIsLoading] = useState(false);
  const [state, setState] = useState({
    email: '',
    step: passwordResetToken ? steps.passwordReset : steps.init,
    loading: false,
    password: '',
    retypedPassword: '',
    statusMessage: '',
    statusMessageType: statusMessageTypes.info,
  });

  const onSuccessLogIn = useCallback(() => {
    setSessionCookieState(getSessionToken());
    history.push(getRedirectAfterLoginTo() || ROUTE_PROPERTIES_MY);
    clearRedirectAfterLoginTo();
    clearInitialUrl();
  }, [history, setSessionCookieState]);

  const { signInWithGoogle } = useGoogleSignIn({
    onSuccess: async (response) => {
      const { errorMessage } = await requestLoginWithGoogle(response.code);

      if (errorMessage) {
        new Toast(errorMessage, Toast.TYPE_ERROR);
        setState({
          ...state,
          loading: false,
          statusMessage: '',
        });
        return;
      }
      onSuccessLogIn();
    },
    onFailure: (e) => {
      console.error('Error on Google Sing In', e);
      if (e.type === 'popup_failed_to_open' || e.type === 'popup_closed') {
        return;
      }
      new Toast(
        `Google sign in is not successful: ${
          (e as any).details || (e as any).error || e + ''
        }`,
        Toast.TYPE_ERROR
      );
      setState({
        ...state,
        loading: false,
        statusMessage: '',
      });
    },
  });

  const onSubmit = useCallback(
    async (e): Promise<boolean> => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      if (fieldsetRef.current && fieldsetRef.current?.disabled) {
        return false;
      }
      if (!isFormValid(formRef)) {
        setFormInvalid(formRef);
        return false;
      }
      setFormValid(formRef);

      if (state.step === steps.init) {
        setState({
          ...state,
          loading: true,
          statusMessage: '',
        });

        const { exists, errorMessage } = await getIsUserRegistered(
          state.email,
          await getCaptchaToken()
        );
        if (errorMessage) {
          setState({
            ...state,
            loading: false,
            statusMessage: errorMessage,
            statusMessageType: statusMessageTypes.error,
          });
        } else {
          setState({
            ...state,
            loading: false,
            statusMessage: '',
            step: exists ? steps.login : steps.register,
          });
        }
        requestAnimationFrame(() => {
          if (passwordInputRef.current && passwordInputRef.current.focus) {
            passwordInputRef.current.focus();
          }
        });
      }

      if (
        state.step === steps.register ||
        state.step === steps.login ||
        state.step === steps.passwordReset
      ) {
        if (
          (state.step === steps.register ||
            state.step === steps.passwordReset) &&
          state.password !== state.retypedPassword // todo: what?
        ) {
          setState({
            ...state,
            statusMessage: "The passwords you typed doesn't match!",
            statusMessageType: statusMessageTypes.error,
          });
          return false;
        }
        setState({
          ...state,
          loading: true,
          statusMessage: '',
        });

        const token = await getCaptchaToken();
        let result;

        if (state.step === steps.register) {
          result = await requestRegister(state.email, state.password, token);
        } else if (state.step === steps.login) {
          result = await requestLogin(state.email, state.password, token);
        } else if (state.step === steps.passwordReset) {
          result = await resetPassword(
            passwordResetToken ?? '',
            state.password
          );
        }
        if (result?.errorMessage) {
          setState({
            ...state,
            loading: false,
            statusMessage: `Unable to ${
              state.step === steps.login
                ? 'log in'
                : state.step === steps.passwordReset
                ? 'reset password'
                : 'register you'
            }. Details: ${result.errorMessage}`,
            statusMessageType: result?.errorMessage
              ? statusMessageTypes.error
              : statusMessageTypes.info,
          });
          return false;
        }

        if (state.step === steps.passwordReset) {
          new Toast(
            'Your password has changed. Please, login using your new password.',
            Toast.TYPE_DONE
          );
          setState({
            ...state,
            statusMessage: '',
            step: steps.init,
          });
          history.push(ROUTE_SIGN_IN); // Should clean query params
        } else {
          // Login & register
          // If all good - redirect to domain list. Domain list, not domain setup:
          // as the user could also be the one who was invited to administer the domain.
          onSuccessLogIn();
        }
      }

      // Prevent form submission
      return false;
    },
    [state, history, getCaptchaToken, onSuccessLogIn]
  );
  const resetPasswordClick = useCallback(async () => {
    setState({ ...state, loading: true });
    const { errorMessage, response } = await requestPasswordReset(
      state.email,
      await getCaptchaToken()
    );
    const message =
      errorMessage ||
      (response?.ok ? '' : 'Unable to reset password. Please, try again.');
    setState({
      ...state,
      loading: !message, // When success, keep the form disabled - the new user journey now starts from email.
      statusMessage:
        message ||
        `We've sent you instructions to help you reset the password. Please, check your inbox ${state.email}.`,
      statusMessageType: message
        ? statusMessageTypes.error
        : statusMessageTypes.info,
    });
  }, [state, getCaptchaToken]);
  const backToInitClick = useCallback(async () => {
    if (fieldsetRef.current && fieldsetRef.current?.disabled) {
      return;
    }
    setState({
      ...state,
      step: steps.init,
      email: '',
      statusMessage: '',
    });
    requestAnimationFrame(() => emailInputRef.current.focus());
  }, [state]);
  const onGoogleSignInClick = useCallback(async () => {
    setState({
      ...state,
      loading: true,
      statusMessage: '',
    });
    await signInWithGoogle();
  }, [state, signInWithGoogle]);

  const step = state.step || 0;
  return (
    <div className={styles.component}>
      <h1>{titlesForSteps[step].title}</h1>
      <div className={styles.subTitle}>
        {titlesForSteps[step].subtitle && (
          <span>{titlesForSteps[step].subtitle}</span>
        )}
      </div>
      <div>
        <form ref={formRef} onSubmit={onSubmit}>
          <fieldset ref={fieldsetRef} disabled={state.loading}>
            {state.step !== steps.passwordReset && (
              <div className={styles.inputWithButtonContainer}>
                <input
                  ref={emailInputRef}
                  autoFocus
                  tabIndex={1}
                  type="email"
                  disabled={state.step !== steps.init}
                  value={state.email}
                  onChange={({ target: { value } }) =>
                    setState({ ...state, email: value })
                  }
                  required
                  placeholder="Enter your email here"
                />
                {state.step === steps.init ? (
                  <Icon
                    tabIndex="2"
                    image="forward"
                    hoverable
                    button
                    size="giant"
                    onClick={onSubmit}
                  />
                ) : (
                  <Icon
                    tabIndex="2"
                    image="close"
                    hoverable
                    button
                    size="giant"
                    onClick={backToInitClick}
                  />
                )}
              </div>
            )}
            <div>
              {state.step !== steps.init && (
                <div>
                  <input
                    ref={passwordInputRef}
                    tabIndex={3}
                    minLength={6}
                    type="password"
                    required
                    placeholder="Enter your password"
                    onChange={({ target: { value } }) =>
                      setState({ ...state, password: value })
                    }
                  />
                </div>
              )}
              {(state.step === steps.register ||
                state.step === steps.passwordReset) && (
                <div>
                  <input
                    tabIndex={4}
                    minLength={6}
                    type="password"
                    required
                    placeholder="Enter it again"
                    onChange={({ target: { value } }) =>
                      setState({ ...state, retypedPassword: value })
                    }
                  />
                </div>
              )}
              {state.step === steps.register && (
                <button type="submit" tabIndex={5}>
                  <Icon image="forward" margin="right-regular" />
                  <span>Register</span>
                </button>
              )}
              {state.step === steps.login && (
                <button
                  type="reset"
                  tabIndex={4}
                  className="secondary"
                  onClick={resetPasswordClick}
                >
                  <Icon image="backward" margin="right-regular" />
                  <span>Forgot Pass</span>
                </button>
              )}
              {state.step === steps.login && (
                <button type="submit" tabIndex={5}>
                  <Icon image="forward" margin="right-regular" />
                  <span>Sign In</span>
                </button>
              )}
              {state.step === steps.passwordReset && (
                <button type="submit" tabIndex={5}>
                  <Icon image="forward" margin="right-regular" />
                  <span>Reset Password</span>
                </button>
              )}
            </div>
          </fieldset>
        </form>
      </div>
      {state.step === steps.init && (
        <div>
          <div className={styles.orLine}>OR</div>
          <div>
            <button
              disabled={googleSignInIsLoading}
              className={`${styles.fullWidth} secondary`}
              onClick={onGoogleSignInClick}
            >
              <Icon
                image={googleSignInIsLoading ? 'loading' : 'google'}
                margin="right-regular"
              />
              <span>Sign in with Google</span>
            </button>
          </div>
        </div>
      )}
      <div
        className={`${styles.statusMessage}${
          !state.statusMessage ? ` ${styles.statusMessageHidden}` : ''
        }${styles[state.statusMessageType] ? ` ${styles['error']}` : ''}`}
      >
        <Icon
          image={state.statusMessageType}
          size="small"
          margin="right-small"
        />
        {state.statusMessage && <span>{state.statusMessage}</span>}
      </div>
      {state.step === steps.init && (
        <div className={styles.footer}>
          By signing in, you agree with {PRODUCT_NAME}&apos;s{' '}
          <SafeExternalLink href={LINK_TERMS_AND_CONDITIONS}>
            T&amp;C
          </SafeExternalLink>{' '}
          and{' '}
          <SafeExternalLink href={LINK_PRIVACY_POLICY}>
            privacy policy
          </SafeExternalLink>
          .
          <br />
          <br />
          <b style={{ color: 'var(--color-text-warning)' }}>
            DataUnlocker is currently in beta. Use with caution.
          </b>
          <br />
          <br />© {PRODUCT_COPYRIGHT_YEAR}{' '}
          <SafeExternalLink href={PRODUCT_URL_FULL}>
            {PRODUCT_NAME}
          </SafeExternalLink>
        </div>
      )}
    </div>
  );
};

export default PageSignIn;
