import { UiProperty, UiPurchase } from '@dataunlocker/pkg-types';
import { requestPropertyGetInvoice } from 'Api';
import { showDialog } from 'Components/Dialog';
import { DialogButtonBar } from 'Components/Dialog/Common/DialogButtonBar';
import { PRODUCT_NAME, PRODUCT_SUPPORT_EMAIL } from 'Constants';
import React, { useCallback, useState } from 'react';
import { Toast } from 'toaster-js';
import { downloadFile, formatUsdCents } from 'Utils';
import styles from './styles.module.scss';

const DialogPropertyGetInvoice = ({
  property,
  purchases,
  closeDialog,
  onSuccess,
}: {
  property: UiProperty;
  purchases: UiPurchase[];
  closeDialog: () => void;
  onSuccess?: () => any;
}) => {
  const [loading, setLoading] = useState(false);
  const [companyAddress, setCompanyAddress] = useState('');

  const onConfirmClick = useCallback(async () => {
    setLoading(true);

    const { errorMessage, response } = await requestPropertyGetInvoice(
      property.id,
      {
        purchaseIds: purchases.map(({ id }) => id),
        companyAddress,
      }
    );

    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
    } else if (response?.pdf) {
      downloadFile({
        name: response.name || 'invoice.pdf',
        contentType: 'application/pdf;base64',
        content: response.pdf,
      });
      closeDialog();
      if (onSuccess) {
        onSuccess();
      }
    } else {
      new Toast(
        `Unable to generate PDF; please contact ${PRODUCT_SUPPORT_EMAIL}`,
        Toast.TYPE_ERROR
      );
    }

    setLoading(false);
  }, [onSuccess, closeDialog, property.id, purchases, companyAddress]);
  const onTextareaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCompanyAddress(e.target.value);
    },
    []
  );

  return (
    <div>
      <p>
        You are about to download the invoice for the following purchases made
        on {PRODUCT_NAME} for <b>{property.host}</b>:
      </p>
      <table className={styles.purchasesTable}>
        <thead>
          <tr>
            <th>Created at</th>
            <th>ID</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase) => (
            <tr key={purchase.id}>
              <td>{new Date(purchase.createdAt).toLocaleString()}</td>
              <td>{purchase.id}</td>
              <td>{formatUsdCents(purchase.value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <textarea
        className={styles.textarea}
        placeholder="Optional company address which will be printed in the invoice"
        value={companyAddress}
        onChange={onTextareaChange}
        maxLength={300}
      ></textarea>
      <DialogButtonBar
        onCancelClick={closeDialog}
        cancelButtonText="Cancel"
        cancelButtonDisabled={loading}
        confirmButtonDisabled={loading}
        confirmButtonText="Get Invoice"
        onConfirmClick={onConfirmClick}
      />
    </div>
  );
};

export const showPropertyGetInvoiceDialog = ({
  property,
  purchases,
  onSuccess,
}: {
  property: UiProperty;
  purchases: UiPurchase[];
  onSuccess?: () => any;
}) =>
  showDialog({
    title: `Get invoice for traffic purchases`,
    icon: 'invoice',
    content: ({ closeDialog }) => (
      <DialogPropertyGetInvoice
        property={property}
        purchases={purchases}
        closeDialog={closeDialog}
        onSuccess={onSuccess}
      />
    ),
    displayButtons: false,
    padContent: false,
  });
