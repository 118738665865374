import { UiProperty } from '@dataunlocker/pkg-types';
import { getPropertyStatsQuota } from 'Api';
import DuAreaChart from 'Components/Common/AreaChart';
import FeatureGuard from 'Components/Common/FeatureGuard';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import {
  ApiAdminV1PropertiesStatsQuotaResponse,
  getAbsoluteUnixTimeMs,
  LINK_DOCS_BILLING_TRAFFIC,
  PRODUCT_NAME,
  ROUTE_PROPERTY_BILLING,
  TimeRangeTuple,
} from 'Constants';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiResponse } from 'Types';

const getChartDataFromStats = (
  propertyName: string,
  stats: ApiAdminV1PropertiesStatsQuotaResponse
) => {
  const stepSize = Math.floor(
    (stats.seriesEnd - stats.seriesStart) / stats.seriesSteps
  );
  const seriesMap = new Map(stats.series);
  const dataPoints = [];
  let previousBytes = 0;
  for (let i = 0; i < stats.seriesSteps; ++i) {
    dataPoints.push([
      i,
      (previousBytes = seriesMap.has(i) ? seriesMap.get(i)! : previousBytes),
    ]);
  }
  return dataPoints.map(([step, bytes]) => ({
    time: stats.seriesStart + step * stepSize,
    // Sometimes traffic is like -2MB because of proxy were still working before it was turned off.
    // This uglifies the graph.
    [propertyName]: Math.max(0, bytes || 0),
  }));
};

interface PrepaidTrafficChartProps {
  property: UiProperty;
  responsiveContainerHeight?: number;
  onTimeRangeChange: (newTimeRange: TimeRangeTuple) => void;
  timeRange: TimeRangeTuple;
}

const PrepaidTrafficChart = ({
  property,
  responsiveContainerHeight,
  onTimeRangeChange,
  timeRange,
}: PrepaidTrafficChartProps) => {
  const [stats, setStats] = useState(
    null as ApiResponse<ApiAdminV1PropertiesStatsQuotaResponse> | null
  );

  const chartData = useMemo(
    () =>
      stats?.response
        ? getChartDataFromStats(property?.host || 'Bytes', stats.response)
        : null,
    [stats?.response, property?.host]
  );

  const stepDuration = stats?.response
    ? Math.floor(
        (stats.response.seriesEnd - stats.response.seriesStart) /
          stats.response.seriesSteps
      )
    : 60000;

  const onChartUpdate = useCallback(
    async ({ timeRange }: { timeRange: TimeRangeTuple }) => {
      if (!property?.id) {
        return;
      }

      getPropertyStatsQuota(property.id, {
        from: getAbsoluteUnixTimeMs(timeRange[0] - timeRange[1]),
        to: 0,
      })
        .then(setStats)
        .catch(() => setStats(null));
    },
    [property?.id]
  );

  return (
    <DuAreaChart
      chartTitle="Prepaid traffic"
      chartTooltip={
        <span>
          A chart showing how the prepaid traffic for this property was changing
          over time. If prepaid traffic reaches 0, {PRODUCT_NAME}
          &apos;s proxy will stop until you purchase more traffic.{' '}
          <SafeExternalLink href={LINK_DOCS_BILLING_TRAFFIC}>
            Learn more
          </SafeExternalLink>{' '}
          about how {PRODUCT_NAME} measures traffic.{' '}
          <FeatureGuard feature="billingPlans">
            Manage traffic and billing plans in the{' '}
            <Link to={ROUTE_PROPERTY_BILLING(property.id)}>Billing page.</Link>
          </FeatureGuard>
        </span>
      }
      stepDuration={stepDuration}
      chartData={chartData || []}
      stacked={false}
      onChartDataUpdateRequest={onChartUpdate}
      errorMessage={stats?.errorMessage}
      height={responsiveContainerHeight}
      onTimeRangeChange={onTimeRangeChange}
      timeRange={timeRange}
    />
  );
};

export default PrepaidTrafficChart;
