import { UiProperty, UiTempProperty } from '@dataunlocker/pkg-types';
import { requestLogout } from 'Api';
import { showLogoutDialog } from 'Components/Dialog/Logout';
import {
  LOW_BYTES_NOTIFY_BELOW_DEFAULT,
  PRODUCT_NAME,
  PRODUCT_STATUS_URL,
  PRODUCT_URL_FULL,
  ROUTE_FEEDBACK,
  ROUTE_HELP,
  ROUTE_PROPERTIES,
  ROUTE_PROPERTIES_ADD,
  ROUTE_PROPERTIES_MY,
  ROUTE_PROPERTY,
  ROUTE_PROPERTY_BILLING,
  ROUTE_PROPERTY_DASHBOARD,
  ROUTE_PROPERTY_DELETE,
  ROUTE_PROPERTY_SETTINGS,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_SECRETS,
  ROUTE_PROPERTY_SETTINGS_GENERAL,
  ROUTE_PROPERTY_SETTINGS_SETUP,
  ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH,
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
  ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT,
  ROUTE_PROPERTY_SETTINGS_USERS,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_GENERAL,
  ROUTE_SETTINGS_PAYMENT_METHODS,
  ROUTE_SIGN_IN,
} from 'Constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  currentUserState,
  myPropertiesState,
  myTempPropertiesState,
  sessionTokenState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'State';
import {
  clearSuperAdminSessionToken,
  getPropertyHealthSummary,
  getSuperAdminSessionToken,
  isTempProperty,
  setSessionToken,
} from 'Utils';
import MenuCategory from './Category';
import MenuItem from './Item';
import styles from './styles.module.scss';

interface MenuBarProps {
  className?: string;
}

const MenuBar = ({ className }: MenuBarProps) => {
  const history = useHistory();
  const tempPropertiesLoadable = useRecoilValueLoadable(myPropertiesState);
  const propertiesLoadable = useRecoilValueLoadable(myTempPropertiesState);
  const accountLoadable = useRecoilValueLoadable(currentUserState);
  const setIsLoggedIn = useSetRecoilState(sessionTokenState);
  const [superAdminMenu, setSuperAdminMenu] = useState<
    (() => ReactElement) | null
  >(null);
  const properties =
    propertiesLoadable.state === 'hasValue' ? propertiesLoadable.contents : [];
  const tempProperties =
    tempPropertiesLoadable.state === 'hasValue'
      ? tempPropertiesLoadable.contents
      : [];
  const allProperties = properties.concat(tempProperties);
  const user =
    accountLoadable.state === 'hasValue' ? accountLoadable.contents : null;

  const isSuperAdmin = !!(user && user.isSuperAdmin);
  useEffect(() => {
    if (isSuperAdmin) {
      import('Components/SuperAdmin/MenuSuperAdmin')
        .then((exp) => setSuperAdminMenu(exp.default))
        .catch(console.error);
    }
  }, [isSuperAdmin]);

  const getSetupMenu = (
    property: UiTempProperty,
    title = 'Continue Setup',
    warning = ''
  ) => (
    <MenuItem
      icon="setup"
      text={title}
      path={ROUTE_PROPERTY_SETTINGS_SETUP(property.id)}
      to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)}
      warningMessage={warning}
    >
      <MenuItem
        icon="split"
        text="Proxy Endpoints"
        path={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)}
      />
      <MenuItem
        icon="code"
        text="Script Installation"
        path={ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT(property.id)}
      />
      <MenuItem
        icon="pulse"
        text="Health Check"
        path={ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH(property.id)}
        warningMessage={warning}
      />
    </MenuItem>
  );

  return (
    <div className={`${styles.container}${className ? ` ${className}` : ''}`}>
      <MenuCategory title="My Account">
        <MenuItem
          icon="www"
          text="Properties"
          path={ROUTE_PROPERTIES}
          to={ROUTE_PROPERTIES_MY}
        >
          <MenuItem
            icon="www"
            text="My Properties"
            path={ROUTE_PROPERTIES_MY}
          />
          <MenuItem
            icon="add"
            text="Add Property"
            path={ROUTE_PROPERTIES_ADD}
          />
        </MenuItem>
        <MenuItem
          icon="settings"
          text="Settings"
          path={ROUTE_SETTINGS}
          to={ROUTE_SETTINGS_GENERAL}
          warningMessage={
            !user?.emailConfirmed ? 'Please, verify your email' : ''
          }
        >
          <MenuItem
            icon="masterswitch"
            text="General"
            path={ROUTE_SETTINGS_GENERAL}
            warningMessage={
              !user?.emailConfirmed ? 'Please, verify your email' : ''
            }
          />
          <MenuItem
            icon="money"
            text="Payment Methods"
            path={ROUTE_SETTINGS_PAYMENT_METHODS}
          />
        </MenuItem>
        <MenuItem
          icon="logout"
          text="Log Out"
          path="#"
          onClick={() =>
            showLogoutDialog({
              onLogout: async () => {
                await requestLogout();
                const adminToken = getSuperAdminSessionToken();
                if (adminToken) {
                  clearSuperAdminSessionToken();
                  setSessionToken(adminToken);
                  setIsLoggedIn(adminToken);
                  history.push(ROUTE_PROPERTIES_MY);
                } else {
                  setIsLoggedIn('');
                  history.push(ROUTE_SIGN_IN);
                }
              },
            })
          }
        />
      </MenuCategory>
      {!!allProperties.length && (
        <MenuCategory title="Properties">
          {allProperties.map((property) => {
            const isTemp = isTempProperty(property);
            const isHealthy =
              !isTemp && getPropertyHealthSummary(property).isHealthy;
            const setupWarningMessage =
              !isTemp && !isHealthy ? 'Property setup is unhealthy' : '';
            const billingWarningMessage = !isTemp
              ? (property as UiProperty).bytesPrepaid <=
                (property as UiProperty).bytesProxied
                ? 'Prepaid traffic has ran out'
                : (property as UiProperty).bytesProxied >
                  (property as UiProperty).bytesPrepaid -
                    ((property as UiProperty).lowBytesNotifyBelow ||
                      LOW_BYTES_NOTIFY_BELOW_DEFAULT)
                ? 'Prepaid traffic is low'
                : ''
              : '';
            return (
              <MenuItem
                key={property.id}
                icon="www"
                to={
                  isTemp
                    ? ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)
                    : ROUTE_PROPERTY_DASHBOARD(property.id)
                }
                text={property.host}
                path={ROUTE_PROPERTY(property.id)}
                warningMessage={billingWarningMessage || setupWarningMessage}
              >
                {!isTemp && (
                  <MenuItem
                    icon="dashboard"
                    text="Dashboard"
                    path={ROUTE_PROPERTY_DASHBOARD(property.id)}
                  />
                )}
                {!isTemp && (
                  <MenuItem
                    icon="money"
                    text="Billing"
                    path={ROUTE_PROPERTY_BILLING(property.id)}
                    warningMessage={billingWarningMessage}
                  />
                )}
                {!isTemp && (
                  <MenuItem
                    icon="settings"
                    text="Settings"
                    to={ROUTE_PROPERTY_SETTINGS_GENERAL(property.id)}
                    path={ROUTE_PROPERTY_SETTINGS(property.id)}
                    warningMessage={setupWarningMessage}
                  >
                    <MenuItem
                      icon="masterswitch"
                      text="General"
                      path={ROUTE_PROPERTY_SETTINGS_GENERAL(property.id)}
                    />
                    <MenuItem
                      icon="user"
                      text="User Management"
                      path={ROUTE_PROPERTY_SETTINGS_USERS(property.id)}
                    />
                    {getSetupMenu(property, 'Setup', setupWarningMessage)}
                    <MenuItem
                      icon="ci"
                      text="Automation"
                      path={ROUTE_PROPERTY_SETTINGS_AUTOMATION(property.id)}
                      to={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH(
                        property.id
                      )}
                    >
                      <MenuItem
                        icon="padlockClosed"
                        text="Secrets"
                        path={ROUTE_PROPERTY_SETTINGS_AUTOMATION_SECRETS(
                          property.id
                        )}
                      />
                      <MenuItem
                        icon="automation"
                        text="Installation refresh"
                        path={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH(
                          property.id
                        )}
                      />
                    </MenuItem>
                  </MenuItem>
                )}
                {isTemp && getSetupMenu(property)}
                {isTemp && (
                  <MenuItem
                    icon="user"
                    text="User Management"
                    path={ROUTE_PROPERTY_SETTINGS_USERS(property.id)}
                  />
                )}
                {isTemp && (
                  <MenuItem
                    icon="close"
                    text="Cancel Setup"
                    path={ROUTE_PROPERTY_DELETE(property.id)}
                  />
                )}
              </MenuItem>
            );
          })}
        </MenuCategory>
      )}
      <MenuCategory title={PRODUCT_NAME}>
        <MenuItem icon="lab" text="Try DataUnlocker 2.0 🆕" path="https://bit.ly/dataunlocker-2" />
        <MenuItem
          icon="pulse"
          text="Service Status"
          path={PRODUCT_STATUS_URL}
        />
        <MenuItem icon="info" text="Docs &amp; Support" path={ROUTE_HELP} />
        <MenuItem icon="feedback" text="Feedback" path={ROUTE_FEEDBACK} />
        <MenuItem icon="logo" text="Website" path={PRODUCT_URL_FULL} />
      </MenuCategory>
      {superAdminMenu}
    </div>
  );
};

export default MenuBar;
