import { AlwaysProxyBrowsersBit } from '@dataunlocker/pkg-types';
import React from 'react';
import { clearBit, getBit, setBit } from 'Utils';
import Icon from '../Icon';
import styles from './styles.module.scss';

export const ALWAYS_PROXY_OPTIONS = [
  {
    icon: 'www',
    name: 'Other browsers',
    bit: AlwaysProxyBrowsersBit.AllBrowsers,
  },
  {
    icon: 'brave',
    name: 'Brave',
    bit: AlwaysProxyBrowsersBit.Brave,
  },
  {
    icon: 'firefox',
    name: 'Firefox',
    bit: AlwaysProxyBrowsersBit.Firefox,
  },
  {
    icon: 'opera',
    name: 'Opera',
    bit: AlwaysProxyBrowsersBit.Opera,
  },
  {
    icon: 'chrome',
    name: 'Chrome',
    bit: AlwaysProxyBrowsersBit.Chrome,
  },
  {
    icon: 'safari',
    name: 'Safari',
    bit: AlwaysProxyBrowsersBit.Safari,
  },
  {
    icon: 'edge',
    name: 'Edge',
    bit: AlwaysProxyBrowsersBit.Edge,
  },
];

interface AlwaysProxyControlsProps {
  disabled?: boolean;
  config: number;
  onChange?: (config: number) => unknown;
}

const AlwaysProxyControls = ({
  disabled,
  config,
  onChange,
}: AlwaysProxyControlsProps) => {
  return (
    <span className={disabled ? styles.disabled : ''}>
      {ALWAYS_PROXY_OPTIONS.map(({ icon, name, bit }) => (
        <label className={styles.alwaysProxyOptions} key={bit}>
          <input
            type="checkbox"
            checked={!!getBit(config, bit)}
            disabled={disabled}
            onChange={(e) =>
              onChange?.(
                bit === 0
                  ? e.target.checked
                    ? ALWAYS_PROXY_OPTIONS.reduce(
                        (acc, o) => setBit(acc, o.bit),
                        0
                      )
                    : 0
                  : e.target.checked
                  ? setBit(config, bit)
                  : clearBit(config, bit)
              )
            }
          />{' '}
          <Icon image={icon} margin="small" />
          <span className={styles.label}>{name}</span>
        </label>
      ))}
    </span>
  );
};

export default AlwaysProxyControls;
