import {
  ApiAdminV1PropertiesStatsResponse,
  COLOR_PALETTE,
  PRODUCT_NAME,
} from 'Constants';
import { DashboardView } from 'Types';
import {
  MetricsAggregate,
  UiProxiedServiceConfig,
} from '@dataunlocker/pkg-types';

const serviceGroups: Array<[string, RegExp]> = [
  [PRODUCT_NAME, /dataunlocker\.com$/],
  ['Facebook', /facebook\.(?:[a-z]+)$/],
  ['Hotjar', /hotjar/],
  ['AdRoll', /adroll/],
  ['Twitter', /twitter/],
  ['YouTube', /youtube\.[a-z]+(?:\.[a-z]+)?$/],
  ['GitHub', /github(?:usercontent)?\.(?:com|io)$/],
  ['Segment', /segment\.(?:com|io)$/],
  [
    'Google Analytics',
    /(?:google-analytics.com|stats.g.doubleclick.net|analytics.google.com)$/,
  ],
  ['Google', /google\.[a-z]+(?:\.[a-z]+)?$|(?:\.|^)google|gstatic\./],
  ['Other services', /.*/],
];

const getSortedServiceGroupsNames = (
  proxiedServices: { [key: string]: UiProxiedServiceConfig },
  allServicesSet: Set<string>
) => {
  const otherServicesGroupName = serviceGroups[serviceGroups.length - 1][0];
  const groupedServices: Map<string, string[]> = new Map();
  const allSortedServicesList = Array.from(allServicesSet).sort(
    (name1, name2) => {
      const enabled1 = !!(
        proxiedServices[name1] && proxiedServices[name1].enabled !== false
      );
      const enabled2 = !!(
        proxiedServices[name2] && proxiedServices[name2].enabled !== false
      );
      return enabled1 === enabled2
        ? name1 < name2
          ? -1
          : name1 > name2
          ? 1
          : 0
        : +enabled2 - +enabled1;
    }
  );
  for (const serviceName of allSortedServicesList) {
    for (const [group, regExp] of serviceGroups) {
      if (regExp.test(serviceName)) {
        let services: string[] = [];
        if (!groupedServices.has(group)) {
          groupedServices.set(group, services);
        } else {
          services = groupedServices.get(group)!;
        }
        services.push(serviceName);
        break;
      }
    }
  }
  return Array.from(groupedServices.entries()).sort(([groupA], [groupB]) => {
    // Keeps "other services" always in the bottom
    if (groupA === otherServicesGroupName) {
      return 1;
    }
    if (groupB === otherServicesGroupName) {
      return -1;
    }
    if (groupA < groupB) {
      return -1;
    }
    if (groupA > groupB) {
      return 1;
    }
    return 0;
  });
};

export const getDashboardView = (
  statsApiResponse: ApiAdminV1PropertiesStatsResponse,
  proxiedServices: { [key: string]: UiProxiedServiceConfig } = {}
): DashboardView => {
  const allServicesSet = new Set([
    ...(statsApiResponse.serviceData.map((data) => data.name) || []),
    ...Object.keys(proxiedServices),
  ]);
  const serviceGroupNames = getSortedServiceGroupsNames(
    proxiedServices,
    allServicesSet
  );
  const serviceToStats = statsApiResponse.serviceData.reduce(
    (map, { name, series }) => {
      map.set(name, series);
      return map;
    },
    new Map<
      string,
      ApiAdminV1PropertiesStatsResponse['serviceData'][42]['series']
    >()
  );
  const dashboardView: DashboardView = {
    seriesSteps: statsApiResponse.seriesSteps,
    seriesStart: statsApiResponse.seriesStart,
    seriesEnd: statsApiResponse.seriesEnd,
    totals: {
      totalBytes: 0,
      totalCount: 0,
      bytes: [0, 0, 0, 0, 0],
      count: [0, 0, 0, 0, 0],
    },
    groups: [],
  };

  let i = 0;
  for (const [groupName, serviceNamesInGroup] of serviceGroupNames) {
    let totalCountGroup: MetricsAggregate = [0, 0, 0, 0, 0];
    let totalTotalGroupCount = 0;
    let totalBytesGroup: MetricsAggregate = [0, 0, 0, 0, 0];
    let totalTotalGroupBytes = 0;
    let series: DashboardView['groups'][0]['series'] = [];

    dashboardView.groups.push({
      name: groupName,
      color: COLOR_PALETTE[i++ % COLOR_PALETTE.length],
      services: serviceNamesInGroup.map((service) => {
        const serviceStats = serviceToStats.get(service) || [];
        let totalServiceCount: MetricsAggregate = [0, 0, 0, 0, 0];
        let totalTotalServiceCount = 0;
        let totalServiceBytes: MetricsAggregate = [0, 0, 0, 0, 0];
        let totalTotalServiceBytes = 0;
        for (const [step, count, bytes] of serviceStats) {
          let stepCount = 0;
          let stepBytes = 0;
          for (let j = 0; j < 5; ++j) {
            const thisCount = count[j] || 0;
            const thisBytes = bytes[j] || 0;
            stepCount += thisCount;
            stepBytes += thisBytes;
            totalServiceCount[j] += thisCount;
            totalServiceBytes[j] += thisBytes;
            totalCountGroup[j] += thisCount;
            totalBytesGroup[j] += thisBytes;
          }
          totalTotalServiceCount += stepCount;
          totalTotalGroupCount += stepCount;
          totalTotalServiceBytes += stepBytes;
          totalTotalGroupBytes += stepBytes;
          series.push({
            step,
            count: stepCount,
            bytes: stepBytes,
          });
        }
        return {
          name: service,
          config: proxiedServices[service] || {},
          totals: {
            count: totalServiceCount,
            totalCount: totalTotalServiceCount,
            bytes: totalServiceBytes,
            totalBytes: totalTotalServiceBytes,
          },
        };
      }),
      totals: {
        count: totalCountGroup,
        totalCount: totalTotalGroupCount,
        bytes: totalBytesGroup,
        totalBytes: totalTotalGroupBytes,
      },
      series,
    });

    dashboardView.totals.bytes = dashboardView.totals.bytes.map(
      (v, i) => v + totalBytesGroup[i]
    ) as MetricsAggregate;
    dashboardView.totals.count = dashboardView.totals.count.map(
      (v, i) => v + totalCountGroup[i]
    ) as MetricsAggregate;
    dashboardView.totals.totalBytes += totalTotalGroupBytes;
    dashboardView.totals.totalCount += totalTotalGroupCount;
  }

  return dashboardView;
};
