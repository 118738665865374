import {
  Permission,
  PropertyNotificationType,
  UiProperty,
  UiUser,
} from '@dataunlocker/pkg-types';
import {
  getIsUserRegistered,
  requestPropertyUsers,
  requestPropertyUsersUpdate,
} from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import PageHeader from 'Components/Common/Pages/Header';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import Table from 'Components/Common/Table';
import Tooltip from 'Components/Common/Tooltip';
import { showConfirmGrantPermissionToNonExistingUserDialog } from 'Components/Dialog/ConfirmGrantPermissionToNonExistingUser';
import {
  ApiPropertiesUsersListResponse,
  ApiPropertiesUsersUpdateRequest,
  LINK_DOCS_CONFIG_USERS,
} from 'Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import { useReCaptcha } from 'Utils';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty;
  user: UiUser;
}

interface UserRecord {
  id: string;
  email: string;
  permission: Permission;
  notificationTypes: PropertyNotificationType[];
  deleted: Boolean;
}

// Must have the same keys as UserRecord.
interface UserChange {
  permission?: Permission;
  notificationTypes?: PropertyNotificationType[];
  deleted?: Boolean;
}

interface UserChanges {
  [key: string /*user.id*/]: UserChange;
}

const areEqualSets = (array1: string[], array2: string[]) =>
  array1.slice().sort().join(',') === array2.slice().sort().join(',');

const updateChanges = (
  changes: UserChanges,
  users: UserRecord[],
  userId: string,
  setChanges: (
    value: React.SetStateAction<{
      [key: string]: UserChange;
    }>
  ) => void,
  prop: keyof UserRecord,
  propValue: any
) => {
  const user = users.find(({ id }) => id === userId);
  let change = changes[userId] || {};

  if (
    // Compare for equality of a single value and array (array to have same elements).
    user &&
    (user[prop] === propValue ||
      (user[prop] instanceof Array &&
        areEqualSets(user[prop] as string[], propValue)))
  ) {
    delete change[prop as keyof UserChange];
  } else {
    change = changes[userId] = {
      ...change,
      [prop]: propValue,
    };
  }

  if (Object.keys(change).length === 0) {
    delete changes[userId];
    setChanges({
      ...changes,
    });
  } else {
    setChanges({
      ...changes,
      [userId]: change,
    });
  }
};

const PagePropertySettings = ({ property, user }: ComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ApiPropertiesUsersListResponse['users']>(
    []
  );
  const [changes, setChanges] = useState<UserChanges>({});
  const [addingUser, setAddingUser] = useState(false);
  const { getCaptchaToken } = useReCaptcha();

  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  useEffect(() => {
    requestPropertyUsers(property.id).then(({ errorMessage, response }) => {
      if (errorMessage) {
        new Toast(errorMessage, Toast.TYPE_ERROR);
        setUsers([]);
        return;
      } else if (response) {
        setUsers(response.users);
      }
    });
  }, [property.id, property.permissionBindings.length]);

  const originalUsersList: UserRecord[] = users
    .map((user) => {
      const userPermissionBinding = property.permissionBindings.find(
        ({ userId }) => user.id === userId
      );
      const userPermission: Permission = userPermissionBinding
        ? userPermissionBinding.permissions[0]
        : Permission.viewer;

      return {
        id: user.id,
        email: user.email,
        permission: userPermission,
        notificationTypes: userPermissionBinding?.notificationTypes || [],
        deleted: false,
      };
    })
    .concat(
      addingUser
        ? [
            {
              id: '',
              email: '',
              permission: Permission.owner,
              notificationTypes: Object.values(PropertyNotificationType),
              deleted: false,
            },
          ]
        : []
    );
  const actualUsersList: UserRecord[] = originalUsersList.reduce(
    (users, user) => {
      const change = changes[user.id];
      if (!change) {
        users.push(user);
        return users;
      }
      if (change.deleted) {
        return users;
      }
      users.push({
        ...user,
        ...change,
      });
      return users;
    },
    [] as UserRecord[]
  );

  const onUserRoleChange = useCallback(
    (userId: string, permission: Permission) => {
      const otherOwners = actualUsersList.filter(
        ({ id, permission }) => id !== userId && permission === Permission.owner
      );
      if (!otherOwners.length && permission !== Permission.owner) {
        new Toast(
          'There must be at least one owner of the property.',
          Toast.TYPE_ERROR
        );
        return;
      }
      updateChanges(
        changes,
        originalUsersList,
        userId,
        setChanges,
        'permission',
        permission
      );
    },
    [changes, originalUsersList, actualUsersList]
  );

  const onUserNotificationTypeChange = useCallback(
    (
      userId: string,
      notificationType: PropertyNotificationType,
      isSet: boolean
    ) => {
      const thisUser = actualUsersList.find(({ id }) => id === userId);
      const currentNotifications = new Set(
        thisUser?.notificationTypes || Object.values(PropertyNotificationType)
      );

      if (isSet) {
        currentNotifications.add(notificationType);
      } else {
        const otherUsersWithThisNotification = actualUsersList.filter(
          ({ id, notificationTypes }) =>
            id !== userId && notificationTypes.includes(notificationType)
        );
        if (otherUsersWithThisNotification.length === 0) {
          new Toast(
            `There must be at least one user receiving "${notificationType}" notifications.`,
            Toast.TYPE_ERROR
          );
          return;
        }
        currentNotifications.delete(notificationType);
      }

      updateChanges(
        changes,
        originalUsersList,
        userId,
        setChanges,
        'notificationTypes',
        Array.from(currentNotifications)
      );
    },
    [changes, originalUsersList, actualUsersList]
  );

  const onUserDelete = useCallback(
    (userId: string) => {
      const otherOwners = actualUsersList.filter(
        ({ id, permission }) => id !== userId && permission === Permission.owner
      ).length;
      if (!otherOwners) {
        new Toast(
          'There must be at least one owner of the property.',
          Toast.TYPE_ERROR
        );
        return;
      }

      for (const notificationType of Object.values(PropertyNotificationType)) {
        const otherUsersWithThisNotification = actualUsersList.filter(
          ({ id, notificationTypes }) =>
            id !== userId && notificationTypes.includes(notificationType)
        );
        if (otherUsersWithThisNotification.length === 0) {
          new Toast(
            `Deleting this user will result in no one receiving "${notificationType}" notifications. Please, select who will receive this notification first.`,
            Toast.TYPE_ERROR
          );
          return;
        }
      }

      if (!userId) {
        delete changes[''];
        setAddingUser(false);
        setChanges(changes);
        return;
      }

      updateChanges(
        changes,
        originalUsersList,
        userId,
        setChanges,
        'deleted',
        true
      );
    },
    [changes, originalUsersList, actualUsersList]
  );

  const onNewUserEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateChanges(
        changes,
        originalUsersList,
        '',
        setChanges,
        'email',
        event.currentTarget.value
      );
    },
    [changes, originalUsersList]
  );

  const onResetClick = useCallback(() => {
    setChanges({});
    setAddingUser(false);
  }, []);

  const onUserAdd = useCallback(() => {
    setAddingUser(true);
  }, []);

  const newUser = actualUsersList.find((u) => u.id === '');
  const newUserEmail = newUser?.email || '';
  const newUserPermission: Permission =
    newUser?.permission || Permission.viewer;
  const onSaveClick = useCallback(async () => {
    const actualSave = async () => {
      setLoading(true);
      const { errorMessage } = await requestPropertyUsersUpdate(property.id, {
        add: addingUser
          ? [
              {
                email: newUserEmail,
                access: newUserPermission,
                notificationTypes: newUser?.notificationTypes || [],
              },
            ]
          : [],
        update: Object.entries(changes).reduce(
          (arr, [userId, { permission, notificationTypes }]) => {
            const thisUser = actualUsersList.find((u) => u.id === userId);
            if (userId && arr && (permission || notificationTypes)) {
              arr.push({
                id: userId,
                access: permission || thisUser?.permission || Permission.viewer,
                notificationTypes:
                  notificationTypes || thisUser?.notificationTypes || [],
              });
            }
            return arr;
          },
          [] as ApiPropertiesUsersUpdateRequest['update']
        ),
        deleteIds: Object.entries(changes).reduce(
          (arr, [userId, { deleted }]) => {
            if (deleted) {
              arr.push(userId);
            }
            return arr;
          },
          [] as string[]
        ),
      });
      setLoading(false);
      if (errorMessage) {
        new Toast(errorMessage, Toast.TYPE_ERROR);
      } else {
        setChanges({});
        if (addingUser) {
          setAddingUser(false);
        }
        setPropertiesListVersion(Math.random());
      }
    };

    setLoading(true);
    if (addingUser) {
      const result = await getIsUserRegistered(
        newUserEmail,
        await getCaptchaToken()
      );
      if (!result.exists) {
        setLoading(false);
        showConfirmGrantPermissionToNonExistingUserDialog({
          email: newUserEmail,
          permission: newUserPermission,
          domain: property.host,
          onConfirm: actualSave,
        });
      } else {
        await actualSave();
      }
    } else {
      await actualSave();
    }
  }, [
    addingUser,
    newUserEmail,
    newUserPermission,
    newUser,
    property.host,
    property.id,
    getCaptchaToken,
    setPropertiesListVersion,
    changes,
    actualUsersList,
  ]);

  const hasChanges = Object.keys(changes).length > 0 || addingUser;
  const amIOwner = property.permissionBindings
    .find(({ userId }) => userId === user.id)
    ?.permissions.includes(Permission.owner);
  return (
    <div>
      <PageHeader icon="user" title={`${property.host} • Users`} />
      <div className={styles.container}>
        <Card>
          <CardHeader
            title={
              <span>
                Accounts which can access {property.host}
                <Tooltip
                  type="help"
                  content={
                    <span>
                      Manage who can access this property and how. Read more
                      about user management{' '}
                      <SafeExternalLink href={LINK_DOCS_CONFIG_USERS}>
                        here
                      </SafeExternalLink>
                      .
                    </span>
                  }
                />
              </span>
            }
          />
          <Table className={styles.table} fullWidth>
            <thead>
              <tr>
                <th rowSpan={2}>Email</th>
                <th rowSpan={2} className={styles.shrink}>
                  Access type
                  <Tooltip
                    type="help"
                    content={
                      <div className={styles.notificationsHelpTooltipContent}>
                        Owners can manage this property, its users, delete it,
                        etc. Viewers can only view this property and receive
                        notifications, but can&apos;t change anything.
                      </div>
                    }
                  />
                </th>
                <th colSpan={2}>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    Notifications
                    <Tooltip
                      type="help"
                      content={
                        <div className={styles.notificationsHelpTooltipContent}>
                          Select what notifications this user receives about{' '}
                          <b>{property.host}</b>.
                          <br />
                          <br />
                          <b>Dev</b>: development and maintenance-related
                          notifications, such as: script update required,
                          routing URL change required, property stopped
                          receiving traffic, etc.
                          <br />
                          <br />
                          <b>Traffic</b>: traffic-related notifications in
                          concern to billing, such as: traffic is below the
                          threshold specified on the billing page, traffic has
                          ran out and payment is required, etc.
                        </div>
                      }
                    />
                  </span>
                </th>
                <th rowSpan={2} className={styles.shrink}>
                  Actions
                </th>
              </tr>
              <tr>
                <th style={{ width: '50px' }}>Dev</th>
                <th style={{ width: '50px' }}>Traffic</th>
              </tr>
            </thead>
            <tbody>
              {actualUsersList.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>
                      {user.id === '' ? (
                        <input
                          type="email"
                          placeholder="my.colleague@email.com"
                          onChange={onNewUserEmailChange}
                        />
                      ) : (
                        user.email
                      )}
                    </td>
                    <td>
                      <select
                        value={user.permission}
                        disabled={!amIOwner}
                        onChange={(e) =>
                          onUserRoleChange(
                            user.id,
                            e.currentTarget.value as Permission
                          )
                        }
                      >
                        <option value={Permission.owner}>Owner</option>
                        <option value={Permission.viewer}>Viewer</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        disabled={!amIOwner}
                        checked={user.notificationTypes.includes(
                          PropertyNotificationType.dev
                        )}
                        onChange={(e) =>
                          onUserNotificationTypeChange(
                            user.id,
                            PropertyNotificationType.dev,
                            e.currentTarget.checked
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        disabled={!amIOwner}
                        checked={user.notificationTypes.includes(
                          PropertyNotificationType.traffic
                        )}
                        onChange={(e) =>
                          onUserNotificationTypeChange(
                            user.id,
                            PropertyNotificationType.traffic,
                            e.currentTarget.checked
                          )
                        }
                      />
                    </td>
                    <td>
                      {amIOwner && (
                        <Icon
                          image="trash"
                          hoverable
                          size="big"
                          onClick={() => onUserDelete(user.id)}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div>
            <button
              disabled={
                !hasChanges ||
                loading ||
                (addingUser && !/@[^.]+\.[^.]+$/.test(newUserEmail))
              }
              onClick={onSaveClick}
            >
              Save
            </button>
            <button
              disabled={!hasChanges || loading}
              className="secondary"
              onClick={onResetClick}
            >
              Reset
            </button>
            {amIOwner && (
              <button
                disabled={addingUser || loading}
                className={!(!hasChanges || loading) ? 'secondary' : ''}
                onClick={onUserAdd}
              >
                Add user
              </button>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PagePropertySettings;
