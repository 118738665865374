import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME } from 'Constants';
import React from 'react';
import {
  currentUserState,
  selectedPropertyState,
  useRecoilValueLoadable,
} from 'State';
import { handleAddCardRequest } from 'Utils';

const AddCardDialog = () => {
  const userLoadable = useRecoilValueLoadable(currentUserState);
  const propertyLoadable = useRecoilValueLoadable(selectedPropertyState);

  return (
    <div>
      <p>
        This card will be added to your personal account
        <b>
          {userLoadable.state === 'hasValue'
            ? ' ' + userLoadable.contents?.email
            : ''}
        </b>
        . You will be able to select it as a payment method in any property you
        have an editor access to
        {propertyLoadable.state === 'hasValue' && propertyLoadable.contents
          ? `, including ${propertyLoadable.contents.host}`
          : ''}
        .
      </p>
      <p>
        You will be redirected to our payment provider&apos;s page and may be
        charged a small amount ($1 or less) for card authorization purposes,
        which will come back shortly. {PRODUCT_NAME} stores only a masked card
        number, its expiry date and a unique card token required to perform
        automatic charges. You can remove your card at any time.
      </p>
    </div>
  );
};

export const showAddCardDialog = () =>
  showDialog({
    title: 'Add a new card',
    icon: 'money',
    content: <AddCardDialog />,
    confirmButtonText: 'Pre-authorize a new card',
    cancelButtonText: 'Cancel',
    onConfirmClick: handleAddCardRequest,
  });
