import { Permission, UiProperty, UiUser } from '@dataunlocker/pkg-types';
import Button from 'Components/Common/Button';
import Card from 'Components/Common/Card';
import CopyButton from 'Components/Common/CopyButton';
import Icon from 'Components/Common/Icon';
import PageHeader from 'Components/Common/Pages/Header';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import Table from 'Components/Common/Table';
import { showCreatePropertySecretDialog } from 'Components/Dialog/CreatePropertySecret';
import { showDeletePropertySecretDialog } from 'Components/Dialog/DeletePropertySecret';
import { showUpdatePropertySecretDialog } from 'Components/Dialog/UpdatePropertySecret';
import { LINK_DOCS_AUTOMATION_SECRETS } from 'Constants';
import React, { useCallback } from 'react';
import relativeDate from 'relative-date';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty;
  user: UiUser;
}

const PagePropertySettingsAutomationSecrets = ({
  property,
  user,
}: ComponentProps) => {
  const isPropertyOwner = property.permissionBindings
    .find(({ userId }) => userId === user.id)
    ?.permissions.includes(Permission.owner);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const onDeleteClick = useCallback(
    (name: string) => {
      if (!isPropertyOwner) {
        new Toast(
          'Only property owners can delete secrets',
          Toast.TYPE_WARNING
        );
        return;
      }

      showDeletePropertySecretDialog({
        property,
        secret: property.secrets!.find((s) => s.name === name)!,
        onDelete: () => {
          setPropertiesListVersion(Math.random());
        },
      });
    },
    [isPropertyOwner, property, setPropertiesListVersion]
  );
  const onUpdateClick = useCallback(
    (name: string) => {
      if (!isPropertyOwner) {
        new Toast(
          'Only property owners can update secrets',
          Toast.TYPE_WARNING
        );
        return;
      }

      showUpdatePropertySecretDialog({
        property,
        secret: property.secrets!.find((s) => s.name === name)!,
        onUpdate: () => {
          setPropertiesListVersion(Math.random());
        },
      });
    },
    [isPropertyOwner, property, setPropertiesListVersion]
  );
  const onCreateClick = useCallback(() => {
    showCreatePropertySecretDialog({
      property,
      onCreate: () => {
        setPropertiesListVersion(Math.random());
      },
    });
  }, [property, setPropertiesListVersion]);

  return (
    <div>
      <PageHeader
        icon="padlockClosed"
        title={`${property.host} • Automation • Secrets`}
      />
      <div className={styles.container}>
        <div className={styles.topText}>
          <Icon image="info" size="small" margin="right-tiny" /> This page
          allows you to edit{' '}
          <SafeExternalLink href={LINK_DOCS_AUTOMATION_SECRETS}>
            secrets
          </SafeExternalLink>{' '}
          — special variables you can use in the automation pipelines. Secrets
          allow to hide sensitive data such as API tokens used in webhooks; all
          secret values will be hidden in every webhook output.
          <br />
          <Icon image="info" size="small" margin="right-tiny" /> You can use
          secret values in your automation pipelines by writing{' '}
          <code>{`{{secrets.SECRET_NAME}}`}</code>.
        </div>
        <div>
          <h3>Secrets of {property.host}</h3>
          <Card>
            <Table fullWidth>
              <thead>
                <tr>
                  <th>Secret name</th>
                  <th>Metadata</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!property.secrets?.length && (
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                      There&apos;s currently no secrets configured for{' '}
                      {property.host}.
                    </td>
                  </tr>
                )}
                {property.secrets?.map((secret) => (
                  <tr key={secret.name}>
                    <td>
                      <CopyButton inline text={`{{secrets.${secret.name}}}`} />{' '}
                      <b>{secret.name}</b>
                    </td>
                    <td>
                      <div>
                        {secret.updatedAt ? 'Updated at' : 'Created at'}:{' '}
                        {relativeDate(secret.updatedAt || secret.createdAt)}
                      </div>
                    </td>
                    <td>
                      <Button
                        compact
                        type="secondary"
                        image="settings"
                        onClick={() => onUpdateClick(secret.name)}
                      >
                        Edit
                      </Button>
                      <Button
                        compact
                        type="secondary"
                        image="trash"
                        onClick={() => onDeleteClick(secret.name)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
              <Button disabled={!isPropertyOwner} onClick={onCreateClick}>
                Add secret
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PagePropertySettingsAutomationSecrets;
