import Card, { CardHeader } from 'Components/Common/Card';
import ChartTooltip from 'Components/Common/ChartTooltip';
import Icon from 'Components/Common/Icon';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import TimeRangePicker from 'Components/Common/TimeRangePicker';
import Tooltip from 'Components/Common/Tooltip';
import {
  LINK_DOCS_BILLING_TRAFFIC,
  PRODUCT_NAME,
  TimeRangeTuple,
} from 'Constants';
import React, { useMemo } from 'react';
import {
  AxisDomain,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DashboardView } from 'Types';
import { formatBytes } from 'Utils';
import styles from './styles.module.scss';

const barChartMargins = {
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

const timeFormatter = (n: any) =>
  new Date(n).toLocaleString(void 0, {
    dateStyle: 'short',
    timeStyle: 'short',
  } as any);

const tickProperties = { fontSize: 12 };
const chartDomain: [AxisDomain, AxisDomain] = ['auto', 'auto'];
const totalBytesDataPointName = 'Total Bytes';
const totalCountDataPointName = 'Total Count';
const timeDataPointName = 'time';

const getChartDataFromDashboardView = (view: DashboardView) => {
  const chart: {
    data: { [key: string]: number }[];
    keys: string[];
  } = {
    data: [],
    keys: view.groups.map((group) => group.name),
  };
  const stepSize = Math.floor(
    (view.seriesEnd - view.seriesStart) / view.seriesSteps
  );
  const timeToDataPoint: Map<number, { [key: string]: number }> = new Map([
    // Insert +- 1 EMPTY data point for chart to be more beautiful ^^
    [
      view.seriesStart - stepSize,
      { [timeDataPointName]: view.seriesStart - stepSize },
    ],
    [view.seriesEnd, { [timeDataPointName]: view.seriesEnd }],
  ]);

  for (let i = 0; i < view.seriesSteps; ++i) {
    const time = stepSize * i + view.seriesStart;
    timeToDataPoint.set(time, {
      [timeDataPointName]: time,
    });
  }
  for (const { name, series } of view.groups) {
    for (const { step, count, bytes } of series) {
      const time = stepSize * step + view.seriesStart;
      const dataPoint = timeToDataPoint.get(time)!;
      dataPoint[name] = (dataPoint[name] || 0) + bytes;
      dataPoint[`${name}-c`] = (dataPoint[`${name}-c`] || 0) + count;
      dataPoint[totalBytesDataPointName] =
        (dataPoint[totalBytesDataPointName] || 0) + bytes;
      dataPoint[totalCountDataPointName] =
        (dataPoint[totalCountDataPointName] || 0) + count;
      dataPoint[timeDataPointName] = time;
    }
  }

  chart.data = Array.from(timeToDataPoint.values());

  return chart;
};

interface SummaryGraphProps {
  title?: string;
  timeRange?: TimeRangeTuple;
  onTimeRangeChange?: (newTimeRange: TimeRangeTuple) => void;
  dashboardView: DashboardView;
}

const SummaryGraph = ({
  title = 'Proxied traffic',
  timeRange,
  onTimeRangeChange,
  dashboardView,
}: SummaryGraphProps) => {
  const stepSize = Math.floor(
    (dashboardView.seriesEnd - dashboardView.seriesStart) /
      dashboardView.seriesSteps
  );
  const chartData = useMemo(
    () => getChartDataFromDashboardView(dashboardView),
    [dashboardView]
  );

  return (
    <Card>
      <CardHeader
        icon="pulse"
        title={
          <span>
            <span>{title}</span>
            <Tooltip
              type="help"
              content={
                <span>
                  This chart displays all unblocked traffic proxied by{' '}
                  {PRODUCT_NAME}, per service group.{' '}
                  <SafeExternalLink href={LINK_DOCS_BILLING_TRAFFIC}>
                    Learn more
                  </SafeExternalLink>{' '}
                  about how DataUnlocker measures traffic.
                </span>
              }
            />
          </span>
        }
      >
        {timeRange && (
          <TimeRangePicker
            timeRange={timeRange}
            onTimeRangeChange={onTimeRangeChange}
          />
        )}
      </CardHeader>
      {!chartData && (
        <div className={styles.loadingIconContainer}>
          <Icon image="loading" size="huge" />
        </div>
      )}
      {chartData && (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData.data} margin={barChartMargins}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={timeDataPointName}
              scale={timeDataPointName}
              type="number"
              domain={chartDomain}
              tick={tickProperties}
              tickFormatter={timeFormatter}
            />
            <YAxis
              orientation="left"
              tick={tickProperties}
              tickFormatter={formatBytes}
            />
            <RechartsTooltip content={<ChartTooltip stepSize={stepSize} />} />
            {dashboardView.groups.map((group) => {
              return (
                <Bar
                  key={group.name}
                  dataKey={group.name}
                  stackId="1"
                  stroke={group.color}
                  fill={group.color}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default SummaryGraph;
