import {
  AutomationPipelineStatus,
  AutomationPipelineType,
  UiAutomationPipeline,
} from '@dataunlocker/pkg-types';

export const API_PATH_AUTOMATION_CREATE = '/automation/create';
export interface ApiAutomationCreateRequest {
  type?: AutomationPipelineType;
  fromId?: string;
  newPipelineProps?: {
    propertyId?: string;
    [key: string]: any;
  };
}
export interface ApiAutomationCreateResponse {
  pipeline: UiAutomationPipeline;
}

export const API_PATH_AUTOMATION_PIPELINE_UPDATE = (pipelineId: string) =>
  `/automation/${pipelineId}/update`;
export interface ApiAutomationPipelineUpdateRequest {
  updates: {
    status?: AutomationPipelineStatus;
    steps?: {
      [key: number]: {
        data: {
          [key: string]: any;
        };
      };
    };
  };
}
export interface ApiAutomationPipelineUpdateResponse {
  pipeline: UiAutomationPipeline;
}

export const API_PATH_AUTOMATION_START = '/automation/start';
export interface ApiAutomationStartRequest {
  pipelineId: string;
}
export interface ApiAutomationStartResponse {
  started: boolean;
}

export const API_PATH_AUTOMATION_PIPELINE_ABORT = (pipelineId: string) =>
  `/automation/${pipelineId}/abort`;
export interface ApiAutomationAbortResponse {
  pipeline: UiAutomationPipeline;
}
