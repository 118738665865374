import Icon from 'Components/Common/Icon';
import MenuBar from 'Components/Common/LeftNavMenu';
import { PRODUCT_NAME, ROUTES_WITH_MENU_DISPLAYED } from 'Constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { sessionTokenState, useRecoilValue } from 'State';
import { getSuperAdminSessionToken } from 'Utils';
import styles from './styles.module.scss';

const Header = () => {
  const location = useLocation();
  const navButton = useRef<HTMLDivElement>(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const toggleMenu = useCallback(
    () =>
      navButton.current &&
      getComputedStyle(navButton.current).display !== 'none' &&
      setMenuOpened(!menuOpened),
    [menuOpened]
  );

  useRecoilValue(sessionTokenState); // Update component on token changes.

  const displayMenu = !!ROUTES_WITH_MENU_DISPLAYED.find(
    (route: string | Function) => {
      return typeof route === 'function'
        ? new RegExp(route('[^/]+', '[^/]+')).test(location.pathname)
        : route === location.pathname;
    }
  );

  // A hacky way to put a class on #root.
  const root = document.getElementById('root');
  if (root && root.classList) {
    root.classList.toggle(styles.noMenu, !displayMenu);
  }

  useEffect(() => {
    setMenuOpened(false);
  }, [location.pathname]);

  return (
    <>
      <div
        className={`${styles.header}${
          getSuperAdminSessionToken() ? ` ${styles.sa}` : ''
        }`}
      >
        <Link to="/" className={styles.logo}>
          <Icon image="logo" size="huge" margin="right-regular" />{' '}
          <span>
            {PRODUCT_NAME} <sup className={styles.betaBadge}>BETA</sup>
          </span>
        </Link>
        {displayMenu && (
          <div ref={navButton} className={styles.rightNavButton}>
            <Icon
              image={menuOpened ? 'close' : 'menu'}
              size="huge"
              margin="right-small"
              hoverable
              onClick={toggleMenu}
            />
          </div>
        )}
      </div>
      <div
        className={`${styles.overlay}${menuOpened ? ` ${styles.opened}` : ''}`}
        onClick={toggleMenu}
      ></div>
      {displayMenu && (
        <MenuBar
          className={`${styles.menuBar}${
            menuOpened ? ` ${styles.opened}` : ''
          }`}
        />
      )}
    </>
  );
};

export default Header;
