import { UiProperty } from '@dataunlocker/pkg-types';
import React, { MutableRefObject, useCallback, useState } from 'react';
import { useInterval } from 'Utils';
import styles from './styles.module.scss';

interface ProxyEndpointVisualLinesProps {
  leftRefPoint: MutableRefObject<HTMLDivElement | null>;
  rightRefPoint: MutableRefObject<HTMLDivElement | null>;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  proxyEndpoints: NonNullable<UiProperty['proxyEndpoints']>;
}

const ProxyEndpointVisualLines = ({
  leftRefPoint,
  rightRefPoint,
  containerRef,
  proxyEndpoints,
}: ProxyEndpointVisualLinesProps) => {
  const [lineJoints, setLeftLineJoints] = useState(
    [] as [number, number, number, string][] // [left, right, top, class]
  );

  const recalculateVisualLines = useCallback(() => {
    if (
      !leftRefPoint.current ||
      !rightRefPoint.current ||
      !containerRef.current
    ) {
      return;
    }

    const children: HTMLDivElement[] = [].slice.call(
      containerRef.current?.childNodes || []
    );
    const leftRefPointRect = leftRefPoint.current.getBoundingClientRect();
    // const rightRefPointRect = rightRefPoint.current.getBoundingClientRect();

    setLeftLineJoints(
      children.flatMap((c, i) => {
        const rect = c.children[0].getBoundingClientRect();
        const correspondingConfig = proxyEndpoints[i];

        return [
          [
            rect.x - leftRefPointRect.x,
            rect.x - leftRefPointRect.x + rect.width,
            leftRefPointRect.y - rect.y - rect.height / 2,
            correspondingConfig?.health.ok
              ? `${styles.use} ${styles.ok}`
              : correspondingConfig?.health.ingress > 0 &&
                // For path, additionally check
                (correspondingConfig.type !== 'path' ||
                  correspondingConfig.health.pathOk)
              ? `${styles.use}`
              : '',
          ],
        ];
      })
    );
  }, [containerRef, leftRefPoint, rightRefPoint, proxyEndpoints]);

  useInterval(recalculateVisualLines, 17);

  return (
    <svg className={styles.svgLines}>
      {lineJoints.map(([lx, rx, y, className], i) => (
        <g key={i}>
          <path
            className={className}
            d={`M0,0 C${lx},0 0,${-y} ${lx},${-y}`}
          ></path>
          <path
            className={className}
            d={`M${rx},${-y} C${rx + lx},${-y} ${rx},0 ${rx + lx},0`}
          ></path>
        </g>
      ))}
    </svg>
  );
};

export default ProxyEndpointVisualLines;
