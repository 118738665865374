export const randomString = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

export const encodeQueryString = (obj: { [key: string]: any }) => {
  var str = [];
  for (var [p, v] of Object.entries(obj)) {
    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(v));
  }
  return str.join('&');
};
