import { PropertyBilling } from '@dataunlocker/pkg-types';
import {
  ApiAdminV1PropertiesDeleteResponse,
  ApiAdminV1PropertiesGetInvoiceRequest,
  ApiAdminV1PropertiesGetInvoiceResponse,
  ApiAdminV1PropertiesIdProxyEndpointsAddResponse,
  ApiAdminV1PropertiesIdProxyEndpointsCheckRequest,
  ApiAdminV1PropertiesIdProxyEndpointsCheckResponse,
  ApiAdminV1PropertiesIdProxyEndpointsDeleteRequest,
  ApiAdminV1PropertiesIdProxyEndpointsDeleteResponse,
  ApiAdminV1PropertiesIdProxyEndpointsEditRequest,
  ApiAdminV1PropertiesIdProxyEndpointsEditResponse,
  ApiAdminV1PropertiesPurchasesResponse,
  ApiAdminV1PropertiesStatsQuotaResponse,
  ApiAdminV1PropertiesStatsResponse,
  ApiAdminV1PropertiesTrafficTransferGetResponse,
  ApiAdminV1PropertiesTrafficTransferPostRequest,
  ApiAdminV1PropertiesTrafficTransferPostResponse,
  ApiAdminV1PropertiesUsageSummaryResponse,
  ApiPropertiesAddServiceRequest,
  ApiPropertiesAddServiceResponse,
  ApiPropertiesAddTempRequest,
  ApiPropertiesAddTempResponse,
  ApiPropertiesAutomationPipelinesGetQueryParams,
  ApiPropertiesAutomationPipelinesGetResponse,
  ApiPropertiesBillingRetryRequest,
  ApiPropertiesBillingRetryResponse,
  ApiPropertiesCancelSetupResponse,
  ApiPropertiesDeleteSecretRequest,
  ApiPropertiesDeleteSecretResponse,
  ApiPropertiesDeleteServiceRequest,
  ApiPropertiesDeleteServiceResponse,
  ApiPropertiesEstimateCtpRequest,
  ApiPropertiesEstimateCtpResponse,
  ApiPropertiesHealthCheckResponse,
  ApiPropertiesListResponse,
  ApiPropertiesResetProxiedServicesRequest,
  ApiPropertiesResetProxiedServicesResponse,
  ApiPropertiesScriptInstallQuery,
  ApiPropertiesScriptInstallResponse,
  ApiPropertiesTempHealthCheckResponse,
  ApiPropertiesUpdateBillingRequest,
  ApiPropertiesUpdateBillingResponse,
  ApiPropertiesUpdateLowBytesNotificationRequest,
  ApiPropertiesUpdateLowBytesNotificationResponse,
  ApiPropertiesUpdateProxyEndpointSwappingRequest,
  ApiPropertiesUpdateProxyEndpointSwappingResponse,
  ApiPropertiesUpdateProxyFlagsRequest,
  ApiPropertiesUpdateProxyFlagsResponse,
  ApiPropertiesUpdateSecretRequest,
  ApiPropertiesUpdateSecretResponse,
  ApiPropertiesUpdateServiceProxyFlagsRequest,
  ApiPropertiesUpdateServiceProxyFlagsResponse,
  ApiPropertiesUsersListResponse,
  ApiPropertiesUsersUpdateRequest,
  ApiPropertiesUsersUpdateResponse,
  API_PATH_ADMIN_V1_PROPERTIES_DELETE,
  API_PATH_ADMIN_V1_PROPERTIES_GET_INVOICE,
  API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_ADD,
  API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_CHECK,
  API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_DELETE,
  API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_EDIT,
  API_PATH_ADMIN_V1_PROPERTIES_PURCHASES,
  API_PATH_ADMIN_V1_PROPERTIES_TRAFFIC_TRANSFER,
  API_PATH_ADMIN_V1_PROPERTIES_UPDATE_PROXY_ENDPOINT_SWAPPING,
  API_PATH_ADMIN_V1_PROPERTIES_USAGE_SUMMARY,
  API_PATH_PROPERTIES_ADD_SERVICE,
  API_PATH_PROPERTIES_ADD_TEMP,
  API_PATH_PROPERTIES_AUTOMATION_PIPELINES,
  API_PATH_PROPERTIES_BILLING_RETRY,
  API_PATH_PROPERTIES_CANCEL_SETUP,
  API_PATH_PROPERTIES_DELETE_SECRET,
  API_PATH_PROPERTIES_DELETE_SERVICE,
  API_PATH_PROPERTIES_ESTIMATE_CTP,
  API_PATH_PROPERTIES_HEALTH_CHECK,
  API_PATH_PROPERTIES_LIST,
  API_PATH_PROPERTIES_RESET_PROXIED_SERVICES,
  API_PATH_PROPERTIES_SCRIPT_INSTALL,
  API_PATH_PROPERTIES_STATS,
  API_PATH_PROPERTIES_STATS_QUOTA,
  API_PATH_PROPERTIES_TEMP_HEALTH_CHECK,
  API_PATH_PROPERTIES_UPDATE_BILLING,
  API_PATH_PROPERTIES_UPDATE_LOW_BYTES_NOTIFICATION,
  API_PATH_PROPERTIES_UPDATE_PROXY_FLAGS,
  API_PATH_PROPERTIES_UPDATE_SECRET,
  API_PATH_PROPERTIES_UPDATE_SERVICE_PROXY_FLAGS,
  API_PATH_PROPERTIES_USERS_LIST,
  API_PATH_PROPERTIES_USERS_UPDATE,
} from 'Constants';
import { apiRequest, toQueryString } from 'Utils';

/* Returns the list of domains owned by the current account. */
export const getPropertiesList = async () => {
  const res = (
    await apiRequest<void, ApiPropertiesListResponse>(API_PATH_PROPERTIES_LIST)
  ).response;
  return {
    properties: res?.properties ?? [],
    tempProperties: res?.tempProperties ?? [],
  };
};

/* Returns the list of purchases by a given property. */
export const getPropertyPurchasesList = async (propertyId: string) => {
  return await apiRequest<void, ApiAdminV1PropertiesPurchasesResponse>(
    API_PATH_ADMIN_V1_PROPERTIES_PURCHASES(propertyId)
  );
};

export const requestPropertyEnableProxyEndpointSwapping = async (
  propertyId: string,
  enabled: boolean
) => {
  return await apiRequest<
    ApiPropertiesUpdateProxyEndpointSwappingRequest,
    ApiPropertiesUpdateProxyEndpointSwappingResponse
  >(API_PATH_ADMIN_V1_PROPERTIES_UPDATE_PROXY_ENDPOINT_SWAPPING(propertyId), {
    enabled,
  });
};

export const createTempProperty = async (req: ApiPropertiesAddTempRequest) => {
  return await apiRequest<
    ApiPropertiesAddTempRequest,
    ApiPropertiesAddTempResponse
  >(API_PATH_PROPERTIES_ADD_TEMP, req);
};

export const getPropertyAutomationPipelines = async (
  propertyId: string,
  query?: ApiPropertiesAutomationPipelinesGetQueryParams
) => {
  return await apiRequest<
    ApiPropertiesAddTempRequest,
    ApiPropertiesAutomationPipelinesGetResponse
  >(
    `${API_PATH_PROPERTIES_AUTOMATION_PIPELINES(propertyId)}${
      query ? `?${toQueryString(query)}` : ''
    }`
  );
};

export const propertyRetryBilling = async (propertyId: string) => {
  return await apiRequest<
    ApiPropertiesBillingRetryRequest,
    ApiPropertiesBillingRetryResponse
  >(API_PATH_PROPERTIES_BILLING_RETRY(propertyId), {});
};

export const propertyUpdateSecret = async (
  propertyId: string,
  request: ApiPropertiesUpdateSecretRequest
) => {
  return await apiRequest<
    ApiPropertiesUpdateSecretRequest,
    ApiPropertiesUpdateSecretResponse
  >(API_PATH_PROPERTIES_UPDATE_SECRET(propertyId), request);
};

export const propertyDeleteSecret = async (
  propertyId: string,
  request: ApiPropertiesDeleteSecretRequest
) => {
  return await apiRequest<
    ApiPropertiesDeleteSecretRequest,
    ApiPropertiesDeleteSecretResponse
  >(API_PATH_PROPERTIES_DELETE_SECRET(propertyId), request);
};

export const estimatePropertyCtp = async (
  propertyId: string,
  billing: Partial<PropertyBilling>
) => {
  return await apiRequest<
    ApiPropertiesEstimateCtpRequest,
    ApiPropertiesEstimateCtpResponse
  >(API_PATH_PROPERTIES_ESTIMATE_CTP(propertyId), {
    newBillingSettings: billing,
  });
};

export const updatePropertyProxyFlags = async (
  propertyId: string,
  flags: ApiPropertiesUpdateProxyFlagsRequest
) => {
  return await apiRequest<
    ApiPropertiesUpdateProxyFlagsRequest,
    ApiPropertiesUpdateProxyFlagsResponse
  >(API_PATH_PROPERTIES_UPDATE_PROXY_FLAGS(propertyId), flags);
};

export const resetPropertyProxiedServices = async (propertyId: string) => {
  return await apiRequest<
    ApiPropertiesResetProxiedServicesRequest,
    ApiPropertiesResetProxiedServicesResponse
  >(API_PATH_PROPERTIES_RESET_PROXIED_SERVICES(propertyId), { all: true });
};

export const deleteProperty = async (propertyId: string) => {
  return await apiRequest<void, ApiAdminV1PropertiesDeleteResponse>(
    API_PATH_ADMIN_V1_PROPERTIES_DELETE(propertyId),
    undefined,
    { type: 'DELETE' }
  );
};

export const addPropertyService = async (
  propertyId: string,
  params: ApiPropertiesAddServiceRequest
) => {
  return await apiRequest<
    ApiPropertiesAddServiceRequest,
    ApiPropertiesAddServiceResponse
  >(API_PATH_PROPERTIES_ADD_SERVICE(propertyId), params);
};

export const deletePropertyService = async (
  propertyId: string,
  params: ApiPropertiesDeleteServiceRequest
) => {
  return await apiRequest<
    ApiPropertiesDeleteServiceRequest,
    ApiPropertiesDeleteServiceResponse
  >(API_PATH_PROPERTIES_DELETE_SERVICE(propertyId), params);
};

export const updatePropertyServiceProxyFlags = async (
  propertyId: string,
  data: ApiPropertiesUpdateServiceProxyFlagsRequest
) => {
  return await apiRequest<
    ApiPropertiesUpdateServiceProxyFlagsRequest,
    ApiPropertiesUpdateServiceProxyFlagsResponse
  >(API_PATH_PROPERTIES_UPDATE_SERVICE_PROXY_FLAGS(propertyId), data);
};

export const updatePropertyBilling = async (
  propertyId: string,
  data: ApiPropertiesUpdateBillingRequest['billing']
) => {
  return await apiRequest<
    ApiPropertiesUpdateBillingRequest,
    ApiPropertiesUpdateBillingResponse
  >(API_PATH_PROPERTIES_UPDATE_BILLING(propertyId), { billing: data });
};

export const updatePropertyLowBytesNotification = async (
  propertyId: string,
  request: ApiPropertiesUpdateLowBytesNotificationRequest
) => {
  return await apiRequest<
    ApiPropertiesUpdateLowBytesNotificationRequest,
    ApiPropertiesUpdateLowBytesNotificationResponse
  >(API_PATH_PROPERTIES_UPDATE_LOW_BYTES_NOTIFICATION(propertyId), request);
};

export const cancelTempPropertySetup = async (propertyId: string) => {
  return await apiRequest<Object, ApiPropertiesCancelSetupResponse>(
    API_PATH_PROPERTIES_CANCEL_SETUP(propertyId),
    {}
  );
};

export const getPropertyInstallScript = async (
  propertyId: string,
  config: ApiPropertiesScriptInstallQuery & { version?: string }
) => {
  const query: ApiPropertiesScriptInstallQuery = {
    debug: typeof config.debug === 'undefined' ? false : config.debug,
    encoded: typeof config.encoded === 'undefined' ? true : config.encoded,
    ...(config.endpointUrlKey ? { endpointUrlKey: config.endpointUrlKey } : {}),
  };
  return await apiRequest<void, ApiPropertiesScriptInstallResponse>(
    `${API_PATH_PROPERTIES_SCRIPT_INSTALL(
      propertyId,
      config.version || 'latest'
    )}?${toQueryString(query)}`
  );
};

export const requestPropertyHealthCheck = async (propertyId: string) =>
  await apiRequest<Object, ApiPropertiesHealthCheckResponse>(
    API_PATH_PROPERTIES_HEALTH_CHECK(propertyId),
    {}
  );

export const requestPropertyUsers = async (propertyId: string) =>
  await apiRequest<void, ApiPropertiesUsersListResponse>(
    API_PATH_PROPERTIES_USERS_LIST(propertyId)
  );

export const requestPropertyUsersUpdate = async (
  propertyId: string,
  updates: ApiPropertiesUsersUpdateRequest
) =>
  await apiRequest<
    ApiPropertiesUsersUpdateRequest,
    ApiPropertiesUsersUpdateResponse
  >(API_PATH_PROPERTIES_USERS_UPDATE(propertyId), updates);

export const requestPropertyTempHealthCheck = async (propertyId: string) =>
  await apiRequest<Object, ApiPropertiesTempHealthCheckResponse>(
    API_PATH_PROPERTIES_TEMP_HEALTH_CHECK(propertyId),
    {}
  );

export const getPropertyStats = async (
  propertyId: string,
  options?: { from?: number; to?: number }
) => {
  const now = Date.now();
  return await apiRequest<void, ApiAdminV1PropertiesStatsResponse>(
    `${API_PATH_PROPERTIES_STATS(propertyId)}`,
    void 0,
    {
      query: {
        from: options?.from || now - 1000 * 60 * 60 * 24,
        to: options?.to || now,
      },
    }
  );
};

export const getPropertyUsageSummary = async (propertyId: string) => {
  return await apiRequest<void, ApiAdminV1PropertiesUsageSummaryResponse>(
    `${API_PATH_ADMIN_V1_PROPERTIES_USAGE_SUMMARY(propertyId)}`
  );
};

export const getPropertyStatsQuota = async (
  propertyId: string,
  options: { from?: number; to?: number } = {}
) => {
  const now = Date.now();
  return await apiRequest<void, ApiAdminV1PropertiesStatsQuotaResponse>(
    `${API_PATH_PROPERTIES_STATS_QUOTA(propertyId)}`,
    void 0,
    {
      query: {
        from: options?.from || now - 1000 * 60 * 60 * 24,
        to: options?.to || 0,
      },
    }
  );
};

export const getTransferrableTraffic = async ({
  propertyId,
  toPropertyId,
  bytesToTransfer,
}: {
  propertyId: string;
  toPropertyId?: string;
  bytesToTransfer?: number;
}) => {
  return await apiRequest<void, ApiAdminV1PropertiesTrafficTransferGetResponse>(
    API_PATH_ADMIN_V1_PROPERTIES_TRAFFIC_TRANSFER(propertyId, {
      ...(toPropertyId ? { toPropertyId } : {}),
      ...(bytesToTransfer
        ? { bytesToTransfer: bytesToTransfer.toString() }
        : {}),
    })
  );
};

export const requestTrafficTransfer = async (
  propertyId: string,
  request: ApiAdminV1PropertiesTrafficTransferPostRequest
) => {
  return await apiRequest<
    ApiAdminV1PropertiesTrafficTransferPostRequest,
    ApiAdminV1PropertiesTrafficTransferPostResponse
  >(API_PATH_ADMIN_V1_PROPERTIES_TRAFFIC_TRANSFER(propertyId), request);
};

export const requestPropertyAddProxyEndpoint = async (propertyId: string) => {
  return await apiRequest<
    void,
    ApiAdminV1PropertiesIdProxyEndpointsAddResponse
  >(API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_ADD(propertyId), void 0, {
    type: 'POST',
  });
};

export const requestPropertyDeleteProxyEndpoint = async (
  propertyId: string,
  urlKey: string
) => {
  return await apiRequest<
    ApiAdminV1PropertiesIdProxyEndpointsDeleteRequest,
    ApiAdminV1PropertiesIdProxyEndpointsDeleteResponse
  >(API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_DELETE(propertyId), {
    urlKey,
  });
};

export const requestPropertyCheckProxyEndpoint = async (
  propertyId: string,
  urlKey: string
) => {
  return await apiRequest<
    ApiAdminV1PropertiesIdProxyEndpointsCheckRequest,
    ApiAdminV1PropertiesIdProxyEndpointsCheckResponse
  >(API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_CHECK(propertyId), {
    urlKey,
  });
};

export const requestPropertyEditProxyEndpoint = async (
  propertyId: string,
  urlKey: string,
  edits: ApiAdminV1PropertiesIdProxyEndpointsEditRequest['edits']
) => {
  return await apiRequest<
    ApiAdminV1PropertiesIdProxyEndpointsEditRequest,
    ApiAdminV1PropertiesIdProxyEndpointsEditResponse
  >(API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_EDIT(propertyId), {
    urlKey,
    edits,
  });
};

export const requestPropertyGetInvoice = async (
  propertyId: string,
  {
    purchaseIds,
    companyAddress,
  }: { purchaseIds: string[]; companyAddress?: string }
) => {
  return await apiRequest<
    ApiAdminV1PropertiesGetInvoiceRequest,
    ApiAdminV1PropertiesGetInvoiceResponse
  >(API_PATH_ADMIN_V1_PROPERTIES_GET_INVOICE(propertyId), {
    purchaseIds,
    companyAddress,
  });
};
