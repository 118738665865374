import React from 'react';
import styles from './styles.module.scss';

interface TextInputProps {
  value: number;
  max?: number;
  min?: number;
  label?: React.ReactNode;
  disabled?: boolean;
  compact?: boolean;
  inline?: boolean;
  wide?: boolean;
  onChange: (newValue: number) => any;
}

const NumberInput = ({
  value,
  onChange,
  disabled,
  compact,
  label,
  inline,
  max,
  min,
  wide,
}: TextInputProps) => {
  return (
    <div
      className={[
        styles.textInput,
        inline ? styles.inline : '',
        compact ? styles.compact : '',
        wide ? styles.wide : '',
      ].join(' ')}
    >
      {label && <label>{label}</label>}
      <input
        className={styles.input}
        type="number"
        max={max}
        min={min}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(
            Math.max(
              min || -Infinity,
              Math.min(+e.target.value, max || Infinity)
            )
          )
        }
        disabled={!!disabled}
      />
    </div>
  );
};

export default NumberInput;
