import { UiTempProperty } from '@dataunlocker/pkg-types';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import Tooltip from 'Components/Common/Tooltip';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import {
  LINK_DOCS_INSTALL_ROUTING,
  PRODUCT_NAME,
  PRODUCT_SUPPORT_EMAIL,
  ROUTE_PROPERTY_SETTINGS_USERS,
} from 'Constants';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiTempProperty;
}

const NeedHelpFooter = ({ property }: ComponentProps) => {
  return (
    <div className={styles.container}>
      <Tooltip
        content={
          <span>
            Take a look on {PRODUCT_NAME}&apos;s{' '}
            <SafeExternalLink href={LINK_DOCS_INSTALL_ROUTING}>
              docs
            </SafeExternalLink>{' '}
            or{' '}
            <Link to={ROUTE_PROPERTY_SETTINGS_USERS(property.id)}>invite</Link>{' '}
            someone else to complete these setup steps.
            <br />
            <br />
            If something unexpected happens during the setup, reach out to{' '}
            <UnsafeExternalLink href={`mailto:${PRODUCT_SUPPORT_EMAIL}`}>
              {PRODUCT_SUPPORT_EMAIL}
            </UnsafeExternalLink>
            .
          </span>
        }
      >
        <div className={styles.textContainer}>Need help?</div>
      </Tooltip>
    </div>
  );
};

export default NeedHelpFooter;
