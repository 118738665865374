import Icon from 'Components/Common/Icon';
import React, { useCallback, useRef } from 'react';
import styles from './styles.module.scss';

interface Props {
  content?: string | React.ReactNode;
  type?: 'info' | 'help';
  children?: React.ReactNode;
}

const MAX_WIDTH = 400;

const Tooltip = ({ content, children, type }: Props) => {
  const tooltip = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const onHover = useCallback(() => {
    if (!tooltip.current || !container.current) {
      return;
    }
    const rect = container.current.getBoundingClientRect();
    const isOnRight = rect.left + rect.width / 2 > window.innerWidth / 2;
    const isOnBottom = rect.top + rect.height / 2 > window.innerHeight / 2;
    tooltip.current.style[isOnRight ? 'right' : 'left'] = `${
      isOnRight ? window.innerWidth - (rect.left + rect.width) : rect.left
    }px`;
    tooltip.current.style[isOnRight ? 'left' : 'right'] = 'auto';
    tooltip.current.style[isOnBottom ? 'bottom' : 'top'] = `${
      isOnBottom ? window.innerHeight - rect.top : rect.bottom
    }px`;
    tooltip.current.style[isOnBottom ? 'top' : 'bottom'] = 'auto';
    tooltip.current.style.display = 'block';
  }, []);
  const onExit = useCallback(() => {
    if (!tooltip.current) {
      return;
    }
    tooltip.current.style.display = 'none';
  }, []);

  return (
    <span
      className={styles.container}
      style={{ cursor: content ? 'help' : '' }}
      onMouseEnter={content ? onHover : undefined}
      onMouseLeave={onExit}
      ref={container}
    >
      <div className={styles.content}>
        {type === 'help' && !children ? (
          <Icon accent="minor" image="help" margin="left-small" />
        ) : (
          children
        )}
      </div>
      <div
        ref={tooltip}
        style={{ maxWidth: MAX_WIDTH }}
        className={styles.tooltip}
      >
        <div>
          {type === 'info' && (
            <Icon image="info" size="small" margin="right-small" />
          )}
          {content}
        </div>
      </div>
    </span>
  );
};

export default Tooltip;
