import PageError from 'Components/Common/Pages/Error';
import React from 'react';

const PagePropertyDoesNotExist = () => (
  <PageError
    icon="www"
    message="This property doesn't exists or you don't have an access to it"
  />
);

export default PagePropertyDoesNotExist;
