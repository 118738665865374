import React, { useState } from 'react';
import styles from './styles.module.scss';
import Icon from 'Components/Common/Icon';

interface ComponentProps {
  inProgress?: boolean;
  ok?: boolean;
  warn?: boolean;
  isExpectedToFail?: boolean;
  rowElement: React.ReactNode;
  children?: React.ReactNode;
}

const errorIcon = (
  <Icon image="error" size="big" margin="none" className={styles.error} />
);
const skippedIcon = (
  <Icon image="info" size="big" margin="none" className={styles.minor} />
);
const checkmarkIcon = (
  <Icon image="checkmark" size="big" margin="none" className={styles.success} />
);
const warningIcon = (
  <Icon image="warning" size="big" margin="none" className={styles.warning} />
);

const Row = ({
  rowElement,
  inProgress,
  ok,
  warn,
  isExpectedToFail,
  children,
}: ComponentProps) => {
  const [isOpenState, setIsOpen] = useState(false);
  const loadingIcon = <Icon image="loading" size="big" margin="none" />;

  const iconToDisplay = inProgress
    ? loadingIcon
    : ok
    ? checkmarkIcon
    : isExpectedToFail
    ? skippedIcon
    : warn
    ? warningIcon
    : errorIcon;
  const isOpen = ok || inProgress ? false : isOpenState;
  return (
    <tr
      className={`${styles.row}${
        children && !ok && !inProgress ? ` ${styles.withChildren}` : ''
      }`}
      onClick={() => !ok && !inProgress && setIsOpen(!isOpen)}
    >
      <td>{iconToDisplay}</td>
      <td>
        <div>{rowElement}</div>
        {isOpen && (
          <div className={styles.errorContainer}>{children ?? null}</div>
        )}
      </td>
    </tr>
  );
};

export default Row;
