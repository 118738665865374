import React from 'react';
import Icon from 'Components/Common/Icon';

import styles from './styles.module.scss';

interface PageHeaderProps {
  icon?: string;
  title: string;
  // Expected 0 or 1 blocks:
  children?: React.ReactNode;
}

const PageHeader = ({ icon, title, children }: PageHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {icon && <Icon size="big" image={icon} margin="right-small" />}
        <h2>{title}</h2>
      </div>
      <div className={styles.controlsContainer}>{children}</div>
    </div>
  );
};

export default PageHeader;
