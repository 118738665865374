import copyToClipboard from 'copy-to-clipboard';
import React, { useCallback, useState } from 'react';
import Icon from '../Icon';
import styles from './styles.module.scss';

interface Props {
  text: string;
  inline?: boolean;
  withLabel?: boolean | string;
}

const CopyButton = ({ text, inline, withLabel }: Props) => {
  const [copied, setCopied] = useState(false);
  const copy = useCallback(() => {
    copyToClipboard(text);
    setCopied(true);
  }, [text]);
  const unCopy = useCallback(() => {
    setCopied(false);
  }, []);

  return (
    <label className={withLabel ? styles.hoverable : ''}>
      {withLabel && (
        <code className={styles.label}>
          {typeof withLabel === 'string' ? withLabel : text}
        </code>
      )}
      <button
        type="button"
        onClick={copy}
        onBlur={unCopy}
        className={inline ? styles.inline : ''}
      >
        <Icon
          image={copied ? 'checkmark' : 'copy'}
          margin={inline ? 'left-small' : 'right-regular'}
        />
        {inline ? null : 'Copy'}
      </button>
    </label>
  );
};

export default CopyButton;
