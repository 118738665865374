import {
  AutomationPipelineStatus,
  AutomationPipelineStepDataWebhookBasic,
  AutomationPipelineStepId,
  AutomationPipelineType,
  UiAutomationPipeline,
  UiProperty,
} from '@dataunlocker/pkg-types';
import {
  getPropertyAutomationPipelines,
  requestAutomationPipelineAbort,
  requestAutomationPipelineCreate,
  requestAutomationPipelineStart,
  requestPropertyEnableProxyEndpointSwapping,
} from 'Api';
import Button from 'Components/Common/Button';
import Card from 'Components/Common/Card';
import HorizontalStepper, {
  StepperStep,
} from 'Components/Common/HorizontalStepper';
import Icon from 'Components/Common/Icon';
import MessageBlock from 'Components/Common/MessageBlock';
import PageHeader from 'Components/Common/Pages/Header';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import Tooltip from 'Components/Common/Tooltip';
import {
  LINK_DOCS_AUTOMATION_CONCEPTS,
  LINK_DOCS_AUTOMATION_REFRESH_OVERVIEW,
  PRODUCT_NAME,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH_PIPELINE,
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
} from 'Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import relativeDate from 'relative-date';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import { useInterval } from 'Utils';
import PipelineStep, { PipelineStepCommonProps } from '../Common/PipelineStep';
import PipelineStepCreateProxyEndpoint from '../Common/Steps/CreateProxyEndpoint';
import PipelineStepDeleteProxyEndpoint from '../Common/Steps/DeleteProxyEndpoint';
import PipelineStepEnd from '../Common/Steps/End';
import PipelineStepPickProxyEndpointToReplace from '../Common/Steps/PickProxyEndpointToReplace';
import PipelineStepStart from '../Common/Steps/Start';
import PipelineStepWaitProxyEndpointIsHealthy from '../Common/Steps/WaitProxyEndpointIsHealthy';
import PipelineStepWaitProxyEndpointIsUnhealthy from '../Common/Steps/WaitProxyEndpointIsUnhealthy';
import PipelineStepWaitProxyEndpointTrafficStop from '../Common/Steps/WaitProxyEndpointTrafficStop';
import PipelineStepWaitUpdateClientCode from '../Common/Steps/WaitUpdateClientCode';
import PipelineStepWebhookCreateProxyEndpoint from '../Common/Steps/WebhookCreateProxyEndpoint';
import PipelineStepWebhookDeleteProxyEndpoint from '../Common/Steps/WebhookDeleteProxyEndpoint';
import PipelineStepWebhookUpdateClientCode from '../Common/Steps/WebhookUpdateClientCode';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty;
}

const pipelineStepIdToComponent: {
  [key: string]: (props: PipelineStepCommonProps) => React.ReactElement;
} = {
  [AutomationPipelineStepId.Start]: PipelineStepStart,
  [AutomationPipelineStepId.PickProxyEndpointToReplace]: PipelineStepPickProxyEndpointToReplace,
  [AutomationPipelineStepId.CreateProxyEndpoint]: PipelineStepCreateProxyEndpoint,
  [AutomationPipelineStepId.WebhookCreateProxyEndpoint]: PipelineStepWebhookCreateProxyEndpoint,
  [AutomationPipelineStepId.WaitProxyEndpointIsHealthy]: PipelineStepWaitProxyEndpointIsHealthy,
  [AutomationPipelineStepId.WebhookUpdateClientCode]: PipelineStepWebhookUpdateClientCode,
  [AutomationPipelineStepId.WaitUpdateClientCode]: PipelineStepWaitUpdateClientCode,
  [AutomationPipelineStepId.WaitProxyEndpointTrafficStop]: PipelineStepWaitProxyEndpointTrafficStop,
  [AutomationPipelineStepId.WebhookDeleteProxyEndpoint]: PipelineStepWebhookDeleteProxyEndpoint,
  [AutomationPipelineStepId.WaitProxyEndpointIsUnhealthy]: PipelineStepWaitProxyEndpointIsUnhealthy,
  [AutomationPipelineStepId.DeleteProxyEndpoint]: PipelineStepDeleteProxyEndpoint,
  [AutomationPipelineStepId.End]: PipelineStepEnd,
};

const refreshPipelines = async ({
  setPipelines,
  propertyId,
  run = 1, // Prevents indefinite loop if `requestAutomationPipelineCreate` has a glitch.
}: {
  setPipelines: Function;
  propertyId: string;
  run?: number;
}) => {
  const propertyPipelines = await getPropertyAutomationPipelines(propertyId, {
    type: AutomationPipelineType.InstallationRefresh,
  });
  if (propertyPipelines.errorMessage) {
    new Toast(propertyPipelines.errorMessage, Toast.TYPE_ERROR);
  } else if (propertyPipelines.response?.pipelines) {
    setPipelines(propertyPipelines.response.pipelines);
    if (propertyPipelines.response.pipelines.length === 0 && run === 1) {
      const createResponse = await requestAutomationPipelineCreate({
        type: AutomationPipelineType.InstallationRefresh,
        newPipelineProps: {
          propertyId,
        },
      });
      if (createResponse.errorMessage) {
        new Toast(createResponse.errorMessage, Toast.TYPE_ERROR);
      }
      await refreshPipelines({ setPipelines, propertyId, run: run + 1 });
    }
  }
};

const PagePropertySettingsAutomationRefresh = ({
  property,
}: ComponentProps) => {
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname.replace(/\/$/, '');
  const [pipelines, setPipelines] = useState([] as UiAutomationPipeline[]);

  const stepperPipelines: (StepperStep & {
    pipeline: UiAutomationPipeline;
  })[] = pipelines.map((pipeline) => ({
    label:
      pipeline.status === AutomationPipelineStatus.Running
        ? 'running'
        : pipeline.status === AutomationPipelineStatus.New
        ? 'editing'
        : relativeDate(pipeline.endedAt || pipeline.createdAt),
    icon:
      pipeline.status === AutomationPipelineStatus.Paused
        ? 'pause'
        : pipeline.status === AutomationPipelineStatus.Running
        ? 'play'
        : pipeline.status === AutomationPipelineStatus.Errored
        ? 'error'
        : pipeline.status === AutomationPipelineStatus.Aborted
        ? 'close'
        : pipeline.status === AutomationPipelineStatus.New
        ? 'settings'
        : pipeline.status === AutomationPipelineStatus.Done
        ? 'checkmark'
        : 'split',
    status:
      pipeline.status === AutomationPipelineStatus.Done
        ? 'done'
        : pipeline.status === AutomationPipelineStatus.Running
        ? 'progress'
        : 'new',
    pipeline,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [recentlyStartedPipelineId, setRecentlyStartedPipelineId] = useState(
    ''
  );
  const latestPipeline = pipelines[pipelines.length - 1] as
    | UiAutomationPipeline
    | undefined;
  const selectedPipeline = (stepperPipelines.find((p) =>
    pathname.endsWith(p.pipeline.id)
  )?.pipeline || stepperPipelines[stepperPipelines.length - 1]?.pipeline) as
    | UiAutomationPipeline
    | undefined;
  const readyToBeRun = [
    AutomationPipelineStepId.WebhookCreateProxyEndpoint,
    AutomationPipelineStepId.WebhookDeleteProxyEndpoint,
    AutomationPipelineStepId.WebhookUpdateClientCode,
  ]
    .map(
      (id) =>
        !!(selectedPipeline?.steps.find((s) => s.id === id)?.data as
          | AutomationPipelineStepDataWebhookBasic
          | undefined)?.webhookUrl
    )
    .reduce((a, b) => a && b);

  // Refresh pipelines if property ID has changed.
  useEffect(() => {
    refreshPipelines({ setPipelines, propertyId: property.id });
  }, [property.id]);

  // Refresh pipelines every 5 seconds.
  useInterval(async () => {
    refreshPipelines({ setPipelines, propertyId: property.id });
    setPropertiesListVersion(Math.random());
  }, 6000);

  // Clear recentlyStartedPipelineId if it has started successfully (releases "Start" button).
  useEffect(() => {
    if (
      !(
        pipelines.find((p) => p.id === recentlyStartedPipelineId)?.status ===
        AutomationPipelineStatus.New
      )
    ) {
      setRecentlyStartedPipelineId('');
    }
  }, [recentlyStartedPipelineId, pipelines]);

  const refreshPipeline = useCallback(async () => {
    await refreshPipelines({ setPipelines, propertyId: property.id });
  }, [property.id]);
  const onStartClick = useCallback(async () => {
    setIsLoading(true);
    const startResult = await requestAutomationPipelineStart({
      pipelineId: selectedPipeline!.id,
    });
    setIsLoading(false);

    if (startResult.errorMessage) {
      new Toast(startResult.errorMessage, Toast.TYPE_ERROR);
      return;
    }

    setRecentlyStartedPipelineId(selectedPipeline!.id);
    // do nothing yet
  }, [selectedPipeline]);
  const onAbortClick = useCallback(async () => {
    setIsLoading(true);
    const result = await requestAutomationPipelineAbort(selectedPipeline!.id);
    setIsLoading(false);

    if (result.errorMessage) {
      new Toast(result.errorMessage, Toast.TYPE_ERROR);
      return;
    }

    refreshPipelines({ setPipelines, propertyId: property.id });
  }, [selectedPipeline, property.id]);
  const onEditClick = useCallback(async () => {
    const lastPipeline = pipelines[pipelines.length - 1];

    if (lastPipeline.status === AutomationPipelineStatus.New) {
      history.push(
        ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH_PIPELINE(
          property.id,
          lastPipeline.id
        )
      );
      return;
    }

    setIsLoading(true);
    const createResponse = await requestAutomationPipelineCreate({
      type: AutomationPipelineType.InstallationRefresh,
      fromId: selectedPipeline?.id,
    });
    setIsLoading(false);
    if (createResponse.errorMessage) {
      new Toast(createResponse.errorMessage, Toast.TYPE_ERROR);
    } else {
      await refreshPipelines({ setPipelines, propertyId: property.id });
      history.push(
        ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH_PIPELINE(
          property.id,
          createResponse.response?.pipeline.id || selectedPipeline?.id || ''
        )
      );
    }
  }, [pipelines, history, property.id, selectedPipeline?.id]);
  const onSelectedPipelineChange = useCallback(
    ({ pipeline }) => {
      history.push(
        ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH_PIPELINE(
          property.id,
          pipeline.id
        )
      );
    },
    [history, property.id]
  );
  const onToggleEndpointSwapping = useCallback(async () => {
    setIsLoading(true);
    const response = await requestPropertyEnableProxyEndpointSwapping(
      property.id,
      !property.proxyEndpointSwappingEnabled
    );
    setIsLoading(false);
    if (response.errorMessage) {
      new Toast(response.errorMessage, Toast.TYPE_ERROR);
    } else {
      setPropertiesListVersion(Math.random());
    }
  }, [
    property.id,
    property.proxyEndpointSwappingEnabled,
    setPropertiesListVersion,
  ]);

  let lastPipelineResultReason = '';
  for (const step of selectedPipeline?.steps || []) {
    if (step.resultReason) {
      lastPipelineResultReason = step.resultReason;
    }
  }

  return (
    <div>
      <PageHeader
        icon="ci"
        title={`${property.host} • Automation • Installation refresh`}
      />
      <div className={styles.container}>
        <div className={styles.topText}>
          <Icon image="info" size="small" margin="right-tiny" /> This page
          allows you to configure and monitor{' '}
          <SafeExternalLink href={LINK_DOCS_AUTOMATION_CONCEPTS}>
            automatic {PRODUCT_NAME} installation refresh
          </SafeExternalLink>{' '}
          pipeline. You need it in order to automate manual actions required
          when parts of your {PRODUCT_NAME} setup such as{' '}
          <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)}>
            proxy endpoints
          </Link>{' '}
          appear in blacklists.
        </div>
        <div>
          <Card>
            <div className={styles.mainControls}>
              <ToggleSwitch
                disabled={
                  isLoading ||
                  (!pipelines.find(
                    (p) => p.status === AutomationPipelineStatus.Done
                  ) &&
                    !property.proxyEndpointSwappingEnabled)
                }
                disabledTooltip={
                  isLoading ? (
                    ''
                  ) : (
                    <span>
                      Automatic {PRODUCT_NAME} installation refresh will be
                      enabled once you run at least one successful pipeline.
                      <br />
                      <br />
                      <SafeExternalLink
                        href={LINK_DOCS_AUTOMATION_REFRESH_OVERVIEW}
                      >
                        Read more
                      </SafeExternalLink>{' '}
                      about this automation pipelines.
                    </span>
                  )
                }
                onChange={onToggleEndpointSwapping}
                checked={!!property.proxyEndpointSwappingEnabled}
              />{' '}
              Enable automatic {PRODUCT_NAME} installation refresh
              <Tooltip
                type="help"
                content={
                  <span>
                    Makes {PRODUCT_NAME} automatically run this pipeline once
                    the serving proxy endpoint is found in any known content
                    filtering list. {PRODUCT_NAME} will <b>clone</b> the most
                    recent pipeline regardless of its status and start it
                    automatically.
                    <br />
                    <br />
                    <SafeExternalLink
                      href={LINK_DOCS_AUTOMATION_REFRESH_OVERVIEW}
                    >
                      Read more
                    </SafeExternalLink>{' '}
                    about this automation pipeline.
                  </span>
                }
              />
            </div>
            <div className={styles.controlsBar}>
              <div>
                {selectedPipeline && (
                  <div>
                    <MessageBlock
                      type={
                        selectedPipeline.status ===
                        AutomationPipelineStatus.Done
                          ? 'success'
                          : selectedPipeline.status ===
                            AutomationPipelineStatus.Aborted
                          ? 'warning'
                          : selectedPipeline.status ===
                            AutomationPipelineStatus.Errored
                          ? 'error'
                          : 'info'
                      }
                      display="inline"
                    >
                      The selected pipeline{' '}
                      {selectedPipeline.status === AutomationPipelineStatus.Done
                        ? 'is completed successfully.'
                        : selectedPipeline.status ===
                          AutomationPipelineStatus.Aborted
                        ? `was aborted with the following reason: ${lastPipelineResultReason}`
                        : selectedPipeline.status ===
                          AutomationPipelineStatus.Errored
                        ? `has failed with the following reason: ${lastPipelineResultReason}`
                        : selectedPipeline.status ===
                          AutomationPipelineStatus.Running
                        ? 'is running.'
                        : selectedPipeline.status ===
                          AutomationPipelineStatus.New
                        ? readyToBeRun
                          ? 'is ready to be run.'
                          : 'is waiting for initial configuration.'
                        : 'is paused.'}
                    </MessageBlock>
                  </div>
                )}
              </div>
              <div></div>
              <div>
                {selectedPipeline?.status ===
                AutomationPipelineStatus.Running ? (
                  <Button
                    image="close"
                    type="danger"
                    disabled={
                      isLoading ||
                      selectedPipeline?.status !==
                        AutomationPipelineStatus.Running
                    }
                    onClick={onAbortClick}
                  >
                    Abort
                  </Button>
                ) : selectedPipeline?.status ===
                  AutomationPipelineStatus.New ? (
                  <Button
                    disabled={
                      !readyToBeRun || isLoading || !!recentlyStartedPipelineId
                    }
                    image="play"
                    onClick={onStartClick}
                    disabledTooltip={
                      isLoading
                        ? ''
                        : 'To start this pipeline, implement the requested webhooks. Click on each pipeline step requiring changes to see the implementation details.'
                    }
                  >
                    Start
                  </Button>
                ) : (
                  <Button
                    disabled={
                      isLoading ||
                      (!(
                        latestPipeline?.status ===
                          AutomationPipelineStatus.Errored ||
                        latestPipeline?.status ===
                          AutomationPipelineStatus.Aborted ||
                        latestPipeline?.status === AutomationPipelineStatus.Done
                      ) &&
                        !(
                          pipelines[pipelines.length - 1]?.status ===
                          AutomationPipelineStatus.New
                        ))
                    }
                    image="settings"
                    onClick={onEditClick}
                    disabledTooltip="Let the latest pipeline complete or fail before you will be able to edit it"
                  >
                    Edit
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </div>
        <HorizontalStepper
          currentStep={stepperPipelines.findIndex(
            ({ pipeline }) => pipeline.id === selectedPipeline?.id
          )}
          onStepSelect={onSelectedPipelineChange}
          steps={stepperPipelines}
          scrollable={true}
          font="small"
        ></HorizontalStepper>
        {!selectedPipeline ? (
          <div className={styles.topText}>
            You don&apos;t have any proxy endpoint swap pipelines yet.
          </div>
        ) : (
          <div className={styles.stepsContainer}>
            {selectedPipeline.steps.map((step, i) => {
              const Component = pipelineStepIdToComponent[step.id];
              if (!Component) {
                return (
                  <PipelineStep
                    key={`${selectedPipeline.id}-${step.id}`}
                    icon="error"
                    title={`Unknown step "${step.id}"`}
                    step={step}
                    pipeline={selectedPipeline}
                    property={property}
                    refreshPipeline={refreshPipeline}
                  />
                );
              }
              return (
                <Component
                  key={`${selectedPipeline.id}-${i}`}
                  pipeline={selectedPipeline}
                  step={step}
                  property={property}
                  refreshPipeline={refreshPipeline}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PagePropertySettingsAutomationRefresh;
