import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { cancelTempPropertySetup } from 'Api';
import {
  useRecoilValueLoadable,
  selectedTempPropertyState,
  propertiesListVersionState,
  useSetRecoilState,
  useRecoilValue,
} from 'State';
import { ROUTE_PROPERTIES_MY } from 'Constants';
import Icon from 'Components/Common/Icon';
import { useHistory } from 'react-router-dom';
import { Toast } from 'toaster-js';

const PagePropertyDelete = () => {
  const history = useHistory();
  const propertiesListVersion = useRecoilValue(propertiesListVersionState);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );
  const propertyLoadable = useRecoilValueLoadable(selectedTempPropertyState);
  const property =
    propertyLoadable.state === 'hasValue' ? propertyLoadable.contents : null;
  const [loading, setLoading] = useState(false);
  const propertyId = property?.id;
  const cancelSetup = useCallback(async () => {
    if (!propertyId) {
      return;
    }
    setLoading(true);
    const { errorMessage } = await cancelTempPropertySetup(propertyId);
    setPropertiesListVersion(propertiesListVersion + 1);
    setLoading(false);
    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
    } else {
      history.push(ROUTE_PROPERTIES_MY);
    }
  }, [propertyId, history, propertiesListVersion, setPropertiesListVersion]);
  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div>
          <div>
            If you made a mistake, you can cancel the setup of{' '}
            <b>{property?.host}</b>.
          </div>
          <div className={styles.info}>
            This action will delete this property.
          </div>
        </div>
        <div className={styles.iconContainer}>
          <Icon
            className={
              styles.icon + ' ' + (loading ? styles['zoomed-out'] : '')
            }
            image="trash"
            size="ultra-giant"
          />
        </div>
        <button disabled={loading} onClick={cancelSetup}>
          Cancel Setup
        </button>
      </div>
    </div>
  );
};

export default PagePropertyDelete;
