import React, { ReactNode } from 'react';

/**
 * Unsafe external link to external, non-DataUnlocker websites.
 * Use to link external to DataUnlocker resources and websites (to remove "referer" header).
 * @see https://stackoverflow.com/questions/50709625/
 */
const UnsafeExternalLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => (
  <a href={href} target="_blank" rel="noreferrer">
    {children}
  </a>
);

export default UnsafeExternalLink;
