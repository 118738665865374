import { DEFAULT_FEATURE_TOGGLES } from 'Constants';
import React from 'react';
import { getFeatureToggle } from 'Utils';

interface FeatureGuardProps {
  feature: keyof typeof DEFAULT_FEATURE_TOGGLES;
  whenDisabled?: boolean;
  children: React.ReactNode;
}

const FeatureGuard = ({
  feature,
  children,
  whenDisabled = false,
}: FeatureGuardProps) => {
  return <>{!!getFeatureToggle(feature) !== whenDisabled ? children : null}</>;
};

export default FeatureGuard;
