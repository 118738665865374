import { UiProperty, UiTempProperty } from '@dataunlocker/pkg-types';
import Button from 'Components/Common/Button';
import Card, { CardHeader } from 'Components/Common/Card';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import Tooltip from 'Components/Common/Tooltip';
import PropertyHealthCheck from 'Components/Pages/Properties/Settings/Setup/Health/Box';
import {
  LINK_DOCS_HEALTH_CHECK,
  PRODUCT_NAME,
  ROUTE_PROPERTY_DASHBOARD,
  ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT,
} from 'Constants';
import { updatePropertyProxyFlagsHelper } from 'Helpers';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import { getPropertyHealthSummary, isTempProperty } from 'Utils';
import NeedHelpFooter from '../NeedHelpFooter';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty | UiTempProperty;
}

const PropertySetupHealth = ({ property }: ComponentProps) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const isPropertyHealthy = getPropertyHealthSummary(property).isHealthy;

  const onEnableDataUnlockerClick = useCallback(() => {
    if (isTempProperty(property)) {
      new Toast(
        'Please try again when this property will be confirmed.',
        Toast.TYPE_ERROR
      );
      return;
    }

    updatePropertyProxyFlagsHelper({
      setLoading,
      property: property as UiProperty,
      history,
      flags: {
        proxyEnabled: true,
      },
      onUpdate: () => setPropertiesListVersion(Math.random()),
    });
  }, [property, setPropertiesListVersion, history]);

  return (
    <div>
      <Card>
        <CardHeader
          title={
            <span>
              <span>Health check for {property.host}</span>
              <Tooltip
                type="help"
                content={
                  <span>
                    This health check allows you to verify whether the setup is
                    complete and your property is ready for DataUnlocker. You
                    may need to retry the health check several times before it
                    will become all-green. Click on failed checks to find out
                    what should be done next.{' '}
                    <SafeExternalLink href={LINK_DOCS_HEALTH_CHECK}>
                      Learn more
                    </SafeExternalLink>
                  </span>
                }
              />
            </span>
          }
        />
        <PropertyHealthCheck
          property={property}
          evaluateOnInit={
            // Health check page renders this component immediately, even if the URL isn't /health-check.
            // This allows to evaluate only when the page component switches to the actual URL.
            /health-check\/?$/.test(location.pathname) &&
            isTempProperty(property)
          }
        />
      </Card>
      <div className={styles.footer}>
        <Button
          type="secondary"
          onClick={() =>
            history.push(ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT(property.id))
          }
          image="backward"
        >
          Back to Script Setup
        </Button>
        {(property as UiProperty).proxyEnabled ? (
          <Button
            type={isPropertyHealthy ? 'primary' : 'secondary'}
            disabled={isTempProperty(property)}
            onClick={() => history.push(ROUTE_PROPERTY_DASHBOARD(property.id))}
            image="forward"
          >
            Go to Dashboard
          </Button>
        ) : (
          <Button
            type={isPropertyHealthy ? 'primary' : 'secondary'}
            onClick={onEnableDataUnlockerClick}
            disabled={loading || isTempProperty(property)}
            image="checkmark"
          >
            Enable {PRODUCT_NAME}
          </Button>
        )}
      </div>
      <NeedHelpFooter property={property} />
    </div>
  );
};

export default PropertySetupHealth;
