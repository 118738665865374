export const getFromLocalStorage = <T = any>(key: string): T | undefined => {
  const value = window.localStorage.getItem(key);
  if (value) {
    return JSON.parse(value) as T;
  } else {
    return undefined;
  }
};

export const setToLocalStorage = <T = any>(
  key: string,
  value: T
): T | undefined => {
  window.localStorage.setItem(key, JSON.stringify(value));
  return getFromLocalStorage<T>(key);
};

export const clearFromLocalStorage = (key: string) =>
  window.localStorage.removeItem(key);
