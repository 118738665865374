import {
  AutomationPipelineStatus,
  AutomationPipelineStepDataBasic,
} from '@dataunlocker/pkg-types';
import { requestAutomationPipelineUpdate } from 'Api';
import Button from 'Components/Common/Button';
import MessageBlock from 'Components/Common/MessageBlock';
import NumberInput from 'Components/Common/NumberInput';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import { ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING } from 'Constants';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'toaster-js';
import { getProxyEndpointUrl } from 'Utils';

const PipelineStepWaitUpdateClientCode = (props: PipelineStepCommonProps) => {
  const proxyEndpoint = props.property.proxyEndpoints.find(
    (e) => props.pipeline.context.newProxyEndpointUrlKey === e.urlKey
  );

  const { refreshPipeline } = props;
  const stepData: AutomationPipelineStepDataBasic = props.step.data || {};

  const pipelineStepIndex = props.pipeline.steps.findIndex(
    (s) => s === props.step
  );
  const initialWaitForHours = Math.floor(
    (stepData.maxWaitFor || 1000 * 60 * 60 * 24 * 2) / 1000 / 60 / 60
  );

  const [maxWaitForHours, setMaxWaitForHours] = useState(initialWaitForHours);
  const [isLoading, setIsLoading] = useState(false);

  const onCancelClick = useCallback(() => {
    setMaxWaitForHours(initialWaitForHours);
  }, [initialWaitForHours]);
  const onSaveClick = useCallback(async () => {
    setIsLoading(true);
    const data: AutomationPipelineStepDataBasic = {
      maxWaitFor: maxWaitForHours * 1000 * 60 * 60,
    };
    const response = await requestAutomationPipelineUpdate(props.pipeline.id, {
      updates: {
        steps: {
          [pipelineStepIndex]: {
            data,
          },
        },
      },
    });
    if (response.errorMessage) {
      new Toast(response.errorMessage, Toast.TYPE_ERROR);
    } else {
      await refreshPipeline();
    }
    setIsLoading(false);
  }, [refreshPipeline, props.pipeline.id, pipelineStepIndex, maxWaitForHours]);

  const hasChanges = initialWaitForHours !== maxWaitForHours;

  return (
    <PipelineStep
      icon="clock"
      title="Wait until the client code is updated"
      {...props}
    >
      <MessageBlock display="inline">
        Waiting for the served script to point to the new proxy endpoint. This
        step will periodically trigger property&apos;s health check and complete
        once the script starts using the new proxy endpoint
        {proxyEndpoint ? (
          <>
            {' '}
            <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(props.property.id)}>
              {getProxyEndpointUrl(proxyEndpoint, props.property)}
            </Link>
            .
          </>
        ) : (
          '.'
        )}
      </MessageBlock>
      <h3
        style={{
          marginTop: 'var(--spacing-big)',
          marginLeft: 'var(--spacing-small)',
          marginBottom: 'var(--spacing-regular)',
        }}
      >
        Step configuration
      </h3>
      <NumberInput
        disabled={props.pipeline.status !== AutomationPipelineStatus.New}
        label="Max hours to wait for update"
        wide
        max={24 * 7}
        min={1}
        value={maxWaitForHours}
        onChange={setMaxWaitForHours}
      />
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          disabled={!hasChanges || isLoading}
          onClick={onSaveClick}
        >
          Save
        </Button>
        <Button
          type="secondary"
          disabled={!hasChanges || isLoading}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </div>
    </PipelineStep>
  );
};

export default PipelineStepWaitUpdateClientCode;
