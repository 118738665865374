import MessageBlock from 'Components/Common/MessageBlock';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import { ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING } from 'Constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { getProxyEndpointUrl } from 'Utils';

const PipelineStepDeleteProxyEndpoint = (props: PipelineStepCommonProps) => {
  const proxyEndpoint = props.property.proxyEndpoints.find(
    (e) => props.pipeline.context.oldProxyEndpointUrlKey === e.urlKey
  );

  return (
    <PipelineStep icon="trash" title="Delete the old proxy endpoint" {...props}>
      <div>
        {proxyEndpoint ? (
          <MessageBlock display="inline">
            Proxy endpoint to delete:{' '}
            <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(props.property.id)}>
              {getProxyEndpointUrl(proxyEndpoint, props.property)}
            </Link>
          </MessageBlock>
        ) : (
          <MessageBlock display="inline">
            This step deletes the old proxy endpoint.
          </MessageBlock>
        )}
      </div>
    </PipelineStep>
  );
};

export default PipelineStepDeleteProxyEndpoint;
