import React from 'react';
import Icon from 'Components/Common/Icon';

import styles from './styles.module.scss';

interface PageHeaderProps {
  icon?: string;
  title: string | React.ReactNode;
  children?: React.ReactNode;
}

const CardHeader = ({ icon, title, children }: PageHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {icon && <Icon size="big" image={icon} margin="right-small" />}
        <h3>{title}</h3>
      </div>
      <div className={styles.controlsContainer}>{children}</div>
    </div>
  );
};

export default CardHeader;
