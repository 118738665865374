import { UiProperty } from '@dataunlocker/pkg-types';
import { updatePropertyProxyFlags } from 'Api';
import Icon from 'Components/Common/Icon';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import { PRODUCT_NAME } from 'Constants';
import React, { useCallback, useState } from 'react';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

interface UnconfiguredServicesRowProps {
  property: UiProperty;
}

const UnconfiguredServicesRow = ({
  property,
}: UnconfiguredServicesRowProps) => {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [proxyUnconfiguredEnabled, setProxyUnconfiguredEnabled] = useState(
    property.proxyNotConfiguredServices
  );
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const onRowClick = useCallback(() => {
    setOpened(!opened);
  }, [opened]);
  const onProxyUnconfiguredEnabledChange = useCallback(() => {
    setProxyUnconfiguredEnabled(!proxyUnconfiguredEnabled);
  }, [proxyUnconfiguredEnabled]);
  const onResetClick = useCallback(() => {
    setProxyUnconfiguredEnabled(property.proxyNotConfiguredServices);
  }, [property.proxyNotConfiguredServices]);
  const onSaveClick = useCallback(async () => {
    setLoading(true);
    const result = await updatePropertyProxyFlags(property.id, {
      proxyNotConfiguredServices: proxyUnconfiguredEnabled,
    });
    if (result.errorMessage) {
      new Toast(result.errorMessage, Toast.TYPE_ERROR);
    } else {
      setPropertiesListVersion(Math.random());
    }
    setLoading(false);
  }, [property.id, proxyUnconfiguredEnabled, setPropertiesListVersion]);

  const hasChanges =
    proxyUnconfiguredEnabled !== property.proxyNotConfiguredServices;
  return (
    <>
      <tr className={styles.allServicesRow} onClick={onRowClick}>
        <td className={`${styles.middleChildrenAlign} ${styles.noWrap}`}>
          <Icon size="small" image="www" margin="right-small" />
          <span>Unconfigured services</span>
        </td>
        <td
          className={`${styles.noWrap} ${
            property.proxyNotConfiguredServices && property.proxyEnabled
              ? styles.enabled
              : styles.disabled
          }`}
        >
          <Icon
            image={property.proxyNotConfiguredServices ? 'checkmark' : 'close'}
            size="small"
            margin="none"
          />{' '}
          {property.proxyNotConfiguredServices ? 'Enabled' : 'Disabled'}
        </td>
        <td colSpan={11} className={styles.minor}>
          <Icon image="info" size="small" margin="right-small" />
          When enabled, {PRODUCT_NAME} attempts to proxy{' '}
          <u>all failed 3rd-party requests</u> and adds them to this table. If
          you disable this option, only enabled services from the table above
          will be proxied.
        </td>
      </tr>
      {!opened ? null : (
        <tr className={styles.serviceControlsRow}>
          <td colSpan={13}>
            <div className={styles.settingsContainer}>
              <div>
                <div className={styles.header}>
                  Proxy settings for not configured services
                </div>
              </div>
              <table className={`${styles.controlsTable} failed-submit`}>
                <tbody>
                  <tr>
                    <td>
                      Proxy all failed requests to not
                      <br />
                      configured 3rd-party services
                    </td>
                    <td>
                      <ToggleSwitch
                        checked={proxyUnconfiguredEnabled}
                        onChange={onProxyUnconfiguredEnabledChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <button
                  disabled={loading || !hasChanges}
                  className={styles.noBottomMargin}
                  onClick={onSaveClick}
                >
                  Save
                </button>
                <button
                  disabled={loading || !hasChanges}
                  className="secondary"
                  onClick={onResetClick}
                >
                  Reset
                </button>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default UnconfiguredServicesRow;
