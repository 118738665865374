import { UiProperty } from '@dataunlocker/pkg-types';
import React from 'react';
import { DashboardViewGroup } from 'Types';
import { formatBytes, formatUsdCents, getUsdCentsPerBytes } from 'Utils';
import styles from './styles.module.scss';

interface Props {
  group: DashboardViewGroup;
  property: UiProperty;
}

const GroupHeaderRow = ({ group, property }: Props) => {
  return (
    <tr className={styles.groupHeaderRow}>
      <td className={styles.groupHeaderCell} colSpan={2}>
        <div
          className={styles.groupIcon}
          style={{ backgroundColor: group.color }}
        ></div>
        <span>{group.name}</span>
      </td>
      <td>{group.totals.count[1]}</td>
      <td>{group.totals.count[2]}</td>
      <td>{group.totals.count[3]}</td>
      <td>{group.totals.count[4]}</td>
      <td>{group.totals.totalCount}</td>
      <td>{formatBytes(group.totals.bytes[1])}</td>
      <td>{formatBytes(group.totals.bytes[2])}</td>
      <td>{formatBytes(group.totals.bytes[3])}</td>
      <td>{formatBytes(group.totals.bytes[4])}</td>
      <td>{formatBytes(group.totals.totalBytes)}</td>
      <td>
        {formatUsdCents(
          getUsdCentsPerBytes(group.totals.totalBytes, property.billing)
        )}
      </td>
    </tr>
  );
};

export default GroupHeaderRow;
