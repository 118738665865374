import { DEFAULT_FEATURE_TOGGLES } from 'Constants';
import { getFromLocalStorage, setToLocalStorage } from './local-storage';

type FeatureToggles = Partial<typeof DEFAULT_FEATURE_TOGGLES>;

export const getFeatureToggle = (
  experimentName: keyof FeatureToggles
): boolean => !!getFromLocalStorage('exp')?.[experimentName];

export const getFeatureToggles = (): FeatureToggles =>
  getFromLocalStorage('exp') || {};

export const setFeatureToggles = (exps: FeatureToggles): FeatureToggles =>
  setToLocalStorage(
    'exp',
    Object.assign(getFromLocalStorage('exp') || {}, exps)
  );

/*
 * To toggle from console:
 * localStorage.exp = JSON.stringify({ billingPlans: true}); location.reload();
 */
