import React from 'react';
import Icon from 'Components/Common/Icon';
import styles from './styles.module.scss';

const PageError = ({ message }: { message?: string } = {}) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.iconContainer}>
          <Icon image="loading" size="giant" />
        </div>
        {message && (
          <div className={styles.errorMessageContainer}>{message}</div>
        )}
      </div>
    </div>
  );
};

export default PageError;
