import React from 'react';
import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME } from 'Constants';
import { Permission } from '@dataunlocker/pkg-types';

interface Props {
  domain: string;
  email: string;
  permission: Permission;
}

const DialogConfirmGrantPermissionToNonExistingUser = ({
  email,
  permission,
}: Props) => {
  return (
    <div>
      <p>
        You are about to grant <b>{permission}</b> permissions to <b>{email}</b>
        , <b>who is not registered at {PRODUCT_NAME}</b>.
      </p>
      <p>
        {email} will be able to access the property only after they log in with
        the <b>exact same email address</b>. Press &quot;Continue&quot; to grant{' '}
        {permission} permissions to {email}.
      </p>
    </div>
  );
};

interface DialogArguments extends Props {
  onConfirm?: () => void;
}

export const showConfirmGrantPermissionToNonExistingUserDialog = (
  args: DialogArguments
) =>
  showDialog({
    title: `Add ${args.email} to ${args.domain}?`,
    icon: 'user',
    content: <DialogConfirmGrantPermissionToNonExistingUser {...args} />,
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    onConfirmClick: args.onConfirm,
  });
