import { UiProperty, UiTempProperty } from '@dataunlocker/pkg-types';
import {
  requestPropertyHealthCheck,
  requestPropertyTempHealthCheck,
} from 'Api';
import Icon from 'Components/Common/Icon';
import React, { useCallback, useEffect, useState } from 'react';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import {
  getPropertyHealthSummary,
  isTempProperty as getIsTempProperty,
} from 'Utils';
import RowEndpointsState from './RowEndpointsState';
import RowScriptEndpointState from './RowScriptEndpointState';
import RowScriptIsFirst from './RowScriptIsFirst';
import RowScriptIsFound from './RowScriptIsFound';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty | UiTempProperty;
  evaluateOnInit?: boolean;
}

const PropertyHealthCheck = ({
  property,
  evaluateOnInit = false,
}: ComponentProps) => {
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const isTempProperty = getIsTempProperty(property);
  const [loading, setLoading] = useState(evaluateOnInit);
  const reevaluate = useCallback(async () => {
    setLoading(true);
    const promise = isTempProperty
      ? requestPropertyTempHealthCheck(property.id)
      : requestPropertyHealthCheck(property.id);

    const { errorMessage } = await promise;

    setLoading(false);
    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
      return;
    }

    setPropertiesListVersion(Math.random());
  }, [property.id, isTempProperty, setPropertiesListVersion]);

  useEffect(() => {
    if (evaluateOnInit) {
      reevaluate();
    }
  }, [evaluateOnInit, reevaluate]);

  const propertyHealthSummary = getPropertyHealthSummary(property);
  return (
    <div>
      <div className={styles.setupVerificationStatusBlock}>
        {loading ? (
          <div>
            <Icon image="loading" size="huge" margin="right-regular" />
            <div>
              <div>Setup assessment is in progress, please wait...</div>
              <div className={styles.sub}>
                This can take up to 1 minute. Click on failed checks to know
                what to do next.
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Icon
              image={propertyHealthSummary.isHealthy ? 'checkmark' : 'warning'}
              size="huge"
              margin="right-regular"
            />
            <div>
              <div>Results are presented in the table below.</div>
              {!!property.health.lastAssessmentAt && (
                <div className={styles.sub}>
                  Last assessment at:{' '}
                  {new Date(property.health.lastAssessmentAt).toLocaleString()}
                </div>
              )}
            </div>
          </div>
        )}
        <div>
          <button
            type="button"
            className={
              getPropertyHealthSummary(property).isHealthy ? 'secondary' : ''
            }
            disabled={loading}
            onClick={reevaluate}
          >
            Retry
          </button>
        </div>
      </div>
      <div className={styles.p}>
        <table className={styles.table}>
          <tbody>
            <RowScriptIsFound property={property} loading={loading} />
            <RowScriptIsFirst property={property} loading={loading} />
            <RowEndpointsState property={property} loading={loading} />
            <RowScriptEndpointState property={property} loading={loading} />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PropertyHealthCheck;
