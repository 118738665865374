import {
  ServiceSupportGrade,
  UiProperty,
  UiProxiedServiceConfig,
} from '@dataunlocker/pkg-types';
import { deletePropertyService, updatePropertyServiceProxyFlags } from 'Api';
import AlwaysProxyControls from 'Components/Common/AlwaysProxyControls';
import FeatureGuard from 'Components/Common/FeatureGuard';
import Icon from 'Components/Common/Icon';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import Tooltip from 'Components/Common/Tooltip';
import { showDialog } from 'Components/Dialog';
import {
  ALWAYS_PROXY_DEFAULT_CONFIG,
  LINK_DOCS_MEASURING_IMPACT,
  LINK_DOCS_MEASURING_IMPACT_GA,
  LINK_DOCS_MEASURING_IMPACT_UA,
  PRODUCT_BASE_DOMAIN,
  PRODUCT_NAME,
  PRODUCT_SUPPORT_EMAIL,
  ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH,
  ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT,
} from 'Constants';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { getSupportGradeInfo } from 'Utils';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'toaster-js';
import Last45xxErrorDetails from './Last45xxErrorDetails';
import styles from './styles.module.scss';

interface ServiceControlsProps {
  property: UiProperty;
  serviceName: string;
}

const DEFAULT_CUSTOM_DIMENSION_NAME = 'dimension7';
const DEFAULT_CUSTOM_DIMENSION_VALUE = 'true';

const alwaysOnNotification = () =>
  new Toast('This setting cannot be changed.', Toast.TYPE_DONE);
const emptyMutatedObject = {};

const ServiceControls = ({ property, serviceName }: ServiceControlsProps) => {
  const serviceConfig: Partial<UiProxiedServiceConfig> =
    property.proxiedServices[serviceName] || emptyMutatedObject;
  const isConfigured = Object.keys(serviceConfig).length > 0;
  const supportGrade = getSupportGradeInfo(serviceConfig.supportGrade);

  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const defaultOverrideOp =
    typeof serviceConfig.apEnabled !== 'undefined' ||
    typeof serviceConfig.apBrowsers !== 'undefined';
  const [overrideApEnabled, setOverrideApEnabled] = useState(defaultOverrideOp);
  const defaultApBrowsers =
    typeof serviceConfig.apBrowsers === 'undefined'
      ? ALWAYS_PROXY_DEFAULT_CONFIG
      : serviceConfig.apBrowsers;
  const [apBrowsers, setApBrowsers] = useState(defaultApBrowsers);
  const defaultApEnabled =
    typeof serviceConfig.apEnabled === 'undefined'
      ? true
      : serviceConfig.apEnabled;
  const [apEnabled, setApEnabled] = useState(defaultApEnabled);

  const [loading, setLoading] = useState(false);
  const defaultEnabled = isConfigured
    ? serviceConfig.enabled !== false
    : property.proxyNotConfiguredServices;
  const [enabled, setEnabled] = useState(defaultEnabled);
  const defaultCustomDimensionEnabled = !!serviceConfig.cdName;
  const [customDimensionEnabled, setCustomDimensionEnabled] = useState(
    defaultCustomDimensionEnabled
  );
  const defaultCustomDimensionName = defaultCustomDimensionEnabled
    ? serviceConfig.cdName || ''
    : DEFAULT_CUSTOM_DIMENSION_NAME;
  const [cdName, setCdName] = useState(defaultCustomDimensionName);
  const defaultCustomDimensionValue = defaultCustomDimensionEnabled
    ? serviceConfig.cdValue || ''
    : DEFAULT_CUSTOM_DIMENSION_VALUE;
  const [cdValue, setCdValue] = useState(defaultCustomDimensionValue);
  const hasChanges =
    enabled !== defaultEnabled ||
    customDimensionEnabled !== defaultCustomDimensionEnabled ||
    (customDimensionEnabled && cdName !== defaultCustomDimensionName) ||
    (customDimensionEnabled && cdValue !== defaultCustomDimensionValue) ||
    overrideApEnabled !== defaultOverrideOp ||
    (overrideApEnabled &&
      (apBrowsers !== defaultApBrowsers || apEnabled !== defaultApEnabled));
  const onResetClick = useCallback(() => {
    setEnabled(defaultEnabled);
    setCustomDimensionEnabled(defaultCustomDimensionEnabled);
    setCdName(serviceConfig.cdName || DEFAULT_CUSTOM_DIMENSION_NAME);
    setCdValue(serviceConfig.cdValue || DEFAULT_CUSTOM_DIMENSION_VALUE);
    setOverrideApEnabled(defaultOverrideOp);
    setApBrowsers(defaultApBrowsers);
    setApEnabled(defaultApEnabled);
  }, [
    serviceConfig,
    defaultEnabled,
    defaultCustomDimensionEnabled,
    defaultOverrideOp,
    defaultApBrowsers,
    defaultApEnabled,
  ]);
  const onSaveServiceProxySettingsClick = useCallback(async () => {
    setLoading(true);
    const result = await updatePropertyServiceProxyFlags(property.id, {
      service: serviceName,
      enabled,
      cdEnabled: customDimensionEnabled,
      ...(customDimensionEnabled ? { cdName, cdValue } : {}),
      apOverride: overrideApEnabled,
      ...(overrideApEnabled ? { apEnabled, apBrowsers } : {}),
    });
    setLoading(false);
    if (result.errorMessage) {
      new Toast(result.errorMessage, Toast.TYPE_ERROR);
    } else {
      setPropertiesListVersion(Math.random());
    }
  }, [
    property.id,
    enabled,
    customDimensionEnabled,
    cdName,
    cdValue,
    serviceName,
    setPropertiesListVersion,
    overrideApEnabled,
    apEnabled,
    apBrowsers,
  ]);
  const onCdNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCdName(event.currentTarget.value);
    },
    []
  );
  const onCdValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCdValue(event.currentTarget.value);
    },
    []
  );
  const onDeleteServiceClick = useCallback(() => {
    showDialog({
      title: `Delete configuration for ${serviceName}?`,
      content: `This action will delete the existing configuration of ${serviceName}, but won't clear any associated metrics nor affect the consumed traffic. In case ${PRODUCT_NAME} is configured to proxy traffic to "All other services", and some traffic is proxied to ${serviceName}, it will again appear as a configured service.`,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',
      onConfirmClick: async () => {
        const result = await deletePropertyService(property.id, {
          domainName: serviceName,
        });
        if (result.errorMessage) {
          new Toast(result.errorMessage, Toast.TYPE_ERROR);
          return;
        }
        setPropertiesListVersion(Math.random());
      },
    });
  }, [serviceName, property.id, setPropertiesListVersion]);

  const isSaveDisabled =
    !hasChanges || loading || (customDimensionEnabled && (!cdName || !cdValue));
  const supportGradeMessage =
    serviceConfig.supportGradeMessage ||
    (serviceConfig.supportGrade === ServiceSupportGrade.unknown
      ? `${PRODUCT_NAME} uses standard reverse proxy for ${serviceName}. Despite DataUnlocker passes f.e. ` +
        `X-Forwarded-For and X-Real-IP headers, the geolocation (IP address) of the proxied request may not be ` +
        `properly handled by ${serviceName}. Contact ${PRODUCT_SUPPORT_EMAIL} if you spot any ` +
        `problems when proxying ${serviceName}.`
      : serviceConfig.supportGrade === ServiceSupportGrade.full
      ? `${PRODUCT_NAME} is known to work well with ${serviceName}.`
      : '');
  const isWildcard = serviceName.startsWith('*.');
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <span>
            Proxy settings for <b>{serviceName}</b>
            {isConfigured ? '' : ' (not configured)'}
          </span>

          {isWildcard && (
            <Tooltip
              type="help"
              content={
                <span>
                  Wildcard service configuration works for any <b>subdomain</b>{' '}
                  of any level. I.e. <pre>{serviceName}</pre> will match{' '}
                  <pre>test.{serviceName.replace(/\*\./, '')}</pre>,{' '}
                  <pre>test.test.{serviceName.replace(/\*\./, '')}</pre> and so
                  on. Another configured service which domain&apos;s name
                  matches the proxied host exactly always has a higher priority
                  than this wildcard configuration. If there are more than 10
                  distinct subdomains of the same 2nd-level domain proxied, a
                  wildcard configuration will be applied to all new and not
                  configured subdomain names of a particular domain. To control
                  a specific domain, manually add a configuration for it.
                </span>
              }
            />
          )}
        </div>
        <Tooltip type="info" content={supportGradeMessage}>
          <div
            className={`${styles.alignMiddle} ${
              styles[`cc-${supportGrade.colorCoding}`]
            }`}
          >
            <Icon image={supportGrade.icon} margin="right-small" size="small" />{' '}
            <span>{supportGrade.text}</span>
          </div>
        </Tooltip>
      </div>

      <table className={`${styles.controlsTable} failed-submit`}>
        <tbody>
          <tr>
            <td>
              <div>Proxy enabled</div>
              <div className={styles.minor}>
                {enabled ? (
                  <span>Proxy when requests fail</span>
                ) : (
                  <span>Don&apos;t proxy this service</span>
                )}
              </div>
            </td>
            <td>
              <ToggleSwitch noMargin checked={enabled} onChange={setEnabled} />
            </td>
          </tr>
          <FeatureGuard feature="alwaysProxy">
            {!serviceName.endsWith(PRODUCT_BASE_DOMAIN) && (
              <tr>
                <td>
                  Override always-proxy mode{' '}
                  <Tooltip
                    type="help"
                    content={
                      <span>
                        Use individual always-proxy configuration for this
                        service.
                      </span>
                    }
                  >
                    <Icon image="help" margin="left-small" size="small" />
                  </Tooltip>
                </td>
                <td>
                  <ToggleSwitch
                    noMargin
                    checked={overrideApEnabled}
                    onChange={setOverrideApEnabled}
                  />
                </td>
              </tr>
            )}
            {overrideApEnabled && (
              <tr>
                <td>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Always proxy
                  enabled{' '}
                  <Tooltip
                    type="help"
                    content={
                      <>
                        <p>
                          When enabled, {serviceName} will be proxied through{' '}
                          {PRODUCT_NAME}
                          and only if failed is retried without the proxy.
                        </p>
                        <p>
                          When always proxy is disabled, the behavior is
                          standard: first try original request, then proxy.
                        </p>
                        <p>
                          Be careful when enabling this option, as successfully
                          proxied request may not mean it is successfully
                          handled by the destination server.
                        </p>
                      </>
                    }
                  >
                    <Icon image="help" margin="left-small" size="small" />
                  </Tooltip>
                </td>
                <td>
                  <ToggleSwitch
                    noMargin
                    checked={apEnabled}
                    onChange={setApEnabled}
                  />
                </td>
              </tr>
            )}
            {overrideApEnabled && (
              <tr>
                <td>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Always-proxy
                  in browsers{' '}
                  <Tooltip
                    type="help"
                    content={
                      <span>
                        Select web browsers in which <b>all requests</b> to{' '}
                        {serviceName} will go via {PRODUCT_NAME},{' '}
                        <b>regardless</b> of whether they are blocked or not. In
                        case of always-proxy mode the fallback logic is
                        reversed: if the request to {PRODUCT_NAME} fails, the
                        original URL is requested.
                        <br />
                        <br />
                        Changing this config requires a{' '}
                        <Link
                          to={ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT(property.id)}
                        >
                          script update
                        </Link>{' '}
                        (or re-running{' '}
                        <Link
                          to={ROUTE_PROPERTY_SETTINGS_AUTOMATION_REFRESH(
                            property.id
                          )}
                        >
                          installation refresh
                        </Link>{' '}
                        pipeline).
                      </span>
                    }
                  >
                    <Icon image="help" margin="left-small" size="small" />
                  </Tooltip>
                </td>
                <td>
                  <AlwaysProxyControls
                    disabled={!apEnabled}
                    config={apBrowsers}
                    onChange={setApBrowsers}
                  />
                </td>
              </tr>
            )}
          </FeatureGuard>
          {!!serviceConfig.ipAddressProxySupport && (
            <tr>
              <td>Overwrite client IP for unblocked hits</td>
              <td>
                <ToggleSwitch
                  noMargin
                  checked={true}
                  onChange={alwaysOnNotification}
                />
              </td>
            </tr>
          )}
          {serviceConfig.cdSupported && (
            <>
              <tr>
                <td>
                  <span>Add custom dimension to unblocked hits</span>

                  <Tooltip
                    type="info"
                    content={
                      <span>
                        When set, DataUnlocker will add a custom dimension to{' '}
                        <b>proxied</b> Google Analytics hits. Read more about
                        how to use custom dimensions with {PRODUCT_NAME}{' '}
                        <SafeExternalLink href={LINK_DOCS_MEASURING_IMPACT}>
                          here
                        </SafeExternalLink>
                        .
                      </span>
                    }
                  >
                    <Icon image="help" margin="left-small" size="small" />
                  </Tooltip>
                </td>
                <td>
                  <ToggleSwitch
                    noMargin
                    checked={true}
                    onChange={setCustomDimensionEnabled}
                    disabled
                  />
                </td>
              </tr>
              {true && (
                <tr>
                  <td>
                    <span>Custom dimension name</span>
                    <Tooltip
                      type="info"
                      content={
                        <span>
                          For{' '}
                          <SafeExternalLink
                            href={LINK_DOCS_MEASURING_IMPACT_UA}
                          >
                            Universal Analytics
                          </SafeExternalLink>
                          , create a new dimension and enter &quot;dimension
                          <b>X</b>&quot;, where <b>X</b> is a new
                          dimension&apos;s index. For{' '}
                          <SafeExternalLink
                            href={LINK_DOCS_MEASURING_IMPACT_GA}
                          >
                            Google Analytics 4
                          </SafeExternalLink>
                          , you can use any name but you also need to create a
                          dimension with the same name in Google Analytics.
                        </span>
                      }
                    >
                      <Icon image="help" margin="left-small" size="small" />
                    </Tooltip>
                  </td>
                  <td className={styles.smallPadding}>
                    <input
                      type="text"
                      placeholder='f.e. "unblocked"'
                      required
                      value={"dataunlocker"}
                      onChange={onCdNameChange}
                      disabled
                    />
                  </td>
                </tr>
              )}
              {true && (
                <tr>
                  <td>
                    <span>Custom dimension value</span>

                    <Tooltip
                      type="info"
                      content={
                        <span>
                          The value of the dimension can be anything, but keep
                          it consistent with other Google Analytics endpoints.
                        </span>
                      }
                    >
                      <Icon image="help" margin="left-small" size="small" />
                    </Tooltip>
                  </td>
                  <td className={styles.smallPadding}>
                    <input
                      type="text"
                      placeholder='f.e. "true"'
                      required
                      value={"true"}
                      disabled
                      onChange={onCdValueChange}
                    />
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>

      <div>
        <button
          disabled={isSaveDisabled && isConfigured}
          className={styles.noBottomMargin}
          onClick={onSaveServiceProxySettingsClick}
        >
          Save
        </button>
        <button
          disabled={isSaveDisabled}
          className={`${styles.noBottomMargin} secondary`}
          onClick={onResetClick}
        >
          Reset
        </button>
      </div>

      {isConfigured && (
        <>
          <div className={styles.header}>
            <div>Last errors</div>
          </div>

          {!serviceConfig.last4xx &&
            !serviceConfig.last5xx &&
            !serviceConfig.last1xx && (
              <div className={styles.no45xxNote}>
                You will see error details here if the proxy ever encounters a
                4xx or 5xx response for {serviceName}.
              </div>
            )}

          {serviceConfig.last4xx && (
            <Last45xxErrorDetails errorDetails={serviceConfig.last4xx} />
          )}
          {serviceConfig.last5xx && (
            <Last45xxErrorDetails errorDetails={serviceConfig.last5xx} />
          )}
          {serviceConfig.last1xx && (
            <Last45xxErrorDetails errorDetails={serviceConfig.last1xx} />
          )}

          <div className={styles.header}>
            <div>Actions</div>
          </div>

          <div>
            <button
              disabled={loading}
              className="secondary"
              onClick={onDeleteServiceClick}
            >
              Delete configuration
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceControls;
