import { UiProperty, UiProxiedServiceConfig } from '@dataunlocker/pkg-types';
import FeatureGuard from 'Components/Common/FeatureGuard';
import Icon from 'Components/Common/Icon';
import Tooltip from 'Components/Common/Tooltip';
import React from 'react';
import { DashboardViewService } from 'Types';
import { formatBytes, formatUsdCents, getUsdCentsPerBytes } from 'Utils';
import ProxyStatusTooltip from '../ProxyStatusTooltip';
import ServiceControls from '../ServiceControls';
import styles from './styles.module.scss';

const iconEnabled = <Icon margin="none" image="checkmark" size="small" />;
const iconDisabled = <Icon margin="none" image="close" size="small" />;

interface ServiceRowProps {
  onClick?: (serviceName: string) => any;
  opened: boolean;
  property: UiProperty;
  service: DashboardViewService;
}

const ServiceRow = ({
  onClick,
  opened = false,
  property,
  service,
}: ServiceRowProps) => {
  const serviceConfig: UiProxiedServiceConfig =
    property.proxiedServices[service.name] || {};
  const isServiceEnabled = property.proxyNotConfiguredServices
    ? serviceConfig?.enabled !== false
    : serviceConfig
    ? serviceConfig.enabled !== false
    : false;
  const hasSpecialControls = !!(
    serviceConfig?.cdSupported || serviceConfig.ipAddressProxySupport
  );
  const isApEnabled =
    typeof service.config.apEnabled === 'undefined'
      ? !!property.apEnabled
      : service.config.apEnabled;

  return (
    <>
      <tr
        className={`${styles.serviceTableRow}${
          opened ? ` ${styles.selectedRow}` : ''
        }`}
        onClick={() => onClick?.(service.name)}
      >
        <td className={styles.hostName}>
          <span>{service.name}</span>
          {hasSpecialControls && (
            <Tooltip
              content={`This service has additional configuration options. Click on this row to see them.`}
            >
              <Icon
                size="small"
                margin="left-small"
                image="info"
                className={styles.specialControlsIcon}
              />
            </Tooltip>
          )}
        </td>
        <td
          className={`${styles.noWrap} ${
            isServiceEnabled && property.proxyEnabled
              ? styles.enabled
              : styles.disabled
          }`}
        >
          {isServiceEnabled ? iconEnabled : iconDisabled}{' '}
          <FeatureGuard feature="alwaysProxy">
            <ProxyStatusTooltip>
              {isServiceEnabled
                ? isApEnabled
                  ? 'Always'
                  : 'On demand'
                : 'Disabled'}
            </ProxyStatusTooltip>
          </FeatureGuard>
          <FeatureGuard whenDisabled feature="alwaysProxy">
            {isServiceEnabled ? 'Enabled' : 'Disabled'}
          </FeatureGuard>
        </td>
        <td>{service.totals.count[1]}</td>
        <td>{service.totals.count[2]}</td>
        <td className={service.totals.count[3] ? styles.error : ''}>
          {service.totals.count[3]}
        </td>
        <td className={service.totals.count[4] ? styles.error : ''}>
          {service.totals.count[4]}
        </td>
        <td>{service.totals.totalCount}</td>
        <td className={styles.noWrap}>
          {formatBytes(service.totals.bytes[1])}
        </td>
        <td className={styles.noWrap}>
          {formatBytes(service.totals.bytes[2])}
        </td>
        <td
          className={`${styles.noWrap}${
            service.totals.bytes[3] ? ` ${styles.error}` : ''
          }`}
        >
          {formatBytes(service.totals.bytes[3])}
        </td>
        <td
          className={`${styles.noWrap}${
            service.totals.bytes[4] ? ` ${styles.error}` : ''
          }`}
        >
          {formatBytes(service.totals.bytes[4])}
        </td>
        <td className={styles.noWrap}>
          {formatBytes(service.totals.totalBytes)}
        </td>
        <td>
          {formatUsdCents(
            getUsdCentsPerBytes(service.totals.totalBytes, property.billing)
          )}
        </td>
      </tr>
      {!opened ? null : (
        <tr className={styles.serviceControlsRow}>
          <td colSpan={13}>
            <ServiceControls property={property} serviceName={service.name} />
          </td>
        </tr>
      )}
    </>
  );
};

export default ServiceRow;
