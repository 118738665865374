import { UiUser } from '@dataunlocker/pkg-types';
import { requestDeleteUser } from 'Api';
import MessageBlock from 'Components/Common/MessageBlock';
import { showDialog } from 'Components/Dialog';
import { ApiUsersDeleteResponse, PRODUCT_NAME } from 'Constants';
import React, { useCallback, useState } from 'react';
import { Toast } from 'toaster-js';
import { DialogButtonBar } from '../Common/DialogButtonBar';

const responsePropertiesToJsx = (
  properties: NonNullable<
    ApiUsersDeleteResponse['whyNot']
  >['userIsBoundToProperties'],
  {
    closeDialog,
    onPropertyClick,
  }: { closeDialog: () => void; onPropertyClick: (id: string) => void }
) =>
  properties.flatMap(({ id, host }, i, arr) =>
    [
      <a
        key={id}
        href={(() => '#')()}
        onClick={() => {
          closeDialog();
          onPropertyClick(id);
        }}
      >
        {host}
      </a>,
    ].concat(i === arr.length - 1 ? [] : [<span key={`s${id}`}>, </span>])
  );

const DialogDeleteUser = ({
  user,
  closeDialog,
  onUserDeleted,
  onPropertyClick,
}: {
  user: UiUser;
  closeDialog: () => void;
  onUserDeleted: () => void;
  onPropertyClick: (id: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ApiUsersDeleteResponse>({
    isDeleted: true,
  });
  const onDeleteClick = useCallback(async () => {
    if (!user) {
      return;
    }

    setLoading(true);
    const { errorMessage, response } = await requestDeleteUser(user.id);
    setLoading(false);

    if (errorMessage || !response) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
      return;
    }

    if (response.isDeleted) {
      closeDialog();
      onUserDeleted();
      return;
    }

    setResponse(response);
  }, [user, onUserDeleted, closeDialog]);

  return (
    <div>
      <p>
        Deleting <b>{user.email}</b> from {PRODUCT_NAME} is permanent and cannot
        be undone. All associated data with <b>{user.email}</b> will also be
        removed from {PRODUCT_NAME} immediately.
      </p>
      <p>
        Ensure that this user does not belong to any of the properties or
        otherwise delete the associated properties or find new owners for them.
      </p>
      <p>
        Later you will still be able to register on {PRODUCT_NAME} with the same
        email address.
      </p>
      {!!response.whyNot?.cardIsBoundToProperties.length && (
        <MessageBlock type="warning">
          The card of the current user is used for traffic purchases in the
          following properties:{' '}
          {responsePropertiesToJsx(response.whyNot.cardIsBoundToProperties, {
            closeDialog,
            onPropertyClick,
          })}
          . First, remove this card from properties.
        </MessageBlock>
      )}
      {!!(
        (response.whyNot?.userIsBoundToProperties.length ? 1 : 0) +
        (response.whyNot?.userIsBoundToTempProperties.length ? 1 : 0)
      ) && (
        <MessageBlock type="warning">
          The current user is added to the following properties:{' '}
          {responsePropertiesToJsx(
            (response.whyNot?.userIsBoundToProperties || []).concat(
              response.whyNot?.userIsBoundToTempProperties || []
            ),
            { closeDialog, onPropertyClick }
          )}
          . Remove the user from these properties first or delete them to delete
          the user.
        </MessageBlock>
      )}
      <DialogButtonBar
        onCancelClick={closeDialog}
        cancelButtonText="Cancel"
        cancelButtonDisabled={loading}
        confirmButtonDisabled={loading}
        confirmButtonText="DELETE PERMANENTLY"
        confirmButtonClass="danger"
        onConfirmClick={onDeleteClick}
      />
    </div>
  );
};

// TODO: use history & make loading state internal
export const showDeleteUserDialog = ({
  user,
  onUserDeleted,
  onPropertyClick,
}: {
  user: UiUser;
  onUserDeleted: () => void;
  onPropertyClick: (id: string) => void;
}) =>
  showDialog({
    title: `Delete user ${user.email}?`,
    icon: 'trash',
    content: ({ closeDialog }) => (
      <DialogDeleteUser
        closeDialog={closeDialog}
        onUserDeleted={onUserDeleted}
        onPropertyClick={onPropertyClick}
        user={user}
      />
    ),
    displayButtons: false,
  });
