import { UiProperty } from '@dataunlocker/pkg-types';
import { deleteProperty } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import PageHeader from 'Components/Common/Pages/Header';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import { showDeletePropertyDialog } from 'Components/Dialog/DeleteProperty';
import { showHealthCheckBeforePropertyDeleteDialog } from 'Components/Dialog/HealthCheckBeforePropertyDelete';
import { PRODUCT_NAME, ROUTE_PROPERTIES_MY } from 'Constants';
import { updatePropertyProxyFlagsHelper } from 'Helpers';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { propertiesListVersionState, useSetRecoilState } from 'State';
import { Toast } from 'toaster-js';
import { getPropertyHealthSummary } from 'Utils';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty;
}

const PagePropertySettings = ({ property }: ComponentProps) => {
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [proxyEnabled, setProxyEnabled] = useState(!!property.proxyEnabled);
  const [proxyNotConfiguredServices, setProxyNotConfiguredServices] = useState(
    !!property.proxyNotConfiguredServices
  );
  const hasChanges =
    proxyEnabled !== !!property.proxyEnabled ||
    proxyNotConfiguredServices !== !!property.proxyNotConfiguredServices;
  const onResetClick = useCallback(() => {
    setProxyEnabled(!!property.proxyEnabled);
    setProxyNotConfiguredServices(!!property.proxyNotConfiguredServices);
  }, [property.proxyEnabled, property.proxyNotConfiguredServices]);
  const onSaveProxySettingsClick = useCallback(() => {
    updatePropertyProxyFlagsHelper({
      setLoading,
      property,
      history,
      flags: {
        proxyEnabled,
        proxyNotConfiguredServices,
      },
      onUpdate: () => setPropertiesListVersion(Math.random()),
    });
  }, [
    property,
    proxyEnabled,
    proxyNotConfiguredServices,
    setPropertiesListVersion,
    history,
  ]);
  const onDeleteClick = useCallback(() => {
    if (!getPropertyHealthSummary(property).canBeDeleted) {
      showHealthCheckBeforePropertyDeleteDialog({ property, history });
      return;
    }
    showDeletePropertyDialog({
      property,
      onConfirmClick: async () => {
        history.push(ROUTE_PROPERTIES_MY);
        const { errorMessage } = await deleteProperty(property.id);
        if (errorMessage) {
          new Toast(errorMessage, Toast.TYPE_ERROR);
        } else {
          setPropertiesListVersion(Math.random());
        }
      },
    });
  }, [property, history, setPropertiesListVersion]);

  return (
    <div>
      <PageHeader
        icon="settings"
        title={`${property.host} • Property settings`}
      />
      <div className={styles.containerCenter}>
        <Card>
          <CardHeader title="Proxy settings" />
          <div>
            <ToggleSwitch
              label={`${PRODUCT_NAME} proxy`}
              checked={proxyEnabled}
              onChange={setProxyEnabled}
            />
            <ToggleSwitch
              label="Proxy services which are not configured"
              checked={proxyNotConfiguredServices}
              onChange={setProxyNotConfiguredServices}
            />
          </div>

          <div>
            <button
              disabled={!hasChanges || loading}
              onClick={onSaveProxySettingsClick}
            >
              Save
            </button>
            <button
              disabled={!hasChanges || loading}
              className="secondary"
              onClick={onResetClick}
            >
              Reset
            </button>
          </div>
        </Card>
        <Card>
          <CardHeader title="Danger zone" />
          <button className="danger" onClick={onDeleteClick}>
            Delete Property
          </button>
        </Card>
      </div>
    </div>
  );
};

export default PagePropertySettings;
