import {
  AutomationPipelineStatus,
  AutomationPipelineStep,
  AutomationPipelineStepDataWebhookBasic,
} from '@dataunlocker/pkg-types';
import { requestAutomationPipelineUpdate } from 'Api';
import Button from 'Components/Common/Button';
import CopyButton from 'Components/Common/CopyButton';
import KeyValueInput from 'Components/Common/KeyValueInput';
import MessageBlock from 'Components/Common/MessageBlock';
import SelectInput from 'Components/Common/SelectInput';
import Table from 'Components/Common/Table';
import TextInput from 'Components/Common/TextInput';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import { ROUTE_PROPERTY_SETTINGS_AUTOMATION_SECRETS } from 'Constants';
import React, { ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

const webhookMethodOptions = [
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'POST',
    value: 'POST',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
  {
    label: 'PATCH',
    value: 'PATCH',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
  },
];
const INITIAL_WEBHOOK_METHOD = 'POST';
const INITIAL_BODY_ENABLED = false;
const CONTENT_TYPE_HEADER_NAME = 'content-type';
const INITIAL_CONTENT_TYPE = 'application/json';

interface Props extends PipelineStepCommonProps {
  children?: ReactNode;
  title: string;
  icon: string;
  initialUrl: string;
  initialBodyPayload?: string;
  variables?: {
    // List of {{VARIABLES}} which can be used in this webhook.
    name: string;
    example?: string;
    description: React.ReactNode | string;
    required?: boolean;
  }[];
}

const PipelineStepWebhook = (props: Props) => {
  const { refreshPipeline } = props;
  const stepData: AutomationPipelineStepDataWebhookBasic =
    props.step.data || {};
  const stepContext: AutomationPipelineStep['context'] =
    props.step.context || {};
  const pipelineStepIndex = props.pipeline.steps.findIndex(
    (s) => s === props.step
  );
  const originalUrl = stepData.webhookUrl || props.initialUrl;
  const originalMethod = stepData.webhookMethod || INITIAL_WEBHOOK_METHOD;
  const originalBodyEnabled = !!stepData.webhookBody || INITIAL_BODY_ENABLED;
  const originalHeaders = Object.entries(
    stepData.webhookHeaders || {}
  ).map(([key, value]) => ({ key, value }));
  const originalBodyPayload =
    stepData.webhookBody ||
    props.initialBodyPayload ||
    `${JSON.stringify(
      Object.fromEntries(
        props.variables
          ?.filter((v) => v.required)
          .map((v) => [v.name, `{{${v.name}}}`]) || []
      ),
      null,
      2
    )}` ||
    '';

  const [url, setUrl] = useState(originalUrl);
  const [webhookMethod, setWebhookMethod] = useState(originalMethod);
  const [bodyEnabled, setBodyEnabled] = useState(originalBodyEnabled);
  const [headers, setHeaders] = useState(originalHeaders);
  const [bodyPayload, setBodyPayload] = useState(originalBodyPayload);
  const [isLoading, setIsLoading] = useState(false);
  const [displayVariables, setDisplayVariables] = useState(false);

  const onToggleBodyEnabled = useCallback(
    (enabled: boolean) => {
      if (enabled) {
        if (
          !headers.find(
            ({ key }) => key.toLowerCase() === CONTENT_TYPE_HEADER_NAME
          )
        ) {
          setHeaders(
            headers.concat([
              { key: CONTENT_TYPE_HEADER_NAME, value: INITIAL_CONTENT_TYPE },
            ])
          );
        }
      } else {
        if (
          headers.find(
            ({ key }) => key.toLowerCase() === CONTENT_TYPE_HEADER_NAME
          )
        ) {
          setHeaders(
            headers.filter(
              ({ key }) => key.toLowerCase() !== CONTENT_TYPE_HEADER_NAME
            )
          );
        }
      }
      setBodyEnabled(enabled);
    },
    [headers]
  );

  const onCancelClick = useCallback(() => {
    setUrl(originalUrl);
    setWebhookMethod(originalMethod);
    setBodyEnabled(originalBodyEnabled);
    setHeaders(originalHeaders);
    setBodyPayload(originalBodyPayload);
  }, [
    originalUrl,
    originalMethod,
    originalBodyEnabled,
    originalHeaders,
    originalBodyPayload,
  ]);
  const onSaveClick = useCallback(async () => {
    setIsLoading(true);
    const data: AutomationPipelineStepDataWebhookBasic = {
      webhookUrl: url,
      webhookMethod: webhookMethod,
      webhookHeaders: Object.fromEntries(
        headers.filter(({ key }) => !!key).map(({ key, value }) => [key, value])
      ),
      webhookBody: bodyEnabled && webhookMethod !== 'GET' ? bodyPayload : '',
    };
    const response = await requestAutomationPipelineUpdate(props.pipeline.id, {
      updates: {
        steps: {
          [pipelineStepIndex]: {
            data,
          },
        },
      },
    });
    if (response.errorMessage) {
      new Toast(response.errorMessage, Toast.TYPE_ERROR);
    } else {
      await refreshPipeline();
    }
    setIsLoading(false);
  }, [
    props.pipeline.id,
    pipelineStepIndex,
    url,
    webhookMethod,
    bodyEnabled,
    headers,
    bodyPayload,
    refreshPipeline,
  ]);

  const hasChanges =
    url !== originalUrl ||
    webhookMethod !== originalMethod ||
    JSON.stringify(headers) !== JSON.stringify(originalHeaders) ||
    ((bodyEnabled !== originalBodyEnabled || bodyEnabled) &&
      (bodyEnabled !== originalBodyEnabled ||
        bodyPayload !== originalBodyPayload));
  const configured =
    !!stepData.webhookUrl &&
    !!stepData.webhookUrl.match(/\/\/([^/]+)/)?.[1] ===
      !!props.initialUrl.match(/\/\/([^/]+)/)?.[1];
  const secretsRoute = ROUTE_PROPERTY_SETTINGS_AUTOMATION_SECRETS(
    props.property.id
  );

  return (
    <PipelineStep configured={configured} {...props}>
      {props.children}
      <MessageBlock type="info" display="inline">
        <Link to="#" onClick={() => setDisplayVariables(!displayVariables)}>
          Click here
        </Link>{' '}
        to see what <b>{`{{VARIABLES}}`}</b> you can use in this webhook. Every
        occurrence of {`{{VARIABLE_NAME}}`} in the request URL, body or in the
        header value will be replaced.
      </MessageBlock>
      {displayVariables && (
        <>
          <h3 className={styles.sectionHeader}>Webhook variables</h3>
          <Table fullWidth compact>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {(props.variables || [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .concat(
                  (props.property.secrets || []).map(({ name }) => ({
                    name: `secrets.${name}`,
                    description: (
                      <span>
                        A secret value &quot;{name}&quot; configured in{' '}
                        <Link to={secretsRoute}>secrets</Link>.
                      </span>
                    ),
                  }))
                )
                .map(({ name, description, example, required }) => (
                  <tr key={name}>
                    <td style={{ whiteSpace: 'pre' }}>
                      <CopyButton inline text={`{{${name}}}`} />{' '}
                      {required ? <b>{name}</b> : name}
                    </td>
                    <td>
                      {description}
                      {example && (
                        <>
                          <br />
                          <i>
                            <b>Value example</b>: {example}
                          </i>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
      <h3 className={styles.sectionHeader}>Webhook configuration</h3>
      <div style={{ display: 'flex' }}>
        <SelectInput
          compact
          label="Method"
          disabled={
            isLoading || props.pipeline.status !== AutomationPipelineStatus.New
          }
          value={webhookMethod}
          onChange={setWebhookMethod}
          options={webhookMethodOptions}
        />
        <TextInput
          disabled={
            isLoading || props.pipeline.status !== AutomationPipelineStatus.New
          }
          value={url}
          onChange={setUrl}
          compact
          wide
          label="Your API endpoint URL"
        />
      </div>
      <div>
        <KeyValueInput
          keyValues={headers}
          onChange={setHeaders}
          compact
          disabled={
            isLoading || props.pipeline.status !== AutomationPipelineStatus.New
          }
          addButtonText="Add Header"
          keyLabel="Header name"
          valueLabel="Header value"
        />
      </div>
      {webhookMethod !== 'GET' && (
        <div>
          <ToggleSwitch
            label="Send body with this request"
            disabled={
              isLoading ||
              props.pipeline.status !== AutomationPipelineStatus.New
            }
            checked={
              bodyEnabled &&
              !isLoading &&
              props.pipeline.status === AutomationPipelineStatus.New
            }
            onChange={onToggleBodyEnabled}
          />
          {bodyEnabled && (
            <div>
              <TextInput
                disabled={
                  isLoading ||
                  props.pipeline.status !== AutomationPipelineStatus.New
                }
                value={bodyPayload}
                onChange={setBodyPayload}
                compact
                wide
                textarea
                label="Body payload"
              />
            </div>
          )}
        </div>
      )}
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          disabled={!hasChanges || isLoading}
          onClick={onSaveClick}
        >
          Save
        </Button>
        <Button
          type="secondary"
          disabled={!hasChanges || isLoading}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </div>
      <h3 className={styles.sectionHeader}>Delivery result</h3>

      {!stepContext.webhookResponse && (
        <div>This webhook hasn&apos;t been delivered yet.</div>
      )}
      {!!stepContext.webhookRequest && (
        <Table compact fullWidth className={styles.deliveryStatusTable}>
          <thead>
            <tr>
              <th colSpan={2}>Request</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>URL</th>
              <td>
                <b>{stepContext.webhookRequest.method}</b>{' '}
                {stepContext.webhookRequest.url}
              </td>
            </tr>
            <tr>
              <th>Headers</th>
              <td>
                {Object.entries(stepContext.webhookRequest.headers || {}).map(
                  ([key, value], i) => (
                    <div key={i}>
                      <b>{key}</b>: {value}
                    </div>
                  )
                )}
              </td>
            </tr>
            <tr>
              <th>Body</th>
              <td>
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                  <div>{stepContext.webhookRequest.bodyRaw || ''}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>Date</th>
              <td>
                {new Date(stepContext.webhookRequest.at).toLocaleString()}
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      {!!stepContext.webhookResponse && (
        <Table compact fullWidth className={styles.deliveryStatusTable}>
          <thead>
            <tr>
              <th colSpan={2}>Response</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Date</th>
              <td>
                {new Date(stepContext.webhookResponse.at).toLocaleString()}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{stepContext.webhookResponse.statusCode || 'Unknown'}</td>
            </tr>
            <tr>
              <th>Headers</th>
              <td>
                {Object.entries(stepContext.webhookResponse.headers || {}).map(
                  ([key, value], i) => (
                    <div key={i}>
                      <b>{key}</b>: {value}
                    </div>
                  )
                )}
              </td>
            </tr>
            <tr>
              <th>Body</th>
              <td>
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                  <div>{stepContext.webhookResponse.bodyRaw || ''}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </PipelineStep>
  );
};

export default PipelineStepWebhook;
