import {
  AutomationPipelineStatus,
  AutomationPipelineStepDataWaitProxyEndpointTrafficStop,
} from '@dataunlocker/pkg-types';
import { requestAutomationPipelineUpdate } from 'Api';
import Button from 'Components/Common/Button';
import MessageBlock from 'Components/Common/MessageBlock';
import NumberInput from 'Components/Common/NumberInput';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import { ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING } from 'Constants';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'toaster-js';
import { getProxyEndpointUrl } from 'Utils';

const PipelineStepWaitProxyEndpointTrafficStop = (
  props: PipelineStepCommonProps
) => {
  const { refreshPipeline } = props;
  const proxyEndpoint = props.property.proxyEndpoints.find(
    (e) => props.pipeline.context.oldProxyEndpointUrlKey === e.urlKey
  );
  const stepData: AutomationPipelineStepDataWaitProxyEndpointTrafficStop =
    props.step.data || {};
  const pipelineStepIndex = props.pipeline.steps.findIndex(
    (s) => s === props.step
  );
  const initialWaitForSeconds = Math.floor(
    (stepData.waitFor || 1000 * 60 * 5) / 1000
  );
  const initialWaitForHours = Math.floor(
    (stepData.maxWaitFor || 1000 * 60 * 60 * 20) / 1000 / 60 / 60
  );
  const [waitForSeconds, setWaitForSeconds] = useState(initialWaitForSeconds);
  const [maxWaitForHours, setMaxWaitForHours] = useState(initialWaitForHours);
  const [isLoading, setIsLoading] = useState(false);

  const onCancelClick = useCallback(() => {
    setWaitForSeconds(initialWaitForSeconds);
    setMaxWaitForHours(initialWaitForHours);
  }, [initialWaitForSeconds, initialWaitForHours]);
  const onSaveClick = useCallback(async () => {
    setIsLoading(true);
    const data: AutomationPipelineStepDataWaitProxyEndpointTrafficStop = {
      waitFor: waitForSeconds * 1000,
      maxWaitFor: maxWaitForHours * 1000 * 60 * 60,
    };
    const response = await requestAutomationPipelineUpdate(props.pipeline.id, {
      updates: {
        steps: {
          [pipelineStepIndex]: {
            data,
          },
        },
      },
    });
    if (response.errorMessage) {
      new Toast(response.errorMessage, Toast.TYPE_ERROR);
    } else {
      await refreshPipeline();
    }
    setIsLoading(false);
  }, [
    waitForSeconds,
    refreshPipeline,
    props.pipeline.id,
    pipelineStepIndex,
    maxWaitForHours,
  ]);

  const hasChanges =
    initialWaitForSeconds !== waitForSeconds ||
    initialWaitForHours !== maxWaitForHours;

  return (
    <PipelineStep
      icon="clock"
      title="Wait for the old proxy endpoint to stop receiving traffic"
      {...props}
    >
      {proxyEndpoint ? (
        <MessageBlock display="inline">
          Waiting for this proxy endpoint to stop receiving traffic:{' '}
          <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(props.property.id)}>
            {getProxyEndpointUrl(proxyEndpoint, props.property)}
          </Link>
          . After {initialWaitForSeconds} seconds of endpoint not receiving
          traffic this step will complete.
        </MessageBlock>
      ) : (
        <MessageBlock display="inline">
          This step will wait until the old proxy endpoint doesn&apos;t receive
          traffic anymore. After {initialWaitForSeconds} seconds of that
          endpoint not receiving traffic this step will complete.
        </MessageBlock>
      )}
      <h3
        style={{
          marginTop: 'var(--spacing-big)',
          marginLeft: 'var(--spacing-small)',
          marginBottom: 'var(--spacing-regular)',
        }}
      >
        Step configuration
      </h3>
      <NumberInput
        disabled={props.pipeline.status !== AutomationPipelineStatus.New}
        label={`Seconds to wait for ${
          proxyEndpoint
            ? getProxyEndpointUrl(proxyEndpoint, props.property)
            : 'the old endpoint'
        } to become idle`}
        wide
        value={waitForSeconds}
        onChange={setWaitForSeconds}
      />
      <NumberInput
        disabled={props.pipeline.status !== AutomationPipelineStatus.New}
        label="Max hours to wait for"
        wide
        max={24 * 7}
        min={1}
        value={maxWaitForHours}
        onChange={setMaxWaitForHours}
      />
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          disabled={!hasChanges || isLoading}
          onClick={onSaveClick}
        >
          Save
        </Button>
        <Button
          type="secondary"
          disabled={!hasChanges || isLoading}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </div>
    </PipelineStep>
  );
};

export default PipelineStepWaitProxyEndpointTrafficStop;
