import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME } from 'Constants';
import React from 'react';

interface Props {
  propertyHost: string;
  onCheckHealthClick: () => void;
}

const EnableUnhealthyPropertyDialog = ({
  propertyHost,
  onCheckHealthClick,
}: Props) => {
  return (
    <div>
      <p>
        You are about to enable {PRODUCT_NAME} for <b>{propertyHost}</b> while
        the last setup assessment is unhealthy. This means {PRODUCT_NAME} may
        not work as expected.
      </p>
      <p>
        Check the{' '}
        <a href={(() => '#')()} onClick={onCheckHealthClick}>
          property health assessment
        </a>{' '}
        before enabling {PRODUCT_NAME}.
      </p>
    </div>
  );
};

interface Parameters extends Props {
  onComplete: () => void;
}

export const showEnableUnhealthyPropertyDialog = (params: Parameters) => {
  const { close } = showDialog({
    title: `Enable DataUnlocker?`,
    icon: 'masterswitch',
    content: (
      <EnableUnhealthyPropertyDialog
        {...params}
        onCheckHealthClick={() => {
          close();
          params.onCheckHealthClick();
        }}
      />
    ),
    confirmButtonText: 'Enable Anyway',
    cancelButtonText: 'Cancel',
    onConfirmClick: params.onComplete,
  });
};
