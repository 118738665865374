import {
  AutomationPipelineType,
  MetricsAggregate,
  Permission,
  PropertyBilling,
  PropertyNotificationType,
  PropertyProxyEndpoint,
  UiAutomationPipeline,
  UiProperty,
  UiPurchase,
  UiTempProperty,
} from '@dataunlocker/pkg-types';
import { encodeQueryString } from 'Utils';

export const API_PATH_PROPERTIES_LIST = '/properties/list';
export interface ApiPropertiesListResponse {
  properties: UiProperty[];
  tempProperties: UiTempProperty[];
}

export const API_PATH_PROPERTIES_ADD_TEMP = '/properties/add-temp';
export interface ApiPropertiesAddTempRequest {
  host: string;
  force?: boolean;
}
export interface ApiPropertiesAddTempResponse {
  tempProperty: UiTempProperty;
}

export const API_PATH_PROPERTIES_USERS_LIST = (propertyId: string) =>
  `/properties/property/${propertyId}/users/list`;
export interface ApiPropertiesUsersListResponse {
  users: {
    id: string;
    email: string;
  }[];
}

export const API_PATH_PROPERTIES_USERS_UPDATE = (propertyId: string) =>
  `/properties/property/${propertyId}/users/update`;
export interface ApiPropertiesUsersUpdateRequest {
  add?: {
    email: string;
    access: Permission;
    notificationTypes: PropertyNotificationType[];
  }[];
  update?: {
    id: string;
    access: Permission;
    notificationTypes: PropertyNotificationType[];
  }[];
  deleteIds?: string[];
}
export interface ApiPropertiesUsersUpdateResponse {
  property: UiProperty | null;
  tempProperty: UiTempProperty | null;
}

export const API_PATH_PROPERTIES_SCRIPT_INSTALL = (
  propertyId: string,
  scriptVersion: string = 'latest'
) => `/properties/property/${propertyId}/script-install/${scriptVersion}`;
export interface ApiPropertiesScriptInstallQuery {
  debug?: boolean;
  encoded?: boolean;
  endpointUrlKey?: string; // Endpoint (duKey value) to use within this script.
}
export interface ApiPropertiesScriptInstallResponse {
  version: string;
  htmlCode: string;
  size: number;
  allScriptVersions: string[];
}

export const API_PATH_PROPERTIES_UPDATE_LOW_BYTES_NOTIFICATION = (
  propertyId: string
) => `/properties/property/${propertyId}/update-low-bytes-notification`;
export interface ApiPropertiesUpdateLowBytesNotificationRequest {
  lowBytesNotifyBelow: number;
}
export interface ApiPropertiesUpdateLowBytesNotificationResponse {
  property: UiProperty | null;
}

export const API_PATH_PROPERTIES_HEALTH_CHECK = (propertyId: string) =>
  `/properties/property/${propertyId}/health-check`;
export interface ApiPropertiesHealthCheckResponse {
  property: UiProperty;
}

export const API_PATH_PROPERTIES_TEMP_HEALTH_CHECK = (propertyId: string) =>
  `/properties/property/${propertyId}/temp-health-check`;
export interface ApiPropertiesTempHealthCheckResponse {
  tempProperty: UiTempProperty | null;
  property?: UiProperty; // New property is returned in case temp property's ownership was confirmed.
}

export const API_PATH_ADMIN_V1_PROPERTIES_DELETE = (propertyId: string) =>
  `/properties/property/${propertyId}/delete`;
export interface ApiAdminV1PropertiesDeleteResponse {}

export const API_PATH_PROPERTIES_CANCEL_SETUP = (propertyId: string) =>
  `/properties/property/${propertyId}/cancel-setup`;
export interface ApiPropertiesCancelSetupResponse {
  deleted: boolean;
}

export const API_PATH_ADMIN_V1_PROPERTIES_GET_INVOICE = (propertyId: string) =>
  `/properties/property/${propertyId}/get-invoice`;
export interface ApiAdminV1PropertiesGetInvoiceRequest {
  companyAddress?: string;
  purchaseIds: string[];
}
export interface ApiAdminV1PropertiesGetInvoiceResponse {
  name: string; // File name.
  pdf: string; // BASE64-encoded PDF.
}

export const API_PATH_PROPERTIES_STATS = (propertyId: string) =>
  `/properties/property/${propertyId}/stats`;
export interface ApiAdminV1PropertiesStatsResponse {
  propertyId: string;
  seriesSteps: number; // Number of data points in series
  seriesStart: number; // In ms
  seriesEnd: number; // In ms
  serviceData: {
    name: string; // Service name (domain)
    series: [
      number /* = index of the step in series, (time - seriesStart) / seriesStep */,
      MetricsAggregate /* = count */,
      MetricsAggregate /* = bytes */
    ][];
  }[];
}

export const API_PATH_ADMIN_V1_PROPERTIES_USAGE_SUMMARY = (
  propertyId: string
) => `/properties/property/${propertyId}/usage-summary`;
export interface ApiAdminV1PropertiesUsageSummaryResponse {
  propertyId: string;
  bytes: {
    last24h: number;
    last3d: number;
    last7d: number;
    last30d: number;
    total: number;
    left: number;
  };
  count: {
    last24h: number;
    last3d: number;
    last7d: number;
    last30d: number;
    total: number;
  };
}

export const API_PATH_ADMIN_V1_PROPERTIES_PURCHASES = (propertyId: string) =>
  `/properties/property/${propertyId}/purchases`;
export interface ApiAdminV1PropertiesPurchasesResponse {
  purchases: UiPurchase[];
}

export const API_PATH_PROPERTIES_STATS_QUOTA = (propertyId: string) =>
  `/properties/property/${propertyId}/stats-quota`;
export interface ApiAdminV1PropertiesStatsQuotaResponse {
  propertyId: string;
  seriesSteps: number; // Number of data points in series
  seriesStart: number; // In ms
  seriesEnd: number; // In ms
  series: [
    number /* = index of the step in series, (time - seriesStart) / seriesStep */,
    number /* = bytes (prepaid bytes at this moment) */
  ][];
}

export const API_PATH_PROPERTIES_UPDATE_PROXY_FLAGS = (propertyId: string) =>
  `/properties/property/${propertyId}/update-proxy-flags`;
export interface ApiPropertiesUpdateProxyFlagsRequest {
  proxyEnabled?: boolean;
  proxyNotConfiguredServices?: boolean;

  // Always-proxy mode
  apEnabled?: boolean;
  apBrowsers?: number;
}
export interface ApiPropertiesUpdateProxyFlagsResponse {
  property: UiProperty | null;
}

export const API_PATH_PROPERTIES_ADD_SERVICE = (propertyId: string) =>
  `/properties/property/${propertyId}/add-service`;
export interface ApiPropertiesAddServiceRequest {
  domainName: string; // Domain of the service (f.e. www.google-analytics.com)
  enabled?: boolean;
}
export interface ApiPropertiesAddServiceResponse {
  property: UiProperty | null;
}

export const API_PATH_PROPERTIES_DELETE_SERVICE = (propertyId: string) =>
  `/properties/property/${propertyId}/delete-service`;
export interface ApiPropertiesDeleteServiceRequest {
  domainName: string; // Domain of the service (f.e. www.google-analytics.com)
}
export interface ApiPropertiesDeleteServiceResponse {
  property: UiProperty | null;
}

export const API_PATH_PROPERTIES_UPDATE_SERVICE_PROXY_FLAGS = (
  propertyId: string
) => `/properties/property/${propertyId}/update-service-proxy-flags`;
export interface ApiPropertiesUpdateServiceProxyFlagsRequest {
  service: string;
  enabled?: boolean;

  // Enable/disable custom dimensions (for www.google-analytics.com).
  cdEnabled?: boolean;
  cdName?: string;
  cdValue?: string;

  // Always-proxy mode
  apOverride?: boolean; // Whether global config is overridden by a service config. If false, service config is dropped.
  apEnabled?: boolean;
  apBrowsers?: number;
}
export interface ApiPropertiesUpdateServiceProxyFlagsResponse {
  property: UiProperty | null;
}

export const API_PATH_PROPERTIES_RESET_PROXIED_SERVICES = (
  propertyId: string
) => `/properties/property/${propertyId}/reset-proxied-services`;
export interface ApiPropertiesResetProxiedServicesRequest {
  all: true;
}
export interface ApiPropertiesResetProxiedServicesResponse {
  property: UiProperty | null;
}

// There might be a case when automatic billing encounters an error.
// A manual action of retry is required in this case.
export const API_PATH_PROPERTIES_BILLING_RETRY = (propertyId: string) =>
  `/properties/property/${propertyId}/billing-retry`;
export interface ApiPropertiesBillingRetryRequest {}
export interface ApiPropertiesBillingRetryResponse {
  property: UiProperty;
}

// Estimate continuous traffic purchase: a value of the purchase for the next X days (if specified in request).
export const API_PATH_PROPERTIES_ESTIMATE_CTP = (propertyId: string) =>
  `/properties/property/${propertyId}/estimate-ctp`;
export interface ApiPropertiesEstimateCtpRequest {
  newBillingSettings?: Partial<PropertyBilling>;
}
export interface ApiPropertiesEstimateCtpResponse {
  cents: number;
  bytes: number;
  avgSpendDays: number;
  avgSpendBytesPerDay: number;
  avgSpendCentsPerDay: number;
}

export const API_PATH_PROPERTIES_UPDATE_BILLING = (propertyId: string) =>
  `/properties/property/${propertyId}/update-billing`;
export interface ApiPropertiesUpdateBillingRequest {
  billing: Partial<
    Omit<
      PropertyBilling,
      'gbMultiplier' | 'baseCentsPerGb' | 'ctpExpiresAt' | 'ctpUserId'
    >
  >;
}
export interface ApiPropertiesUpdateBillingResponse {
  property: UiProperty;
}

export interface ApiAdminV1PropertiesTrafficTransferGetQueryParams {
  toPropertyId?: string;
  bytesToTransfer?: string;
}
export const API_PATH_ADMIN_V1_PROPERTIES_TRAFFIC_TRANSFER = (
  propertyId: string,
  queryParams: ApiAdminV1PropertiesTrafficTransferGetQueryParams = {}
) =>
  `/properties/property/${propertyId}/traffic-transfer${
    Object.keys(queryParams) ? `?${encodeQueryString(queryParams)}` : ''
  }`;
export interface ApiAdminV1PropertiesTrafficTransferGetResponse {
  availableBytes: number; // Bytes available to migrate
  creditedBytes?: number; // Bytes which can/will be credited to a target property, if specified.
}
export interface ApiAdminV1PropertiesTrafficTransferPostRequest {
  toPropertyId: string;
  bytes: number;
}
export interface ApiAdminV1PropertiesTrafficTransferPostResponse {
  creditedBytes: number;
}

export const API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_ADD = (
  propertyId: string
) => `/properties/${propertyId}/proxy-endpoints/add`;
export interface ApiAdminV1PropertiesIdProxyEndpointsAddResponse {
  property: UiTempProperty | UiProperty | null;
}

export const API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_DELETE = (
  propertyId: string
) => `/properties/${propertyId}/proxy-endpoints/delete`;
export interface ApiAdminV1PropertiesIdProxyEndpointsDeleteRequest {
  urlKey: string;
}
export interface ApiAdminV1PropertiesIdProxyEndpointsDeleteResponse {
  property: UiTempProperty | UiProperty | null;
}

export const API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_CHECK = (
  propertyId: string
) => `/properties/${propertyId}/proxy-endpoints/check`;
export interface ApiAdminV1PropertiesIdProxyEndpointsCheckRequest {
  urlKey: string;
}
export interface ApiAdminV1PropertiesIdProxyEndpointsCheckResponse {
  property: UiTempProperty | UiProperty | null;
}

export const API_PATH_ADMIN_V1_PROPERTIES_ID_PROXY_ENDPOINTS_EDIT = (
  propertyId: string
) => `/properties/${propertyId}/proxy-endpoints/edit`;
export interface ApiAdminV1PropertiesIdProxyEndpointsEditRequest {
  urlKey: string;
  edits: {
    type: PropertyProxyEndpoint['type'];
  };
}
export interface ApiAdminV1PropertiesIdProxyEndpointsEditResponse {
  property: UiTempProperty | UiProperty | null;
}

export const API_PATH_ADMIN_V1_PROPERTIES_UPDATE_PROXY_ENDPOINT_SWAPPING = (
  propertyId: string
) => `/properties/property/${propertyId}/update-proxy-endpoint-swapping`;
export interface ApiPropertiesUpdateProxyEndpointSwappingRequest {
  enabled?: boolean;
}
export interface ApiPropertiesUpdateProxyEndpointSwappingResponse {
  property: UiProperty | null;
}

export const API_PATH_PROPERTIES_AUTOMATION_PIPELINES = (propertyId: string) =>
  `/properties/property/${propertyId}/automation-pipelines`;
export interface ApiPropertiesAutomationPipelinesGetQueryParams {
  // Filters to apply.
  type?: AutomationPipelineType;
  id?: string;
}
export interface ApiPropertiesAutomationPipelinesGetResponse {
  pipelines: UiAutomationPipeline[];
}

export const API_PATH_PROPERTIES_UPDATE_SECRET = (propertyId: string) =>
  `/properties/property/${propertyId}/update-secret`;
export interface ApiPropertiesUpdateSecretRequest {
  name: string;
  value: string;
}
export interface ApiPropertiesUpdateSecretResponse {
  property: UiProperty | null;
}

export const API_PATH_PROPERTIES_DELETE_SECRET = (propertyId: string) =>
  `/properties/property/${propertyId}/delete-secret`;
export interface ApiPropertiesDeleteSecretRequest {
  name: string;
}
export interface ApiPropertiesDeleteSecretResponse {
  property: UiProperty | null;
}
