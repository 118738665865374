import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';

export interface ButtonBarProps {
  confirmButtonText?: string;
  confirmButtonClass?: string;
  confirmButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  cancelButtonText?: string;
  onConfirmClick?: () => unknown;
  onCancelClick?: () => void;
}

export const DialogButtonBar = ({
  confirmButtonText,
  confirmButtonClass,
  confirmButtonDisabled,
  cancelButtonDisabled,
  cancelButtonText,
  onConfirmClick,
  onCancelClick,
}: ButtonBarProps) => {
  const [loading, setLoading] = useState(false);
  const onSuccessClick = useCallback(async () => {
    setLoading(true);
    if (onConfirmClick) {
      try {
        await onConfirmClick();
      } catch (e) {
        console.error(e);
      }
    }
    setLoading(false);
  }, [onConfirmClick]);

  return (
    <div className={styles.buttonsContainer}>
      <button
        className={confirmButtonClass ? confirmButtonClass : ''}
        disabled={loading || !!confirmButtonDisabled}
        onClick={onSuccessClick}
      >
        {confirmButtonText || 'OK'}
      </button>
      {cancelButtonText && (
        <button
          disabled={loading || !!cancelButtonDisabled}
          onClick={onCancelClick}
          className="secondary"
        >
          {cancelButtonText || 'Cancel'}
        </button>
      )}
    </div>
  );
};
