import { ROUTE_SIGN_IN } from 'Constants';

let initialUrl = window.location.pathname;

let redirectAfterLoginTo = '';

export const getInitialUrl = () => initialUrl;

export const clearInitialUrl = () => (initialUrl = '');

export const clearRedirectAfterLoginTo = () => (redirectAfterLoginTo = '');

export const setRedirectAfterLoginTo = (url: string) => {
  if (url && !url.startsWith(ROUTE_SIGN_IN)) {
    redirectAfterLoginTo = url;
  }
};

export const getRedirectAfterLoginTo = () => redirectAfterLoginTo;
