import { UiBillingPlan } from '@dataunlocker/pkg-types';
import Icon from 'Components/Common/Icon';
import React from 'react';
import { formatBytes, formatUsdCents } from 'Utils';
import styles from './styles.module.scss';

export interface BillingPlanSelectCardProps {
  billingPlan: UiBillingPlan;
  /** Index of billing plan in array sorted by price */
  billingPlanIndex: number;
  currentPlan: UiBillingPlan;
  selectedPlan: UiBillingPlan;
  onSelectedPlanChange: (newSelectedPlan: UiBillingPlan) => void;
  onCurrentPlanChange: (newCurrentPlan: UiBillingPlan) => void;
}

//TODO(https://github.com/dataunlocker/ops/issues/5): implement free tier
export const BillingPlanSelectCard = ({
  billingPlan,
  billingPlanIndex,
  currentPlan,
  selectedPlan,
  onSelectedPlanChange,
  onCurrentPlanChange,
}: BillingPlanSelectCardProps) => {
  const headerStyles = [
    styles.headerVolumeBased,
    styles.headerBusiness,
    styles.headerEnterprise,
  ];
  const descriptions = (
    <>
      <strong>Pay monthly with fixed pricing and discount</strong> ideally
      suitable for a budget planning.
    </>
  );

  const price = <span>{formatUsdCents(billingPlan.price.value)}</span>;

  const features = (
    <ul>
      <li>
        {formatBytes(billingPlan.bytesPerTrafficPeriod)}{' '}
        {billingPlan.trafficPeriod}ly
      </li>
      <li>Up to {formatBytes(billingPlan.maxUnpaidBytes)} overuse</li>
      <li>All love of the world!</li>
    </ul>
  );

  return (
    <div className={styles.container}>
      <div className={`${styles.header} ${headerStyles[billingPlanIndex]} `}>
        <h3>{billingPlan.name.toUpperCase()}</h3>
        <p className={styles.description}>{descriptions}</p>
      </div>
      <div className={styles.price}>{price}</div>
      <hr />
      <div className={styles.feature}>{features}</div>
      <hr />
      <button
        className={`secondary ${styles.selectPlanButton}`}
        onClick={() => {
          onCurrentPlanChange(selectedPlan);
        }}
      >
        {billingPlan.id === currentPlan.id ? (
          <>
            <Icon image="money" />{' '}
            <span className={styles.currentPlan}>Current</span>
          </>
        ) : (
          <>
            <Icon image="money" /> <span>Subscribe</span>
          </>
        )}
      </button>
    </div>
  );
};
