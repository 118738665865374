import {
  ApiAdminBillingPlansAddRequest,
  ApiAdminBillingPlansAddResponse,
  ApiAdminBillingPlansIdGetRequest,
  ApiAdminBillingPlansIdGetResponse,
  ApiAdminBillingPlansUpdateRequest,
  ApiAdminBillingPlansUpdateResponse,
  ApiAdminV1BillingPlansListResponse,
  API_PATH_ADMIN_V1_BILLING_PLANS,
  API_PATH_ADMIN_V1_BILLING_PLANS_ID,
} from 'Constants';
import { apiRequest } from 'Utils';

export const requestListBillingPlans = async () =>
  await apiRequest<void, ApiAdminV1BillingPlansListResponse>(
    API_PATH_ADMIN_V1_BILLING_PLANS
  );

export const requestAddBillingPlan = async (
  billingPlan: ApiAdminBillingPlansAddRequest
) =>
  await apiRequest<
    ApiAdminBillingPlansAddRequest,
    ApiAdminBillingPlansAddResponse
  >(API_PATH_ADMIN_V1_BILLING_PLANS, billingPlan);

export const requestDeleteBillingPlan = async (id: string) =>
  await apiRequest<void, void>(
    API_PATH_ADMIN_V1_BILLING_PLANS_ID(id),
    undefined,
    {
      type: 'DELETE',
    }
  );

export const requestUpdateBillingPlan = async (
  id: string,
  update: ApiAdminBillingPlansUpdateRequest
) =>
  await apiRequest<
    ApiAdminBillingPlansUpdateRequest,
    ApiAdminBillingPlansUpdateResponse
  >(API_PATH_ADMIN_V1_BILLING_PLANS_ID(id), update);

export const requestGetBillingPlan = async ({ id }: { id: string }) =>
  await apiRequest<
    ApiAdminBillingPlansIdGetRequest,
    ApiAdminBillingPlansIdGetResponse
  >(API_PATH_ADMIN_V1_BILLING_PLANS_ID(id));
