import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';

interface TextInputProps {
  value: string;
  label?: string;
  disabled?: boolean;
  compact?: boolean;
  textarea?: boolean;
  wide?: boolean;
  validator?: RegExp;
  onChange: (newValue: string, valid: boolean) => any;
}

const TextInput = ({
  value,
  onChange,
  disabled,
  compact,
  textarea,
  validator,
  label,
  wide,
}: TextInputProps) => {
  const Component = textarea ? 'textarea' : 'input';
  const [valid, setValid] = useState(validator ? validator.test(value) : true);
  const onChangeInternal = useCallback(
    (value: string) => {
      const valid = validator ? validator.test(value) : true;
      if (validator) {
        setValid(valid);
      }
      onChange(value, valid);
    },
    [onChange, validator]
  );

  return (
    <div
      className={[
        styles.textInput,
        compact ? styles.compact : '',
        wide ? styles.wide : '',
        valid ? '' : styles.invalid,
      ].join(' ')}
    >
      {label && <label>{label}</label>}
      <Component
        className={styles.input}
        type="text"
        value={value}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => onChangeInternal(e.target.value)}
        disabled={!!disabled}
      />
    </div>
  );
};

export default TextInput;
