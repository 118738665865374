import { UiProperty, UiTempProperty } from '@dataunlocker/pkg-types';
import { createTempProperty } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import IconWithSubtext from 'Components/Common/IconWithSubtext';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import { showConfirmAddExactlyThisDomainDialog } from 'Components/Dialog/ConfirmAddExactlyThisDomain';
import {
  ErrorDataUnableToAddTempPropertyBecauseOfRedirect,
  ERROR_CODE_UNABLE_TO_ADD_TEMP_PROPERTY_BECAUSE_OF_REDIRECT,
  LINK_DOCS_FAQ,
  LINK_DOCS_INSTALL_ROUTING,
  LINK_YOUTUBE_SETUP_TUTORIAL,
  PRODUCT_NAME,
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
} from 'Constants';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  propertiesListVersionState,
  selectedPropertyIdState,
  useSetRecoilState,
} from 'State';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

interface Props {
  properties: UiProperty[];
  tempProperties: UiTempProperty[];
}

const PagePropertyAdd = ({ properties, tempProperties }: Props) => {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null!);
  const [isLoading, setIsLoading] = useState(false);
  const [domain, setDomain] = useState('');
  const setSelectedPropertyId = useSetRecoilState(selectedPropertyIdState);

  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const onSubmit = useCallback(
    async (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (formRef.current && formRef.current?.checkValidity) {
        if (!formRef.current.checkValidity()) {
          return false;
        }
      }

      const existingProperty = tempProperties
        .concat(properties)
        .find((prop) => prop.host === domain);
      if (existingProperty) {
        setSelectedPropertyId(existingProperty.id);
        history.push(
          ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(existingProperty.id)
        );
        return;
      }

      setIsLoading(true);
      const {
        errorMessage,
        errorCode,
        errorData,
        response,
      } = await createTempProperty({ host: domain });

      if (errorMessage || !response) {
        setIsLoading(false);
        if (
          errorCode ===
          ERROR_CODE_UNABLE_TO_ADD_TEMP_PROPERTY_BECAUSE_OF_REDIRECT
        ) {
          const data: ErrorDataUnableToAddTempPropertyBecauseOfRedirect = errorData;
          showConfirmAddExactlyThisDomainDialog({
            enteredDomain: data.hostname,
            redirectsTo: data.redirectsTo,
            onConfirmClick: async () => {
              setIsLoading(true);
              const { errorMessage, response } = await createTempProperty({
                host: domain,
                force: true,
              });
              setIsLoading(false);

              if (errorMessage || !response) {
                new Toast(
                  errorMessage ||
                    "Oops, we've got a problem redirecting you to a new property.",
                  Toast.TYPE_ERROR
                );
                return;
              }

              setPropertiesListVersion(Math.random());
              setSelectedPropertyId(response.tempProperty.id);
              history.push(
                ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(response.tempProperty.id)
              );
            },
          });
          return;
        }
        new Toast(
          errorMessage ||
            "Oops, we've got a problem redirecting you to a new property.",
          Toast.TYPE_ERROR
        );
        return;
      }

      setPropertiesListVersion(Math.random());
      setSelectedPropertyId(response.tempProperty.id);
      history.push(
        ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(response.tempProperty.id)
      );
    },
    [
      setSelectedPropertyId,
      setPropertiesListVersion,
      history,
      domain,
      properties,
      tempProperties,
    ]
  );

  return (
    <div>
      <div className={styles.container}>
        <Card>
          <CardHeader
            icon="add"
            title={`Install ${PRODUCT_NAME} on your website`}
          />
          <div className={styles.topInfo}>
            We will guide you through the 3-step {PRODUCT_NAME} setup process.
            Start by entering your website&apos;s domain first, where you want
            to recover blocked traffic.
          </div>
          <form onSubmit={onSubmit} ref={formRef}>
            <div className={styles.center}>
              <input
                type="text"
                value={domain}
                onChange={({ target }) => setDomain(target.value)}
                placeholder="enter.your.domain.com"
                pattern={
                  window.location.host === 'localhost' ? '.+' : '.+\\.[^\\.]+'
                }
                required
                disabled={isLoading}
              />
              <button type="submit" disabled={isLoading}>
                <Icon image="forward" margin="right-regular" />
                <span>Begin Setup</span>
              </button>
            </div>
          </form>
          <div className={styles.info}>
            <Icon image="warning" size="small" margin="right-small" />
            {PRODUCT_NAME} is <b>domain-centric</b>, meaning that you need to
            perform a new installation for every distinct domain. Note that{' '}
            <b>yourwebsite.com</b>, <b>www.yourwebsite.com</b> and{' '}
            <b>subdomain.yourwebsite.com</b> are 3 different domains.
          </div>
          <div className={styles.info}>
            <Icon image="info" size="small" margin="right-small" />
            This setup won&apos;t take any effect{' '}
            <b>until you manually enable {PRODUCT_NAME}</b> in settings after
            performing all the steps (including the script installation).
          </div>
        </Card>
        <div className={styles.iconsBlock}>
          <SafeExternalLink href={LINK_DOCS_INSTALL_ROUTING}>
            <IconWithSubtext image="book" size="huge" hoverable text="Docs" />
          </SafeExternalLink>
          <SafeExternalLink href={LINK_DOCS_FAQ}>
            <IconWithSubtext image="help" size="huge" hoverable text="FAQ" />
          </SafeExternalLink>
          <UnsafeExternalLink href={LINK_YOUTUBE_SETUP_TUTORIAL}>
            <IconWithSubtext
              image="play"
              size="huge"
              hoverable
              text="Tutorial"
            />
          </UnsafeExternalLink>
        </div>
      </div>
    </div>
  );
};

export default PagePropertyAdd;
