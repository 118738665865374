import { getPropertyStats, resetPropertyProxiedServices } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import FeatureGuard from 'Components/Common/FeatureGuard';
import Icon from 'Components/Common/Icon';
import PageError from 'Components/Common/Pages/Error';
import PageHeader from 'Components/Common/Pages/Header';
import PageLoading from 'Components/Common/Pages/Loading';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import Tooltip from 'Components/Common/Tooltip';
import { showConfirmResetProxiedServicesDialog } from 'Components/Dialog/ConfirmResetProxiedServices';
import PagePropertyDoesNotExist from 'Components/Pages/Properties/NotFound';
import {
  ApiAdminV1PropertiesStatsResponse,
  getAbsoluteUnixTimeMs,
  INTERVAL_1D,
  LINK_DOCS_CONFIG_PROXY,
  PRODUCT_NAME,
  ROUTE_PROPERTY_SETTINGS_GENERAL,
  ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH,
  TimeRangeTuple,
} from 'Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  propertiesListVersionState,
  selectedPropertyState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'State';
import { ApiResponse, DashboardView } from 'Types';
import { getDashboardView } from 'Utils';
import PrepaidTrafficChart from '../Billing/PrepaidTrafficChart';
import AddNewServiceCard from './AddNewServiceCard';
import AlwaysOnProxyConfig from './AlwaysOnProxyConfig';
import styles from './styles.module.scss';
import SummaryGraph from './SummaryGraph';
import SummaryTable from './SummaryTable';

const CHART_AUTO_REFRESH_RATE = 15 * 1000;

const PagePropertyDashboard = () => {
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );
  const propertyLoadable = useRecoilValueLoadable(selectedPropertyState);
  const property =
    propertyLoadable.state === 'hasValue' ? propertyLoadable.contents : null;
  const host = property?.host || '';
  const propertyId = property?.id || '';

  const [isAddServiceOpened, setIsAddServiceOpened] = useState(false);
  const [apiStats, setApiStats] =
    useState<ApiResponse<ApiAdminV1PropertiesStatsResponse> | null>(null);
  const [timeRange, setTimeRange] = useState<TimeRangeTuple>([0, INTERVAL_1D]);
  const onTimeRangeChange = useCallback((newRange: TimeRangeTuple) => {
    setTimeRange(newRange);
  }, []);
  const onAddProxyPress = useCallback(() => {
    setIsAddServiceOpened(true);
    requestAnimationFrame(() => {
      window.scrollTo({
        top: 99999,
      });
    });
  }, []);
  const onResetProxyConfigPress = useCallback(() => {
    showConfirmResetProxiedServicesDialog({
      domain: host,
      onConfirm: async () => {
        await resetPropertyProxiedServices(propertyId);
        await setPropertiesListVersion(Math.random());
      },
    });
  }, [host, propertyId, setPropertiesListVersion]);
  const onAddProxyClose = useCallback(() => setIsAddServiceOpened(false), []);

  const dashboardView: DashboardView =
    property && apiStats && apiStats.response
      ? getDashboardView(apiStats.response, property.proxiedServices)
      : {
          seriesStart: timeRange[0],
          seriesEnd: timeRange[1],
          seriesSteps: 60,
          totals: {
            totalBytes: 0,
            totalCount: 0,
            bytes: [0, 0, 0, 0, 0],
            count: [0, 0, 0, 0, 0],
          },
          groups: [],
        };

  // Stats initial request and auto-refresh logic
  useEffect(() => {
    if (!property?.id) {
      return;
    }
    getPropertyStats(property.id, {
      from: getAbsoluteUnixTimeMs(timeRange[0] - timeRange[1]),
      to: getAbsoluteUnixTimeMs(timeRange[0]),
    }).then(setApiStats);
    const interval = setInterval(async () => {
      if (!document.hidden) {
        // Warning: deps could have changed
        await setApiStats(
          await getPropertyStats(property.id, {
            from: getAbsoluteUnixTimeMs(timeRange[0] - timeRange[1]),
            to: getAbsoluteUnixTimeMs(timeRange[0]),
          })
        );
        await setPropertiesListVersion(Math.random());
      }
    }, CHART_AUTO_REFRESH_RATE);
    return () => clearInterval(interval);
  }, [property?.id, timeRange, setPropertiesListVersion]);

  const isPropertyHealthy = !!property?.health.ok;
  return propertyLoadable.state === 'hasError' || apiStats?.errorMessage ? (
    <PageError
      message={
        (propertyLoadable.state === 'hasError'
          ? propertyLoadable.contents
          : apiStats?.errorMessage) + ''
      }
    />
  ) : propertyLoadable.state === 'loading' ? (
    <PageLoading />
  ) : !property ? (
    <PagePropertyDoesNotExist />
  ) : (
    <div>
      <PageHeader icon="dashboard" title={`${property.host} • Dashboard`}>
        <div className={styles.statusBar}>
          <div className={styles.statusBarStatuses}>
            <div>
              <div>DataUnlocker proxy</div>
              <div>
                <Icon
                  className={
                    property.proxyEnabled ? styles.enabled : styles.disabledRed
                  }
                  image={property.proxyEnabled ? 'checkmark' : 'close'}
                  size="small"
                  margin="small"
                />
              </div>
              <div
                className={
                  property.proxyEnabled ? styles.enabled : styles.disabledRed
                }
              >
                <Link to={ROUTE_PROPERTY_SETTINGS_GENERAL(property.id)}>
                  {property.proxyEnabled ? 'enabled' : 'disabled'}
                </Link>
              </div>
            </div>
            <div>
              <div>DataUnlocker setup</div>
              <div>
                <Icon
                  className={
                    isPropertyHealthy ? styles.enabled : styles.disabledRed
                  }
                  image={isPropertyHealthy ? 'checkmark' : 'error'}
                  size="small"
                  margin="small"
                />
              </div>
              <div
                className={
                  isPropertyHealthy ? styles.enabled : styles.disabledRed
                }
              >
                <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH(property.id)}>
                  {isPropertyHealthy ? 'healthy' : 'unhealthy'}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </PageHeader>
      <div className={styles.container}>
        <FeatureGuard feature="billingPlans">
          <PrepaidTrafficChart
            property={property}
            responsiveContainerHeight={150}
            onTimeRangeChange={onTimeRangeChange}
            timeRange={timeRange}
          />
        </FeatureGuard>
        <SummaryGraph
          timeRange={timeRange}
          onTimeRangeChange={onTimeRangeChange}
          dashboardView={dashboardView}
        />
        <FeatureGuard feature="alwaysProxy">
          <Card>
            <CardHeader
              title={
                <>
                  <span>Always-on proxy</span>
                  <Tooltip
                    type="help"
                    content={
                      <span>
                        Always-on proxy allows to change {PRODUCT_NAME} behavior{' '}
                        from proxying failed requests only to proxying all
                        requests. When enabled, requests will first be attempted
                        to be proxied and only if failed retried using the
                        original URL.
                      </span>
                    }
                  />
                </>
              }
              icon="split"
            />
            <AlwaysOnProxyConfig property={property} />
          </Card>
        </FeatureGuard>
        <Card>
          <CardHeader
            icon="split"
            title={
              <>
                <span>On-demand proxy</span>
                <Tooltip
                  type="help"
                  content={
                    <span>
                      All third-party services configured for proxy. Click on
                      each row to get more controls.{' '}
                      <SafeExternalLink href={LINK_DOCS_CONFIG_PROXY}>
                        Learn more
                      </SafeExternalLink>{' '}
                      about how to configure proxy.
                    </span>
                  }
                />
              </>
            }
          />
          <SummaryTable property={property} dashboardView={dashboardView} />
          <div>
            {!isAddServiceOpened && (
              <button
                className="secondary"
                disabled={isAddServiceOpened}
                onClick={onAddProxyPress}
              >
                Add Configuration
              </button>
            )}
            <button className="secondary" onClick={onResetProxyConfigPress}>
              Reset Configuration
            </button>
          </div>
        </Card>
        {isAddServiceOpened && (
          <AddNewServiceCard onClose={onAddProxyClose} property={property} />
        )}
      </div>
    </div>
  );
};

export default PagePropertyDashboard;
