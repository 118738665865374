import { UiBillingPlan, UiProperty } from '@dataunlocker/pkg-types';
import { formatBytes } from '@dataunlocker/pkg-utils';
import { requestAddSubscription } from 'Api';
import RadioInput from 'Components/Common/RadioInput';
import { showDialog } from 'Components/Dialog';
import {
  ApiAdminV1SubscriptionsAddRequest,
  ApiAdminV1SubscriptionsAddResponse,
  PRODUCT_NAME,
  PRODUCT_SUPPORT_EMAIL,
} from 'Constants';
import React, { useState } from 'react';
import { Toast } from 'toaster-js';
import { formatUsdCents } from 'Utils';
import { DialogButtonBar } from '../Common/DialogButtonBar';
import styles from './styles.module.scss';

export interface ShowNewBillingPlanDialogProps {
  property: UiProperty;
  newPlan: UiBillingPlan;
  visibleCards: string[];
  newSubscriptionAdded: (
    response: ApiAdminV1SubscriptionsAddResponse
  ) => Promise<void>;
}

interface NewBillingPlanDialogProps extends ShowNewBillingPlanDialogProps {
  closeDialog: () => void;
}

const CARDS_RADIO_GROUP_NAME = 'cards';

const NewBillingPlanDialog = ({
  property,
  newPlan,
  visibleCards,
  newSubscriptionAdded,
  closeDialog,
}: NewBillingPlanDialogProps) => {
  const [selectedCard, setSelectedCard] = useState<string | 'NEW' | null>(null);
  const currentPrepaidTraffic = property.bytesPrepaid - property.bytesProxied;
  const commonMessage = (
    <>
      Your current subscription will be canceled. Existing{' '}
      {currentPrepaidTraffic > 0 && (
        <span className={styles.nowrap}>
          {formatBytes(currentPrepaidTraffic)}
        </span>
      )}{' '}
      prepaid traffic will remain on the balance.
    </>
  );

  const onConfirmClick = async () => {
    const request: ApiAdminV1SubscriptionsAddRequest = {
      propertyId: property.id,
      billingPlanId: newPlan.id,
    };
    const isNewCard = selectedCard === 'NEW' || !visibleCards.length;
    if (!isNewCard) {
      if (!selectedCard) {
        new Toast(
          `Unable to process the selected card. Please, contact support ${PRODUCT_SUPPORT_EMAIL}`,
          Toast.TYPE_ERROR
        );
        return;
      }
      request.billingMethod = { cardNumber: selectedCard, type: 'card' };
    }
    const { errorMessage, response } = await requestAddSubscription(request);
    if (errorMessage || !response) {
      new Toast(
        errorMessage ||
          `Unable to process new subscription. Please, contact support ${PRODUCT_SUPPORT_EMAIL}`,
        Toast.TYPE_ERROR
      );
      return;
    }
    await newSubscriptionAdded(response);
    closeDialog();
  };

  return (
    <>
      <p>
        You are subscribing to the <b>{newPlan.name.toUpperCase()}</b> plan for{' '}
        <span className={styles.nowrap}>
          <b>
            {formatUsdCents(newPlan.price.value, 0, true) +
              '/' +
              newPlan.billingPeriod}{' '}
          </b>
          and
          <b> {formatBytes(newPlan.bytesPerTrafficPeriod)}</b>
        </span>{' '}
        of traffic. {commonMessage}
      </p>
      {visibleCards.length ? (
        <div>
          <span>Select a card to use for this subscription</span>
          <div>
            {visibleCards.map((cardNumber) => (
              <RadioInput
                name={CARDS_RADIO_GROUP_NAME}
                checked={selectedCard === cardNumber}
                key={cardNumber}
                label={cardNumber}
                onChange={() => setSelectedCard(cardNumber)}
              />
            ))}
            {
              <RadioInput
                name={CARDS_RADIO_GROUP_NAME}
                checked={selectedCard === 'NEW'}
                key="NEW"
                label="Add a new card"
                onChange={() => setSelectedCard('NEW')}
              />
            }
          </div>
        </div>
      ) : (
        <p>
          {PRODUCT_NAME} stores only a masked card number, its expiry date and a
          unique card token required to perform automatic charges. You can
          cancel your subscription and remove your card at any time.
        </p>
      )}
      <DialogButtonBar
        confirmButtonText={
          visibleCards.length ? 'Confirm' : 'Pre-authorize a new card'
        }
        confirmButtonDisabled={!!visibleCards.length && !selectedCard}
        cancelButtonText="Cancel"
        onCancelClick={closeDialog}
        onConfirmClick={onConfirmClick}
      />
    </>
  );
};

export const showNewBillingPlanDialog = (
  props: ShowNewBillingPlanDialogProps
) => {
  return showDialog({
    title: 'New subscription',
    icon: 'money',
    content: ({ closeDialog }) => (
      <NewBillingPlanDialog {...props} closeDialog={closeDialog} />
    ),
    displayButtons: false,
    padContent: false,
  });
};
