import Card, { CardHeader } from 'Components/Common/Card';
import {
  ButtonBarProps,
  DialogButtonBar,
} from 'Components/Dialog/Common/DialogButtonBar';
import React, { useCallback } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { RecoilRoot } from 'recoil';
import styles from './styles.module.scss';

interface DialogSharedProps {
  closeDialog: () => void;
}

interface DialogPropsInternal extends DialogProps, DialogSharedProps {}

interface DialogProps extends Omit<ButtonBarProps, 'onCancelClick'> {
  title: string;
  icon?: string;
  content?:
    | React.ReactNode
    | string
    | ((props: DialogSharedProps) => React.ReactNode | string);
  displayButtons?: boolean;
  padContent?: boolean;
}

const Dialog = ({
  title,
  icon,
  content,
  closeDialog,
  confirmButtonText,
  confirmButtonClass,
  cancelButtonText,
  onConfirmClick,
  displayButtons = true,
  padContent = true,
}: DialogPropsInternal) => {
  const onSuccessClick = useCallback(async () => {
    if (onConfirmClick) {
      const result = await onConfirmClick();
      if (result === false) {
        return;
      }
    }
    closeDialog();
  }, [closeDialog, onConfirmClick]);
  const onCancelClick = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  return (
    <RecoilRoot>
      <div className={styles.dialogContainer}>
        <Card padding="big" className={styles.card}>
          <CardHeader title={title} icon={icon} />
          {content && (
            <div
              className={`${styles.content}${
                padContent ? ` ${styles.padded}` : ''
              }`}
            >
              {typeof content === 'function'
                ? content({ closeDialog })
                : content}
            </div>
          )}
          {displayButtons && (
            <DialogButtonBar
              confirmButtonText={confirmButtonText}
              confirmButtonClass={confirmButtonClass}
              cancelButtonText={cancelButtonText}
              onConfirmClick={onSuccessClick}
              onCancelClick={onCancelClick}
            />
          )}
        </Card>
      </div>
    </RecoilRoot>
  );
};

export const showDialog = (props: DialogProps) => {
  const element = document.createElement('div');
  element.classList.add(styles.overlayInit);
  element.classList.add(styles.overlay);
  requestAnimationFrame(() => element.classList.remove(styles.overlayInit));
  document.body.appendChild(element);
  const onClose = () => {
    unmountComponentAtNode(element);
    element.parentNode?.removeChild(element);
  };
  element.addEventListener(
    'click',
    (event) => event.target === element && onClose()
  );
  render(<Dialog {...props} closeDialog={onClose} />, element);

  return {
    close: onClose,
  };
};
