interface DownloadFileAttributes {
  name: string;
  content: string;
  contentType?: 'application/pdf;base64';
}
export const downloadFile = ({
  name,
  content,
  contentType = 'application/pdf;base64',
}: DownloadFileAttributes) => {
  const linkSource = `data:${contentType},${content}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = name;
  downloadLink.click();
};
