import { UiProperty } from '@dataunlocker/pkg-types';
import { getMyPurchases, getPropertyPurchasesList } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import Tooltip from 'Components/Common/Tooltip';
import { showPropertyGetInvoiceDialog } from 'Components/Dialog/PropertyGetInvoice';
import {
  ApiAdminV1PropertiesPurchasesResponse,
  ApiUsersListMyPurchasesResponse,
} from 'Constants';
import React, { useEffect, useState } from 'react';
import { currentUserState, useRecoilValueLoadable } from 'State';
import { ApiResponse } from 'Types';
import PurchasesTable from './PurchasesTable';
import styles from './styles.module.scss';

interface PurchasesListCardProps {
  property?: UiProperty;
  title?: string;
}

const LIST_FIRST_REFRESH_INTERVAL = 5 * 1000;
const LIST_AUTO_REFRESH_RATE = 30 * 1000;

const PurchasesListCard = ({ property, title }: PurchasesListCardProps) => {
  const [purchases, setPurchases] = useState(
    null as ApiResponse<
      ApiAdminV1PropertiesPurchasesResponse | ApiUsersListMyPurchasesResponse
    > | null
  );
  const [selectedPurchaseIds, setSelectedPurchaseIds] = useState(
    [] as string[]
  );
  const [loading, setLoading] = useState(0); // 0 -> 1 -> 2
  const [statsVersion, setStatsVersion] = useState(0.333);

  const userLoadable = useRecoilValueLoadable(currentUserState);
  const user = userLoadable.state === 'hasValue' ? userLoadable.contents : null;

  useEffect(() => {
    let refreshIn = LIST_FIRST_REFRESH_INTERVAL;
    const f = () => {
      if (refreshIn === LIST_FIRST_REFRESH_INTERVAL) {
        clearInterval(interval);
        interval = setInterval(
          () => !document.hidden && f(),
          (refreshIn = LIST_AUTO_REFRESH_RATE)
        );
      }
      setStatsVersion(Math.random());
    };
    let interval = setInterval(() => !document.hidden && f(), refreshIn);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (loading === 0) {
      setLoading(1);
    }
    let promise: Promise<
      ApiResponse<
        ApiAdminV1PropertiesPurchasesResponse | ApiUsersListMyPurchasesResponse
      >
    >;
    if (property?.id) {
      promise = getPropertyPurchasesList(property.id);
    } else {
      promise = getMyPurchases();
    }
    promise
      .then(setPurchases)
      .catch(() => setPurchases(null))
      .finally(() => setLoading(2));
  }, [property?.id, user?.id, statsVersion, loading]);

  const isLoading = loading === 1;
  const hasAnyTrafficTransactions =
    (purchases?.response?.purchases.length || 0) > 0 ||
    (property && property.bytesPrepaidChanges.length > 0);
  const displayTable = !isLoading && hasAnyTrafficTransactions;
  const propertyPurchases = purchases?.response?.purchases || [];
  return (
    <Card>
      <CardHeader
        title={
          <span>
            <span>{title || 'Traffic transactions'}</span>
            <Tooltip
              type="help"
              content={`This table displays a list of previous traffic purchases and all relevant transactions.`}
            />
          </span>
        }
      >
        {!!(property && selectedPurchaseIds.length) && (
          <Tooltip content="Get invoice for selected purchases">
            <Icon
              image="invoice"
              hoverable
              margin="none"
              size="big"
              onClick={() =>
                showPropertyGetInvoiceDialog({
                  property,
                  purchases: propertyPurchases.filter(({ id }) =>
                    selectedPurchaseIds.includes(id)
                  ),
                })
              }
            />
          </Tooltip>
        )}
      </CardHeader>
      {isLoading && (
        <div className={styles.centerMinor}>
          <Icon image="loading" />
        </div>
      )}
      {!hasAnyTrafficTransactions && (
        <div className={styles.centerMinor}>
          You don&apos;t have any transactions yet.
        </div>
      )}
      {purchases?.errorMessage && (
        <div className={`${styles.centerMinor} ${styles.error}`}>
          An error happened when fetching purchases list:{' '}
          {purchases.errorMessage}
        </div>
      )}
      {displayTable && (
        <PurchasesTable
          purchases={propertyPurchases}
          property={property}
          selectedPurchaseIds={selectedPurchaseIds}
          setSelectedPurchaseIds={setSelectedPurchaseIds}
        />
      )}
    </Card>
  );
};

export default PurchasesListCard;
