import React, { useCallback } from 'react';
import {
  INTERVAL_MAX,
  INTERVAL_1D,
  INTERVAL_1H,
  INTERVAL_30D,
  INTERVAL_6H,
  INTERVAL_7D,
  TimeRangeTuple,
} from 'Constants';

import styles from './styles.module.scss';

interface TimeRangePickerProps {
  timeRange: TimeRangeTuple;
  onTimeRangeChange?: (interval: TimeRangeTuple) => any;
}

export {
  INTERVAL_MAX,
  INTERVAL_1D,
  INTERVAL_1H,
  INTERVAL_30D,
  INTERVAL_6H,
  INTERVAL_7D,
};

// Keep sorted by ms, from min to max
const intervals = [
  {
    label: '1h',
    ms: INTERVAL_1H,
  },
  {
    label: '6h',
    ms: INTERVAL_6H,
  },
  {
    label: '1d',
    ms: INTERVAL_1D,
  },
  {
    label: '7d',
    ms: INTERVAL_7D,
  },
  {
    label: '30d',
    ms: INTERVAL_30D,
  },
  {
    label: 'max',
    ms: INTERVAL_MAX,
  },
];

const TimeRangePicker = ({
  timeRange,
  onTimeRangeChange = () => {},
}: TimeRangePickerProps) => {
  const selectedInterval =
    intervals.find(({ ms }) => ms >= timeRange[1]) ||
    intervals[intervals.length - 1];
  const onTimeRangeClick = useCallback(
    (ms) => {
      if (ms === timeRange[1]) {
        return;
      }
      onTimeRangeChange([timeRange[0], ms]);
    },
    [timeRange, onTimeRangeChange]
  );

  return (
    <div className={styles.container}>
      <table>
        <tbody>
          <tr>
            {intervals.map((i) => (
              <td
                key={i.ms}
                className={`${styles.option}${
                  i.ms === selectedInterval.ms ? ` ${styles.selected}` : ''
                }`}
                onClick={() => onTimeRangeClick(i.ms)}
              >
                {i.label}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TimeRangePicker;
