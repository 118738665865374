import React, { useState } from 'react';
import Icon from 'Components/Common/Icon';
import Tooltip from 'Components/Common/Tooltip';
import { LastServiceErrorGroup } from '@dataunlocker/pkg-types';

import styles from './styles.module.scss';

interface Last45xxErrorDetailsProps {
  errorDetails: LastServiceErrorGroup;
}

const Last45xxErrorDetails = ({ errorDetails }: Last45xxErrorDetailsProps) => {
  const [opened, setOpened] = useState(false);
  const is1xx = errorDetails.statusCode < 200;

  const date = new Date(errorDetails.at).toLocaleString();
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr onClick={() => setOpened(!opened)}>
            <th colSpan={2}>
              <Icon
                image={opened ? 'up' : 'down'}
                margin="right-small"
                size="small"
              />
              {is1xx ? (
                <span className={styles.padOnHover}>
                  <span>Last common proxy error</span>{' '}
                  {!opened && <span>({date})</span>}
                  <Tooltip
                    type="help"
                    content={`Common proxy errors happen when, for example, the user closes the page before the proxy request is completed, thus unexpectedly terminating the request. Common proxy errors also consume traffic and are not displayed in the proxied services table.`}
                  />
                </span>
              ) : (
                <span className={styles.padOnHover}>
                  Last{' '}
                  <b className={styles.error}>
                    {Math.floor(errorDetails.statusCode / 100)}xx error
                  </b>{' '}
                  details {!opened && <span>({date})</span>}
                </span>
              )}
            </th>
          </tr>
        </thead>
        {opened && (
          <tbody>
            <tr>
              <td>Requested at</td>
              <td>{date}</td>
            </tr>
            {errorDetails.method && (
              <tr>
                <td>Method</td>
                <td>{errorDetails.method}</td>
              </tr>
            )}
            <tr>
              <td>Request path</td>
              <td>{errorDetails.url}</td>
            </tr>
            <tr>
              <td>Response code</td>
              <td>{errorDetails.statusCode}</td>
            </tr>
            <tr>
              <td>Response body</td>
              <td className={styles.tinyText}>{errorDetails.response}</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Last45xxErrorDetails;
