import { UiProperty } from '@dataunlocker/pkg-types';
import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME, ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH } from 'Constants';
import { History } from 'history';
import React from 'react';

const DialogHealthCheckBeforePropertyDelete = ({
  property,
}: {
  property: UiProperty;
}) => {
  return (
    <>
      <p>
        You can&apos;t delete <b>{property.host}</b> from {PRODUCT_NAME} without
        deleting {PRODUCT_NAME}&apos;s script and invalidating all proxy
        endpoints.
      </p>
      <p>
        Please, remove {PRODUCT_NAME}&apos;s script and retry all proxy
        endpoints until they are invalid, also deleting DNS records. Then, retry
        the health check (to make it all-red) before deleting this property.
      </p>
    </>
  );
};

export const showHealthCheckBeforePropertyDeleteDialog = ({
  property,
  history,
}: {
  property: UiProperty;
  history: History;
}) =>
  showDialog({
    title: `Health check is still green for ${property.host}`,
    icon: 'trash',
    content: <DialogHealthCheckBeforePropertyDelete property={property} />,
    confirmButtonText: 'Go to Health Check',
    cancelButtonText: 'Cancel',
    onConfirmClick: () =>
      history.push(ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH(property.id)),
  });
