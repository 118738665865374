import { UiProperty } from '@dataunlocker/pkg-types';
import { getTransferrableTraffic, requestTrafficTransfer } from 'Api';
import Icon from 'Components/Common/Icon';
import TrafficInput from 'Components/Common/TrafficInput';
import { showDialog } from 'Components/Dialog';
import { DialogButtonBar } from 'Components/Dialog/Common/DialogButtonBar';
import React, { useCallback, useEffect, useState } from 'react';
import { Toast } from 'toaster-js';
import { formatBytes } from 'Utils';
import styles from './styles.module.scss';

const DialogTransferTraffic = ({
  property,
  properties,
  closeDialog,
  onSuccess,
}: {
  property: UiProperty;
  properties: UiProperty[];
  closeDialog: () => void;
  onSuccess?: () => any;
}) => {
  const otherProperties = properties.filter((p) => p.host !== property.host);
  const [loading, setLoading] = useState(false);
  const [maxTransferrableBytes, setMaxTransferrableBytes] = useState(0);
  const [creditedBytes, setCreditedBytes] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState<UiProperty | null>(
    otherProperties[0] || null
  );
  const onSelectedPropertyIdChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedProperty(
        otherProperties.filter((p) => p.id === event.currentTarget.value)[0] ||
          null
      );
    },
    [otherProperties]
  );
  const [trafficToTransfer, setTrafficToTransfer] = useState(100 * 1024 * 1024);

  const onConfirmClick = useCallback(async () => {
    if (!selectedProperty) {
      return;
    }

    setLoading(true);

    const { errorMessage, response } = await requestTrafficTransfer(
      property.id,
      {
        toPropertyId: selectedProperty.id,
        bytes: trafficToTransfer,
      }
    );

    setLoading(false);

    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
    } else if (response?.creditedBytes) {
      new Toast(
        `${selectedProperty.host} received ${formatBytes(
          response.creditedBytes
        )} of traffic.`,
        Toast.TYPE_DONE
      );
      closeDialog();
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [
    onSuccess,
    closeDialog,
    trafficToTransfer,
    property.id,
    selectedProperty,
  ]);

  useEffect(() => {
    if (!(trafficToTransfer > 0)) {
      setMaxTransferrableBytes(0);
      setCreditedBytes(0);
      return;
    }
    setLoading(true);
    (async () => {
      const result = await getTransferrableTraffic({
        propertyId: property.id,
        bytesToTransfer: trafficToTransfer,
        ...(selectedProperty ? { toPropertyId: selectedProperty.id } : {}),
      });
      setLoading(false);
      if (result.errorMessage) {
        new Toast(result.errorMessage);
        return;
      }
      setMaxTransferrableBytes(result.response?.availableBytes || 0);
      setCreditedBytes(result.response?.creditedBytes || 0);
    })();
  }, [trafficToTransfer, property.id, selectedProperty]);

  const valid =
    trafficToTransfer > 0 && trafficToTransfer <= maxTransferrableBytes;
  const hasOtherProperties = otherProperties.length > 0;
  return (
    <div>
      <p>
        You can transfer up to <b>{formatBytes(maxTransferrableBytes)}</b> of
        traffic from <b>{property.host}</b> to other properties you can view.
        Only purchased or previously transferred traffic can be transferred to
        other properties.
      </p>
      {hasOtherProperties && (
        <div className={styles.selectContainer}>
          <div>
            <div>
              <select disabled>
                <option>{property.host}</option>
              </select>
            </div>
            <div>
              <TrafficInput
                value={trafficToTransfer}
                onChange={setTrafficToTransfer}
                valid={valid}
              />
            </div>
          </div>
          <div>
            <div>
              <Icon image="arrowRight" size="huge" />
            </div>
          </div>
          <div>
            <div>
              <select
                value={selectedProperty?.id || ''}
                onChange={onSelectedPropertyIdChange}
              >
                {otherProperties.map(({ id, host }) => (
                  <option key={id} value={id}>
                    {host}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <TrafficInput value={creditedBytes} disabled />
            </div>
          </div>
        </div>
      )}
      <p className={styles.statusTextLine}>
        {loading ? (
          'Loading...'
        ) : !hasOtherProperties ? (
          `You have no properties except ${property.host} to transfer traffic to.`
        ) : valid ? (
          <span>
            This action will transfer <b>{formatBytes(trafficToTransfer)}</b> of
            traffic to <b>{selectedProperty?.host}</b>
            {creditedBytes !== trafficToTransfer ? (
              <>
                , which converts to <b>{formatBytes(creditedBytes)}</b> due to
                applicable discounts. <b>{selectedProperty?.host}</b> will
                receive <b>{formatBytes(creditedBytes)}</b> of traffic
              </>
            ) : (
              ''
            )}
            .
          </span>
        ) : !(trafficToTransfer > 0) ? (
          'Please enter a valid positive traffic value.'
        ) : (
          <span>
            You can&apos;t transfer more than{' '}
            <b>{formatBytes(maxTransferrableBytes)}</b> of traffic to{' '}
            <b>{selectedProperty?.host || 'other properties'}</b>.
          </span>
        )}
      </p>
      <DialogButtonBar
        onCancelClick={closeDialog}
        confirmButtonDisabled={
          !hasOtherProperties || !(trafficToTransfer > 0) || loading || !valid
        }
        cancelButtonText="Cancel"
        confirmButtonText="Transfer"
        onConfirmClick={onConfirmClick}
      />
    </div>
  );
};

export const showTransferTrafficDialog = ({
  property,
  properties,
  onSuccess,
}: {
  property: UiProperty;
  properties: UiProperty[];
  onSuccess?: () => any;
}) =>
  showDialog({
    title: `Choose where to transfer traffic`,
    icon: 'transfer',
    content: ({ closeDialog }) => (
      <DialogTransferTraffic
        property={property}
        properties={properties}
        closeDialog={closeDialog}
        onSuccess={onSuccess}
      />
    ),
    displayButtons: false,
    padContent: false,
  });
