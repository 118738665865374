import { UiTempProperty } from '@dataunlocker/pkg-types';
import Row from 'Components/Pages/Properties/Settings/Setup/Health/Box/Row';
import {
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
  ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT,
} from 'Constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { getProxyEndpointUrl } from 'Utils';

interface Props {
  property: UiTempProperty;
  loading: boolean;
}

const RowTlsCertState = ({ property, loading }: Props) => {
  const endpointUsedByScript = property.proxyEndpoints?.find(
    ({ urlKey }) => urlKey === property.health.scriptProxyEndpointUrlKey
  );
  const proxyEndpointUrl = endpointUsedByScript
    ? getProxyEndpointUrl(endpointUsedByScript, property)
    : '';
  const expectedToFail = !property.health.scriptVersion;

  return (
    <Row
      ok={
        !!endpointUsedByScript?.health.ok &&
        !endpointUsedByScript?.blacklistedIn?.length
      }
      inProgress={endpointUsedByScript?.health.cert === 'pending' || loading}
      isExpectedToFail={expectedToFail}
      rowElement={
        <span>
          {endpointUsedByScript ? (
            <span>
              Proxy endpoint <b>{proxyEndpointUrl}</b> used in the script is
              healthy
            </span>
          ) : (
            <span>Proxy endpoint used by the script is healthy</span>
          )}
        </span>
      }
    >
      {expectedToFail ? (
        <span>
          This check is expected to fail because the script was not found at
          https://{property.host}/.
        </span>
      ) : proxyEndpointUrl ? (
        endpointUsedByScript?.blacklistedIn?.length ? (
          <span>
            Proxy endpoint {proxyEndpointUrl} is found in content filtering
            lists. Please go back to the{' '}
            <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)}>
              routing step
            </Link>{' '}
            and add a new endpoint. Then, replace the old script with the new
            one copied from the{' '}
            <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT(property.id)}>
              script step
            </Link>
            .
          </span>
        ) : (
          <span>
            Proxy endpoint {proxyEndpointUrl} used by the installed script is
            not healthy. Please go back to the{' '}
            <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)}>
              routing step
            </Link>{' '}
            and check why this endpoint is unhealthy.
          </span>
        )
      ) : (
        <span>
          The installed script uses proxy endpoint &quot;
          {property.health.scriptProxyEndpointUrlKey}&quot; which is not
          configured in this property. Please, go back to the{' '}
          <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT(property.id)}>
            script installation step
          </Link>
          , select a healthy endpoint and copy the correct script to your web
          application.
        </span>
      )}
    </Row>
  );
};

export default RowTlsCertState;
