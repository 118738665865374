import {
  PropertyAutoPurchaseStrategy,
  UiProperty,
} from '@dataunlocker/pkg-types';
import { estimatePropertyCtp, updatePropertyBilling } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import NumberInput from 'Components/Common/NumberInput';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import Tooltip from 'Components/Common/Tooltip';
import { showAddCardDialog } from 'Components/Dialog/AddBankCard';
import {
  ApiPropertiesEstimateCtpResponse,
  LINK_DOCS_BILLING_AUTOMATIC_PURCHASES,
  PRODUCT_NAME,
  ROUTE_SETTINGS_PAYMENT_METHODS,
} from 'Constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  currentUserState,
  propertiesListVersionState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'State';
import { Toast } from 'toaster-js';
import {
  formatBytes,
  formatUsdCents,
  getBytesFromPaidCentsForProperty,
} from 'Utils';
import styles from './styles.module.scss';

interface RecurringTrafficPurchaseCardProps {
  property: UiProperty;
}

const RecurringTrafficPurchaseCard = ({
  property,
}: RecurringTrafficPurchaseCardProps) => {
  const userLoadable = useRecoilValueLoadable(currentUserState);
  const user = userLoadable.state === 'hasValue' ? userLoadable.contents : null;
  const visibleCards = Array.from(
    new Set(
      Object.values(user?.cards || {})
        .map((card) => card.number)
        .concat(
          property.billing.ctpUserCard ? [property.billing.ctpUserCard] : []
        )
    )
  );

  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const radioGroupName = useMemo(() => Math.random().toString().slice(2), []);

  const [loading, setLoading] = useState(false);

  const purchasesEnabledDefault = !!property.billing.ctpEnabled; // TODO: read from property
  const [purchasesEnabled, setPurchasesEnabled] = useState(
    purchasesEnabledDefault
  );
  const onPurchasesEnabledChange = useCallback(() => {
    setPurchasesEnabled(!purchasesEnabled);
  }, [purchasesEnabled]);

  const purchaseStrategyDefault =
    property.billing.ctpStrategy || PropertyAutoPurchaseStrategy.auto;
  const [
    purchaseStrategy,
    setPurchaseStrategy,
  ] = useState<PropertyAutoPurchaseStrategy>(purchaseStrategyDefault);
  const onPurchaseStrategyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPurchaseStrategy(
        event.currentTarget.value as PropertyAutoPurchaseStrategy
      );
    },
    []
  );

  const fixedAmountValueDefault = (
    (property.billing.ctpStrategyFixedCents || 500) / 100
  ).toString();
  const [fixedAmountValue, setFixedAmountValue] = useState(
    fixedAmountValueDefault
  );
  const onFixedAmountChange = useCallback((value: number) => {
    setFixedAmountValue(value + '');
  }, []);

  const daysToCoverDefault = (
    property.billing.ctpStrategyAutoDays || 30
  ).toString();
  const [daysToCover, setDaysToCover] = useState(daysToCoverDefault);
  const onDaysToCoverChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDaysToCover(event.currentTarget.value);
    },
    []
  );

  const trafficThresholdUnitDefault = !property.billing.ctpThresholdBytes
    ? 'MB'
    : property.billing.ctpThresholdBytes > 1024 * 1024 * 1024
    ? 'GB'
    : 'MB';
  const [trafficThresholdUnit, setTrafficThresholdUnit] = useState(
    trafficThresholdUnitDefault
  );
  const onTrafficThresholdUnitChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setTrafficThresholdUnit(event.currentTarget.value);
    },
    []
  );

  const trafficThresholdDefault = property.billing.ctpThresholdBytes
    ? (+(
        property.billing.ctpThresholdBytes /
        1024 /
        1024 /
        (trafficThresholdUnitDefault === 'GB' ? 1024 : 1)
      ).toFixed(3)).toString()
    : '500';
  const [trafficThreshold, setTrafficThreshold] = useState(
    trafficThresholdDefault
  );
  const onTrafficThresholdChange = useCallback((value: number) => {
    setTrafficThreshold(value + '');
  }, []);
  const trafficThresholdBytes =
    +trafficThreshold *
    (trafficThresholdUnit === 'GB' ? 1024 : 1) *
    1024 *
    1024;

  const selectedCardDefault =
    property.billing.ctpUserCard || visibleCards[0] || '';
  const [selectedCard, setSelectedCard] = useState(selectedCardDefault);
  const indeedSelectedCard = selectedCard || visibleCards[0] || '';

  const [
    centsEstimate,
    setCentsEstimate,
  ] = useState<ApiPropertiesEstimateCtpResponse | null>();
  useEffect(() => {
    setCentsEstimate(null);
    estimatePropertyCtp(property.id, {
      ctpEnabled: purchasesEnabled,
      ctpStrategy: purchaseStrategy,
      ctpStrategyAutoDays: +daysToCover,
    }).then(
      (response) => response.response && setCentsEstimate(response.response)
    );
  }, [purchasesEnabled, purchaseStrategy, daysToCover, property.id]);

  const onResetClick = useCallback(() => {
    setPurchasesEnabled(purchasesEnabledDefault);
    setPurchaseStrategy(purchaseStrategyDefault);
    setFixedAmountValue(fixedAmountValueDefault);
    setDaysToCover(daysToCoverDefault);
    setTrafficThreshold(trafficThresholdDefault);
    setTrafficThresholdUnit(trafficThresholdUnitDefault);
    setSelectedCard(selectedCardDefault);
  }, [
    purchasesEnabledDefault,
    purchaseStrategyDefault,
    fixedAmountValueDefault,
    daysToCoverDefault,
    trafficThresholdDefault,
    trafficThresholdUnitDefault,
    selectedCardDefault,
  ]);
  const onSaveClick = useCallback(async () => {
    setLoading(true);
    const result = await updatePropertyBilling(property.id, {
      ctpEnabled: purchasesEnabled,
      ctpStrategy: purchaseStrategy,
      ctpStrategyAutoDays: +daysToCover,
      ctpStrategyFixedCents: 100 * +fixedAmountValue,
      ctpThresholdBytes: trafficThresholdBytes,
      ctpUserCard: indeedSelectedCard,
    });
    setLoading(false);
    if (result.errorMessage) {
      new Toast(result.errorMessage, Toast.TYPE_ERROR);
      return;
    }
    setPropertiesListVersion(Math.random());
  }, [
    property.id,
    setPropertiesListVersion,
    purchasesEnabled,
    purchaseStrategy,
    daysToCover,
    fixedAmountValue,
    trafficThresholdBytes,
    indeedSelectedCard,
  ]);

  // TODO: make this realtime? (grab from the prepaid traffic stats)
  const currentPrepaidTraffic = property.bytesPrepaid - property.bytesProxied;
  const hasChanges =
    purchasesEnabled !== purchasesEnabledDefault ||
    purchaseStrategy !== purchaseStrategyDefault ||
    fixedAmountValue !== fixedAmountValueDefault ||
    daysToCover !== daysToCoverDefault ||
    trafficThreshold !== trafficThresholdDefault ||
    trafficThresholdUnit !== trafficThresholdUnitDefault ||
    indeedSelectedCard !== selectedCardDefault;
  const buttonsDisabled = loading || !hasChanges || !indeedSelectedCard;

  return (
    <Card>
      <CardHeader
        title={
          <span>
            <span>Automatic traffic purchases</span>
            <Tooltip
              type="help"
              content={
                <span>
                  This will let you automatically purchase traffic when the
                  current prepaid traffic balance is getting low. You can
                  specify when and how to do these purchases.{' '}
                  <SafeExternalLink
                    href={LINK_DOCS_BILLING_AUTOMATIC_PURCHASES}
                  >
                    Learn more about automatic purchases
                  </SafeExternalLink>
                </span>
              }
            />
          </span>
        }
      />
      <div>
        <div className={styles.continuousTrafficPurchase}>
          <ToggleSwitch
            label="Purchase traffic automatically when prepaid traffic is low"
            checked={purchasesEnabled}
            onChange={onPurchasesEnabledChange}
          />
        </div>
        {!purchasesEnabled && (
          <div className={styles.infoText}>
            <Icon image="info" size="small" margin="right-small" />{' '}
            <span>
              You can setup {PRODUCT_NAME} to make automatic traffic purchases
              when the prepaid traffic of this property falls below the
              configured threshold. You can disable continuous purchases at any
              time later.
            </span>
          </div>
        )}
        {purchasesEnabled && (
          <div>
            <div className={styles.radioOptionsContainer}>
              <label
                className={styles.radioOptionContainer}
                htmlFor={`${radioGroupName}-${PropertyAutoPurchaseStrategy.auto}`}
              >
                <input
                  type="radio"
                  id={`${radioGroupName}-${PropertyAutoPurchaseStrategy.auto}`}
                  name={radioGroupName}
                  value={PropertyAutoPurchaseStrategy.auto}
                  checked={
                    purchaseStrategy === PropertyAutoPurchaseStrategy.auto
                  }
                  onChange={onPurchaseStrategyChange}
                />
                <div>
                  <div>Determine the purchase amount based on the usage</div>
                  <div>
                    DataUnlocker determines what amount to charge based on the
                    traffic consumption
                  </div>
                </div>
              </label>
              <label
                className={styles.radioOptionContainer}
                htmlFor={`${radioGroupName}-${PropertyAutoPurchaseStrategy.fixed}`}
              >
                <input
                  type="radio"
                  id={`${radioGroupName}-${PropertyAutoPurchaseStrategy.fixed}`}
                  name={radioGroupName}
                  value={PropertyAutoPurchaseStrategy.fixed}
                  checked={
                    purchaseStrategy === PropertyAutoPurchaseStrategy.fixed
                  }
                  onChange={onPurchaseStrategyChange}
                />
                <div>
                  <div>Purchase a fixed amount</div>
                  <div>
                    {PRODUCT_NAME} will charge the specified value when prepaid
                    traffic falls below the threshold
                  </div>
                </div>
              </label>
            </div>
            <div className={styles.inputGroup}>
              <div>Make a purchase when the prepaid traffic is below</div>
              <div>
                <NumberInput
                  inline
                  value={+trafficThreshold}
                  onChange={onTrafficThresholdChange}
                />
                <select
                  value={trafficThresholdUnit}
                  onChange={onTrafficThresholdUnitChange}
                >
                  <option value="MB">MB</option>
                  <option value="GB">GB</option>
                </select>
              </div>
              <div className={styles.subInfo}>
                <Icon image="info" size="small" margin="right-small" />{' '}
                <span>
                  You currently have {formatBytes(currentPrepaidTraffic)} of
                  prepaid traffic. This is{' '}
                  {currentPrepaidTraffic > trafficThresholdBytes
                    ? 'above'
                    : currentPrepaidTraffic < trafficThresholdBytes
                    ? 'below'
                    : 'at'}{' '}
                  the current threshold of {formatBytes(trafficThresholdBytes)},
                  hence the traffic will{' '}
                  <b>
                    {currentPrepaidTraffic >= trafficThresholdBytes
                      ? 'not '
                      : ''}
                  </b>
                  be purchased right now.
                </span>
              </div>
            </div>
            {purchaseStrategy === PropertyAutoPurchaseStrategy.auto && (
              <div className={styles.inputGroup}>
                <div>Purchase enough traffic to cover the next</div>
                <div>
                  <input
                    type="number"
                    value={daysToCover}
                    onChange={onDaysToCoverChange}
                  />
                  <select>
                    <option value="days">Days</option>
                  </select>
                </div>
                <div className={styles.subInfo}>
                  <Icon image="info" size="small" margin="right-small" />{' '}
                  <span>
                    If you had been charged <b>now</b>, you would have paid{' '}
                    <b>
                      {centsEstimate
                        ? formatUsdCents(centsEstimate.cents)
                        : '...'}
                    </b>
                    {centsEstimate
                      ? ` (for ${formatBytes(
                          centsEstimate.bytes
                        )}), which is based on an average spending of ${formatUsdCents(
                          centsEstimate.avgSpendCentsPerDay
                        )} (${formatBytes(
                          centsEstimate.avgSpendBytesPerDay
                        )}) per day during the last ${
                          centsEstimate.avgSpendDays
                        } days. Thus, ${formatUsdCents(
                          centsEstimate.avgSpendCentsPerDay
                        )} × ${daysToCover} days = ${formatUsdCents(
                          centsEstimate.cents
                        )}`
                      : ''}
                    . The minimum charge is always {formatUsdCents(100)}.
                  </span>
                </div>
              </div>
            )}
            {purchaseStrategy === PropertyAutoPurchaseStrategy.fixed && (
              <div className={styles.inputGroup}>
                <div>Make a purchase worth of</div>
                <div>
                  <NumberInput
                    inline
                    value={+fixedAmountValue}
                    onChange={onFixedAmountChange}
                  />
                  <span className={styles.customValueText}>
                    <b>USD</b> ={' '}
                    {formatBytes(
                      getBytesFromPaidCentsForProperty(
                        property,
                        +fixedAmountValue * 100
                      )
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className={styles.inputGroup}>
              <div>Make purchases from the card</div>
              <div className={styles.alignChildTop}>
                {!visibleCards.length && (
                  <button onClick={showAddCardDialog}>Add a new card</button>
                )}
                {visibleCards.map((cardNumber) => (
                  <label
                    className={styles.cardsListItem}
                    key={cardNumber}
                    htmlFor={`${radioGroupName}-${cardNumber}`}
                    onClick={() => setSelectedCard(cardNumber)}
                  >
                    <input
                      type="radio"
                      id={`${radioGroupName}-${cardNumber}`}
                      name={`${radioGroupName}-${cardNumber}`}
                      value={cardNumber}
                      checked={indeedSelectedCard === cardNumber}
                      readOnly
                    />
                    <span>{cardNumber}</span>
                  </label>
                ))}
              </div>
              <div className={styles.subInfo}>
                <Icon image="info" size="small" margin="right-small" />{' '}
                <span>
                  You can manage your cards on{' '}
                  <Link to={ROUTE_SETTINGS_PAYMENT_METHODS}>
                    My Payment Methods
                  </Link>{' '}
                  page. Once continuous traffic purchase is enabled, only the
                  card owner account can change billing-related settings.
                </span>
              </div>
            </div>
          </div>
        )}
        {!(
          !purchasesEnabledDefault &&
          purchasesEnabled === purchasesEnabledDefault
        ) && (
          <div>
            <button disabled={buttonsDisabled} onClick={onSaveClick}>
              {purchasesEnabledDefault ? 'Update' : 'Enable purchases'}
            </button>
            <button
              className="secondary"
              disabled={buttonsDisabled}
              onClick={onResetClick}
            >
              Reset
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default RecurringTrafficPurchaseCard;
