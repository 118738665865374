import React from 'react';
import styles from './styles.module.scss';

interface Props {
  title: string;
  className?: string;
  children: React.ReactNode;
}

const MenuCategory = ({ title, children, className }: Props) => {
  return (
    <div>
      <div
        className={`${styles.menuCategoryItem}${
          className ? ` ${className}` : ''
        }`}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

export default MenuCategory;
