import MessageBlock from 'Components/Common/MessageBlock';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import { ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING } from 'Constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { getProxyEndpointUrl } from 'Utils';

const PipelineStepWaitProxyEndpointIsUnhealthy = (
  props: PipelineStepCommonProps
) => {
  const proxyEndpoint = props.property.proxyEndpoints.find(
    (e) => props.pipeline.context.oldProxyEndpointUrlKey === e.urlKey
  );
  return (
    <PipelineStep
      icon="clock"
      title="Wait for the old proxy endpoint to become unhealthy"
      {...props}
    >
      {proxyEndpoint ? (
        <MessageBlock display="inline">
          Waiting for old proxy endpoint to become unhealthy:{' '}
          <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(props.property.id)}>
            {getProxyEndpointUrl(proxyEndpoint, props.property)}
          </Link>
          . This step will periodically test the proxy endpoint&apos;s health
          and complete once it becomes unhealthy.
        </MessageBlock>
      ) : (
        <MessageBlock display="inline">
          This step will periodically trigger the old proxy endpoint&apos;s
          health check and complete once it becomes unhealthy (in other words,
          you remove the DNS record or path of that proxy endpoint).
        </MessageBlock>
      )}
    </PipelineStep>
  );
};

export default PipelineStepWaitProxyEndpointIsUnhealthy;
