import React from 'react';
import styles from './styles.module.scss';
import * as icons from './icons';

const Icon = ({
  image = 'logo',
  size = 'regular',
  hoverable = false,
  rounded = false,
  margin = 'regular',
  accent = '', // 'minor'
  className = '',
  shadow = false,
  style = undefined,
  imageonly = false,
  button = false,
  onClick = undefined,
  ...otherProps
}) => {
  const Icon = icons[image] || icons.logo;
  const imageStyle = styles[`image-${image}`];
  const sizeStyle = styles[`size-${size.toLowerCase()}`];
  const Element = button ? 'button' : 'span';
  return (
    <Element
      style={style}
      className={`${styles.icon}${hoverable ? ` ${styles.hoverable}` : ''} ${
        styles[`margin-${margin && !imageonly ? margin : 'none'}`]
      }${rounded ? ` ${styles.rounded}` : ''}${
        imageStyle ? ` ${imageStyle}` : ''
      }${sizeStyle && !imageonly ? ` ${sizeStyle}` : ''}${
        className ? ` ${className}` : ''
      }${shadow ? ` ${styles.shadow}` : ''}${
        accent ? ` ${styles[`accent-${accent}`]}` : ''
      }`}
      onClick={(e) => typeof onClick === 'function' && onClick(e)}
      {...(button ? { type: 'button' } : {})}
      {...otherProps}
    >
      <Icon />
    </Element>
  );
};

export default Icon;
