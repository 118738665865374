import { UiUser } from '@dataunlocker/pkg-types';
import {
  ApiUsersCheckRequest,
  ApiUsersCheckResponse,
  ApiUsersConfirmEmailRequestQuery,
  ApiUsersConfirmEmailResponse,
  ApiUsersDeleteCardRequest,
  ApiUsersDeleteCardResponse,
  ApiUsersDeleteRequest,
  ApiUsersDeleteResponse,
  ApiUsersListMyPurchasesResponse,
  ApiUsersLoginGoogleRequest,
  ApiUsersLoginGoogleResponse,
  ApiUsersLoginRequest,
  ApiUsersLoginResponse,
  ApiUsersPasswordResetRequest,
  ApiUsersPasswordResetRequestRequest,
  ApiUsersPasswordResetRequestResponse,
  ApiUsersPasswordResetResponse,
  ApiUsersReconfirmEmailResponse,
  ApiUsersRegisterRequest,
  ApiUsersRegisterResponse,
  ApiUsersWhoAmIResponse,
  API_PATH_ADMIN_V1_USERS_DELETE,
  API_PATH_AUTH_CHECK,
  API_PATH_USERS_CONFIRM_EMAIL,
  API_PATH_USERS_DELETE_CARD,
  API_PATH_USERS_LIST_MY_PURCHASES,
  API_PATH_USERS_LOGIN,
  API_PATH_USERS_LOGIN_GOOGLE,
  API_PATH_USERS_PASSWORD_RESET_REQUEST,
  API_PATH_USERS_RECONFIRM_EMAIL,
  API_PATH_USERS_REGISTER,
  API_PATH_USERS_WHOAMI,
  API_PATH_USER_PASSWORD_RESET,
} from 'Constants';
import { ApiResponse } from 'Types';
import {
  apiRequest,
  clearSessionToken,
  markSessionAsExpired,
  setSessionToken,
} from 'Utils';

/** Returns whether the user email was previously registered (so it has a password). */
export const getIsUserRegistered = async (
  email: string,
  captchaToken: string
): Promise<{ exists: boolean; errorMessage?: string }> => {
  const result = await apiRequest<ApiUsersCheckRequest, ApiUsersCheckResponse>(
    API_PATH_AUTH_CHECK,
    { email, captchaToken }
  );
  return {
    exists: !!result.response?.exists,
    errorMessage: result.errorMessage,
  };
};

/* Return the session token if the user was granted an access, otherwise throws. */
export const requestRegister = async (
  email: string,
  password: string,
  captchaToken: string
): Promise<ApiResponse<ApiUsersRegisterResponse>> => {
  const result = await apiRequest<
    ApiUsersRegisterRequest,
    ApiUsersRegisterResponse
  >(API_PATH_USERS_REGISTER, {
    email,
    password,
    captchaToken,
  });
  if (result.response?.jwtToken) {
    setSessionToken(result.response.jwtToken);
  }
  return result;
};

/* Return the session token if the user was granted an access, otherwise throws. */
export const requestLogin = async (
  email: string,
  password: string,
  captchaToken: string
): Promise<ApiResponse<ApiUsersLoginResponse>> => {
  const result = await apiRequest<ApiUsersLoginRequest, ApiUsersLoginResponse>(
    API_PATH_USERS_LOGIN,
    {
      email,
      password,
      captchaToken,
    }
  );
  if (result.response?.jwtToken) {
    setSessionToken(result.response.jwtToken);
  }
  return result;
};

/* Return the session token if the user was granted an access, otherwise throws. */
export const requestLoginWithGoogle = async (
  code: string
): Promise<ApiResponse<ApiUsersLoginGoogleResponse>> => {
  const result = await apiRequest<
    ApiUsersLoginGoogleRequest,
    ApiUsersLoginGoogleResponse
  >(API_PATH_USERS_LOGIN_GOOGLE, {
    tokenId: code,
    useAuthCode: true,
    redirectUrl: window.location.origin,
  });
  if (result.response?.jwtToken) {
    setSessionToken(result.response.jwtToken);
  }
  return result;
};

export const requestLogout = (): Promise<void> => {
  clearSessionToken();
  return Promise.resolve();
};

export const getCurrentUser = async (): Promise<UiUser | null> => {
  const result = await apiRequest<void, ApiUsersWhoAmIResponse>(
    API_PATH_USERS_WHOAMI
  );
  if (result.statusCode === 404) {
    // If no user found
    markSessionAsExpired();
  }
  return result.response?.user ?? null;
};

export const getMyPurchases = async () => {
  return await apiRequest<void, ApiUsersListMyPurchasesResponse>(
    API_PATH_USERS_LIST_MY_PURCHASES
  );
};

export const confirmEmail = async (token: string): Promise<boolean> => {
  const result = await apiRequest<
    ApiUsersConfirmEmailRequestQuery,
    ApiUsersConfirmEmailResponse
  >(API_PATH_USERS_CONFIRM_EMAIL, undefined, { query: { token } });
  return !!result.response?.ok;
};

export const requestDeleteCard = async (cardNumber: string) => {
  return await apiRequest<
    ApiUsersDeleteCardRequest,
    ApiUsersDeleteCardResponse
  >(API_PATH_USERS_DELETE_CARD, { cardNumber });
};

export const requestDeleteUser = async (userId: string) => {
  return await apiRequest<ApiUsersDeleteRequest, ApiUsersDeleteResponse>(
    API_PATH_ADMIN_V1_USERS_DELETE(userId),
    {}
  );
};

export const reconfirmEmail = async (): Promise<
  ApiResponse<ApiUsersReconfirmEmailResponse>
> => {
  const result = await apiRequest<Object, ApiUsersReconfirmEmailResponse>(
    API_PATH_USERS_RECONFIRM_EMAIL,
    {}
  );
  return result;
};

export const requestPasswordReset = async (
  email: string,
  captchaToken: string
): Promise<ApiResponse<ApiUsersPasswordResetRequestResponse>> => {
  const result = await apiRequest<
    ApiUsersPasswordResetRequestRequest,
    ApiUsersPasswordResetRequestResponse
  >(API_PATH_USERS_PASSWORD_RESET_REQUEST, {
    email,
    captchaToken,
  });
  return result;
};

export const resetPassword = async (
  passwordResetToken: string,
  newPassword: string
): Promise<ApiResponse<ApiUsersPasswordResetResponse>> => {
  const result = await apiRequest<
    ApiUsersPasswordResetRequest,
    ApiUsersPasswordResetResponse
  >(API_PATH_USER_PASSWORD_RESET, {
    passwordResetToken,
    newPassword,
  });
  return result;
};
