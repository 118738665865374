import { selector, atom } from 'recoil';
import { UiUser } from '@dataunlocker/pkg-types';
import { URL_PARAMETER_CONFIRM_EMAIL_TOKEN } from 'Constants';
import { sessionTokenState } from './auth';
import { Toast } from 'toaster-js';
import { getCurrentUser, confirmEmail } from 'Api';

const emailConfirmationSearchRegExp = new RegExp(
  `(?:\\?|&)${URL_PARAMETER_CONFIRM_EMAIL_TOKEN}=([^&#]+)`
);
// Init immediately after page load
const emailConfirmationToken = window.location.search.match(
  emailConfirmationSearchRegExp
)?.[1];

// Handles email open link with the URL containing email confirmation token.
export const confirmEmailState = selector<string>({
  key: 'confirmEmail',
  get: async () => {
    if (emailConfirmationToken) {
      let confirmed = false;
      try {
        confirmed = await confirmEmail(emailConfirmationToken);
      } catch (e) {
        //
      }
      new Toast(
        confirmed
          ? 'Your email has been confirmed!'
          : 'Error confirming email! It is either confirmed already or the confirmation token is malformed.',
        confirmed ? Toast.TYPE_DONE : Toast.TYPE_ERROR,
        Toast.TIME_LONG
      );
      window.history.pushState(
        null,
        document.title,
        window.location.href.replace(emailConfirmationSearchRegExp, '')
      );
    }
    return emailConfirmationToken ?? '';
  },
});

export const userVersionState = atom<number>({
  key: 'userVersion',
  default: 1,
});

let oldUserState = null as UiUser | null;
export const currentUserState = selector<UiUser | null>({
  key: 'currentUser',
  get: async ({ get }) => {
    try {
      get(confirmEmailState);
      get(userVersionState);
      oldUserState = get(sessionTokenState) ? await getCurrentUser() : null;
    } catch (e) {
      //
    }
    return oldUserState;
  },
});
