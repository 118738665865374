import { UiTempProperty } from '@dataunlocker/pkg-types';
import Row from 'Components/Pages/Properties/Settings/Setup/Health/Box/Row';
import { PRODUCT_NAME, ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING } from 'Constants';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  property: UiTempProperty;
  loading: boolean;
}

const RowTlsCertState = ({ property, loading }: Props) => {
  const oneIsOk = property.proxyEndpoints?.reduce(
    (acc, b) => acc || b.health.ok,
    false as boolean
  );

  const isAnyTlsCertPending = !!property.proxyEndpoints.find(
    (endpoint) => endpoint.health.cert === 'pending'
  );

  return (
    <Row
      ok={oneIsOk}
      inProgress={(isAnyTlsCertPending && !oneIsOk) || loading}
      rowElement={<span>There&apos;s at least one healthy proxy endpoint</span>}
    >
      You need at least one healthy{' '}
      <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(property.id)}>
        proxy endpoint
      </Link>{' '}
      through which blocked traffic is proxied to {PRODUCT_NAME}.
    </Row>
  );
};

export default RowTlsCertState;
