import { requestLeaveFeedback } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import PageHeader from 'Components/Common/Pages/Header';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import { showDialog } from 'Components/Dialog';
import { PRODUCT_SUPPORT_EMAIL } from 'Constants';
import React, { useCallback, useState } from 'react';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

const PageFeedback = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [selectedType, setSelectedType] = useState<'' | 'feedback' | 'issue'>(
    ''
  );
  const onTextareaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFeedback(e.currentTarget.value);
    },
    []
  );
  const onSubmitFeedback = useCallback(async () => {
    const { errorMessage } = await requestLeaveFeedback({ rating, feedback });

    if (errorMessage) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
      return;
    }

    showDialog({
      title: 'Thank you!',
      content: 'Your feedback is submitted.',
    });

    setFeedback('');
    setRating(0);
    setSelectedType('');
  }, [rating, feedback]);
  const getSelectedTypeHandler = useCallback(
    (type: typeof selectedType) => () => {
      setSelectedType(type);
    },
    []
  );

  const isSubmitEnabled =
    !!selectedType &&
    (selectedType === 'feedback' ? !!rating : feedback.length > 10);
  return (
    <div>
      <PageHeader icon="feedback" title="Feedback" />
      <div className={styles.container}>
        <div className={styles.content}>
          <Card>
            <CardHeader
              icon="feedback"
              title="Leave your feedback about our service"
            />
            <div>
              <div className={styles.secondary}>
                <p>
                  You are always welcome to express your love or hate, success
                  or pain, suggest features etc. Your feedback helps us build a
                  better service for you.
                </p>
                <p>
                  This form is a no-response-required alternative to writing
                  directly to{' '}
                  <UnsafeExternalLink href={`mailto:${PRODUCT_SUPPORT_EMAIL}`}>
                    {PRODUCT_SUPPORT_EMAIL}
                  </UnsafeExternalLink>
                  . The anonymized information entered in this form might be
                  shared publicly.
                </p>
                {!selectedType && (
                  <div className={styles.feedbackLine}>
                    <div></div>
                    <div>
                      <button onClick={getSelectedTypeHandler('issue')}>
                        Report a Problem
                      </button>
                      <button onClick={getSelectedTypeHandler('feedback')}>
                        Submit Feedback
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {!!selectedType && (
                <div>
                  <textarea
                    placeholder="Your optional feedback..."
                    value={feedback}
                    onChange={onTextareaChange}
                  ></textarea>
                  <div className={styles.feedbackLine}>
                    <div className={styles.stars}>
                      {selectedType === 'feedback' && (
                        <div>
                          <Icon
                            className={rating > 0 ? styles.active : ''}
                            onClick={() => setRating(1)}
                            image="star"
                            size="huge"
                            hoverable
                          />
                          <Icon
                            className={rating > 1 ? styles.active : ''}
                            onClick={() => setRating(2)}
                            image="star"
                            size="huge"
                            hoverable
                          />
                          <Icon
                            className={rating > 2 ? styles.active : ''}
                            onClick={() => setRating(3)}
                            image="star"
                            size="huge"
                            hoverable
                          />
                          <Icon
                            className={rating > 3 ? styles.active : ''}
                            onClick={() => setRating(4)}
                            image="star"
                            size="huge"
                            hoverable
                          />
                          <Icon
                            className={rating > 4 ? styles.active : ''}
                            onClick={() => setRating(5)}
                            image="star"
                            size="huge"
                            hoverable
                          />
                        </div>
                      )}
                      {selectedType === 'feedback' && (
                        <div>
                          {rating === 0
                            ? 'Click to rate'
                            : rating === 1
                            ? 'Poor, you did it all wrong'
                            : rating === 2
                            ? "It's ugly but somewhat usable"
                            : rating === 3
                            ? 'Not satisfied nor disappointed'
                            : rating === 4
                            ? 'Good but could be better'
                            : 'Excellent service, keep it up'}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        disabled={!isSubmitEnabled}
                        onClick={onSubmitFeedback}
                      >
                        {selectedType === 'issue'
                          ? 'Report a Problem'
                          : 'Submit Feedback'}
                      </button>
                      <button
                        className="secondary"
                        onClick={getSelectedTypeHandler('')}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PageFeedback;
