import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import MessageBlock from 'Components/Common/MessageBlock';
import TimeRangePicker from 'Components/Common/TimeRangePicker';
import Tooltip from 'Components/Common/Tooltip';
import { COLOR_PALETTE, TimeRangeTuple } from 'Constants';
import React, { ReactNode, useEffect } from 'react';
import {
  Area,
  AreaChart,
  AxisDomain,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatBytes } from 'Utils';
import ChartTooltip from '../ChartTooltip';
import styles from './styles.module.scss';

const barChartMargins = {
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

const chartTooltipFormatter = (value: any) => formatBytes(+value, 2);
const timeFormatter = (n: any) =>
  new Date(n).toLocaleString(void 0, {
    dateStyle: 'short',
    timeStyle: 'short',
  } as any);

const tickProperties = { fontSize: 12 };
const chartDomain: [AxisDomain, AxisDomain] = ['auto', 'auto'];

const CHART_AUTO_REFRESH_RATE = 30 * 1000;

export type ChartData = {
  time: number; // Time series in milliseconds
  [key: string]: number; // Data to render
}[];

interface DuAreaChartProps {
  stepDuration: number;
  chartTitle: string;
  chartTooltip?: ReactNode;
  chartData: ChartData;
  stacked?: boolean;
  errorMessage?: string;
  onChartDataUpdateRequest?: (params: { timeRange: TimeRangeTuple }) => any;
  height?: number;
  onTimeRangeChange: (newTimeRange: TimeRangeTuple) => void;
  timeRange: TimeRangeTuple;
}

const DuAreaChart = ({
  chartTooltip,
  chartTitle,
  stepDuration,
  errorMessage,
  stacked = true,
  chartData,
  height = 300,
  onChartDataUpdateRequest,
  onTimeRangeChange,
  timeRange,
}: DuAreaChartProps) => {
  const allDataKeys = chartData?.reduce((set, dataStep) => {
    for (const key of Object.keys(dataStep)) {
      if (key === 'time') {
        continue;
      }
      set.add(key);
    }
    return set;
  }, new Set<string>());

  useEffect(() => {
    const interval = setInterval(() => {
      if (!document.hidden && onChartDataUpdateRequest) {
        onChartDataUpdateRequest({ timeRange });
      }
    }, CHART_AUTO_REFRESH_RATE);
    return () => clearInterval(interval);
  }, [onChartDataUpdateRequest, timeRange]);

  // Request update either on update function change or time range change.
  useEffect(() => {
    if (onChartDataUpdateRequest) {
      onChartDataUpdateRequest({ timeRange });
    }
  }, [onChartDataUpdateRequest, timeRange]);

  return (
    <Card>
      <CardHeader
        title={
          <span>
            <span>{chartTitle}</span>
            {chartTooltip && <Tooltip type="help" content={chartTooltip} />}
          </span>
        }
      >
        {!!onChartDataUpdateRequest && (
          <TimeRangePicker
            timeRange={timeRange}
            onTimeRangeChange={onTimeRangeChange}
          />
        )}
      </CardHeader>
      <div className={styles.chartContainer}>
        {(!chartData || chartData.length === 0) && (
          <div className={styles.centeredContent}>
            {errorMessage ? (
              <MessageBlock type="error">{errorMessage}</MessageBlock>
            ) : (
              <Icon image="loading" size="huge" />
            )}
          </div>
        )}
        {chartData && (
          <ResponsiveContainer width="100%" height={height}>
            <AreaChart data={chartData} margin={barChartMargins}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                scale="time"
                type="number"
                domain={chartDomain}
                tick={tickProperties}
                tickFormatter={timeFormatter}
              />
              <YAxis
                orientation="left"
                tick={tickProperties}
                tickFormatter={chartTooltipFormatter}
                type="number"
                domain={chartDomain}
              />
              <RechartsTooltip
                content={<ChartTooltip stepSize={stepDuration} />}
              />
              {!allDataKeys
                ? null
                : Array.from(allDataKeys.values()).map((dataKey, i) => (
                    <Area
                      key={dataKey}
                      type="monotone"
                      stackId={stacked ? '1' : undefined}
                      dataKey={dataKey}
                      fill={COLOR_PALETTE[i % COLOR_PALETTE.length]}
                      stroke={COLOR_PALETTE[i % COLOR_PALETTE.length]}
                    />
                  ))}
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
};

export default DuAreaChart;
