import React from 'react';
import { useRecoilValueLoadable, currentUserState } from 'State';
import { showDialog } from 'Components/Dialog';

const DialogLogout = () => {
  const userLoadable = useRecoilValueLoadable(currentUserState);
  const from =
    userLoadable.state === 'hasValue' && userLoadable.contents?.email
      ? ' ' + userLoadable.contents?.email
      : '';

  return (
    <div>
      You are about to log out from your account
      {from && <b>{' ' + from}</b>}. Are you sure?
    </div>
  );
};

export const showLogoutDialog = ({ onLogout }: { onLogout?: () => void }) =>
  showDialog({
    title: `Log out?`,
    icon: 'logout',
    content: <DialogLogout />,
    confirmButtonText: 'Log Out',
    cancelButtonText: 'Cancel',
    onConfirmClick: async () => {
      if (onLogout) {
        onLogout();
      }
    },
  });
