import MessageBlock from 'Components/Common/MessageBlock';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import React from 'react';

const PipelineStepStart = (props: PipelineStepCommonProps) => {
  return (
    <PipelineStep icon="play" title="Start pipeline" {...props}>
      {props.step.startedAt ? (
        <MessageBlock display="inline">
          This pipeline has started at{' '}
          {new Date(props.step.startedAt || Date.now()).toLocaleString()}.
        </MessageBlock>
      ) : (
        <MessageBlock display="inline">
          This pipeline hasn&apos;t started yet.
        </MessageBlock>
      )}
    </PipelineStep>
  );
};

export default PipelineStepStart;
