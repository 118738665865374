import { UiProperty } from '@dataunlocker/pkg-types';
import { updatePropertyProxyFlags } from 'Api';
import AlwaysProxyControls from 'Components/Common/AlwaysProxyControls';
import Icon from 'Components/Common/Icon';
import ToggleSwitch from 'Components/Common/ToggleSwitch';
import Tooltip from 'Components/Common/Tooltip';
import { ALWAYS_PROXY_DEFAULT_CONFIG, PRODUCT_NAME } from 'Constants';
import React, { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { propertiesListVersionState } from 'State';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

interface Props {
  property: UiProperty;
}

const AlwaysOnProxyConfig = ({ property }: Props) => {
  const [loading, setLoading] = useState(false);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );

  const defaultApEnabled = property.apEnabled || false;
  const [apEnabled, setApEnabled] = useState(!!defaultApEnabled);

  const defaultApBrowsers =
    typeof property.apBrowsers === 'undefined'
      ? ALWAYS_PROXY_DEFAULT_CONFIG
      : property.apBrowsers;
  const [apBrowsers, setApBrowsers] = useState(defaultApBrowsers);

  const onResetClick = useCallback(() => {
    setApEnabled(defaultApEnabled);
    setApBrowsers(defaultApBrowsers);
  }, [defaultApEnabled, defaultApBrowsers]);

  const onSaveClick = useCallback(async () => {
    setLoading(true);
    const result = await updatePropertyProxyFlags(property.id, {
      apEnabled,
      apBrowsers,
    });
    setLoading(false);

    if (result.errorMessage) {
      new Toast(result.errorMessage, Toast.TYPE_ERROR);
    } else {
      setPropertiesListVersion(Math.random());
    }
  }, [setPropertiesListVersion, property.id, apEnabled, apBrowsers]);

  const hasChanges =
    apEnabled !== defaultApEnabled || apBrowsers !== defaultApBrowsers;
  const isSaveDisabled = !hasChanges || loading;
  return (
    <div>
      <div>
        <table className={`${styles.controlsTable} failed-submit`}>
          <tbody>
            <tr>
              <td>
                Always proxy all configured services{' '}
                <Tooltip
                  type="help"
                  content={
                    <>
                      <p>
                        When enabled, all configured services (in the table
                        below) will be proxied through {PRODUCT_NAME} by default
                        and if failed, retried without the proxy.
                      </p>
                      <p>
                        When disabled, the behavior is standard: first try
                        original request, then proxy.
                      </p>
                      <p>
                        Be careful when enabling this option, as successfully
                        proxied request may not mean it is successfully handled
                        by the destination server.
                      </p>
                    </>
                  }
                >
                  <Icon
                    image="help"
                    margin="none"
                    size="small"
                    className={styles.minor}
                  />
                </Tooltip>
              </td>
              <td>
                <ToggleSwitch
                  noMargin
                  checked={apEnabled}
                  onChange={setApEnabled}
                />
              </td>
            </tr>
            <tr>
              <td>
                Always-proxy in browsers{' '}
                <Tooltip
                  type="help"
                  content={
                    <span>
                      Select web browsers in which <b>all requests</b> will be
                      at first attempted to be proxied via {PRODUCT_NAME},{' '}
                      <b>regardless</b> of whether they are blocked or not. When
                      using always-proxy mode the request fallback logic is
                      reversed: if the request to {PRODUCT_NAME} fails, the
                      original URL is requested.
                      <br />
                      <br />
                      Changing this configuration requires a script update or
                      re-running installation refresh pipeline.
                    </span>
                  }
                >
                  <Icon
                    image="help"
                    margin="none"
                    size="small"
                    className={styles.minor}
                  />
                </Tooltip>
              </td>
              <td>
                <AlwaysProxyControls
                  disabled={!apEnabled}
                  config={apBrowsers}
                  onChange={setApBrowsers}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button
          disabled={isSaveDisabled}
          className={styles.noBottomMargin}
          onClick={onSaveClick}
        >
          Save
        </button>
        <button
          disabled={isSaveDisabled}
          className={`${styles.noBottomMargin} secondary`}
          onClick={onResetClick}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default AlwaysOnProxyConfig;
