import {
  AutomationPipelineStepDataWebhookBasic,
  AutomationPipelineType,
  UiPropertySecret,
  UiTempProperty,
} from '@dataunlocker/pkg-types';
import { getPropertyAutomationPipelines, propertyDeleteSecret } from 'Api';
import MessageBlock from 'Components/Common/MessageBlock';
import { showDialog } from 'Components/Dialog';
import React, { useEffect, useState } from 'react';
import relativeDate from 'relative-date';
import { Toast } from 'toaster-js';

interface Props {
  property: UiTempProperty;
  secret: UiPropertySecret;
  onDelete?: () => void;
}

const DeletePropertySecret = ({
  secret,
  property,
}: Pick<Props, 'secret' | 'property'>) => {
  const [warningUsed, setWarningUsed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const pipelines = await getPropertyAutomationPipelines(property.id, {
        type: AutomationPipelineType.InstallationRefresh,
      });

      const latestPipeline = pipelines.response?.pipelines.sort(
        (a, b) => b.createdAt - a.createdAt
      )[0];
      const variable = `{{secrets.${secret.name}}}`;

      if (
        latestPipeline?.steps.find((s) => {
          const data = (s.data as AutomationPipelineStepDataWebhookBasic) || {};
          return (
            data.webhookBody?.includes(variable) ||
            data.webhookUrl?.includes(variable) ||
            Object.values(data.webhookHeaders || {}).find((v) =>
              (v + '').includes(variable)
            )
          );
        })
      ) {
        setWarningUsed(true);
      }

      setLoading(false);
    })();
  }, [secret.name, property.id]);

  return (
    <div>
      <p>
        This action is permanent. You are about to delete the following secret
        from {property.host}:
      </p>
      <ul>
        <li>
          <b>{secret.name}</b> (last updated {relativeDate(secret.updatedAt)})
        </li>
      </ul>
      {loading ? (
        <MessageBlock type="info" display="inline">
          Checking whether this secret is still used...
        </MessageBlock>
      ) : warningUsed ? (
        <MessageBlock type="error" display="inline">
          This variable is used in the latest pipeline. By deleting it, the next
          pipeline run may fail as the secret won&apos;t be interpolated into
          the context.
        </MessageBlock>
      ) : (
        <MessageBlock type="success" display="inline">
          This variable is not used in the latest pipeline.
        </MessageBlock>
      )}
    </div>
  );
};

export const showDeletePropertySecretDialog = ({
  secret,
  property,
  onDelete,
}: Props) =>
  showDialog({
    title: `Delete this secret?`,
    icon: 'trash',
    content: <DeletePropertySecret secret={secret} property={property} />,
    confirmButtonText: 'Delete',
    confirmButtonClass: 'danger',
    cancelButtonText: 'Cancel',
    onConfirmClick: async () => {
      const response = await propertyDeleteSecret(property.id, {
        name: secret.name,
      });

      if (response.errorMessage) {
        new Toast(response.errorMessage, Toast.TYPE_ERROR);
        return false;
      } else {
        new Toast(
          `Property secret "${secret.name}" was deleted.`,
          Toast.TYPE_DONE
        );
        onDelete?.();
      }
    },
  });
