import React, { useState, useCallback, useEffect } from 'react';
import styles from './styles.module.scss';

interface TrafficInputProps {
  value: number; // In bytes
  disabled?: boolean;
  valid?: boolean;
  onChange?: (newValue: number) => any;
}

const bytesToInputValue = (bytes: number, trafficUnitBytes: number) =>
  (bytes / trafficUnitBytes).toFixed(3).replace(/\.?0+?$/, '');

const TrafficInput = ({
  value,
  onChange,
  disabled,
  valid,
}: TrafficInputProps) => {
  const trafficThresholdUnitDefault = value > 1024 * 1024 * 1024 ? 'GB' : 'MB';
  const [trafficThresholdUnit, setTrafficThresholdUnit] = useState(
    trafficThresholdUnitDefault
  );
  const trafficUnitBytes =
    (trafficThresholdUnit === 'GB' ? 1024 : 1) * 1024 * 1024;
  const [inputValue, setInputValue] = useState(
    bytesToInputValue(value, trafficUnitBytes)
  );
  const onTrafficThresholdUnitChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setTrafficThresholdUnit(event.currentTarget.value);
    },
    []
  );
  const onTrafficThresholdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let nominalValue = +event.currentTarget.value;
      setInputValue(event.currentTarget.value);
      if (!isNaN(nominalValue) && isFinite(nominalValue) && onChange) {
        onChange(Math.floor(nominalValue * trafficUnitBytes));
      }
    },
    [trafficUnitBytes, onChange]
  );

  // Prioritizes given value in props over the current input.
  useEffect(() => {
    if (!isNaN(+value) && isFinite(+value)) {
      setInputValue(bytesToInputValue(value, trafficUnitBytes));
    }
  }, [trafficUnitBytes, value]);

  const displayedValue =
    !isNaN(+inputValue) && isFinite(+inputValue)
      ? inputValue
      : bytesToInputValue(value, trafficUnitBytes);
  return (
    <div className={styles.container}>
      <input
        type="number"
        value={displayedValue}
        onChange={onTrafficThresholdChange}
        disabled={!!disabled}
        className={valid === false ? 'invalid' : ''}
      />
      <select
        value={trafficThresholdUnit}
        disabled={!!disabled}
        onChange={onTrafficThresholdUnitChange}
      >
        <option value="MB">MB</option>
        <option value="GB">GB</option>
      </select>
    </div>
  );
};

export default TrafficInput;
