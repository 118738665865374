import {
  AutomationPipelineStepDataCreateProxyEndpoint,
  AutomationPipelineStepId,
} from '@dataunlocker/pkg-types';
import MessageBlock from 'Components/Common/MessageBlock';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import { PipelineStepCommonProps } from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import {
  LINK_DOCS_AUTOMATION_REFRESH_CREATE_PROXY_ENDPOINT,
  PRODUCT_NAME,
} from 'Constants';
import React, { useMemo } from 'react';
import { getDDnsDomainForDuKey } from 'Utils';
import PipelineStepWebhook from '../../PipelineStepWebhook';

const PipelineStepWebhookCreateProxyEndpoint = (
  props: PipelineStepCommonProps
) => {
  const potentialEndpointType: 'dns' | 'path' =
    (props.pipeline.steps.find(
      (s) => s.id === AutomationPipelineStepId.CreateProxyEndpoint
    )?.data as AutomationPipelineStepDataCreateProxyEndpoint)
      ?.proxyEndpointType || 'dns';
  const latestProxyEndpoint =
    props.property.proxyEndpoints.find(
      (e) => e.urlKey === props.pipeline.context.newProxyEndpointUrlKey
    ) ||
    props.property.proxyEndpoints[props.property.proxyEndpoints.length - 1];
  const variables = useMemo(
    () => [
      {
        name: 'HOSTNAME',
        example: `${potentialEndpointType === 'dns' ? `random-hash.` : ''}${
          props.property.host
        }`,
        required: potentialEndpointType === 'dns',
        description: `A new domain name which ${PRODUCT_NAME} asks to create in case when DNS proxy endpoint is created.`,
      },
      {
        name: 'TYPE',
        example: 'path',
        description:
          'Either "dns" for DNS proxy endpoints or "path" for path-based proxy endpoints.',
      },
      {
        name: 'URL_KEY',
        required: true,
        example: latestProxyEndpoint.urlKey,
        description: `New proxy endpoint's URL key (a random part of the subdomain in case of DNS proxy endpoint and a random URL path part in case of a PATH proxy endpoint).`,
      },
      {
        name: 'PATH',
        required: potentialEndpointType === 'path',
        example: `${
          potentialEndpointType === 'path'
            ? `/${latestProxyEndpoint.urlKey}`
            : '/'
        }`,
        description: `A proxy path on the ${props.property.host} ${PRODUCT_NAME} asks to create in case when PATH proxy endpoint is used. For path-based proxy endpoints, this variable equals {{URL_KEY}} with a slash in front.`,
      },
      {
        name: 'TARGET_HOST',
        example: `${getDDnsDomainForDuKey(latestProxyEndpoint.duKey)}`,
        required: true,
        description:
          potentialEndpointType === 'dns' ? (
            <span>
              The value of a new DNS record. <b>Do not validate this value</b>{' '}
              when handling the webhook, as the domain pattern used by{' '}
              {PRODUCT_NAME} may change to keep circumventing filtering lists.
            </span>
          ) : (
            <span>
              The hostname your reverse proxy server must forward traffic to.
            </span>
          ),
      },
      {
        name: 'DNS_RECORD_NAME',
        example: `${
          potentialEndpointType === 'dns'
            ? `${latestProxyEndpoint.urlKey}.${props.property.host.replace(
                /\.?[^.]+\.[^.]+$/,
                ''
              )}`.replace(/\.$/, '')
            : '<empty string for path proxy endpoints>'
        }`,
        required: potentialEndpointType === 'dns',
        description: `Same as {{HOSTNAME}} but excluding the base domain name (${
          props.property.host.match(/[^.]+\.[^.]+$/)?.[0] || props.property.host
        }). Can be used to configure a DNS record.`,
      },
    ],
    [
      potentialEndpointType,
      props.property.host,
      latestProxyEndpoint.urlKey,
      latestProxyEndpoint.duKey,
    ]
  );

  return (
    <PipelineStepWebhook
      initialUrl="https://my-api.com/handle?newEndpoint={{URL_KEY}}"
      icon="split"
      title="Send webhook to configure a new proxy endpoint"
      variables={variables}
      {...props}
    >
      <MessageBlock display="inline">
        Implement an API endpoint which, when requested by {PRODUCT_NAME}, will{' '}
        {potentialEndpointType === 'dns'
          ? `add a new dns record to ${props.property.host}`
          : `create a new path prefix on ${props.property.host} pointing to ${PRODUCT_NAME} proxy`}
        .{' '}
        <SafeExternalLink
          href={LINK_DOCS_AUTOMATION_REFRESH_CREATE_PROXY_ENDPOINT}
        >
          Learn more
        </SafeExternalLink>{' '}
        about implementing this webhook.
      </MessageBlock>
    </PipelineStepWebhook>
  );
};

export default PipelineStepWebhookCreateProxyEndpoint;
