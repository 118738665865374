import {
  UiPurchase,
  UiSubscription,
  UiSubscriptionBillingMethod,
} from '@dataunlocker/pkg-types';

export const API_PATH_ADMIN_V1_PAYMENTS_PURCHASE_TRAFFIC =
  '/payments/purchase-traffic';
export interface ApiAdminV1PaymentsNewRequest {
  propertyId: string;
  cents: number;
}
export interface ApiAdminV1PaymentsNewResponse {
  purchase: UiPurchase;
}

export const API_PATH_PAYMENTS_ADD_CARD = '/payments/add-card';
export interface ApiPaymentsAddCardRequest {}
export interface ApiPaymentsAddCardResponse {
  purchase: UiPurchase;
}

export const API_PATH_ADMIN_V1_SUBSCRIPTIONS = '/subscriptions';
export interface ApiAdminV1SubscriptionsAddRequest {
  propertyId: string;
  billingPlanId: string;
  billingMethod?: Omit<UiSubscriptionBillingMethod, 'userId'>;
}
export interface ApiAdminV1SubscriptionsAddResponse {
  subscription: UiSubscription;
}

export interface ApiAdminV1SubscriptionsGetQueryParams {
  propertyId: string;
}
export interface ApiAdminV1SubscriptionsGetResponse {
  subscriptions: UiSubscription[];
}
