import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { RECAPTCHAV3_SITE_KEY } from 'Constants';
import waitFor from 'p-wait-for';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Toast } from 'toaster-js';

export const useScript = (
  url: string,
  {
    cleanup,
    startup,
  }: { cleanup?: Function; startup?: (event: Event) => unknown }
) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.defer = true;
    if (startup) {
      script.addEventListener('load', startup);
    }

    document.body.appendChild(script);

    return () => {
      if (cleanup) {
        cleanup();
      }
      if (script.parentNode) {
        try {
          script.parentNode.removeChild(script);
        } catch (e) {
          console.error(e);
        }
      }
    };
  }, [url, cleanup, startup]);
};

// Removes RecaptchaV3 from screen (after it has been used).
const removeRecaptchaV3 = () => {
  const captchaElement = document.querySelector('.grecaptcha-badge');
  let el = captchaElement?.parentNode;
  while (el?.parentNode && el?.parentNode !== document.body) {
    el = el.parentNode;
  }
  el?.parentNode?.removeChild(el);
};

export const useReCaptcha = () => {
  const getCaptchaToken = useCallback(async () => {
    const onError = (e: Error) => {
      console.error(e);
      new Toast(
        'Unable to load Google Recaptcha V3. Please try again',
        Toast.TYPE_ERROR
      );
    };
    try {
      await waitFor(() => !!window.grecaptcha, {
        timeout: 5000,
      });
    } catch (e) {
      onError(e);
      return '';
    }
    await new Promise((r) => window.grecaptcha.ready(r as () => unknown));
    try {
      return await window.grecaptcha.execute(RECAPTCHAV3_SITE_KEY, {
        action: 'submit',
      });
    } catch (e) {
      onError(e);
      return '';
    }
  }, []);
  useScript(
    'https://www.google.com/recaptcha/api.js?render=6LeRrL8ZAAAAAHmY-5kHiM5IZ3tCj5MKm75kFsWS',
    { cleanup: removeRecaptchaV3 }
  );

  return {
    getCaptchaToken,
  };
};

interface useGoogleSignInOptions {
  onSuccess: (
    res: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>
  ) => void;
  onFailure: Parameters<typeof useGoogleLogin>[0]['onNonOAuthError'];
}
export const useGoogleSignIn = ({
  onSuccess,
  onFailure,
}: useGoogleSignInOptions) => {
  const signIn = useGoogleLogin({
    onSuccess,
    onNonOAuthError: onFailure,
    ux_mode: 'popup',
    flow: 'auth-code',
  });

  return {
    signInWithGoogle: signIn,
  };
};

const findForm = (
  ref: MutableRefObject<HTMLElement>
): HTMLFormElement | null => {
  let treeObject: HTMLElement | null = ref.current;
  while (treeObject && treeObject.tagName !== 'FORM') {
    treeObject = treeObject.parentNode as HTMLElement | null;
  }
  return treeObject ? (treeObject as HTMLFormElement) : null;
};

export const setFormInvalid = (formRef: MutableRefObject<HTMLFormElement>) =>
  findForm(formRef)?.classList.add('failed-submit');
export const setFormValid = (formRef: MutableRefObject<HTMLFormElement>) =>
  findForm(formRef)?.classList.remove('failed-submit');

/* Returns whether the form is valid. If no forms found in the tree hierarchy, returns true */
export const isFormValid = (formRef: MutableRefObject<HTMLElement>) => {
  const form = findForm(formRef);
  return form ? form.checkValidity() : true;
};

export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

export const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
};
