import Card from 'Components/Common/Card';
import IconWithSubtext from 'Components/Common/IconWithSubtext';
import MessageBlock from 'Components/Common/MessageBlock';
import PageHeader from 'Components/Common/Pages/Header';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import {
  LINK_DOCS,
  LINK_GITHUB_DATAUNLOCKER,
  LINK_NIKITA_TK,
  LINK_PRIVACY_POLICY,
  LINK_TELEGRAM_CHANNEL_ANNOUNCEMENTS,
  LINK_TERMS_AND_CONDITIONS,
  LINK_YOUTUBE_CHANNEL,
  LINK_YOUTUBE_SETUP_TUTORIAL,
  PRODUCT_COPYRIGHT_YEAR,
  PRODUCT_NAME,
  PRODUCT_SUPPORT_EMAIL,
  PRODUCT_URL_FULL,
  ROUTE_FEEDBACK,
} from 'Constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'toaster-js';
import styles from './styles.module.scss';

const PageHelp = () => {
  return (
    <div>
      <PageHeader icon="info" title="Documentation and support" />
      <div className={styles.container}>
        <div className={styles.content}>
          <MessageBlock type="warning">
            {PRODUCT_NAME} is currently in beta. Please{' '}
            <Link to={ROUTE_FEEDBACK}>report</Link> any problems or friction
            points you may encounter.
          </MessageBlock>
          <Card className={styles.center}>
            <p>
              If you&apos;re looking for information, here&apos;s what we have:
            </p>
            <p className={styles.icons}>
              <UnsafeExternalLink href={LINK_GITHUB_DATAUNLOCKER}>
                <IconWithSubtext
                  image="github"
                  size="huge"
                  hoverable
                  text="GitHub"
                />
              </UnsafeExternalLink>
              <a
                href={(() => '#')()}
                onClick={() =>
                  new Toast('Coming soon. Please, use email option for now.')
                }
              >
                <IconWithSubtext
                  image="feedback"
                  size="huge"
                  hoverable
                  text="Chat"
                />
              </a>
              <UnsafeExternalLink href={`mailto:${PRODUCT_SUPPORT_EMAIL}`}>
                <IconWithSubtext
                  image="email"
                  size="huge"
                  hoverable
                  text="Email"
                />
              </UnsafeExternalLink>
              <UnsafeExternalLink href={LINK_YOUTUBE_CHANNEL}>
                <IconWithSubtext
                  image="youtube"
                  size="huge"
                  hoverable
                  text="YouTube"
                />
              </UnsafeExternalLink>
              <UnsafeExternalLink href={LINK_TELEGRAM_CHANNEL_ANNOUNCEMENTS}>
                <IconWithSubtext
                  image="bell"
                  size="huge"
                  hoverable
                  text="Announcements"
                />
              </UnsafeExternalLink>
              <SafeExternalLink href={LINK_DOCS}>
                <IconWithSubtext
                  image="book"
                  size="huge"
                  hoverable
                  text="Docs"
                />
              </SafeExternalLink>
              <UnsafeExternalLink href={LINK_YOUTUBE_SETUP_TUTORIAL}>
                <IconWithSubtext
                  image="play"
                  size="huge"
                  hoverable
                  text="Tutorial"
                />
              </UnsafeExternalLink>
              <SafeExternalLink href={LINK_TERMS_AND_CONDITIONS}>
                <IconWithSubtext
                  image="info"
                  size="huge"
                  hoverable
                  text="T&amp;C"
                />
              </SafeExternalLink>
              <SafeExternalLink href={LINK_PRIVACY_POLICY}>
                <IconWithSubtext
                  image="user"
                  size="huge"
                  hoverable
                  text="Privacy"
                />
              </SafeExternalLink>
            </p>
          </Card>
          <div className={styles.footer}>
            <div>
              © {PRODUCT_COPYRIGHT_YEAR}{' '}
              <SafeExternalLink href={PRODUCT_URL_FULL}>
                {PRODUCT_NAME}
              </SafeExternalLink>
            </div>
            <div>
              Brought to you by{' '}
              <SafeExternalLink href={LINK_NIKITA_TK}>
                Nikita Savchenko
              </SafeExternalLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHelp;
