import {
  AutomationPipelineStep,
  UiAutomationPipeline,
  UiProperty,
} from '@dataunlocker/pkg-types';
import Icon from 'Components/Common/Icon';
import MessageBlock from 'Components/Common/MessageBlock';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import Tooltip from 'Components/Common/Tooltip';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import { LINK_DOCS_AUTOMATION_WEBHOOKS } from 'Constants';
import React, { useState } from 'react';
import relativeDate from 'relative-date';
import { useInterval } from 'Utils';
import StepperStep from './StepperStep';
import styles from './styles.module.scss';

export interface PipelineStepCommonProps {
  step: AutomationPipelineStep;
  pipeline: UiAutomationPipeline;
  property: UiProperty;
  refreshPipeline: () => Promise<unknown>;
}

interface Props extends PipelineStepCommonProps {
  icon: string;
  title: string;
  children?: React.ReactNode;
  configured?: boolean; // Visually highlight that this pipeline step needs to be configured.
}

const PipelineStep = ({
  icon,
  step,
  title,
  configured = true,
  children,
  pipeline,
}: Props) => {
  const now = Date.now();
  const isDone = !!step.endedAt && step.endedAt < now;
  const stepIndex = pipeline.steps.findIndex((s) => s.id === step.id);
  const isLastStep = pipeline.steps[pipeline.steps.length - 1] === step;
  const inProgress = !!step.startedAt && !step.endedAt;
  const timePassed = Math.round(
    ((step.endedAt || now) - (step.startedAt || now)) / 1000
  );
  const timePassedSeconds = timePassed % 60;
  const timePassedMinutes = ((timePassed - timePassedSeconds) / 60) % 60;
  const timePassedHours =
    (timePassed - timePassedSeconds - timePassedMinutes * 60) / 60 / 60;
  const [expanded, setExpanded] = useState(false);
  const [, setTick] = useState(0);
  const nextRunAtString: string = step.nextRunAt
    ? relativeDate(Date.now() * 2 - step.nextRunAt).replace(/\s?ago/, '')
    : '';

  useInterval(() => {
    if (inProgress) {
      setTick(Math.random());
    }
  }, 1000);

  return (
    <div className={styles.pipelineStep}>
      <div>
        <div>
          {[
            `${timePassedHours}h`,
            `${timePassedMinutes}m`,
            `${timePassedSeconds}s`,
          ]
            .filter((s) => s[0] !== '0')
            .join(' ') ||
            (isDone || inProgress ? (
              '0s'
            ) : step.id.includes('webhook') ? (
              <Tooltip
                content={
                  <span>
                    You can access this step&apos;s results in other webhooks by
                    using JavaScript-like object access syntax for variable{' '}
                    <code>pipeline</code>:{' '}
                    <code>{`{{pipeline.steps[${stepIndex}].response}}`}</code>.
                    For instance, if the webhook JSON response is{' '}
                    <code>{`{"count": 42}`}</code>, you can interpolate the
                    value <code>42</code> to the context with{' '}
                    <code>{`{{pipeline.steps[${stepIndex}].response.body.count}}`}</code>
                    . You can&apos;t use any other JavaScript features in
                    webhooks just yet; prefer using services like{' '}
                    <UnsafeExternalLink href="https://zapier.com/">
                      Zapier
                    </UnsafeExternalLink>
                    ,{' '}
                    <UnsafeExternalLink href="https://pipedream.com/">
                      Pipedream
                    </UnsafeExternalLink>{' '}
                    or your own backend for implementing a complex custom logic.
                    <br />
                    <br />
                    Read more about{' '}
                    <SafeExternalLink href={LINK_DOCS_AUTOMATION_WEBHOOKS}>
                      webhooks and variables
                    </SafeExternalLink>
                    .
                  </span>
                }
              >
                Step #{stepIndex}
              </Tooltip>
            ) : (
              ''
            ))}
        </div>
      </div>
      <StepperStep
        icon={icon}
        inProgress={inProgress}
        done={isDone}
        isLast={isLastStep}
      />
      <div className={`${styles.card}${expanded ? ` ${styles.expanded}` : ''}`}>
        <div
          className={`${styles.head}${expanded ? ` ${styles.expanded}` : ''}`}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={styles.displayedUrl}>{title}</div>
          <div className={styles.flexGrow}></div>
          <div
            className={`${styles.statusBox} ${
              !configured
                ? styles.orange
                : step.result === 'failed'
                ? styles.red
                : step.result === 'skipped'
                ? ''
                : isDone
                ? styles.green
                : ''
            }`}
          >
            <Icon
              image={
                !configured
                  ? 'warning'
                  : step.result === 'failed'
                  ? 'error'
                  : step.result === 'skipped'
                  ? 'close'
                  : isDone
                  ? 'checkmark'
                  : inProgress
                  ? 'clock'
                  : 'play'
              }
              margin="right-small"
            />
            <span style={{ lineHeight: 1 }}>
              {!configured
                ? 'Changes required'
                : step.result === 'failed'
                ? 'Failed'
                : step.result === 'skipped'
                ? 'Skipped'
                : isDone
                ? 'Done'
                : inProgress
                ? 'Pending'
                : 'Queued'}
            </span>
          </div>
        </div>
        <div
          className={`${styles.body}${expanded ? ` ${styles.expanded}` : ''}`}
        >
          <div className={styles.bodyContainer}>
            {step.resultReason && (
              <MessageBlock
                type={
                  step.result === 'failed'
                    ? 'error'
                    : step.result === 'done'
                    ? 'success'
                    : 'info'
                }
                display="inline"
              >
                {step.resultReason}
              </MessageBlock>
            )}
            {step.nextRunAt && !step.endedAt && (
              <MessageBlock type="info" display="inline">
                Next run will be performed{' '}
                {nextRunAtString.includes('now') ? '' : 'in '}
                {nextRunAtString}
              </MessageBlock>
            )}
            {children || 'This pipeline step has no body.'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineStep;
