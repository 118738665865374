import React from 'react';
import Icon from 'Components/Common/Icon';
import styles from './styles.module.scss';

interface PageErrorProps {
  message?: string;
  icon?: string;
  color?: 'regular' | 'red';
  actionText?: string;
  onActionClick?: () => unknown;
}

const PageError = ({
  message,
  icon,
  actionText,
  color,
  onActionClick,
}: PageErrorProps = {}) => {
  if (!color) {
    color = 'red';
  }

  return (
    <div className={`${styles.container} ${styles[`color-${color}`]}`}>
      <div>
        {message && onActionClick && (
          <div className={styles.errorMessageContainer}>{message}</div>
        )}
        <div className={styles.iconContainer}>
          <Icon image={icon || 'error'} size="ultra-giant" />
        </div>
        {message && !onActionClick && (
          <div className={styles.errorMessageContainer}>{message}</div>
        )}
        {onActionClick && (
          <button onClick={onActionClick}>{actionText || 'OK'}</button>
        )}
      </div>
    </div>
  );
};

export default PageError;
