import {
  AutomationPipelineStepDataCreateProxyEndpoint,
  AutomationPipelineStepId,
  typeGuard,
} from '@dataunlocker/pkg-types';
import MessageBlock from 'Components/Common/MessageBlock';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import { PipelineStepCommonProps } from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import {
  LINK_DOCS_AUTOMATION_REFRESH_DELETE_PROXY_ENDPOINT,
  PRODUCT_NAME,
} from 'Constants';
import React, { useMemo } from 'react';
import PipelineStepWebhook from '../../PipelineStepWebhook';

const PipelineStepWebhookDeleteProxyEndpoint = (
  props: PipelineStepCommonProps
) => {
  const potentialEndpointType: 'dns' | 'path' =
    typeGuard<AutomationPipelineStepDataCreateProxyEndpoint | undefined>(
      props.pipeline.steps.find(
        (s) => s.id === AutomationPipelineStepId.CreateProxyEndpoint
      )?.data
    )?.proxyEndpointType || 'dns';
  const oldProxyEndpoint =
    props.property.proxyEndpoints.find(
      (e) => props.pipeline.context.oldProxyEndpointUrlKey === e.urlKey
    ) || props.property.proxyEndpoints[0];

  const variables = useMemo(
    () => [
      {
        name: 'HOSTNAME',
        example: `${oldProxyEndpoint.type === 'dns' ? 'random-hash.' : ''}${
          props.property.host
        }`,
        required: oldProxyEndpoint.type === 'dns',
        description: `The domain name which ${PRODUCT_NAME} asks to delete in case when it was a DNS proxy endpoint type.`,
      },
      {
        name: 'PATH',
        required: oldProxyEndpoint.type === 'path',
        example: `${
          oldProxyEndpoint.type === 'path' ? `/${oldProxyEndpoint.urlKey}` : '/'
        }`,
        description: `A proxy path on the ${props.property.host} ${PRODUCT_NAME} asks to delete in case when PATH proxy endpoint is used.`,
      },
      {
        name: 'URL_KEY',
        example: oldProxyEndpoint.urlKey,
        description: `The old proxy endpoint's URL key (a random part of domain in case of DNS proxy endpoint and a random URL path part in case of PATH proxy endpoint).`,
      },
      {
        name: 'TYPE',
        example: oldProxyEndpoint.type,
        description:
          'Either "dns" for DNS proxy endpoints or "path" for path-based proxy endpoints.',
      },
      {
        name: 'DNS_RECORD_NAME',
        example: `${
          potentialEndpointType === 'dns'
            ? `${oldProxyEndpoint.urlKey}.${props.property.host.replace(
                /\.?[^.]+\.[^.]+$/,
                ''
              )}`.replace(/\.$/, '')
            : '<empty string for path proxy endpoints>'
        }`,
        description: `Same as {{HOSTNAME}} but excluding the base domain name (${
          props.property.host.match(/[^.]+\.[^.]+$/)?.[0] || props.property.host
        }). Can be used to delete a DNS record.`,
      },
    ],
    [
      oldProxyEndpoint.urlKey,
      oldProxyEndpoint.type,
      props.property.host,
      potentialEndpointType,
    ]
  );

  return (
    <PipelineStepWebhook
      initialUrl="https://my-api.com/handle?oldEndpoint={{URL_KEY}}"
      icon="split"
      title="Send webhook to cleanup the old proxy endpoint"
      variables={variables}
      {...props}
    >
      <MessageBlock display="inline">
        Implement an API endpoint which will invalidate the old proxy endpoint{' '}
        {potentialEndpointType === 'path'
          ? `by deleting route of the old proxy endpoint used on ${props.property.host}`
          : `by deleting the DNS record of the old proxy endpoint`}
        .{' '}
        <SafeExternalLink
          href={LINK_DOCS_AUTOMATION_REFRESH_DELETE_PROXY_ENDPOINT}
        >
          Learn more
        </SafeExternalLink>{' '}
        about implementing this webhook.
      </MessageBlock>
    </PipelineStepWebhook>
  );
};

export default PipelineStepWebhookDeleteProxyEndpoint;
