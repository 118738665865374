import { PRODUCT_URL_FULL } from './general';

export const LINK_PRIVACY_POLICY = `${PRODUCT_URL_FULL}/privacy/`;
export const LINK_TERMS_AND_CONDITIONS = `${PRODUCT_URL_FULL}/terms/`;
export const LINK_GITHUB_DATAUNLOCKER = 'https://github.com/dataunlocker';
export const LINK_GITHUB_DATAUNLOCKER_EXAMPLE_NODNS_PROXY_NODEJS =
  'https://github.com/dataunlocker/example-nodns-proxy-nodejs';
export const LINK_TELEGRAM_CHANNEL_ANNOUNCEMENTS = 'https://t.me/dataunlocker';
export const LINK_DOCS = `${PRODUCT_URL_FULL}/docs/`;
export const LINK_ADGUARD = 'https://adguard.com/';
export const LINK_DOCS_FAQ = `${PRODUCT_URL_FULL}/docs/faq/`;
export const LINK_DOCS_AUTOMATION_CONCEPTS = `${PRODUCT_URL_FULL}/docs/automation/concepts/`;
export const LINK_DOCS_AUTOMATION_SECRETS = `${PRODUCT_URL_FULL}/docs/automation/secrets/`;
export const LINK_DOCS_AUTOMATION_REFRESH_CREATE_PROXY_ENDPOINT = `${PRODUCT_URL_FULL}/docs/automation/refresh/create-proxy-endpoint/`;
export const LINK_DOCS_AUTOMATION_REFRESH_DELETE_PROXY_ENDPOINT = `${PRODUCT_URL_FULL}/docs/automation/refresh/delete-proxy-endpoint/`;
export const LINK_DOCS_AUTOMATION_REFRESH_SCRIPT_UPDATE = `${PRODUCT_URL_FULL}/docs/automation/refresh/script-update/`;
export const LINK_DOCS_AUTOMATION_REFRESH_OVERVIEW = `${PRODUCT_URL_FULL}/docs/automation/refresh/overview/`;
export const LINK_DOCS_AUTOMATION_WEBHOOKS = `${PRODUCT_URL_FULL}/docs/automation/webhooks/`;
export const LINK_DOCS_SCRIPT_DYNAMIC = `${PRODUCT_URL_FULL}/docs/install/script/dynamic/`;
export const LINK_DOCS_SCRIPT_STATIC = `${PRODUCT_URL_FULL}/docs/install/script/static/`;
export const LINK_DOCS_SCRIPT_TESTING = `${PRODUCT_URL_FULL}/docs/install/script/testing/`;
export const LINK_DOCS_CONFIG_PROXY = `${PRODUCT_URL_FULL}/docs/config/proxy/`;
export const LINK_DOCS_CONFIG_USERS = `${PRODUCT_URL_FULL}/docs/config/users/`;
export const LINK_DOCS_INSTALL_ROUTING = `${PRODUCT_URL_FULL}/docs/install/routing/`;
export const LINK_DOCS_INSTALL_ROUTING_PATH = `${PRODUCT_URL_FULL}/docs/install/routing/#setting-up-a-path-based-proxy-endpoint`;
export const LINK_DOCS_HEALTH_CHECK = `${PRODUCT_URL_FULL}/docs/install/health-check/`;
export const LINK_DOCS_MEASURING_IMPACT = `${PRODUCT_URL_FULL}/docs/measuring-impact/tools/`;
export const LINK_DOCS_MEASURING_IMPACT_UA = `${PRODUCT_URL_FULL}/docs/measuring-impact/universal-analytics/`;
export const LINK_DOCS_MEASURING_IMPACT_GA = `${PRODUCT_URL_FULL}/docs/measuring-impact/google-analytics/`;
export const LINK_DOCS_BILLING_TRAFFIC = `${PRODUCT_URL_FULL}/docs/billing/traffic/`;
export const LINK_DOCS_BILLING_ONE_TIME_PURCHASES = `${PRODUCT_URL_FULL}/docs/billing/one-time-purchases/`;
export const LINK_DOCS_BILLING_AUTOMATIC_PURCHASES = `${PRODUCT_URL_FULL}/docs/billing/automatic-purchases/`;
export const LINK_CLOUDFLARE_DNS_PROXY =
  'https://support.cloudflare.com/hc/en-us/articles/200169626-Identifying-subdomains-compatible-with-Cloudflare-s-proxy';
export const LINK_CLOUDFLARE_WORKERS = 'https://workers.cloudflare.com/';
export const LINK_MXTOOLBOX_CNAME_LOOKUP_FOR = (domain: string) =>
  `https://mxtoolbox.com/SuperTool.aspx?action=cname%3a${encodeURIComponent(
    domain
  )}&run=toolpage`;
export const LINK_YOUTUBE_SETUP_TUTORIAL = 'https://youtu.be/oMzxB4qoivA';
export const LINK_YOUTUBE_CHANNEL =
  'https://www.youtube.com/channel/UCR0N74pYLQ7UYoDqIpUl7jQ';
export const LINK_NIKITA_TK = 'https://nikita.tk';
export const LINK_EASYLIST_TO = 'https://easylist.to';

// Help page about how to CONFIGURE/CREATE a custom dimension in Google Analytics.
export const LINK_GOOGLE_ANALYTICS_CUSTOM_DIMENSIONS =
  'https://support.google.com/analytics/answer/2709829?hl=en';
export const LINK_GOOGLE_TAG_MANAGER =
  'https://marketingplatform.google.com/about/tag-manager/';
