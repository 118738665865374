import {
  ApiAutomationAbortResponse,
  ApiAutomationCreateRequest,
  ApiAutomationCreateResponse,
  ApiAutomationPipelineUpdateRequest,
  ApiAutomationPipelineUpdateResponse,
  ApiAutomationStartRequest,
  ApiAutomationStartResponse,
  API_PATH_AUTOMATION_CREATE,
  API_PATH_AUTOMATION_PIPELINE_ABORT,
  API_PATH_AUTOMATION_PIPELINE_UPDATE,
  API_PATH_AUTOMATION_START,
} from 'Constants';
import { apiRequest } from 'Utils';

export const requestAutomationPipelineCreate = async (
  request: ApiAutomationCreateRequest
) => {
  return await apiRequest<
    ApiAutomationCreateRequest,
    ApiAutomationCreateResponse
  >(API_PATH_AUTOMATION_CREATE, request);
};

export const requestAutomationPipelineUpdate = async (
  pipelineId: string,
  request: ApiAutomationPipelineUpdateRequest
) => {
  return await apiRequest<
    ApiAutomationPipelineUpdateRequest,
    ApiAutomationPipelineUpdateResponse
  >(API_PATH_AUTOMATION_PIPELINE_UPDATE(pipelineId), request);
};

export const requestAutomationPipelineStart = async (
  request: ApiAutomationStartRequest
) => {
  return await apiRequest<
    ApiAutomationStartRequest,
    ApiAutomationStartResponse
  >(API_PATH_AUTOMATION_START, request);
};

export const requestAutomationPipelineAbort = async (pipelineId: string) => {
  return await apiRequest<{}, ApiAutomationAbortResponse>(
    API_PATH_AUTOMATION_PIPELINE_ABORT(pipelineId),
    {}
  );
};
