const currentYear = new Date().getFullYear();

export const PRODUCT_SUPPORT_EMAIL = 'support@dataunlocker.com';
export const PRODUCT_BASE_DOMAIN =
  process.env.REACT_APP_PRODUCT_BASE_DOMAIN || 'dataunlocker.com';
export const PRODUCT_URL_FULL = `https://${PRODUCT_BASE_DOMAIN}`;
export const PRODUCT_API_URL = `https://api.${PRODUCT_BASE_DOMAIN}`;
export const PRODUCT_STATUS_URL = `https://status.${PRODUCT_BASE_DOMAIN}`;
export const PRODUCT_NAME = 'DataUnlocker';
export const PRODUCT_COPYRIGHT_YEAR = `2020${
  currentYear > 2020 ? `-${currentYear}` : ''
}`;
