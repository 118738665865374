import { apiRequest } from 'Utils';
import {
  API_PATH_ADMIN_V1_LEAVE_FEEDBACK,
  ApiAdminV1LeaveFeedbackRequest,
} from 'Constants';

export const requestLeaveFeedback = async (
  data: ApiAdminV1LeaveFeedbackRequest
) => {
  return await apiRequest<ApiAdminV1LeaveFeedbackRequest, void>(
    API_PATH_ADMIN_V1_LEAVE_FEEDBACK,
    data
  );
};
