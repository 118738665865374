import {
  AutomationPipelineStatus,
  AutomationPipelineStepDataCreateProxyEndpoint,
  PropertyProxyEndpointType,
} from '@dataunlocker/pkg-types';
import { requestAutomationPipelineUpdate } from 'Api';
import Button from 'Components/Common/Button';
import MessageBlock from 'Components/Common/MessageBlock';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import SelectInput from 'Components/Common/SelectInput';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import {
  LINK_DOCS_INSTALL_ROUTING,
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
} from 'Constants';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'toaster-js';
import { getProxyEndpointUrl } from 'Utils';

const PROXY_ENDPOINT_TYPES: {
  label: string;
  value: PropertyProxyEndpointType;
}[] = [
  {
    label: 'DNS record',
    value: 'dns',
  },
  {
    label: 'Path prefix',
    value: 'path',
  },
];

const PipelineStepCreateProxyEndpoint = (props: PipelineStepCommonProps) => {
  const { refreshPipeline } = props;
  const proxyEndpoint = props.property.proxyEndpoints.find(
    (e) => props.pipeline.context.newProxyEndpointUrlKey === e.urlKey
  );
  const pipelineStepIndex = props.pipeline.steps.findIndex(
    (s) => s === props.step
  );
  const stepData: AutomationPipelineStepDataCreateProxyEndpoint =
    props.step.data || {};
  const initialProxyEndpointType =
    stepData.proxyEndpointType || PROXY_ENDPOINT_TYPES[0].value;
  const [proxyEndpointType, setProxyEndpointType] = useState(
    initialProxyEndpointType
  );
  const [isLoading, setIsLoading] = useState(false);
  const onCancelClick = useCallback(() => {
    setProxyEndpointType(initialProxyEndpointType);
  }, [initialProxyEndpointType]);
  const onSaveClick = useCallback(async () => {
    setIsLoading(true);
    const data: AutomationPipelineStepDataCreateProxyEndpoint = {
      proxyEndpointType,
    };
    const response = await requestAutomationPipelineUpdate(props.pipeline.id, {
      updates: {
        steps: {
          [pipelineStepIndex]: {
            data,
          },
        },
      },
    });
    if (response.errorMessage) {
      new Toast(response.errorMessage, Toast.TYPE_ERROR);
    } else {
      await refreshPipeline();
    }
    setIsLoading(false);
  }, [
    proxyEndpointType,
    refreshPipeline,
    props.pipeline.id,
    pipelineStepIndex,
  ]);

  const hasChanges = initialProxyEndpointType !== proxyEndpointType;

  return (
    <PipelineStep icon="split" title="Create a new proxy endpoint" {...props}>
      <div>
        {proxyEndpoint ? (
          <MessageBlock display="inline">
            Proxy endpoint created:{' '}
            <Link to={ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING(props.property.id)}>
              {getProxyEndpointUrl(proxyEndpoint, props.property)}
            </Link>
          </MessageBlock>
        ) : props.step.startedAt ? (
          <MessageBlock display="inline">
            Creating a new proxy endpoint...
          </MessageBlock>
        ) : (
          <MessageBlock display="inline">
            This step will create a new proxy endpoint.{' '}
            <SafeExternalLink href={LINK_DOCS_INSTALL_ROUTING}>
              Learn more
            </SafeExternalLink>{' '}
            about proxy endpoint types.
          </MessageBlock>
        )}
      </div>
      <h3
        style={{
          marginTop: 'var(--spacing-big)',
          marginBottom: 'var(--spacing-regular)',
        }}
      >
        New proxy endpoint configuration
      </h3>
      <SelectInput
        disabled={props.pipeline.status !== AutomationPipelineStatus.New}
        label="Proxy endpoint type to be created"
        wide
        value={proxyEndpointType}
        options={PROXY_ENDPOINT_TYPES}
        onChange={setProxyEndpointType}
      ></SelectInput>
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          disabled={!hasChanges || isLoading}
          onClick={onSaveClick}
        >
          Save
        </Button>
        <Button
          type="secondary"
          disabled={!hasChanges || isLoading}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </div>
    </PipelineStep>
  );
};

export default PipelineStepCreateProxyEndpoint;
