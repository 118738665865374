import { UiProperty, UiTempProperty, UiUser } from '@dataunlocker/pkg-types';
import HorizontalStepper from 'Components/Common/HorizontalStepper';
import PageHeader from 'Components/Common/Pages/Header';
import {
  ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH,
  ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
  ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT,
} from 'Constants';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import PropertySetupHealth from './Health';
import PropertySetupRouting from './Routing';
import PropertySetupScript from './Script';
import styles from './styles.module.scss';

interface ComponentProps {
  property: UiProperty | UiTempProperty;
  user: UiUser;
}

const STEPPER_STEPS = [
  {
    label: 'Proxy',
    title: (property: UiTempProperty) => `${property.host} • Proxy endpoints`,
    icon: 'split',
    suffix: '/routing',
    urlGetter: ROUTE_PROPERTY_SETTINGS_SETUP_ROUTING,
  },
  {
    label: 'Script',
    title: (property: UiTempProperty) => `${property.host} • Script setup`,
    icon: 'code',
    suffix: '/script',
    urlGetter: ROUTE_PROPERTY_SETTINGS_SETUP_SCRIPT,
  },
  {
    label: 'Health',
    title: (property: UiTempProperty) => `${property.host} • Health check`,
    icon: 'pulse',
    suffix: '/health-check',
    urlGetter: ROUTE_PROPERTY_SETTINGS_SETUP_HEALTH,
  },
];

const PageSettingsSetup = ({ property, user }: ComponentProps) => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname.replace(/\/$/, '');

  const currentStepIndex = STEPPER_STEPS.findIndex(({ suffix }) =>
    pathname.endsWith(suffix)
  );
  const currentStep = STEPPER_STEPS[currentStepIndex];
  const onCurrentStepChange = useCallback(
    (_, i) => {
      history.push(STEPPER_STEPS[i].urlGetter(property.id));
    },
    [history, property.id]
  );

  return (
    <div>
      <PageHeader icon={currentStep.icon} title={currentStep.title(property)} />
      <div className={styles.setupStepper}>
        <HorizontalStepper
          currentStep={currentStepIndex}
          onStepSelect={onCurrentStepChange}
          steps={STEPPER_STEPS}
        ></HorizontalStepper>
      </div>
      <div className={styles.hiddenOverflow}>
        <div className={styles.contentContainer}>
          <div
            style={{
              left: `${-100 * currentStepIndex}%`,
            }}
          >
            <div className={currentStepIndex === 0 ? styles.selected : ''}>
              <PropertySetupRouting property={property} user={user} />
            </div>
            <div className={currentStepIndex === 1 ? styles.selected : ''}>
              <PropertySetupScript property={property} />
            </div>
            <div className={currentStepIndex === 2 ? styles.selected : ''}>
              <PropertySetupHealth property={property} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSettingsSetup;
