import React from 'react';
import styles from './styles.module.scss';

interface TableProps {
  children: React.ReactNode;
  compact?: boolean;
  fullWidth?: boolean;
  className?: string;
}

const Table = ({
  children = [],
  className = '',
  fullWidth = false,
  compact = false,
}: TableProps) => {
  return (
    <div
      className={`${styles.container}${compact ? ` ${styles.compact}` : ''}`}
    >
      <table
        className={className}
        style={{ width: fullWidth ? '100%' : 'auto' }}
      >
        {children}
      </table>
    </div>
  );
};

export default Table;
