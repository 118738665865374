import {
  ApiAdminV1PaymentsNewRequest,
  ApiAdminV1PaymentsNewResponse,
  ApiAdminV1SubscriptionsAddRequest,
  ApiAdminV1SubscriptionsAddResponse,
  ApiAdminV1SubscriptionsGetQueryParams,
  ApiAdminV1SubscriptionsGetResponse,
  ApiPaymentsAddCardRequest,
  ApiPaymentsAddCardResponse,
  API_PATH_ADMIN_V1_PAYMENTS_PURCHASE_TRAFFIC,
  API_PATH_ADMIN_V1_SUBSCRIPTIONS,
  API_PATH_PAYMENTS_ADD_CARD,
} from 'Constants';
import { apiRequest, toQueryString } from 'Utils';

export const requestOneTimePurchase = async (
  data: ApiAdminV1PaymentsNewRequest
) => {
  return await apiRequest<
    ApiAdminV1PaymentsNewRequest,
    ApiAdminV1PaymentsNewResponse
  >(API_PATH_ADMIN_V1_PAYMENTS_PURCHASE_TRAFFIC, data);
};

export const requestAddCard = async (data?: ApiPaymentsAddCardRequest) => {
  return await apiRequest<
    ApiPaymentsAddCardRequest,
    ApiPaymentsAddCardResponse
  >(API_PATH_PAYMENTS_ADD_CARD, data || {});
};

export const requestAddSubscription = async (
  data: ApiAdminV1SubscriptionsAddRequest
) => {
  return await apiRequest<
    ApiAdminV1SubscriptionsAddRequest,
    ApiAdminV1SubscriptionsAddResponse
  >(API_PATH_ADMIN_V1_SUBSCRIPTIONS, data);
};

export const requestGetSubscriptions = async (
  query: ApiAdminV1SubscriptionsGetQueryParams
) => {
  return await apiRequest<void, ApiAdminV1SubscriptionsGetResponse>(
    `${API_PATH_ADMIN_V1_SUBSCRIPTIONS}?${toQueryString(query)}`
  );
};
