import { UiPurchase, PurchaseState } from '@dataunlocker/pkg-types';

export const getPurchaseStateName = (purchase: UiPurchase) =>
  purchase.type === 'auth' && purchase.state === PurchaseState.failed
    ? 'Declined'
    : purchase.type === 'auth' && purchase.state === PurchaseState.paid
    ? 'Approved'
    : purchase.state === PurchaseState.new
    ? 'New'
    : purchase.state === PurchaseState.pending &&
      purchase.expiresAt >= Date.now()
    ? 'Awaiting payment'
    : purchase.state === PurchaseState.failed
    ? 'Failed'
    : purchase.state === PurchaseState.refunded
    ? 'Refunded'
    : purchase.state === PurchaseState.reversed
    ? 'Reversed'
    : purchase.state === PurchaseState.paid
    ? 'Paid'
    : purchase.expiresAt < Date.now()
    ? 'Expired'
    : 'Unknown';
