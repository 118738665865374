import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import PageHeader from 'Components/Common/Pages/Header';
import PurchasesListCard from 'Components/Common/PurchasesListCard';
import { showAddCardDialog } from 'Components/Dialog/AddBankCard';
import { showDeleteCardDialog } from 'Components/Dialog/DeleteBankCard';
import React, { useCallback } from 'react';
import {
  currentUserState,
  useRecoilValueLoadable,
  userVersionState,
  useSetRecoilState,
} from 'State';
import styles from './styles.module.scss';

const PageSettingsPaymentMethods = () => {
  const userLoadable = useRecoilValueLoadable(currentUserState);
  const setUserVersion = useSetRecoilState(userVersionState);

  const user = userLoadable.state === 'hasValue' ? userLoadable.contents : null;
  const cardsArray = Object.values(user?.cards || {});

  const onCardDeleted = useCallback(() => {
    setUserVersion(Math.random());
  }, [setUserVersion]);

  return (
    <div className={styles.container}>
      <PageHeader icon="settings" title={`My payment methods`} />
      <Card>
        <CardHeader title="Saved cards" />
        <table className={styles.savedCardsTable}>
          <thead>
            <tr>
              <th>Card number</th>
              <th>Card type</th>
              <th>Saved on</th>
              <th>Expires on</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cardsArray.length === 0 && (
              <tr>
                <td colSpan={5} className={styles.minorCenterText}>
                  You have no saved cards.
                </td>
              </tr>
            )}
            {cardsArray.map((card) => (
              <tr key={card.number}>
                <td>{card.number}</td>
                <td>{card.type}</td>
                <td>{new Date(card.addedAt).toLocaleDateString()}</td>
                <td>
                  {new Date(
                    card.expiresAt - 23.5 * 60 * 60 * 1000
                  ).toLocaleDateString()}
                </td>
                <td>
                  <Icon
                    image="trash"
                    hoverable
                    onClick={() =>
                      showDeleteCardDialog(card.number, onCardDeleted)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.alignRight}>
          <button onClick={showAddCardDialog}>Add a new card</button>
        </div>
      </Card>
      <PurchasesListCard title={'My transactions'} />
    </div>
  );
};

export default PageSettingsPaymentMethods;
