import { UiTempProperty } from '@dataunlocker/pkg-types';
import { propertyUpdateSecret } from 'Api';
import TextInput from 'Components/Common/TextInput';
import { showDialog } from 'Components/Dialog';
import { REGEXP_SECRET_NAME, REGEXP_SECRET_VALUE } from 'Constants';
import React, { useCallback, useState } from 'react';
import { Toast } from 'toaster-js';

interface Props {
  property: UiTempProperty;
  onCreate?: () => unknown;
}

const CreatePropertySecret = ({
  onNameUpdate,
  onValueUpdate,
}: {
  onNameUpdate: (v: string) => unknown;
  onValueUpdate: (v: string) => unknown;
}) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const valueChange = useCallback(
    (v: string) => {
      setValue(v);
      onValueUpdate(v);
    },
    [onValueUpdate]
  );
  const nameChange = useCallback(
    (v: string) => {
      setName(v);
      onNameUpdate(v);
    },
    [onNameUpdate]
  );

  return (
    <div>
      <p>
        Provide a name and a value for the new secret. You will be able to
        reference this secret later in the automation pipeline as{' '}
        <code>{`{{secrets.${name || 'SECRET_NAME'}}}`}</code>.
      </p>
      <p>
        You will <b>never</b> be able to see the value of this secret again, but
        you can always update it later with the new value.
      </p>
      <TextInput
        validator={REGEXP_SECRET_NAME}
        label="Secret name"
        value={name}
        onChange={nameChange}
        wide
      />
      <TextInput
        validator={REGEXP_SECRET_VALUE}
        textarea
        label="Secret value"
        value={value}
        onChange={valueChange}
        wide
      />
    </div>
  );
};

export const showCreatePropertySecretDialog = ({
  property,
  onCreate,
}: Props) => {
  let newName = '';
  let newValue = '';

  showDialog({
    title: `Create secret in ${property.host}`,
    icon: 'add',
    content: (
      <CreatePropertySecret
        onValueUpdate={(v) => (newValue = v)}
        onNameUpdate={(v) => (newName = v)}
      />
    ),
    confirmButtonText: 'Create',
    cancelButtonText: 'Cancel',
    onConfirmClick: async () => {
      if (!newValue || !newName) {
        return false;
      }

      const response = await propertyUpdateSecret(property.id, {
        name: newName,
        value: newValue,
      });
      if (response.errorMessage) {
        new Toast(response.errorMessage, Toast.TYPE_ERROR);
        return false;
      } else {
        new Toast(`Property secret "${newName}" is created.`, Toast.TYPE_DONE);
        onCreate?.();
      }
    },
  });
};
