import {
  SubscriptionState,
  UiBillingPlan,
  UiProperty,
  UiSubscription,
} from '@dataunlocker/pkg-types';
import { requestGetBillingPlan, requestListBillingPlans } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import PageLoading from 'Components/Common/Pages/Loading';
import Tooltip from 'Components/Common/Tooltip';
import { showNewBillingPlanDialog } from 'Components/Dialog/NewBillingPlan';
import { PRODUCT_NAME, PRODUCT_SUPPORT_EMAIL } from 'Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { currentUserState, propertiesListVersionState } from 'State';
import { Toast } from 'toaster-js';
import {
  formatBytes,
  getCurrentSubscription,
  getRedirectUrlForSubscription,
  useInterval,
} from 'Utils';
import { BillingPlanSelectCard } from './BillingPlanSelectCard';
import styles from './styles.module.scss';

export interface BillingPlanSelectProps {
  property: UiProperty;
}

const NEW_SUBSCRIPTION_POLL_INTERVAL = 1000 * 2;

const BillingPlanSelect = ({ property }: BillingPlanSelectProps) => {
  const userLoadable = useRecoilValueLoadable(currentUserState);
  const setPropertiesListVersion = useSetRecoilState(
    propertiesListVersionState
  );
  const user = userLoadable.state === 'hasValue' ? userLoadable.contents : null;
  const visibleCards = Array.from(
    new Set(Object.values(user?.cards || {}).map((card) => card.number))
  );

  const [plans, setPlans] = useState<UiBillingPlan[]>([]);
  const [currenSubscription, setCurrentSubscription] =
    useState<UiSubscription | null>(null);
  const [currentPlan, setCurrentPlan] = useState<UiBillingPlan | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<UiBillingPlan | null>(null);
  const [isPoll, setIsPoll] = useState(false);
  const [
    isAwaitingRedirectToProviderPage,
    setIsAwaitingRedirectToProviderPage,
  ] = useState(false);

  useEffect(() => {
    (async () => {
      const { errorMessage, response } = await requestListBillingPlans();
      if (errorMessage || !response) {
        new Toast(errorMessage, Toast.TYPE_ERROR);
        return;
      }
      setPlans(response.sort((a, b) => a.price.value - b.price.value));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { errorMessage, subscription } = await getCurrentSubscription({
        property,
      });
      if (errorMessage) {
        new Toast(errorMessage, Toast.TYPE_ERROR);
        return;
      }
      if (
        subscription &&
        subscription.state !== SubscriptionState.active &&
        subscription.state !== SubscriptionState.failed
      ) {
        setIsPoll(true);
      }
      setCurrentSubscription(subscription);
    })();
  }, [property]);

  useEffect(() => {
    (async () => {
      if (!currenSubscription) {
        return;
      }
      const { errorMessage, response } = await requestGetBillingPlan({
        id: currenSubscription.billingPlanId,
      });
      if (errorMessage || !response) {
        new Toast(errorMessage, Toast.TYPE_ERROR);
        return;
      }
      setCurrentPlan(response.billingPlan);
      setSelectedPlan(response.billingPlan);
    })();
  }, [currenSubscription]);

  const refreshSubscription = useCallback(async () => {
    const { subscription, errorMessage } = await getCurrentSubscription({
      property,
    });
    if (errorMessage || !subscription) {
      new Toast(errorMessage, Toast.TYPE_ERROR);
      return;
    }
    if (
      subscription.state === SubscriptionState.active ||
      subscription.state === SubscriptionState.failed
    ) {
      setIsPoll(false);
      setIsAwaitingRedirectToProviderPage(false);
      setCurrentSubscription(subscription);
      setPropertiesListVersion(Math.random());
      return;
    }
    if (!isAwaitingRedirectToProviderPage) {
      return;
    }
    const { redirectUrl } = await getRedirectUrlForSubscription({
      subscription: subscription,
    });
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [isAwaitingRedirectToProviderPage, property, setPropertiesListVersion]);

  useInterval(
    refreshSubscription,
    isPoll ? NEW_SUBSCRIPTION_POLL_INTERVAL : null
  );

  const onCurrentPlanChange = useCallback(
    (newCurrentPlan: UiBillingPlan) => {
      const contactSupport = `if the error persists please contact ${PRODUCT_SUPPORT_EMAIL}`;
      if (newCurrentPlan.id === currentPlan?.id) {
        new Toast(
          `You can not subscribe to your current billing plan, ${contactSupport}`,
          Toast.TYPE_ERROR
        );
        return;
      }
      showNewBillingPlanDialog({
        property,
        newPlan: newCurrentPlan,
        visibleCards,
        // TODO: synchronize purchases with 'Traffic transactions' card. New purchases should be added to 'Traffic transactions' ASAP.
        newSubscriptionAdded: async ({ subscription }) => {
          if (!subscription.billingMethod) {
            setIsAwaitingRedirectToProviderPage(true);
          }
          setIsPoll(true);
          await refreshSubscription(); // request redirectUrl immediately
        },
      });
    },
    [currentPlan?.id, property, refreshSubscription, visibleCards]
  );

  const onSelectedPlanChange = useCallback((newSelectedPlan: UiBillingPlan) => {
    setSelectedPlan(newSelectedPlan);
  }, []);

  const [expanded, setExpanded] = useState(true);

  const isLoading = !currentPlan || !plans.length || !selectedPlan;

  return isLoading ? (
    <PageLoading />
  ) : (
    <Card
      onClick={() => {
        if (!expanded) setExpanded(!expanded);
      }}
      className={expanded ? '' : styles.hoverable}
    >
      <div
        onClick={() => {
          if (expanded) setExpanded(!expanded);
        }}
        className={expanded ? styles.pointer : ''}
      >
        <CardHeader
          title={
            <>
              <Icon hoverable size="big" image={expanded ? 'up' : 'down'} />
              <span>
                Current plan:
                <span className={styles.textInfo}>
                  {currentPlan.name.toUpperCase()}
                </span>
                {currenSubscription && (
                  <span className={styles.subscriptionInfo}>
                    State: {currenSubscription.state} | Next Charge:{' '}
                    {new Date(currenSubscription.nextChargeAt).toLocaleString()}
                  </span>
                )}
                {currenSubscription?.state ===
                  SubscriptionState.awaitingPurchase}
                <Tooltip
                  content={
                    <span>
                      {PRODUCT_NAME} provides different billing plans. Choose
                      which suits you best.
                    </span>
                  }
                  type="help"
                />
              </span>
            </>
          }
        >
          <span className={styles.balance}>
            Balance:{' '}
            <span className={styles.textInfo}>
              {formatBytes(property.bytesPrepaid - property.bytesProxied)}
            </span>
            <Tooltip
              content={
                <span>
                  Prepaid traffic for <b>{property.host}</b>.
                </span>
              }
              type="help"
            />
          </span>
        </CardHeader>
      </div>
      {expanded &&
        plans.forEach((billingPlan, billingPlanIndex) => (
          <BillingPlanSelectCard
            billingPlan={billingPlan}
            billingPlanIndex={billingPlanIndex}
            currentPlan={currentPlan}
            selectedPlan={selectedPlan}
            onCurrentPlanChange={onCurrentPlanChange}
            onSelectedPlanChange={onSelectedPlanChange}
          />
        ))}
    </Card>
  );
};

export default BillingPlanSelect;
