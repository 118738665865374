import { atom, selector, noWait } from 'recoil';
import { UiProperty, UiTempProperty } from '@dataunlocker/pkg-types';
import {
  REVERSE_ROUTE_PROPERTY,
  ApiAdminV1PropertiesStatsResponse,
  ApiAdminV1PropertiesStatsQuotaResponse,
  INTERVAL_1D,
} from 'Constants';
import { sessionTokenState } from './auth';
import {
  getPropertiesList,
  getPropertyStats,
  getPropertyStatsQuota,
} from 'Api';

export const selectedPropertyIdState = atom<string>({
  key: 'selectedPropertyId',
  default: REVERSE_ROUTE_PROPERTY(window.location.pathname),
});

export const propertiesListVersionState = atom<number>({
  key: 'propertiesListVersion',
  default: 1,
});

let allOldProps = { properties: [], tempProperties: [] } as {
  properties: UiProperty[];
  tempProperties: UiTempProperty[];
};
export const myAllPropertiesState = selector<{
  properties: UiProperty[];
  tempProperties: UiTempProperty[];
}>({
  key: 'myAllProperties',
  get: async ({ get }) => {
    try {
      // Dependencies
      get(sessionTokenState);
      get(propertiesListVersionState);

      allOldProps = await getPropertiesList();
    } catch (e) {
      //
    }
    return allOldProps;
  },
});

let oldProps = [] as UiProperty[];
export const myPropertiesState = selector<UiProperty[]>({
  key: 'myProperties',
  get: async ({ get }) => {
    // get() throws until promise is resolved, and then the function is invoked again with the returned value.
    try {
      const { properties } = get(myAllPropertiesState);
      oldProps = properties;
      return properties;
    } catch (e) {
      return oldProps;
    }
  },
});

let oldTempProps = [] as UiTempProperty[];
export const myTempPropertiesState = selector<UiTempProperty[]>({
  key: 'myTempProperties',
  get: async ({ get }) => {
    // get() throws until promise is resolved, and then the function is invoked again with the returned value.
    try {
      const { tempProperties } = get(myAllPropertiesState);
      oldTempProps = tempProperties;
      return tempProperties;
    } catch (e) {
      return oldTempProps;
    }
  },
});

const cachedProperties: Map<string, UiProperty | null> = new Map(); // propertyId => property
export const selectedPropertyState = selector<UiProperty | null>({
  key: 'selectedProperty',
  get: ({ get }) => {
    const selectedPropertyId = get(selectedPropertyIdState);
    let myProperties: UiProperty[] = [];
    try {
      myProperties = get(myPropertiesState);
    } catch (e) {
      return cachedProperties.get(selectedPropertyId) || null;
    }

    const property =
      myProperties.find((property) => property.id === selectedPropertyId) ||
      null;
    cachedProperties.set(selectedPropertyId, property);
    return property;
  },
});

const cachedTempProperties: Map<string, UiTempProperty | null> = new Map(); // propertyId => property
export const selectedTempPropertyState = selector<UiTempProperty | null>({
  key: 'selectedTempProperty',
  get: ({ get }) => {
    const selectedTempPropertyId = get(selectedPropertyIdState);
    let myTempProperties: UiTempProperty[] = [];
    try {
      myTempProperties = get(myTempPropertiesState);
    } catch (e) {
      return cachedTempProperties.get(selectedTempPropertyId) || null;
    }

    const tempProperty =
      myTempProperties.find(
        (property) => property.id === selectedTempPropertyId
      ) || null;
    cachedTempProperties.set(selectedTempPropertyId, tempProperty);
    return tempProperty;
  },
});

export const selectedPropertyDashboardIntervalState = atom<number>({
  key: 'selectedPropertyDashboardInterval',
  default: INTERVAL_1D,
});

export const selectedPropertyStatsVersionState = atom<number>({
  key: 'selectedPropertyStatsVersion',
  default: 0,
});

export const selectedPropertyStatsState = selector<ApiAdminV1PropertiesStatsResponse>(
  {
    key: 'selectedPropertyStats',
    get: async ({ get }) => {
      const selectedPropertyId = get(selectedPropertyIdState);
      const timeRange = get(selectedPropertyDashboardIntervalState);
      get(selectedPropertyStatsVersionState); // Dependency

      const now = Date.now();
      const response = await getPropertyStats(selectedPropertyId, {
        from: now - timeRange,
        to: now,
      });
      if (response.statusCode === 404) {
        const nullResponse: ApiAdminV1PropertiesStatsResponse = {
          propertyId: selectedPropertyId,
          seriesEnd: Date.now(),
          seriesStart: 0,
          seriesSteps: 0,
          serviceData: [],
        };
        return nullResponse;
      }
      if (response.errorMessage) {
        throw new Error(
          `Unable to get property stats: ${response.errorMessage}`
        );
      }
      return response.response!;
    },
  }
);

let previousStatsSyncValue: ApiAdminV1PropertiesStatsResponse | null = null;
export const selectedPropertyStatsSyncState = selector<{
  stats: ApiAdminV1PropertiesStatsResponse | null;
  loading: boolean;
  error?: Error;
}>({
  key: 'selectedPropertyStatsSync',
  get: ({ get }) => {
    const loadable = get(noWait(selectedPropertyStatsState));
    return {
      stats: (previousStatsSyncValue =
        loadable.state === 'hasValue'
          ? loadable.contents
          : previousStatsSyncValue),
      loading: loadable.state === 'loading',
      error: loadable.state === 'hasError' ? loadable.contents : undefined,
    };
  },
});

export const selectedPropertyStatsQuotaVersionState = atom<number>({
  key: 'selectedPropertyStatsQuotaVersion',
  default: 0,
});

export const selectedPropertyStatsQuotaState = selector<ApiAdminV1PropertiesStatsQuotaResponse>(
  {
    key: 'selectedPropertyStatsQuota',
    get: async ({ get }) => {
      const selectedPropertyId = get(selectedPropertyIdState);
      const timeRange = get(selectedPropertyDashboardIntervalState);
      get(selectedPropertyStatsQuotaVersionState); // Dependency

      const now = Date.now();
      const response = await getPropertyStatsQuota(selectedPropertyId, {
        from: now - timeRange,
      });
      if (response.statusCode === 404) {
        const nullResponse: ApiAdminV1PropertiesStatsQuotaResponse = {
          propertyId: selectedPropertyId,
          seriesEnd: Date.now(),
          seriesStart: 0,
          seriesSteps: 0,
          series: [],
        };
        return nullResponse;
      }
      if (response.errorMessage) {
        throw new Error(
          `Unable to get property stats: ${response.errorMessage}`
        );
      }
      return response.response!;
    },
  }
);

let previousStatsQuotaSyncValue: ApiAdminV1PropertiesStatsQuotaResponse | null = null;
export const selectedPropertyStatsQuotaSyncState = selector<{
  stats: ApiAdminV1PropertiesStatsQuotaResponse | null;
  loading: boolean;
  error?: Error;
}>({
  key: 'selectedPropertyStatsQuotaSync',
  get: ({ get }) => {
    const loadable = get(noWait(selectedPropertyStatsQuotaState));
    return {
      stats: (previousStatsQuotaSyncValue =
        loadable.state === 'hasValue'
          ? loadable.contents
          : previousStatsQuotaSyncValue),
      loading: loadable.state === 'loading',
      error: loadable.state === 'hasError' ? loadable.contents : undefined,
    };
  },
});
