import { UiBillingPlan } from '@dataunlocker/pkg-types';

export const API_PATH_ADMIN_V1_BILLING_PLANS = '/billing-plans';
export type ApiAdminV1BillingPlansListResponse = UiBillingPlan[];

export interface SuperadminEditableBillingPlan
  extends Omit<UiBillingPlan, 'id' | 'createdAt' | 'updatedAt'> {}

export type ApiAdminBillingPlansAddRequest = SuperadminEditableBillingPlan;

export interface ApiAdminBillingPlansAddResponse {
  billingPlan: UiBillingPlan;
}

export const API_PATH_ADMIN_V1_BILLING_PLANS_ID = (id: string) =>
  `${API_PATH_ADMIN_V1_BILLING_PLANS}/${id}`;
export type ApiAdminBillingPlansUpdateRequest = SuperadminEditableBillingPlan;
export interface ApiAdminBillingPlansUpdateResponse {
  billingPlan: UiBillingPlan;
}

export interface ApiAdminBillingPlansIdGetRequest {}
export interface ApiAdminBillingPlansIdGetResponse {
  billingPlan: UiBillingPlan;
}
