import MessageBlock from 'Components/Common/MessageBlock';
import PipelineStep, {
  PipelineStepCommonProps,
} from 'Components/Pages/Properties/Settings/Automation/Common/PipelineStep';
import React from 'react';
import { getProxyEndpointUrl } from 'Utils';

const PipelineStepPickProxyEndpointToReplace = (
  props: PipelineStepCommonProps
) => {
  const proxyEndpoint = props.property.proxyEndpoints.find(
    (e) => props.pipeline.context.oldProxyEndpointUrlKey === e.urlKey
  );

  return (
    <PipelineStep
      icon="split"
      title="Pick the proxy endpoint to replace"
      {...props}
    >
      {props.pipeline.context.oldProxyEndpointUrlKey ? (
        <MessageBlock display="inline">
          Picked proxy endpoint to replace:{' '}
          {proxyEndpoint
            ? getProxyEndpointUrl(proxyEndpoint, props.property)
            : props.pipeline.context.oldProxyEndpointUrlKey}
        </MessageBlock>
      ) : (
        <MessageBlock display="inline">
          At this step the pipeline will determine which proxy endpoint to
          replace. The following priority will be used to determine the proxy
          endpoint: serving and blacklisted proxy endpoint, proxy endpoint
          recently receiving traffic, the latest blacklisted proxy endpoint, the
          latest created proxy endpoint.
        </MessageBlock>
      )}
    </PipelineStep>
  );
};

export default PipelineStepPickProxyEndpointToReplace;
