import React from 'react';
import { propertyRetryBilling } from 'Api';
import { showDialog } from 'Components/Dialog';
import { Toast } from 'toaster-js';
import { PRODUCT_NAME } from 'Constants';

const RetryPropertyBillingDialog = () => {
  return (
    <div>
      <p>
        When automatic charge fails (for instance, when your card balance is
        low), the billed property may not be charged anymore until you manually
        retry the charge.
      </p>
      <p>
        After retrying, allow for 1 minute for {PRODUCT_NAME} to try to charge
        your card again. You will see a new transaction.
      </p>
    </div>
  );
};

export const showRetryPropertyBillingDialog = (
  propertyId: string,
  onComplete?: () => void
) =>
  showDialog({
    title: `Retry automatic card charge attempt?`,
    icon: 'money',
    content: <RetryPropertyBillingDialog />,
    confirmButtonText: 'Retry',
    cancelButtonText: 'Close',
    onConfirmClick: async () => {
      const retrial = await propertyRetryBilling(propertyId);
      if (retrial.errorMessage) {
        new Toast(retrial.errorMessage, Toast.TYPE_ERROR);
      } else {
        new Toast(
          `Retry successful. You will see a new automatic transaction soon.`,
          Toast.TYPE_DONE
        );
        if (onComplete) {
          onComplete();
        }
      }
    },
  });
