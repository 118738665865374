import { PropertyProxyEndpoint, UiTempProperty } from '@dataunlocker/pkg-types';
import { getHostPathForEndpoint } from '@dataunlocker/pkg-utils';
import Icon from 'Components/Common/Icon';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import {
  LINK_MXTOOLBOX_CNAME_LOOKUP_FOR,
  PRODUCT_NAME,
  PRODUCT_SUPPORT_EMAIL,
} from 'Constants';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

const isEndpointBlacklisted = (endpoint: PropertyProxyEndpoint) =>
  endpoint.blacklistedIn && endpoint.blacklistedIn.length > 0;

export const getTlsStateIcon = ({
  endpoint,
}: {
  endpoint: PropertyProxyEndpoint;
}) => (
  <Icon
    image={
      endpoint.editable
        ? 'clock'
        : endpoint.health.cert === 'ok'
        ? 'padlockClosed'
        : 'padlockOpened'
    }
    className={`${styles.lockIcon} ${
      endpoint.editable
        ? ''
        : endpoint.health.cert === 'ok'
        ? styles.green
        : styles.red
    }${endpoint.health.cert === 'pending' ? ` ${styles.greenBlinking}` : ''}`}
    margin="right-small"
  />
);

export const getEndpointShortState = ({
  endpoint,
  property,
}: {
  endpoint: PropertyProxyEndpoint;
  property: UiTempProperty;
}) => (
  <>
    <Icon
      image={
        endpoint.editable
          ? 'setup'
          : isEndpointBlacklisted(endpoint)
          ? 'trash'
          : endpoint.health.ok
          ? 'checkmark'
          : endpoint.health.cert === 'pending'
          ? 'loading'
          : 'warning'
      }
      margin="right-small"
    />
    <span>
      {endpoint.editable
        ? 'Waiting'
        : endpoint.health.cert === 'pending'
        ? 'Working'
        : isEndpointBlacklisted(endpoint)
        ? 'Blacklisted'
        : property.health.scriptProxyEndpointUrlKey === endpoint.urlKey
        ? 'Serving'
        : endpoint.health.ok
        ? 'Ready'
        : 'Unhealthy'}
    </span>
  </>
);

export const getEndpointExtendedState = ({
  endpoint,
  property,
  shortEndpointState = getEndpointShortState({ endpoint, property }),
}: {
  endpoint: PropertyProxyEndpoint;
  property: UiTempProperty;
  shortEndpointState?: ReactNode;
}) => {
  const isBlacklisted = isEndpointBlacklisted(endpoint);
  const expectedHostPath = getHostPathForEndpoint(endpoint, property);
  const expectedHost = expectedHostPath.split('/')[0];

  const testItYourselfComponent = (
    <span>
      Test it yourself using{' '}
      <UnsafeExternalLink
        href={`http://${expectedHostPath}/health-check?q=123`}
      >
        this echo endpoint
      </UnsafeExternalLink>{' '}
      our test client actually requests.
    </span>
  );
  return (
    <>
      <div
        className={`${styles.shortEndpointStateBox}${
          isBlacklisted ? ` ${styles.red}` : ''
        }`}
      >
        <div
          className={
            endpoint.health.ok && !endpoint.blacklistedIn?.length
              ? styles.green
              : styles.red
          }
        >
          {shortEndpointState}
        </div>
        {isBlacklisted && <div className={styles.tiny}>Use a new endpoint</div>}
      </div>
      {isBlacklisted ? (
        <ul className={styles.ulBlacklisted}>
          {endpoint.blacklistedIn!.map(({ url, name, at }, i) => (
            <li key={i}>
              <div>
                Found in{' '}
                <UnsafeExternalLink href={url}>{name}</UnsafeExternalLink>
              </div>
              {at ? (
                <div className={styles.tiny}>
                  {' '}
                  Found at {new Date(at).toLocaleString()}
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      ) : endpoint.health.ok ? (
        ''
      ) : (
        <ul>
          {endpoint.health.cert === 'pending' && (
            <li>Waiting for {PRODUCT_NAME} to generate TLS certificate.</li>
          )}
          {(!endpoint.health.dnsFound || !endpoint.health.dnsOk) && (
            <li>
              Waiting for you to add a valid DNS record. You can check its
              presence using{' '}
              <SafeExternalLink
                href={LINK_MXTOOLBOX_CNAME_LOOKUP_FOR(expectedHost)}
              >
                this tool
              </SafeExternalLink>
              . It takes minutes to hours for new DNS records to become
              available.
            </li>
          )}
          {endpoint.health.dnsTo0 && (
            <li>
              This DNS endpoint is blacklisted by {PRODUCT_NAME}. Please,
              contact {PRODUCT_SUPPORT_EMAIL}.
            </li>
          )}
          {!endpoint.health.pathOk &&
            (!endpoint.health.pathBody &&
            !endpoint.health.pathHeader &&
            !endpoint.health.pathIp &&
            !endpoint.health.pathMethod &&
            !endpoint.health.pathPrefix &&
            !endpoint.health.pathUrl ? (
              <li>
                A test HTTP request to this endpoint has failed to reach{' '}
                {PRODUCT_NAME} servers. {testItYourselfComponent}
              </li>
            ) : (
              <>
                <span>
                  Our test client issued a test request to this endpoint, which
                  got the problems listed below. {testItYourselfComponent}
                </span>
                <ul>
                  {!endpoint.health.pathBody && (
                    <li>
                      The request body is not what we&apos;ve sent. Your proxy
                      should preserve the body even if no{' '}
                      <code>Content-Length</code> header is given.
                    </li>
                  )}
                  {!endpoint.health.pathHeader && (
                    <li>Custom request headers are missing.</li>
                  )}
                  {!endpoint.health.pathIp && (
                    <li>
                      The IP of the client (or &quot;x-forwarded-for&quot;
                      header) doesn&apos;t match the IP of our test client.
                    </li>
                  )}
                  {!endpoint.health.pathMethod && (
                    <li>Request method is not POST.</li>
                  )}
                  {!endpoint.health.pathPrefix && (
                    <li>
                      The proxied {endpoint.type === 'dns' ? 'host' : 'path'}{' '}
                      URL should start with{' '}
                      {endpoint.type === 'path' ? '/' : ''}
                      {endpoint.urlKey}.
                    </li>
                  )}
                  {!endpoint.health.pathUrl && (
                    <li>
                      The proxied path or query parameters are unexpected.
                    </li>
                  )}
                </ul>
              </>
            ))}
        </ul>
      )}
    </>
  );
};
