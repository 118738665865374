export const formatBytes = (bytes: number, decimals = 2) => {
  let sign = '';
  if (bytes === 0) return `${bytes.toFixed(decimals)} B`;
  if (bytes < 0) {
    bytes = -bytes;
    sign = '-';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    sign +
    (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + (sizes[i] || ''))
  );
};

export const formatUsdCents = (value: number, decimals = 2, short = false) =>
  `${short ? '$' : ''}${(
    Math.floor(value * Math.pow(10, decimals)) /
    (100 * Math.pow(10, decimals))
  ).toFixed(decimals)}${short ? '' : ' USD'}`;

export const formatPercentage = (value: number, decimals = 2) =>
  `${(value * 100).toFixed(decimals)}%`;

export const toQueryString = (values: Object): string => {
  return Object.entries(values)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
};
