import { PropertyProxyEndpoint, UiTempProperty } from '@dataunlocker/pkg-types';
import MessageBlock from 'Components/Common/MessageBlock';
import { showDialog } from 'Components/Dialog';
import { PRODUCT_NAME } from 'Constants';
import React from 'react';
import relativeDate from 'relative-date';
import { getProxyEndpointUrl } from 'Utils';

interface DeleteCardDialogProps {
  property: UiTempProperty;
  endpoint: PropertyProxyEndpoint;
  onDelete?: () => void;
}

const DeleteProxyEndpoint = ({
  endpoint,
  property,
}: Pick<DeleteCardDialogProps, 'endpoint' | 'property'>) => {
  return (
    <div>
      <p>
        This action is permanent. You are about to delete the following proxy
        endpoint from {PRODUCT_NAME}:
      </p>
      <ul>
        <li>{getProxyEndpointUrl(endpoint, property)}</li>
      </ul>
      {!!endpoint.lastTrafficAt &&
        endpoint.lastTrafficAt > Date.now() - 10 * 60 * 1000 && (
          <MessageBlock type="warning">
            This endpoint received some traffic{' '}
            <b>{relativeDate(endpoint.lastTrafficAt)}</b> and will likely
            continue to receive traffic due to the browser&apos;s caching or
            people not closing browser tabs. You may need to wait for a few days
            until it fully stops receiving traffic.
          </MessageBlock>
        )}
    </div>
  );
};

export const showDeleteProxyEndpointDialog = ({
  endpoint,
  property,
  onDelete,
}: DeleteCardDialogProps) =>
  showDialog({
    title: `Delete this proxy endpoint?`,
    icon: 'trash',
    content: <DeleteProxyEndpoint endpoint={endpoint} property={property} />,
    confirmButtonText: 'Delete',
    confirmButtonClass: 'danger',
    cancelButtonText: 'Cancel',
    onConfirmClick: onDelete,
  });
