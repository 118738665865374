import React from 'react';

import styles from './styles.module.scss';

interface TabsSwitchProps {
  tabs: {
    title: string;
  }[];
  selectedIndex: number;
  onSelectedIndexChange: (index: number) => any;
}

const TabsSwitch = ({
  tabs,
  selectedIndex,
  onSelectedIndexChange,
}: TabsSwitchProps) => {
  return (
    <div className={styles.container}>
      {tabs.map(({ title }, index) => (
        <div
          key={index}
          className={selectedIndex === index ? styles.selected : ''}
          onClick={() => onSelectedIndexChange(index)}
        >
          <div className={styles.stabilizer}>
            <span>{title}</span>
            <div>{title}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TabsSwitch;
