import React, { ReactNode } from 'react';

/* eslint-disable react/jsx-no-target-blank */

/**
 * Safe link to own external websites only.
 * Use to link DataUnlocker-owned resources and websites (to preserve "referer" header).
 * @see https://stackoverflow.com/a/67253020/2590150
 */
const SafeExternalLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => (
  <a href={href} target="_blank">
    {children}
  </a>
);

export default SafeExternalLink;
