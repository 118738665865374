import { UiProperty } from '@dataunlocker/pkg-types';
import { requestOneTimePurchase } from 'Api';
import Card, { CardHeader } from 'Components/Common/Card';
import Icon from 'Components/Common/Icon';
import SafeExternalLink from 'Components/Common/SafeExternalLink';
import SelectInput from 'Components/Common/SelectInput';
import Tooltip from 'Components/Common/Tooltip';
import UnsafeExternalLink from 'Components/Common/UnsafeExternalLink';
import {
  LINK_DOCS_BILLING_ONE_TIME_PURCHASES,
  PRODUCT_SUPPORT_EMAIL,
} from 'Constants';
import React, { useCallback, useState } from 'react';
import { Toast } from 'toaster-js';
import {
  formatBytes,
  formatUsdCents,
  getBytesFromPaidCentsForProperty,
} from 'Utils';
import styles from './styles.module.scss';

interface PurchaseTrafficProps {
  property: UiProperty;
}

const PURCHASE_TRAFFIC_OPTIONS = [1, 5, 10, 25, 50, 100];

const PurchaseTraffic = ({ property }: PurchaseTrafficProps) => {
  const [selectedValue, setSelectedValue] = useState(1);
  const [purchaseIsLoading, setPurchaseLoading] = useState(false);
  const [customTrafficPurchaseValue, setCustomTrafficPurchaseValue] = useState(
    '9.99'
  );
  const purchaseCentsValue =
    selectedValue === 0
      ? +customTrafficPurchaseValue * 100
      : selectedValue * 100;
  const purchaseValueValid =
    !isNaN(purchaseCentsValue) && purchaseCentsValue >= 100;
  const handleSelectedOptionChange = useCallback((value) => {
    setSelectedValue(+value);
  }, []);
  const handleCustomValueInputChange = useCallback((event) => {
    setCustomTrafficPurchaseValue(event.target.value);
  }, []);
  const handlePurchaseButtonClick = useCallback(async () => {
    setPurchaseLoading(true);
    const purchase = await requestOneTimePurchase({
      propertyId: property.id,
      cents: Math.floor(purchaseCentsValue),
    });
    setPurchaseLoading(false);
    if (purchase.errorMessage || !purchase.response) {
      new Toast(
        `Unable to perform this purchase: ${purchase.errorMessage}`,
        Toast.TYPE_ERROR
      );
      return;
    }
    if (purchase.response?.purchase.redirectUrl) {
      window.location.href = purchase.response.purchase.redirectUrl;
    } else {
      new Toast(
        'A purchase was created, however, no redirectUrl is present. Please report this incident',
        Toast.TYPE_ERROR
      );
      return;
    }
  }, [property.id, purchaseCentsValue]);

  return (
    <Card>
      <CardHeader
        title={
          <span>
            <span>One-time traffic purchase</span>
            <Tooltip
              type="help"
              content={
                <span>
                  You can purchase more traffic using one-time purchase. By
                  clicking &quot;Purchase Traffic&quot;, you will be redirected
                  to our payment provider&apos;s page.{' '}
                  <SafeExternalLink href={LINK_DOCS_BILLING_ONE_TIME_PURCHASES}>
                    Learn more about one-time purchases
                  </SafeExternalLink>
                </span>
              }
            />
          </span>
        }
      />
      <div style={{ display: 'flex' }}>
        <SelectInput
          inline
          wide
          value={selectedValue}
          options={PURCHASE_TRAFFIC_OPTIONS.map((usd) => ({
            value: usd,
            label: `${formatBytes(
              getBytesFromPaidCentsForProperty(property, usd * 100)
            )} (${formatUsdCents(usd * 100)})`,
          })).concat({ value: 0, label: 'custom' })}
          onChange={handleSelectedOptionChange}
        />
        <button
          disabled={purchaseIsLoading || !purchaseValueValid}
          onClick={handlePurchaseButtonClick}
        >
          Purchase Traffic
        </button>
      </div>
      {selectedValue === 0 && (
        <div className={styles.customInput}>
          <input
            className={styles.evenInputsWidth}
            placeholder="Custom value in USD"
            type="number"
            value={customTrafficPurchaseValue}
            onChange={handleCustomValueInputChange}
          />
          <span className={styles.customValueText}>
            <b>USD</b> ={' '}
            {formatBytes(
              getBytesFromPaidCentsForProperty(
                property,
                +customTrafficPurchaseValue * 100
              )
            )}
          </span>
        </div>
      )}
      <div className={`${styles.infoText}`}>
        <Icon image="info" size="small" margin="right-small" />{' '}
        <div>This purchase is effective immediately after the payment.</div>
      </div>
      <div className={`${styles.infoText}`}>
        <Icon image="info" size="small" margin="right-small" />{' '}
        <div>
          {/* TODO: add refund policies */}
          You will be charged in UAH equivalent to the specified USD value.
        </div>
      </div>
      <div className={`${styles.infoText}`}>
        <Icon image="info" size="small" margin="right-small" />{' '}
        <div>
          Contact{' '}
          <UnsafeExternalLink
            href={`mailto:${PRODUCT_SUPPORT_EMAIL}?subject=Payment%20questions`}
          >
            {PRODUCT_SUPPORT_EMAIL}
          </UnsafeExternalLink>{' '}
          in case of any questions.
        </div>
      </div>
      {selectedValue === 0 && (
        <div className={`${styles.infoText} ${styles.infoColor}`}>
          <Icon image="info" size="small" margin="right-small" />{' '}
          <div>The minimum value is $1.</div>
        </div>
      )}
    </Card>
  );
};

export default PurchaseTraffic;
