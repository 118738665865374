import React from 'react';
import { useRecoilValueLoadable, currentUserState } from 'State';
import { requestDeleteCard } from 'Api';
import { showDialog } from 'Components/Dialog';
import { Toast } from 'toaster-js';
import { PRODUCT_NAME } from 'Constants';

interface DeleteCardDialogProps {
  cardNumber: string;
}

const DeleteCardDialog = ({ cardNumber }: DeleteCardDialogProps) => {
  const userLoadable = useRecoilValueLoadable(currentUserState);

  return (
    <div>
      <p>
        You are about to delete card {cardNumber} from you account
        <b>
          {userLoadable.state === 'hasValue'
            ? ' ' + userLoadable.contents?.email
            : ''}
        </b>
        . Thus, you won&apos;t be able to use this card anymore on{' '}
        {PRODUCT_NAME}. If this card is used for automatic traffic purchases in
        any property, please, remove this card from the property&apos;s billing
        first.
      </p>
      <p>You will be able to add this or another card again at any time.</p>
    </div>
  );
};

export const showDeleteCardDialog = (
  cardNumber: string,
  onComplete?: () => void
) =>
  showDialog({
    title: `Delete card ${cardNumber}?`,
    icon: 'money',
    content: <DeleteCardDialog cardNumber={cardNumber} />,
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel',
    onConfirmClick: async () => {
      const cardAddRequest = await requestDeleteCard(cardNumber);
      if (cardAddRequest.errorMessage) {
        new Toast(cardAddRequest.errorMessage, Toast.TYPE_ERROR);
      } else {
        new Toast(`The card ${cardNumber} was deleted.`, Toast.TYPE_DONE);
        if (onComplete) {
          onComplete();
        }
      }
    },
  });
