import { UiProperty, UiUser } from '@dataunlocker/pkg-types';
import PageError from 'Components/Common/Pages/Error';
import PageLoading from 'Components/Common/Pages/Loading';
import PagePropertyDoesNotExist from 'Components/Pages/Properties/NotFound';
import React, { FunctionComponent } from 'react';
import {
  currentUserState,
  selectedPropertyState,
  selectedTempPropertyState,
  useRecoilValueLoadable,
} from 'State';

interface Props {
  Page: FunctionComponent<{
    property: UiProperty;
    user: UiUser;
  }>;
}

const PageWithPropertyAndUser = ({ Page }: Props) => {
  const tempPropertyLoadable = useRecoilValueLoadable(
    selectedTempPropertyState
  );
  const propertyLoadable = useRecoilValueLoadable(selectedPropertyState);
  const currentUserLoadable = useRecoilValueLoadable(currentUserState);
  const propertyState =
    propertyLoadable.state === 'hasValue' ? propertyLoadable.contents : null;
  const tempPropertyState =
    tempPropertyLoadable.state === 'hasValue'
      ? tempPropertyLoadable.contents
      : null;
  const user =
    currentUserLoadable.state === 'hasValue'
      ? currentUserLoadable.contents
      : null;
  const isLoading =
    propertyLoadable.state === 'loading' ||
    tempPropertyLoadable.state === 'loading' ||
    currentUserLoadable.state === 'loading';
  const property = tempPropertyState || propertyState;
  const isError =
    propertyLoadable.state === 'hasError' ||
    tempPropertyLoadable.state === 'hasError' ||
    currentUserLoadable.state === 'hasError';

  return isLoading ? (
    <PageLoading />
  ) : isError ? (
    <PageError message={`We've got a problem displaying this page :(`} />
  ) : !property || !user ? (
    <PagePropertyDoesNotExist />
  ) : (
    <Page key={property.id} property={property as UiProperty} user={user} />
  );
};

export default PageWithPropertyAndUser;
