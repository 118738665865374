import { UiTempProperty } from '@dataunlocker/pkg-types';
import Row from 'Components/Pages/Properties/Settings/Setup/Health/Box/Row';
import { PRODUCT_NAME } from 'Constants';
import React from 'react';

interface Props {
  property: UiTempProperty;
  loading: boolean;
}

const RowScriptIsFound = ({ property, loading }: Props) => {
  return (
    <Row
      ok={!!property.health.scriptVersion}
      inProgress={loading}
      rowElement={
        <span>
          {PRODUCT_NAME}&apos;s script is served on <b>{property.host}</b>{' '}
          {property.health.scriptVersion
            ? `(version ${property.health.scriptVersion})`
            : ''}
        </span>
      }
    >
      We tried to render and evaluate JavaScript at <b>{property.host}</b>,
      looking for {PRODUCT_NAME}&apos;s script on the page. It was not found.
      Make sure that{' '}
      {property.host.startsWith('www') ? (
        ''
      ) : (
        <span>
          <b>{property.host}</b> does not redirect users to any other domain
          (for example, <b>www</b>.{property.host}), and{' '}
        </span>
      )}
      <b>http</b>://
      {property.host} either serves your website or redirects to <b>https</b>://
      {property.host}. Please, also ensure you haven&apos;t broken the script
      with formatting. If <b>{property.host}</b> requires authentication or you
      didn&apos;t install the script in the root of this website please{' '}
      <b>ignore all</b> script-related health check results assuming
      they&apos;re fine.
    </Row>
  );
};

export default RowScriptIsFound;
