import React from 'react';
import Icon from 'Components/Common/Icon';

import styles from './styles.module.scss';

type IconProps = Parameters<typeof Icon>[0];
interface Props extends IconProps {
  text: string;
}

const IconWithSubtext = ({ text, ...iconProps }: Props) => {
  return (
    <span className={`${styles.container}`}>
      <span>
        <Icon {...iconProps} />
      </span>
      <span>{text}</span>
    </span>
  );
};

export default IconWithSubtext;
